import React from 'react';
import Wrapper from './styles';
import SportTreeLivebet from '../../tree/livebet/SportTreeLivebet';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';
import SportLobbyCategoriesV2 from '../../lobby-categories-v-2/SportLobbyCategoriesV2';
import { useScrollPosition } from '../../../../hooks/scroll/useScrollPosition';

export default function SportSidebarLive() {
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    const scroll = useScrollPosition();

    return (
        <Wrapper $scroll={scroll}>
            {isMainNavigationV2 && <SportLobbyCategoriesV2 />}
            <SportTreeLivebet />
        </Wrapper>
    );
}
