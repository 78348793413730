import React from 'react';
import PaymentCardFormModal from '../../../../card-form-modal/PaymentCardFormModal';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderNuveiCard({ amount, deviceHash, disclaimer, onClose }: Props) {
    return (
        <PaymentCardFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            isAllowedToGoOverMaxCardsLimit={true}
            isCvcRequiredForSavedCard={false}
            isIframe={true}
            provider={PROVIDERS.NUVEI_CARD}
            providerType={PROVIDER_TYPE.DEPOSIT}
            onClose={onClose}
        />
    );
}
