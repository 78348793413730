import React, { useState } from 'react';
import Wrapper from './styles';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import { FieldError, RegisterOptions, useFormContext } from 'react-hook-form';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';

interface Props extends React.HTMLProps<HTMLInputElement> {
    name: string;
    error?: FieldError;
    label?: string;
    dataTest?: string;
}
export default function Ui2FormCheckbox({
    name,
    label,
    defaultValue,
    error,
    dataTest,
    required = false,
    ...args
}: Props) {
    const { register } = useFormContext();
    const [inputValue, setInputValue] = useState(defaultValue);

    const options: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
        onChange: (event) => {
            setInputValue(event.target.checked);
        },
    };

    return (
        <Wrapper>
            <UiFormLabel>
                <input
                    type="checkbox"
                    {...register(name, options)}
                    name={name}
                    {...args}
                    checked={!!inputValue}
                    data-test={dataTest}
                />
                <span className="pseudo-checkbox" />
                {label && <span className="pseudo-label" dangerouslySetInnerHTML={{ __html: label }} />}
            </UiFormLabel>
            {error && <UiAlert failure>{error.message}</UiAlert>}
        </Wrapper>
    );
}
