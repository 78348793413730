import React, { useEffect } from 'react';
import AppPage from '../app/AppPage';
import { ThemeProvider } from 'styled-components';
import 'moment/locale/nb';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/et';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/is';
import 'moment-duration-format/lib/moment-duration-format';
import { stores } from '../../stores';
import { getToken, hasValidToken } from '../../services/token';
import { setTokenAndAuthenticate } from '../../services/auth';
import System from '../../components/system/System';
import { initSentry } from '../../services/sentry';
import { findLanguage, initLanguage } from '../../services/language';
import { getRoute, isActiveRoute, useRouter } from '../../services/router';
import { loadIpDetails } from '../../services/ip';
import { loadEnvironment, saveAvailableLicenes } from '../../services/environment';
import { loadFeatures } from '../../microservices/features';
import { useTheme } from '../../hooks/useTheme';
import { useStore } from '../../hooks/useStore';
import { logger } from '../../services/logger';
import { useBetslipShare } from '../../hooks/useBetslipSharing';
import MobileApp from '../../components/mobile-app/MobileApp';

export default function BootstrapPage() {
    const { history } = useRouter();
    const [isBootstrapped, setIsBootstrapped] = useStore(stores.isBootstrapped);
    const { handleBetslipShare } = useBetslipShare();
    // TODO: hide this for production bundle and use theme directly
    const theme = useTheme();

    useEffect(() => {
        bootstrap();
    }, []);

    async function bootstrap() {
        await Promise.all([loadEnvironment(), loadFeatures()]);
        initSentry();

        await loadIpDetails(window.userIp);
        saveAvailableLicenes();

        findLanguage();

        const pokerAuthRouteSuffixes = ['/auth-se', '/auth', '/auth-ee', '/auth-mx', '/auth-mt', '/auth-pe'];
        const isPokerAuthRoute = pokerAuthRouteSuffixes.some((suffix) =>
            isActiveRoute(`${getRoute('poker')}${suffix}`),
        );

        if (hasValidToken() && !isPokerAuthRoute) {
            try {
                await setTokenAndAuthenticate(getToken());
            } catch (error) {
                logger.error('BootstrapPage', 'bootstrap', error);
            }
        }

        await handleBetslipShare();
        await initLanguage(history);

        setIsBootstrapped(true);
    }

    return (
        <>
            <MobileApp />
            {theme && <ThemeProvider theme={theme}>{isBootstrapped && <AppPage />}</ThemeProvider>}
            {isBootstrapped && <System />}
        </>
    );
}
