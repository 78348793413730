import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { stores } from '../../stores';
import { clearBetslipSelection } from './betslip';
import omit from 'lodash/omit';
import { getStoreValue } from '../../stores/store/utils';
import { BetslipMode } from './types';

export function removeSelectionFromBetslip(cardId: number) {
    const cardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);
    stores.sports.comboCard.ineligibleCardIds.set((prevState) => prevState.filter((id) => id !== cardId));
    const updatedCards = cardsInBetslip.filter(({ id }) => id !== cardId);
    if (updatedCards.length) {
        stores.sports.comboCard.cardsInBetslip.set(updatedCards);
        stores.sports.comboCard.stakeByCardId.set((prevState) => omit(prevState, cardId));
        return;
    }
    resetComboCardBetSlip();
}

export function resetComboCardBetSlip(clearBetslipSelections = true) {
    if (clearBetslipSelections) {
        clearBetslipSelection(BetslipMode.Betslip);
    }
    stores.sports.comboCard.isComboCardBetslipOpen.set(false);
    stores.sports.comboCard.cardsInBetslip.set([]);
    stores.sports.comboCard.stakeByCardId.set({});
}

export function getMarketIdsFromComboCardMatches(matches: FoComboCardWithOdds['matches']) {
    return matches.flatMap((match) => match.markets.map((market) => market.id));
}

export const checkIsCardMultiSport = (matches: FoComboCardWithOdds['matches']) => {
    const sportNameToCompareTo = matches[0].sport_name;
    return matches.some(({ sport_name }) => sport_name !== sportNameToCompareTo);
};

export const getRemainingBettingEndTime = (days: number, hours: number, minutes: number, seconds: number) => {
    if (days >= 1) {
        return `${days} day${days > 1 ? 's' : ''}`;
    } else {
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
    }
};
