import styled from 'styled-components';

type StyledProps = {
    $iconWidth: number;
};

export default styled.div<StyledProps>`
    .betslip-toggle {
        color: var(--font-color-secondary);
        position: fixed;
        left: 0;
        right: 0;
        z-index: 10;
        bottom: var(--mobile-nav-height);
        background-color: #40404e; // TODO: move to variables after design system is ready
        padding: 0 var(--spacing-20);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: var(--font-14);
        font-weight: 500;
        height: var(--betslip-toggle-height);
        &.active-betslip {
            background-color: var(--color-motd);
            color: var(--button-active-bg);
        }
        .betslip-title {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .icon {
                background-color: var(--button-active-bg);
                color: white;
            }
        }
        .count {
            position: relative;
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                filter: invert();
            }
        }
    }
`;
