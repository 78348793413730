import React from 'react';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import UiBlobLoader from '../../../ui/blob-loader/UiBlobLoader';
import isEmpty from 'lodash/isEmpty';
import asyncComponent from '../../../async-component/AsyncComponent';
import SportBetslipV2Header from '../header/SportBetslipV2Header';
import { BetslipMode } from '../../../../services/sports/types';
import SportTicketList from '../../ticket/list/SportTicketList';
import UiButton from '../../../ui/button/UiButton';
import { getRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import SportBetslipV2Combocards from '../combocards/SportBetslipV2Combocards';

const SportBetslipV2Empty = asyncComponent(
    () => import('../empty/SportBetslipV2Empty' /* webpackChunkName: 'SportBetslipV2Empty'*/),
);
const SportBetslipV2 = asyncComponent(() => import('../SportBetslipV2' /* webpackChunkName: 'SportBetslipV2'*/));

export default function SportBetslipV2Container() {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [limits] = useStore(stores.sports.limits);
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [maintenance] = useStore(stores.maintenance.sportsbookMaintenanceState);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    const [betSlipMode] = useStore(stores.sports.betslipMode);

    const { receiptById } = betSlipPlacingState;

    if (!limits) {
        return <UiBlobLoader />;
    }

    return (
        <>
            <SportBetslipV2Header />

            {betSlipMode === BetslipMode.Betslip && (
                <>
                    {isEmpty(betSlipMarketIdToOutcomeId) && isEmpty(receiptById) && !maintenance.betslip ? (
                        <SportBetslipV2Empty />
                    ) : (
                        <>{isEmpty(cardsInBetslip) ? <SportBetslipV2 /> : <SportBetslipV2Combocards />}</>
                    )}
                </>
            )}

            {betSlipMode === BetslipMode.History && isAuthenticated && (
                <div className="tickets-list">
                    <SportTicketList isCompactView isBetHistory />
                    <UiButton url={getRoute('sport.bet-history')} block className="show-all-button">
                        {translate('Show All Tickets', 'ui.sportsbook')}
                    </UiButton>
                </div>
            )}
        </>
    );
}
