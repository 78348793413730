import React from 'react';
import Wrapper from './styles';
import HorseRacingUiHorseNumber from '../../ui/horse-number/HorseRacingUiHorseNumber';
import { consecutiveRanges } from '../../../../services/horse-racing/horse-racing';
import uniqBy from 'lodash/uniqBy';

import { settingsByBetType } from '../../../../services/lvdc/utils';

interface Props {
    betType: string;
    runnerMaps: number[][];
    breed?: string;
    races?: number[];
}

export default function HorseRacingBetslipRunnerMaps({ breed, betType, races, runnerMaps }: Props) {
    const hasOneSelection = settingsByBetType[betType]?.selectionsCount === 1;

    function getOrdinalNumber(position: number) {
        if ((settingsByBetType[betType]?.isMultiRace ?? false) && races?.length) {
            return `R${races[position - 1]}`;
        }

        const ordinalNumberByPosition: Record<number, string> = {
            1: '1st',
            2: '2nd',
            3: '3rd',
        };

        return ordinalNumberByPosition[position] || `${position}th`;
    }

    return (
        <Wrapper>
            <div className="selection-market">
                <div className="bet-type">{betType}</div>

                {runnerMaps
                    .map((positionArray) =>
                        consecutiveRanges(uniqBy(positionArray, (e) => e).sort((n1, n2) => n1 - n2)),
                    )
                    .map((positionArray, positionIndex) => {
                        return (
                            <div className="multiple-selection-row" key={`selection-row-${positionIndex}`}>
                                {Boolean(positionArray.length) && !hasOneSelection && (
                                    <span>{getOrdinalNumber(positionIndex + 1)} :</span>
                                )}
                                {positionArray.map((runners, index) => {
                                    if (runners.indexOf('-') < 0) {
                                        return (
                                            <HorseRacingUiHorseNumber
                                                key={index}
                                                bettingInterest={Number(runners)}
                                                saddleCloth={runners}
                                                breed={breed}
                                            />
                                        );
                                    }
                                    return (
                                        <div key={index} className="selections-range">
                                            <HorseRacingUiHorseNumber
                                                bettingInterest={Number(runners.split('-')[0])}
                                                saddleCloth={runners.split('-')[0]}
                                                breed={breed}
                                            />
                                            {' - '}
                                            <HorseRacingUiHorseNumber
                                                bettingInterest={Number(runners.split('-')[1])}
                                                saddleCloth={runners.split('-')[1]}
                                                breed={breed}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
            </div>
        </Wrapper>
    );
}
