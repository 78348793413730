import styled from 'styled-components';
import UiGroup from '../../../../ui/group/UiGroup';

export default styled(UiGroup)`
    text-align: initial;

    p {
        margin: 0;
        & ~ p {
            margin-top: 1rem;
        }
    }

    label {
        font-weight: 700;
        font-size: 0.875rem;
        padding: 0;
    }

    .text-bold {
        font-weight: 700;
    }

    .hint-message {
        color: var(--font-color-subtle);
        font-style: italic;
        font-size: 0.75rem;
    }

    .exchange-rate,
    .amount-field-wrapper .input-adornment {
        font-size: 1.125rem;
    }

    label,
    .input-wrapper {
        margin-bottom: var(--spacing-8);
        color: var(--font-color-secondary);
    }

    .input-wrapper {
        height: 2.5rem;
        input {
            padding-right: 0;
        }
    }

    .amount-hint {
        text-align: right;
        line-height: 1.42;
    }

    .exchange-rate {
        max-width: 11rem;
    }

    .amount-field-wrapper {
        align-items: flex-end;
        & :first-child {
            flex: 1;
        }
        .input-wrapper {
            margin-bottom: 0;
        }
        .input-adornment {
            font-weight: 700;
            line-height: normal;
            overflow: visible;
            padding-right: var(--spacing-8);
        }
    }

    .snippet {
        padding: var(--spacing-8);
        background-color: var(--surface-level-3-bg);
        border-radius: var(--ui-radius);
        max-height: 6rem;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--surface-level-1-bg) transparent;
    }

    .progress-message {
        text-align: center;
        font-size: 1rem;
        color: var(--font-color-primary);

        &:before {
            content: '';
            display: inline-block;
            width: 0.75rem;
            height: 0.75rem;
            background: var(--color-primary);
            border-radius: 50%;
            margin-right: var(--spacing-8);
        }
    }
`;
