import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    position: relative;
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    background: var(--surface-level-2-bg);
    padding: 0.8rem;
    margin: 0.8rem 0;

    .selection-header {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.5rem;
    }

    .card-name {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        font-size: var(--font-14);
        font-weight: var(--match-name-weight);
        ${({ theme }) => theme.optimizeFontRendering};
        line-height: 1.5;
        @media ${mediaQuery.isPhone} {
            font-size: 1rem;
        }
    }

    .remove-selection {
        cursor: pointer;
        color: var(--font-color-subtle);
        margin-left: var(--spacing-12);
        align-items: flex-start;
    }
`;
