import React, { useEffect, useState } from 'react';
import { isProd } from '../../../../../../services/environment';
import { environment } from '../../../../../../stores/environment/environment';
import { getStoreValue } from '../../../../../../stores/store/utils';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';

interface Props {
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentDepositProviderTrustlyUsScripts({ onError, onScriptsLoaded }: Props) {
    const { PAYMENTS } = getStoreValue(environment);
    const SDK_URL_ENVIRONMENT = isProd() ? 'https://trustly.one' : 'https://sandbox.trustly.one';
    const SDK_URL = `${SDK_URL_ENVIRONMENT}/start/scripts/trustly.js?accessId=${PAYMENTS?.TRUSTLY_US_ACCESS_ID}`;
    const [isTrustlyUsScriptLoaded, setIsTrustlyUsScriptLoaded] = useState(false);
    useEffect(() => {
        if (isTrustlyUsScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isTrustlyUsScriptLoaded]);
    return <ScriptLoader url={SDK_URL} onError={onError} onLoad={() => setIsTrustlyUsScriptLoaded(true)} />;
}
