import { BetPlaceRequestComboCard } from '@staycool/bets-types/bets';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import every from 'lodash/every';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import invert from 'lodash/invert';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import some from 'lodash/some';
import sum from 'lodash/sum';
import zipObject from 'lodash/zipObject';
import { flushSync } from 'react-dom';
import { postBettingContext } from '../../microservices/analytics';
import {
    getLatestTicketId,
    isSameMatchComboEligible,
    IsSameMatchComboEligiblePayload,
    placeBetRequest,
} from '../../microservices/bets';
import { loadOddsByMarketIds } from '../../microservices/sb-odds';
import { getMarketInfoByOutcomeId } from '../../microservices/sbgate';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { logout, requestLogin } from '../auth';
import { isMobile } from '../browser';
import { getActiveCurrency } from '../currency';
import { getClient } from '../environment';
import { isFeatureAvailable } from '../feature';
import { verifyGeoLocation } from '../geocomply/geocomply';
import { Language } from '../language/types';
import { logger } from '../logger';
import { NativeMessageEventType, sendNativeEvent } from '../mobile-app';
import { convertOdds, getPreciseOdds } from '../odds-format';
import { storageGet, storageSet } from '../storage';
import { translate } from '../translate';
import { Currency } from '../wallet/types';
import { addBetslipError, getErrorCode, removeAllNonFrontendErrors } from './betslip-errors';
import {
    ACCOUNT_CLOSED_ERROR,
    ACCOUNT_CLOSED_ERROR_MESSAGE,
    ADJUSTED_TOWIN_CALC,
    AT_LEAST_TWO_SELECTIONS,
    BET_BUILDER_NOT_ALLOWED_ERROR,
    BET_MIN_ODDS,
    BET_PERIOD_SHOULD_BE_DAY,
    BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE,
    BETBUILDER_SELECTION_IS_REDUNDANT,
    BETSTAKE_NOT_EQUAL_PREV_DAY_MAXWIN,
    BETSTAKE_NOT_EQUAL_PREV_DAY_SATE,
    CAMPAIGN_BET_TYPE_ERROR,
    CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
    CAMPAIGN_MIN_ODDS_ERROR,
    CAMPAIGN_MIN_SELECTIONS_ERROR,
    CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
    CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE,
    CAMPAIGN_WRONG_STAKE_ERROR,
    COMBO_CARD_BETSLIP_MIN_MAX_STAKE,
    COMBO_MARKET_ID,
    COMBO_NOT_ALLOWED_ERROR,
    DUPLICATE_REQUEST,
    DUPLICATE_TICKET_ERROR,
    FIRST_DAY_BETSTAKE_AMOUNT,
    GEOCOMPLY_FAILED_ERROR,
    GEOCOMPLY_FAILED_ERROR_MESSAGE,
    INCORRECT_AMOUNT_OF_SELECTIONS,
    initialBetSlipPlacingState,
    initialBetSlipUserState,
    INPLAY_NOT_ALLOWED,
    INSUFFICIENT_FUNDS_ERROR,
    INVALID_MAIN_LINE,
    INVALID_STAKE_ERROR,
    INVALID_TIME_FORMAT,
    LIMIT_EXCEEDED_ERROR,
    LOCKED_UNTIL_FUNDED,
    LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR,
    LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE,
    MA_DISABLED_ERROR,
    MA_ENABLED_ERROR,
    MARKET_SPECIFIC_ERRORS,
    MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR,
    MATCH_STATUS,
    MAX_SELECTIONS_COMBO_ERROR,
    MAX_SELECTIONS_SYSTEM_ERROR,
    MAX_SELECTIONS_TEASER_ERROR,
    maxComboSelectionsByClient,
    MIN_SELECTIONS_TEASER_ERROR,
    MIN_STAKE_REQUIREMENT,
    MORE_THAN_ONE_BET,
    MULTIPLE_BET_IN_THE_PERIOD,
    NO_CAMPAIGN,
    NOT_ENOUGH_MONEY_LOCKED_BY_BONUS_ERROR,
    NOT_ENOUGH_SELECTIONS,
    NOT_MAIN_LINE,
    NOT_MAIN_LINE_BACKEND,
    ODDS_CHANGED_ERROR,
    ODDS_CHANGED_ERROR_SB_ODDS,
    ODDS_CLOSED_ERROR,
    ODDS_CLOSED_ERRORS_BACKEND,
    ODDS_HEARTBEAT_DOWN,
    ON_DEMAND_BET_ERROR_MESSAGE,
    PARLAY_CARD_BET_TIME_ERROR,
    PARLAY_CARD_MARKET_STATUS,
    PARLAY_CARD_MATCH_STATUS,
    PARLAY_CARD_MAX_WIN,
    PARLAY_CARD_NOT_AVAILABLE,
    PARLAY_CARD_OUTCOME_STATUS,
    PARLAY_CARD_SELECTION_AMOUNT_ERROR,
    PARLAY_CARD_SELECTION_NOT_AVAILABLE,
    PARLAY_CARD_STATUS_ERROR,
    QUICK_STAKES,
    QUICK_STATION_STAKES,
    QUICK_WYNNBET_STAKES,
    SAME_MARKET_IN_BETSLIP_ERROR,
    SAME_MATCH_IN_BETSLIP_ERROR,
    SELECTION_NOT_BETBUILDER_ELIGIBLE,
    sportPrefix,
    systemBetTypeBySystemKey,
    TEASER_CATEGORY_COMBINATION_NOT_ALLOWED,
    TEASERS_WRONG_MARKET_TYPE,
    TICKET_LIMIT_ERROR,
    TOO_MANY_SELECTIONS,
    USER_CURRENCY_NOT_AVAILABLE_IN_CAMPAIGN,
    WRONG_BET_TYPE,
    WRONG_MARKET_TYPE_GROUP,
    WRONG_MATCH,
    WRONG_MATCH_RESULT_TIME,
    WRONG_PRODUCT_ERROR,
    WRONG_SEGMENT,
} from './constants';
import { getMaxWinDaily, getMaxWinWeekly, getMinStake } from './limits';
import { handleIfManualAcceptance, hasBetslipManualAcceptanceError } from './manual-acceptance-helpers';
import { getSystemCombinations } from './system-bet-helpers';
import { BET_TYPE, BetslipMode, MultiBetslipState, MultiParlayState } from './types';
import { getOddsFormat } from './user-settings';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { FEATURE } from '../types';
import { getSportsLayout } from '../layout/utils';
import { SportsLayout } from '../layout/types';
import { payoutRound } from './betslip-formatting';
import { addBetslipErrorForMatch, isOpenbetAvailable, isOpenbetComboBetslip } from '../bet-builder';

export type PlaceBetHandle = (
    isManualAcceptance?: boolean,
    allowErrorForMarket?: boolean,
    isForceDuplicate?: boolean,
) => Promise<boolean | void>;

type PlaceBetRequest = (marketId: string, isManualAcceptance: boolean, isForceDuplicate: boolean) => Promise<string>;

function removeSelection(marketId: string) {
    stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
        delete state[marketId];
    });
}

export function removeSelections(betbuilder?: boolean) {
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    Object.values(marketInfoById).forEach(
        (marketInfo) =>
            (betbuilder ? marketInfo.view_type === 'bet_builder' : marketInfo.view_type !== 'bet_builder') &&
            removeSelection(marketInfo.id.toString()),
    );
}

export function placeBetWrap(placeBetRequest: PlaceBetRequest): PlaceBetHandle {
    return async (isManualAcceptance = false, allowErrorForMarket = false, isForceDuplicate = false) => {
        const isAuthenticated = getStoreValue(stores.isAuthenticated);
        const betSlipUserState = getStoreValue(stores.sports.betSlipUserState);
        const betSlipPlacingState = getStoreValue(stores.sports.betSlipPlacingState);
        const betSlipMarketIdToOutcomeId = isOpenbetComboBetslip(betSlipUserState.betType)
            ? getStoreValue(stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId)
            : getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
        const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
        const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
        const manualAcceptanceDisabled = some(genericErrors, (error) => getErrorCode(error) === MA_DISABLED_ERROR);

        if (!isAuthenticated) {
            requestLogin();
            return;
        }
        if (
            !hasLiveMatchesInBetslip() &&
            !betSlipPlacingState.isConfirmed &&
            !isManualAcceptance &&
            !allowErrorForMarket &&
            isFeatureAvailable(FEATURE.BETSLIP_CONFIRM) &&
            !manualAcceptanceDisabled
        ) {
            stores.sports.betSlipPlacingState.set({ ...betSlipPlacingState, needsConfirm: true });
            return true;
        }
        await verifyGeoLocation('BET');
        const { betType } = betSlipUserState;
        const betSlipMarketIds = Object.keys(betSlipMarketIdToOutcomeId);
        let marketIdsToDoPlaceRequestFor = [String(COMBO_MARKET_ID)];
        if ([BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType)) {
            marketIdsToDoPlaceRequestFor = betSlipMarketIds.filter(
                (marketId) => !hasErrorsByMarketId(marketId) || allowErrorForMarket,
            );
        }
        if (!marketIdsToDoPlaceRequestFor.length && !isForceDuplicate) {
            return true;
        }
        flushSync(() => {
            stores.sports.betSlipPlacingState.set({
                ...betSlipPlacingState,
                isLoading: true,
                needsConfirm: false,
                needsConfirmDuplicate: false,
            });
        });
        removeAllNonFrontendErrors();
        const marketIdByOutcomeId = invert(betSlipMarketIdToOutcomeId);
        let isError = false;
        const marketIds: string[] = [];
        // needs to be sequential
        while (marketIdsToDoPlaceRequestFor.length) {
            const ticketSubmitTime = new Date().getTime();
            const marketId = marketIdsToDoPlaceRequestFor.pop() as string;
            try {
                if (betType === BET_TYPE.COMBO_CARD) {
                    const cardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);
                    const receiptToSaveInStore: Record<number, string> = {};
                    await Promise.all(
                        cardsInBetslip.map(async ({ id: cardId }) => {
                            const ticketId = await placeBetRequest(`${cardId}`, isManualAcceptance, isForceDuplicate);
                            receiptToSaveInStore[cardId] = ticketId;
                        }),
                    );
                    stores.sports.betSlipPlacingState.set((state) => ({
                        ...state,
                        receiptById: { ...state.receiptById, ...receiptToSaveInStore },
                        isLoading: false,
                        isConfirmed: false,
                    }));
                    return;
                }
                const ticketId = await placeBetRequest(marketId, isManualAcceptance, isForceDuplicate);
                stores.sports.betSlipPlacingState.set((state) => {
                    state.receiptById[String(marketId)] = ticketId;
                });
            } catch (error: any) {
                isError = true;
                if (error.meta && MARKET_SPECIFIC_ERRORS === error.code) {
                    Object.keys(error.meta).forEach((outcomeId) => {
                        let errorCode = error.meta[outcomeId].code;
                        if (ODDS_CLOSED_ERRORS_BACKEND.includes(errorCode)) {
                            errorCode = ODDS_CLOSED_ERRORS_BACKEND[0];
                            marketIds.push(marketIdByOutcomeId[outcomeId]);
                        }
                        if ([ODDS_CHANGED_ERROR, ODDS_HEARTBEAT_DOWN].includes(errorCode)) {
                            marketIds.push(marketIdByOutcomeId[outcomeId]);
                        }
                        addBetslipError(marketIdByOutcomeId[outcomeId], errorCode);
                    });
                    loadOddsByMarketIds(marketIds);
                } else if (error.code === DUPLICATE_TICKET_ERROR) {
                    addBetslipError(marketId, error);
                    stores.sports.betSlipPlacingState.set({ ...betSlipPlacingState, needsConfirmDuplicate: true });
                } else if (error.code === GEOCOMPLY_FAILED_ERROR) {
                    sendNativeEvent({ type: NativeMessageEventType.GEOCOMPLY_ERROR });
                    addBetslipError(COMBO_MARKET_ID, error);
                } else if (!error.code || error.code === 1500) {
                    try {
                        const ticketId = await getLatestTicketId(ticketSubmitTime);
                        stores.sports.betSlipPlacingState.set((state) => {
                            state.receiptById[String(marketId)] = ticketId;
                        });
                    } catch (error) {
                        addBetslipError(null, translate('Something went wrong', 'ui.common'));
                        logger.info('SportsBetslipService', 'placeBetWrap', error);
                    }
                } else if (error.code !== ODDS_CHANGED_ERROR_SB_ODDS) {
                    addBetslipError(COMBO_MARKET_ID, error);
                    handleIfManualAcceptance(error, betType);
                }
                logger.info('SportsBetslipService', 'placeBetWrap', `marketId: ${marketId}`);
                logger.info('SportsBetslipService', 'placeBetWrap', error);
            }
        }
        const { receiptById } = getStoreValue(stores.sports.betSlipPlacingState);
        const placedMarketIds = Object.keys(receiptById);
        if (placedMarketIds.length) {
            if (receiptById[String(COMBO_MARKET_ID)]) {
                flushSync(() => {
                    clearBetslip();
                });
            } else {
                flushSync(() => {
                    stores.sports.betSlipMarketIdToOutcomeId.set((state) => omit(state, placedMarketIds));
                });
            }
            stores.sports.betSlipUserState.set((state) => ({
                ...initialBetSlipUserState,
                stakeByMarketId: omit(state.stakeByMarketId, placedMarketIds.concat(String(COMBO_MARKET_ID))),
            }));
            stores.sports.betbuilderBetslipIdByMarketId.set({});
        }

        stores.sports.betSlipPlacingState.set((state) => ({ ...state, isLoading: false, isConfirmed: false }));
        if (!isError) {
            if (some(stores.sports.bonusBetsSelection.state)) {
                stores.sports.bonusBetsSelection.set({});
            }
            isFeatureAvailable(FEATURE.TICKET_TRACKING) &&
                placedMarketIds.forEach(async (marketId) => {
                    const ticket_id = receiptById[marketId];
                    const outcomeId =
                        betSlipMarketIdToOutcomeId[marketId] || Object.values(betSlipMarketIdToOutcomeId)[0];
                    const bettingContext = getStoreValue(stores.sports.bettingContextByOutcomeId)[outcomeId];
                    if (bettingContext) {
                        await postBettingContext({ ticket_id, context: bettingContext });
                    }
                });
        }
        return isError;
    };
}

export function hasBetSlipChangedFromOdds(state) {
    if (!state) {
        return '';
    }

    const outcomeIds = Object.values(getStoreValue(stores.sports.betSlipMarketIdToOutcomeId));

    return outcomeIds
        .map((outcomeId) => (state[outcomeId] ? state[outcomeId].odds_id : `loading_${outcomeId}`))
        .sort()
        .join();
}

const betslipPrefix = 'ui.betslip';

export function getErrorMessage(error) {
    const errorCode = getErrorCode(error);
    if (errorCode === ACCOUNT_CLOSED_ERROR) {
        logout();
    }

    const maEnabledError = 'You are exceeding our limits, please use one of the following options:';
    const maDisabledError = 'You are exceeding our limits, your stake has been set to maximum allowed!';
    const oddsClosedErrorsDict = fromPairs(
        ODDS_CLOSED_ERRORS_BACKEND.map((code) => [code, translate('ODDS_CLOSED', sportPrefix)]),
    );
    const activeCurrency = getActiveCurrency();
    const betslipMode = getStoreValue(stores.sports.betslipMode);
    const betType =
        betslipMode === BetslipMode.ParlayCard
            ? BET_TYPE.PARLAY_CARD
            : getStoreValue(stores.sports.betSlipUserState).betType;
    // insert all new error codes the user can know of below
    // all the errors not here will show Technical error
    return {
        ...oddsClosedErrorsDict,
        [ODDS_CLOSED_ERROR]: translate('ODDS_CLOSED', sportPrefix),
        [ODDS_CHANGED_ERROR]: translate('ODDS_CHANGED', sportPrefix),
        [ODDS_HEARTBEAT_DOWN]: translate('ODDS_CHANGED', sportPrefix),
        [MA_ENABLED_ERROR]: translate(maEnabledError, betslipPrefix),
        [MA_DISABLED_ERROR]: translate(maDisabledError, betslipPrefix),
        [INSUFFICIENT_FUNDS_ERROR]: translate('INSUFFICIENT_FUNDS', sportPrefix),
        [LIMIT_EXCEEDED_ERROR]: translate('INSUFFICIENT_FUNDS', sportPrefix),
        [NOT_ENOUGH_MONEY_LOCKED_BY_BONUS_ERROR]: translate('NOT_ENOUGH_MONEY_LOCKED_BY_BONUS', sportPrefix),
        1001: translate('COMBO_NOT_ALLOWED', sportPrefix),
        1131: translate('IndividualBetLimitExceededError', sportPrefix),
        4009: translate('IndividualBetLimitExceededError', sportPrefix),
        1130: translate('WagerLimitExceededError', sportPrefix),
        4006: translate('WagerLimitExceededError', sportPrefix),
        1112: translate('LossLimitExceededError', sportPrefix),
        4002: translate('LossLimitExceededError', sportPrefix),
        1111: translate('sb-closed-support1', 'ui.account'),
        1110: translate('sb-closed-support1', 'ui.account'),
        [LOCKED_UNTIL_FUNDED]: translate('LOCKED_UNTIL_FUNDED', betslipPrefix),
        1109: translate('DUPLICATE_TICKET_ERROR', betslipPrefix),
        [BET_BUILDER_NOT_ALLOWED_ERROR]: translate('BET_BUILDER_NOT_ALLOWED_ERROR', betslipPrefix),
        [COMBO_NOT_ALLOWED_ERROR]: translate('COMBO_NOT_ALLOWED', sportPrefix),
        [SELECTION_NOT_BETBUILDER_ELIGIBLE]: translate(
            [SELECTION_NOT_BETBUILDER_ELIGIBLE, 'Selection is not betbuilder eligible'],
            'ui.betslip',
        ),
        [BETBUILDER_SELECTION_IS_REDUNDANT]: translate(
            [BETBUILDER_SELECTION_IS_REDUNDANT, 'Selection is redundant for this combination'],
            'ui.betslip',
        ),
        [BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE]: translate(
            [BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE, 'These selections set cannot be combined'],
            'ui.betslip',
        ),
        [SAME_MATCH_IN_BETSLIP_ERROR]: translate('SAME_MATCH_IN_BETSLIP', sportPrefix),
        [SAME_MARKET_IN_BETSLIP_ERROR]: translate('SAME_MARKET_IN_BETSLIP', sportPrefix),
        [INVALID_STAKE_ERROR]: getInvalidStakeErrorMessage(error, activeCurrency),
        [TICKET_LIMIT_ERROR]: translate('TICKET_LIMIT', sportPrefix),
        1119: translate('DAILY_LIMIT', sportPrefix, [getMaxWinDaily(), activeCurrency]),
        1120: translate('WEEKLY_LIMIT', sportPrefix, [getMaxWinWeekly(), activeCurrency]),
        [GEOCOMPLY_FAILED_ERROR]: translate(GEOCOMPLY_FAILED_ERROR_MESSAGE, betslipPrefix),
        [MAX_SELECTIONS_COMBO_ERROR]: translate(MAX_SELECTIONS_COMBO_ERROR, betslipPrefix, {
            maxSelections: getMaxComboSelections(),
        }),
        [MAX_SELECTIONS_TEASER_ERROR]: translate(MAX_SELECTIONS_TEASER_ERROR, betslipPrefix),
        [MIN_SELECTIONS_TEASER_ERROR]: translate(MIN_SELECTIONS_TEASER_ERROR, betslipPrefix),
        [MAX_SELECTIONS_SYSTEM_ERROR]: translate(MAX_SELECTIONS_SYSTEM_ERROR, betslipPrefix),
        [COMBO_CARD_BETSLIP_MIN_MAX_STAKE]: translate(COMBO_CARD_BETSLIP_MIN_MAX_STAKE, betslipPrefix, {
            minValue: error.minStake,
            maxValue: error.maxStake,
            currency: activeCurrency,
        }),
        [AT_LEAST_TWO_SELECTIONS]: translate(AT_LEAST_TWO_SELECTIONS, sportPrefix),
        [NOT_MAIN_LINE]: translate(ON_DEMAND_BET_ERROR_MESSAGE[NOT_MAIN_LINE][betType], betslipPrefix),
        [NOT_MAIN_LINE_BACKEND]: translate(ON_DEMAND_BET_ERROR_MESSAGE[NOT_MAIN_LINE][betType], betslipPrefix),
        [INVALID_MAIN_LINE]: translate(ON_DEMAND_BET_ERROR_MESSAGE[INVALID_MAIN_LINE][betType], betslipPrefix),
        [TEASERS_WRONG_MARKET_TYPE]: translate('TEASERS_WRONG_MARKET_TYPE', betslipPrefix),
        [TEASER_CATEGORY_COMBINATION_NOT_ALLOWED]: translate(TEASER_CATEGORY_COMBINATION_NOT_ALLOWED, betslipPrefix),
        [PARLAY_CARD_SELECTION_AMOUNT_ERROR]: translate(INCORRECT_AMOUNT_OF_SELECTIONS, betslipPrefix),
        [PARLAY_CARD_BET_TIME_ERROR]: translate(PARLAY_CARD_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_STATUS_ERROR]: translate(PARLAY_CARD_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_OUTCOME_STATUS]: translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_MARKET_STATUS]: translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_MATCH_STATUS]: translate(PARLAY_CARD_SELECTION_NOT_AVAILABLE, betslipPrefix),
        [PARLAY_CARD_MAX_WIN]: translate('PARLAY_CARD_MAX_WIN', betslipPrefix),
        [INPLAY_NOT_ALLOWED]: translate('INPLAY_NOT_ALLOWED', betslipPrefix),
        [INCORRECT_AMOUNT_OF_SELECTIONS]: translate(INCORRECT_AMOUNT_OF_SELECTIONS, betslipPrefix),
        [ADJUSTED_TOWIN_CALC]: translate(ADJUSTED_TOWIN_CALC, betslipPrefix),
        [ACCOUNT_CLOSED_ERROR]: translate(ACCOUNT_CLOSED_ERROR_MESSAGE, betslipPrefix),
        [MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR]: translate(
            'MATCH_EXPECTED_RESULT_TIME_AFTER_DEADLINE_ERROR',
            betslipPrefix,
        ),
        [CAMPAIGN_BET_TYPE_ERROR]: translate(CAMPAIGN_BET_TYPE_ERROR, betslipPrefix, {
            betTypes: getCampaignBetTypesInString(),
        }),
        3000: translate('PLAYER_NOT_AUTHENTICATED', sportPrefix),
        [LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR]: translate(LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE, sportPrefix),
        4010: translate(FIRST_DAY_BETSTAKE_AMOUNT, betslipPrefix),
        4011: translate(BETSTAKE_NOT_EQUAL_PREV_DAY_MAXWIN, betslipPrefix),
        4012: translate(BETSTAKE_NOT_EQUAL_PREV_DAY_SATE, betslipPrefix),
        4013: translate(BET_MIN_ODDS, betslipPrefix),
        4014: translate(MORE_THAN_ONE_BET, betslipPrefix),
        4015: translate(MULTIPLE_BET_IN_THE_PERIOD, betslipPrefix),
        4016: translate(MIN_STAKE_REQUIREMENT, betslipPrefix),
        4017: translate(WRONG_SEGMENT, betslipPrefix),
        4018: translate(WRONG_PRODUCT_ERROR, betslipPrefix),
        4019: translate(WRONG_MARKET_TYPE_GROUP, betslipPrefix),
        4020: translate(WRONG_MATCH_RESULT_TIME, betslipPrefix),
        4021: translate(WRONG_MATCH_RESULT_TIME, betslipPrefix),
        4022: translate(WRONG_BET_TYPE, betslipPrefix),
        4023: translate(NOT_ENOUGH_SELECTIONS, betslipPrefix),
        4024: translate(TOO_MANY_SELECTIONS, betslipPrefix),
        4025: translate(WRONG_MATCH, betslipPrefix),
        4026: translate(NO_CAMPAIGN, betslipPrefix),
        4027: translate(BET_PERIOD_SHOULD_BE_DAY, betslipPrefix),
        4028: translate(INVALID_TIME_FORMAT, betslipPrefix),
        4029: translate(DUPLICATE_REQUEST, betslipPrefix),
        4030: translate(USER_CURRENCY_NOT_AVAILABLE_IN_CAMPAIGN, betslipPrefix),
        5000: translate('UNKNOWN_ERROR', betslipPrefix),
        [CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR]: translate(CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR, betslipPrefix),
        [CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR]: translate(CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR, betslipPrefix),
        [CAMPAIGN_WRONG_STAKE_ERROR]: translate(CAMPAIGN_WRONG_STAKE_ERROR, betslipPrefix),
        [CAMPAIGN_MIN_ODDS_ERROR]: translate(CAMPAIGN_MIN_ODDS_ERROR, betslipPrefix),
        [CAMPAIGN_MIN_SELECTIONS_ERROR]: translate(CAMPAIGN_MIN_SELECTIONS_ERROR, betslipPrefix),
    }[errorCode];
}

function getCampaignBetTypesInString() {
    const { selected: selectedCampaign } = getStoreValue(stores.sports.campaigns);

    if (!selectedCampaign?.sport_requirements?.bet_type) {
        return '';
    } else if (selectedCampaign.sport_requirements.bet_type === CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE.BOTH) {
        // TODO: When 'BOTH' is deprecated as a bet type in a campaign's sport requirements, remove this if statement
        return `${BET_TYPE.SINGLE}, ${BET_TYPE.COMBO}`;
    }

    return selectedCampaign.sport_requirements.bet_type
        .split(',')
        .map((betType) => betType.toLowerCase())
        .join(', ');
}

const getInvalidStakeErrorMessage = (error, activeCurrency: Currency) => {
    if (error.maxStake) {
        return translate('MAX_STAKE', sportPrefix, [error.maxStake, activeCurrency]);
    }
    const minStake = error.minStake || getMinStake();
    return translate('MIN_STAKE', sportPrefix, [minStake, activeCurrency]);
};

export function getBetslipErrorMessage(error) {
    return getErrorMessage(error) || translate('technical_error', sportPrefix);
}

export function hasLiveMatchesInBetslip() {
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    return some(
        betSlipMarketIdToOutcomeId,
        (_, marketId) => marketInfoById[marketId] && marketInfoById[marketId].match_status === MATCH_STATUS.LIVE,
    );
}

function getBetslipStakeSingles(stakeByMarketId, betSlipMarketIdToOutcomeId) {
    const marketIdsInBetslip = Object.values(pick(stakeByMarketId, Object.keys(betSlipMarketIdToOutcomeId)));
    return sum(marketIdsInBetslip.map(Number));
}

function getBetslipStakeCombo(stakeByMarketId) {
    return stakeByMarketId[String(COMBO_MARKET_ID)];
}

export function getBetslipStakeComboOrSingles(betType, stakeByMarketId = {}, betSlipMarketIdToOutcomeId = {}) {
    return [BET_TYPE.COMBO, BET_TYPE.TEASER].includes(betType)
        ? getBetslipStakeCombo(stakeByMarketId)
        : getBetslipStakeSingles(stakeByMarketId, betSlipMarketIdToOutcomeId);
}
export function getMaxComboSelections() {
    const client = getClient();
    return maxComboSelectionsByClient[client] || maxComboSelectionsByClient.default;
}

const betslipInfoCodes = [
    ODDS_CHANGED_ERROR,
    ADJUSTED_TOWIN_CALC,
    ODDS_HEARTBEAT_DOWN,
    COMBO_CARD_BETSLIP_MIN_MAX_STAKE,
];
const betslipWarningCodes = [DUPLICATE_TICKET_ERROR];

export function isWarning(errorObjectOrCode) {
    const errorCode = getErrorCode(errorObjectOrCode);
    return betslipWarningCodes.includes(errorCode);
}

export function isInfo(errorObjectOrCode) {
    const errorCode = getErrorCode(errorObjectOrCode);
    return betslipInfoCodes.includes(errorCode);
}

export function clearBetslipSelection(
    betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard,
    navigatePreviousPage = true,
    clearBetslipCollection = false,
) {
    const clearSelection = {
        [BetslipMode.Betslip]: () => {
            stores.sports.betSlipUserState.set(initialBetSlipUserState);
            storageSet('betSlipSettings', {
                betSlipMarketIdToOutcomeId: {},
                updatedAt: new Date().getTime(),
            });
            stores.sports.betSlipMarketIdToOutcomeId.set({});
            stores.sports.betSlipComboCardMarketIdToOutcomeId.set({});
            stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId.set({});
            stores.sports.betSlipErrorByMarketId.set({});
            stores.sports.teaserSelectedPoint.set(undefined);
            stores.sports.betbuilderBetslipIdByMarketId.set({});
            if (clearBetslipCollection) {
                stores.sports.betslipCollection.set([]);
                stores.sports.betSlipPosition.set(0);
            }
            if (isMobile() && navigatePreviousPage) {
                stores.isBetslipOpen.set(false);
            }
        },
        [BetslipMode.ParlayCard]: () => {
            storageSet('parlayCardSettings', {
                betSlipMarketIdToOutcomeId: {},
                updatedAt: new Date().getTime(),
            });
            stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set({});
            stores.sports.parlayCard.betSlipErrors.set([]);
            stores.sports.parlayCard.stake.set(0);
            if (clearBetslipCollection) {
                stores.sports.parlayCard.parlayBetslipCollection.set([]);
                stores.sports.parlayCard.betslipPosition.set(0);
            }
            if (isMobile() && navigatePreviousPage) {
                stores.isParlayBetslipOpen.set(false);
            }
        },
    };
    clearSelection[betslipType]();
    stores.sports.bonusBetsSelection.set({});
}

export function getMarketErrorStatusByErrorsArray(betslipErrors) {
    const { true: betslipWarning, false: betslipError } = keyBy(betslipErrors, isWarning);
    const isBetslipError = Boolean(betslipError);
    const isBetslipWarning = Boolean(betslipWarning);

    return { isBetslipWarning, isBetslipError };
}

function createComboCardBetPlaceDataObject(card: FoComboCardWithOdds, isForceDuplicate: boolean) {
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { betType, acceptAnyOddsChanges, copiedFrom, betslipCode } = userState;

    if (betType !== BET_TYPE.COMBO_CARD || !card) {
        return;
    }

    const language = getStoreValue(stores.language);
    const deviceId = storageGet('uuid');
    const [oddsByOutcomeId, marketInfoById] = [
        getStoreValue(stores.sports.oddsByOutcomeId),
        getStoreValue(stores.sports.marketInfoById),
    ];
    const stakeByCardId = getStoreValue(stores.sports.comboCard.stakeByCardId);

    const foTranslationsByOutcomeId = {};
    const cardMarkets = card.matches.flatMap(({ markets }) => markets);
    cardMarkets.forEach(({ outcome_name, outcome_id, id }) => {
        const { marketName, match_name } = marketInfoById[id];
        foTranslationsByOutcomeId[outcome_id] = {
            outcomeName: outcome_name,
            matchName: match_name,
            marketName,
        };
    });
    const stake = Number(stakeByCardId[card.id]);
    const cardOddsIdByOutcomeIdObjects = cardMarkets.map(({ outcome_id }) => ({
        [outcome_id]: oddsByOutcomeId[outcome_id]?.odds_id,
    }));
    const bet = { stake, oddsIdByOutcomeId: Object.assign({}, ...cardOddsIdByOutcomeIdObjects) };

    const data = {
        ticketType: betType,
        timestamp: new Date().toString(),
        acceptAnyOddsChanges,
        copiedFrom,
        foTranslationsByOutcomeId,
        bets: [bet],
        comboCardId: card.id,
        oddsFormat: getOddsFormat(),
        layout: getSportsLayout(),
        isForceDuplicate,
        deviceId,
        language,
        betslipCode,
    };

    if (isFeatureAvailable(FEATURE.SEND_CURRENCY)) {
        data['currency'] = getActiveCurrency();
    }

    return data as BetPlaceRequestComboCard;
}

export function createBetPlaceDataObjectFromStoreByMarketId(
    marketId: string,
    isManualAcceptance = false,
    isForceDuplicate = false,
) {
    const oddsIdByOutcomeId = {};
    const foTranslationsByOutcomeId = {};
    const bankerOddsIdByOutcomeId = {};

    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { betType, stakeByMarketId, acceptAnyOddsChanges, MAStakeByMarketId, copiedFrom } = userState;
    const betSlipMarketIdToOutcomeId = isOpenbetComboBetslip(betType)
        ? getStoreValue(stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId)
        : getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);

    const marketInfoById = isOpenbetComboBetslip(betType)
        ? getStoreValue(stores.sports.customBetbuilder.betbuilderMarketInfoById)
        : getStoreValue(stores.sports.marketInfoById);
    const language = getStoreValue(stores.language);
    const deviceId = storageGet('uuid');

    const marketIds = Object.keys(betSlipMarketIdToOutcomeId);

    (marketId && marketId !== String(COMBO_MARKET_ID) ? [marketId] : marketIds).forEach((marketId) => {
        const outcomeId = betSlipMarketIdToOutcomeId[marketId];
        const outcome = marketInfoById[marketId].outcomes.find((outcome) => outcome.id === Number(outcomeId));
        foTranslationsByOutcomeId[outcomeId] = {
            outcomeName: outcome ? outcome.name : '',
            marketName: marketInfoById[marketId].marketName,
            matchName: marketInfoById[marketId].match_name,
        };

        oddsIdByOutcomeId[outcomeId] = oddsByOutcomeId[outcomeId]?.odds_id;
    });
    const realTicketType = betType === BET_TYPE.BETBUILDER ? BET_TYPE.SINGLE : betType;
    const data = {
        ticketType: realTicketType,
        timestamp: new Date(),
        acceptAnyOddsChanges,
        copiedFrom,
        foTranslationsByOutcomeId,
        oddsFormat: getOddsFormat(),
        layout: getSportsLayout(),
        isForceDuplicate,
        deviceId,
        language,
        betslipCode: userState.betslipCode,
    };

    if (isFeatureAvailable(FEATURE.SEND_CURRENCY)) {
        data['currency'] = getActiveCurrency();
    }

    if (betType === BET_TYPE.SYSTEM) {
        const systemStakes = getSystemActiveStakes();
        data['systemBet'] = {
            oddsIdByOutcomeId,
            systemStakes: mapValues(systemStakes, (x) => (x ? Number(x) : undefined)),
        };
        if (isManualAcceptance) {
            const maSystemStakes = getSystemManualAcceptanceActiveStakes();
            data['systemBet']['maSystemStakes'] = mapValues(maSystemStakes, (x) => (x ? Number(x) : undefined));
        }
        data['systemBet']['bankerOddsIdByOutcomeId'] = bankerOddsIdByOutcomeId;
    } else {
        const stake = Number(stakeByMarketId[marketId]);
        const bet = { stake, oddsIdByOutcomeId };
        if (isManualAcceptance && MAStakeByMarketId[marketId]) {
            bet['stakeRequest'] = Number(MAStakeByMarketId[marketId]);
        }
        data['bets'] = [bet];
    }

    if (betType === BET_TYPE.TEASER) {
        data['teaserPoints'] = getStoreValue(stores.sports.teaserSelectedPoint);
    }

    const betbuilderBetslipIdByMarketIds = getStoreValue(stores.sports.betbuilderBetslipIdByMarketId);

    if (Object.values(betbuilderBetslipIdByMarketIds).length) {
        if (betType === BET_TYPE.BETBUILDER || betType === BET_TYPE.SINGLE) {
            const betbuilderBetslipId = betbuilderBetslipIdByMarketIds[Number(marketId)];
            if (betbuilderBetslipId) {
                data['betbuilderIdByMarketId'] = { [Number(marketId)]: betbuilderBetslipId };
            }
        } else if (betType === BET_TYPE.COMBO) {
            data['betbuilderIdByMarketId'] = betbuilderBetslipIdByMarketIds;
        }
    }

    if (isOpenbetComboBetslip(betType)) {
        data['openbetBetbuilderBetslipMarketIds'] = marketIds;
    }

    return data as {
        ticketType: BET_TYPE;
        timestamp: Date;
        acceptAnyOddsChanges: boolean;
        copiedFrom: string | null;
        oddsFormat: string;
        layout: SportsLayout;
        foTranslationsByOutcomeId: Record<string, unknown>;
        systemBet?: {
            oddsIdByOutcomeId?: string;
            systemStakes?: Record<string, number>;
            maSystemStakes?: Record<string, number>;
            bankerOddsIdByOutcomeId?: Record<string, number>;
        };
        oddsIdByOutcomeId: string;
        bets?: { stake: number; oddsIdByOutcomeId: Record<string, number>; stakeRequest?: number }[];
        forceDuplicate?: boolean;
        isForceDuplicate: boolean;
        deviceId: string | null;
        language: Language;
        currency?: Currency;
        betslipCode?: string;
    };
}

export async function createDataAndPlaceBet(marketId: string, isManualAcceptance: boolean, isForceDuplicate: boolean) {
    const betAction = placeBetRequest;
    const data = createBetPlaceDataObjectFromStoreByMarketId(marketId, isManualAcceptance, isForceDuplicate);
    stores.sports.betSlipErrorByMarketId.set((state) => {
        // key "null" is a combo bet "marketId"
        if (marketId && marketId !== 'null') {
            delete state[marketId];
        } else {
            return {};
        }
    });

    // TODO: remove after proper fix will be deployed in b2b envs
    if (isFeatureAvailable(FEATURE.SEPARATE_REQUESTS_ON_NORMAL_PLUS_MA_BET)) {
        if (
            data.ticketType !== BET_TYPE.SYSTEM &&
            data.bets &&
            data.bets[0].stake > 0 &&
            data.bets[0].stakeRequest &&
            data.bets[0].stakeRequest > 0
        ) {
            const bet = data.bets[0];
            const normalBetData = { ...data, bets: [omit(bet, 'stakeRequest')], isForceDuplicate: true };
            const manualAcceptanceBetData = { ...data, bets: [{ ...bet, stake: 0 }] };
            const maTicketId = await betAction(manualAcceptanceBetData);
            stores.sports.betSlipPlacingState.set((state) => {
                state.receiptById[String(marketId) + '-ma'] = maTicketId;
            });
            return await betAction(normalBetData);
        } else if (
            data.ticketType === BET_TYPE.SYSTEM &&
            data.systemBet &&
            Object.keys(data.systemBet.systemStakes ?? {}).length > 0 &&
            Object.keys(data.systemBet.maSystemStakes ?? {}).length > 0
        ) {
            const bet = data.systemBet;
            const normalBetData = { ...data, systemBet: omit(bet, 'maSystemStakes'), isForceDuplicate: true };
            const manualAcceptanceBetData = { ...data, systemBet: { ...bet, systemStakes: {} } };
            const maTicketId = await betAction(manualAcceptanceBetData);
            stores.sports.betSlipPlacingState.set((state) => {
                state.receiptById[String(marketId) + '-ma'] = maTicketId;
            });
            return await betAction(normalBetData);
        }
    }

    return await betAction(data);
}

export async function createComboCardsDataAndPlaceBets(
    cardId: string,
    isManualAcceptance: boolean,
    isForceDuplicate: boolean,
) {
    const betAction = placeBetRequest;
    const cardsInBetslip = getStoreValue(stores.sports.comboCard.cardsInBetslip);
    const cardToBetOn = cardsInBetslip.find((card) => card.id === Number(cardId));
    if (!cardToBetOn) {
        return '';
    }
    const comboCardData = createComboCardBetPlaceDataObject(cardToBetOn, isForceDuplicate);
    return await betAction(comboCardData);
}

export function getAllOdds(marketId) {
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const marketIdToOutcomeId =
        marketId && marketId !== String(COMBO_MARKET_ID)
            ? { [marketId]: betSlipMarketIdToOutcomeId[marketId] }
            : betSlipMarketIdToOutcomeId;
    return Object.values(marketIdToOutcomeId)
        .map((outcomeId) => oddsByOutcomeId[outcomeId]?.value)
        .filter((oddsValue): oddsValue is number => !!oddsValue);
}
export function calculateTotalOdds(marketId) {
    const betSlipUserState = getStoreValue(stores.sports.betSlipUserState);
    if (betSlipUserState.betType === BET_TYPE.TEASER) {
        return getTeaserTotalOdds();
    }
    if (isOpenbetAvailable() && betSlipUserState.betType === BET_TYPE.COMBO) {
        return getComboBetbuilderTotalOdds();
    }
    const allOddsValues = getAllOdds(marketId);
    return allOddsValues.reduce((totalOdds, oddsValue) => totalOdds * getPreciseOdds(convertOdds(oddsValue)), 1);
}

function getComboBetbuilderTotalOdds() {
    const betslipMarketIdsByMatchId = getStoreValue(stores.sports.customBetbuilder.betslipMarketIdsByMatchId);
    const betbuilderMarketOddsByMarketId = getStoreValue(stores.sports.customBetbuilder.betbuilderMarketOddsByMarketId);
    const comboBetMarketIdByMatchId = getStoreValue(stores.sports.customBetbuilder.comboBetMarketIdByMatchId);
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    let isSomeOddsInfoMissing = false;
    const totalOdds = Object.keys(betslipMarketIdsByMatchId).reduce((totalOddsAcc, matchId) => {
        const marketId = comboBetMarketIdByMatchId[matchId];
        if (!marketId) {
            isSomeOddsInfoMissing = true;
            return totalOddsAcc;
        }
        if (betbuilderMarketOddsByMarketId[marketId]) {
            return totalOddsAcc * getPreciseOdds(convertOdds(betbuilderMarketOddsByMarketId[marketId]));
        }
        return totalOddsAcc * getPreciseOdds(convertOdds(oddsByOutcomeId[betSlipMarketIdToOutcomeId[marketId]]?.value));
    }, 1);
    return isSomeOddsInfoMissing ? 0 : totalOdds;
}

export function getTeaserTotalOdds() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const selectionsCount = Object.keys(betSlipMarketIdToOutcomeId).length;
    const teaserPoints = getStoreValue(stores.sports.teaserSelectedPoint);
    const teaserPayouts = getStoreValue(stores.sports.teaserPayouts);
    const teaserPayout = teaserPayouts?.payout_odds?.find(
        (payout) => payout.points === teaserPoints && payout.selections_amount === selectionsCount,
    );
    return teaserPayout?.odds || 0;
}

export async function copyTicketToBetslipByOutcomeIds(outcomeIds, ticketId, bettingContext?) {
    try {
        const markets: { id: string }[] = await Promise.all(
            outcomeIds.map((outcomeId) => getMarketInfoByOutcomeId(outcomeId)),
        );
        const marketIds = markets.map((market) => market.id);
        const groupedMarketIdToOutcomeId = zipObject(marketIds, outcomeIds.map(Number));
        stores.sports.betSlipMarketIdToOutcomeId.set((state) => ({ ...state, ...groupedMarketIdToOutcomeId }));
        stores.sports.betSlipUserState.set((state) => ({ ...state, copiedFrom: ticketId }));
        if (bettingContext) {
            const groupedOutcomeIdToBettingContext = zipObject(
                outcomeIds.map(Number),
                outcomeIds.map(() => bettingContext),
            );
            stores.sports.bettingContextByOutcomeId.set((state) => ({ ...state, ...groupedOutcomeIdToBettingContext }));
        }
    } catch (error) {
        logger.error('SportsBetslipService', 'copyTicketToBetslipByOutcomeIds', error);
    }
}

export function isBetSlipLoadingMarkets() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    return some(betSlipMarketIdToOutcomeId, (outcomeId, marketId) => !marketInfoById[marketId]);
}

function hasErrorsByMarketId(marketId) {
    const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
    return (
        betSlipErrorByMarketId[marketId] &&
        betSlipErrorByMarketId[marketId].filter((error) => !isWarning(error) && !isInfo(error)).length
    );
}

export function isBetslipButtonDisabled() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const comboBetMarketIdByMatchId = getStoreValue(stores.sports.customBetbuilder.comboBetMarketIdByMatchId);
    const { betType, stakeByMarketId, MAStakeByMarketId } = getStoreValue(stores.sports.betSlipUserState);
    const marketIdsActive =
        isOpenbetAvailable() && betType === BET_TYPE.COMBO
            ? Object.values(comboBetMarketIdByMatchId)
            : Object.keys(betSlipMarketIdToOutcomeId);
    const betSlipErrorByMarketId = getStoreValue(stores.sports.betSlipErrorByMarketId);
    const isComboError = () =>
        Boolean(
            Object.values(pick(betSlipErrorByMarketId, marketIdsActive))
                .flat()
                .filter((error) => !isWarning(error) && !isInfo(error)).length,
        );
    const excludedErrorCodes = [LIMIT_EXCEEDED_ERROR];
    const genericErrors =
        betSlipErrorByMarketId[String(COMBO_MARKET_ID)]?.filter(
            (err) => !isWarning(err) && !isInfo(err) && !excludedErrorCodes.includes(err.code),
        ) || [];
    const isSingleBetsError = () => every(marketIdsActive, hasErrorsByMarketId);
    const isMarketError = [BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType) ? isSingleBetsError : isComboError;
    const minStake = getMinStake();
    if (!minStake) {
        return true;
    }
    const hasValidStakes = () => {
        const getStake = (marketId) =>
            (Number(stakeByMarketId[marketId]) || 0) + (Number(MAStakeByMarketId[marketId]) || 0);
        if (betType === BET_TYPE.SYSTEM) {
            const systemStakes = Object.values({
                ...getSystemActiveStakes(),
                ...getSystemManualAcceptanceActiveStakes(),
            }).map(Number);
            return sum(systemStakes) >= minStake;
        }
        if (betType === BET_TYPE.COMBO) {
            return getStake(COMBO_MARKET_ID) >= minStake;
        }
        if (betType === BET_TYPE.TEASER) {
            return getStake(COMBO_MARKET_ID) >= minStake;
        }
        const marketIdsNoError = (marketIdsActive as any).filter((marketId) => !hasErrorsByMarketId(marketId));
        return some(marketIdsNoError, (marketId) => getStake(marketId) >= minStake);
    };
    return (!hasBetslipManualAcceptanceError() && genericErrors.length) || isMarketError() || !hasValidStakes();
}

function getSystemActiveKeys() {
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
    const systemTextKeysActive = Object.keys(systemBets).map((integerKey) => systemBetTypeBySystemKey[integerKey]);
    return systemTextKeysActive;
}

function getSystemActiveStakes() {
    const systemTextKeysActive = getSystemActiveKeys();
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { systemStakes } = userState;
    return pick(systemStakes, systemTextKeysActive);
}

function getSystemManualAcceptanceActiveStakes() {
    const systemTextKeysActive = getSystemActiveKeys();
    const userState = getStoreValue(stores.sports.betSlipUserState);
    const { MASystemStakes } = userState;
    return pick(MASystemStakes, systemTextKeysActive);
}

function getCurrentBetslipState() {
    const betslipState: MultiBetslipState = {
        betslipUserState: getStoreValue(stores.sports.betSlipUserState),
        betslipSettings: storageGet('betslipSettings'),
        betSlipMarketIdToOutcomeId: getStoreValue(stores.sports.betSlipMarketIdToOutcomeId),
        betSlipErrorByMarketId: getStoreValue(stores.sports.betSlipErrorByMarketId),
        code: getStoreValue(stores.sports.qrCode),
        teaserSelectedPoint: getStoreValue(stores.sports.teaserSelectedPoint),
        betbuilderBetslipIdByMarketId: getStoreValue(stores.sports.betbuilderBetslipIdByMarketId),
    };
    return betslipState;
}

function getCurrentParlayBetslip() {
    const betslipState: MultiParlayState = {
        marketInfo: getStoreValue(stores.sports.parlayCard.marketInfo),
        betSlipMarketIdToOutcomeIds: getStoreValue(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds),
        stake: getStoreValue(stores.sports.parlayCard.stake),
        betSlipPlacingState: getStoreValue(stores.sports.parlayCard.betSlipPlacingState),
        betSlipErrors: getStoreValue(stores.sports.parlayCard.betSlipErrors),
        manualAcceptanceStake: getStoreValue(stores.sports.parlayCard.manualAcceptanceStake),
        code: getStoreValue(stores.sports.qrCode),
    };
    return betslipState;
}

export function storeAndClearSelection(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const { betslipState, betslipCollection, betslipPosition: position } = getBetslipData(betslipType);
    betslipCollection[position] = betslipState;
    const betslip = initialBetslip(betslipType);
    betslipCollection[betslipCollection.length] = betslip;
    if (betslipType === BetslipMode.Betslip) {
        stores.sports.betslipCollection.set(betslipCollection as MultiBetslipState[]);
        stores.sports.betSlipPosition.set(betslipCollection.length - 1);
    } else {
        stores.sports.parlayCard.parlayBetslipCollection.set(betslipCollection as MultiParlayState[]);
        stores.sports.parlayCard.betslipPosition.set(betslipCollection.length - 1);
    }
    clearBetslipSelection(betslipType);
}

function getBetslipData(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const data = {
        [BetslipMode.Betslip]: {
            betslipState: getCurrentBetslipState(),
            betslipCollection: [...getStoreValue(stores.sports.betslipCollection)],
            betslipPosition: getStoreValue(stores.sports.betSlipPosition),
        },
        [BetslipMode.ParlayCard]: {
            betslipState: getCurrentParlayBetslip(),
            betslipCollection: [...getStoreValue(stores.sports.parlayCard.parlayBetslipCollection)],
            betslipPosition: getStoreValue(stores.sports.parlayCard.betslipPosition),
        },
    };
    return data[betslipType];
}

function initialBetslip(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const betslipMap = {
        [BetslipMode.Betslip]: {
            betslipUserState: initialBetSlipUserState,
            betslipSettings: {
                betSlipMarketIdToOutcomeId: {},
                updatedAt: new Date().getTime(),
            },
            betSlipMarketIdToOutcomeId: {},
            betSlipErrorByMarketId: {},
            teaserSelectedPoint: undefined,
            betbuilderBetslipIdByMarketId: {},
        },
        [BetslipMode.ParlayCard]: {
            marketInfo: {},
            betSlipMarketIdToOutcomeIds: {},
            stake: 0,
            betSlipPlacingState: initialBetSlipPlacingState,
            betSlipErrors: [],
            manualAcceptanceStake: 0,
        },
    };

    return betslipMap[betslipType];
}

export function deleteBetSlip(betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const { betslipCollection, betslipPosition } = getBetslipData(betslipType);
    betslipCollection.splice(betslipPosition, 1);
    const position = betslipPosition - 1 < 0 ? betslipPosition : betslipPosition - 1;
    const betslipState = betslipCollection[position] || initialBetslip(betslipType);
    betslipType === BetslipMode.Betslip
        ? updateBetslipStore(betslipState as MultiBetslipState, position, betslipCollection as MultiBetslipState[])
        : updateParlayBetslipStore(betslipState as MultiParlayState, position, betslipCollection as MultiParlayState[]);
}

export function navigateToBetslip(position: number, betslipType: BetslipMode.Betslip | BetslipMode.ParlayCard) {
    const { betslipCollection, betslipPosition, betslipState: currentBetslipState } = getBetslipData(betslipType);
    betslipCollection[betslipPosition] = currentBetslipState;
    if (position < 0 || position >= betslipCollection.length) {
        return;
    }
    const betSlipState = betslipCollection[position] || {};
    betslipType === BetslipMode.Betslip
        ? updateBetslipStore(betSlipState as MultiBetslipState, position, betslipCollection as MultiBetslipState[])
        : updateParlayBetslipStore(betSlipState as MultiParlayState, position, betslipCollection as MultiParlayState[]);
}

function updateParlayBetslipStore(
    betSlipState: MultiParlayState,
    position: number,
    betslipCollection: MultiParlayState[],
) {
    const {
        marketInfo,
        betSlipMarketIdToOutcomeIds,
        stake,
        betSlipPlacingState,
        betSlipErrors,
        manualAcceptanceStake,
        code,
    } = betSlipState;

    stores.sports.parlayCard.betSlipPlacingState.set(betSlipPlacingState || initialBetSlipPlacingState);
    storageSet('parlayCardSettings', {
        betSlipMarketIdToOutcomeIds: betSlipMarketIdToOutcomeIds || {},
        updatedAt: new Date().getTime(),
    });
    stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set(betSlipMarketIdToOutcomeIds || {});
    stores.sports.parlayCard.betSlipErrors.set(betSlipErrors);
    stores.sports.parlayCard.marketInfo.set(marketInfo);
    stores.sports.parlayCard.stake.set(stake);
    stores.sports.parlayCard.manualAcceptanceStake.set(manualAcceptanceStake);
    stores.sports.parlayCard.betslipPosition.set(position);
    stores.sports.parlayCard.parlayBetslipCollection.set(betslipCollection);
    stores.sports.qrCode.set(code || Math.random().toString().substring(2, 8));
}

function updateBetslipStore(betSlipState: MultiBetslipState, position: number, betslipCollection: MultiBetslipState[]) {
    const {
        betslipUserState,
        betslipSettings,
        betSlipMarketIdToOutcomeId,
        betSlipErrorByMarketId,
        code,
        teaserSelectedPoint,
        betbuilderBetslipIdByMarketId,
    } = betSlipState;
    stores.sports.teaserSelectedPoint.set(teaserSelectedPoint);
    stores.sports.betbuilderBetslipIdByMarketId.set(betbuilderBetslipIdByMarketId || {});
    stores.sports.betSlipUserState.set(betslipUserState || initialBetSlipUserState);
    storageSet('betSlipSettings', {
        betSlipMarketIdToOutcomeId: betslipSettings?.betSlipMarketIdToOutcomeId || {},
        updatedAt: new Date().getTime(),
    });
    stores.sports.betSlipPosition.set(position);
    stores.sports.betSlipMarketIdToOutcomeId.set(betSlipMarketIdToOutcomeId || {});
    stores.sports.betSlipErrorByMarketId.set(betSlipErrorByMarketId || {});
    stores.sports.betslipCollection.set(betslipCollection);
    stores.sports.qrCode.set(code || Math.random().toString().substring(2, 8));
}

function clearBetslip() {
    stores.sports.betSlipMarketIdToOutcomeId.set({});
    stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId.set({});
}

export async function validateSameMatchCombo(marketsByMatchId: Record<number, BetSlipMinimalMarket[]>) {
    const marketsWithError: number[] = [];
    const matchesToValidateInBackend: IsSameMatchComboEligiblePayload = [];
    Object.entries(marketsByMatchId).forEach(([matchId, sameMatchMarkets]) => {
        if (sameMatchMarkets.length < 2) {
            return;
        }

        if (isOpenbetAvailable()) {
            const betbuilderRedundantMarketIdsByMatchId = getStoreValue(
                stores.sports.customBetbuilder.betbuilderRedundantMarketIdsByMatchId,
            );
            const redundantMarketIds = betbuilderRedundantMarketIdsByMatchId?.[matchId];
            if (redundantMarketIds === null) {
                addBetslipErrorForMatch(matchId, BETBUILDER_SELECTIONS_SET_IS_NOT_COMBINABLE);
            }
            sameMatchMarkets.forEach((market) => {
                if (!market.betbuilder_eligible) {
                    addBetslipError(market.id, SELECTION_NOT_BETBUILDER_ELIGIBLE);
                } else if (redundantMarketIds?.includes(market.id)) {
                    addBetslipError(market.id, BETBUILDER_SELECTION_IS_REDUNDANT);
                }
            });
        }

        const sameMatchMarketIds = sameMatchMarkets.map((mr) => mr.id);
        if (sameMatchMarkets.length > 2) {
            marketsWithError.push(...sameMatchMarketIds);
            return;
        }

        const marketsByMarketTypeId = groupBy(sameMatchMarkets, (market) => market.market_type_id);
        const multipleSameMarketTypeMarketIds = Object.values(marketsByMarketTypeId).find(
            (markets) => markets.length > 1,
        );
        if (multipleSameMarketTypeMarketIds) {
            marketsWithError.push(...sameMatchMarketIds);
            return;
        }

        const hasInplayMarket = sameMatchMarkets.some((mr) => mr.in_play);
        if (hasInplayMarket) {
            marketsWithError.push(...sameMatchMarketIds);
            return;
        }

        if (!sameMatchMarkets.every((outcome) => outcome.same_match_combo_allowed)) {
            marketsWithError.push(...sameMatchMarketIds);
            return;
        }

        const hasSomeMarketFor1stHalf = sameMatchMarkets.some(({ rawMarketName, marketName }) =>
            (rawMarketName || marketName).toLowerCase().includes('1st half'),
        );
        const hasSomeMarketForFullTime = sameMatchMarkets.some(
            ({ rawMarketName, marketName }) => !(rawMarketName || marketName).toLowerCase().includes('1st half'),
        );
        if (hasSomeMarketFor1stHalf && hasSomeMarketForFullTime) {
            marketsWithError.push(...sameMatchMarketIds);
            return;
        }

        const totalsMarket = sameMatchMarkets.find((mr) =>
            mr.outcomes.find((outcome) => ['over', 'under'].includes(outcome.result_key.toLowerCase())),
        );
        if (!totalsMarket) {
            marketsWithError.push(...sameMatchMarketIds);
            return;
        }

        const otherMarket = sameMatchMarkets.find((mr) => mr.id !== totalsMarket.id);
        matchesToValidateInBackend.push({
            matchId: Number(matchId),
            totalsMarketId: totalsMarket.id,
            handicapMarketId: otherMarket?.raw_line ? otherMarket.id : undefined,
        });
    });

    if (matchesToValidateInBackend.length) {
        const isSameMatchComboEligibleByMatchId = await isSameMatchComboEligible(matchesToValidateInBackend);
        Object.entries(isSameMatchComboEligibleByMatchId).forEach(([matchId, isEligible]) => {
            if (!isEligible) {
                marketsWithError.push(...marketsByMatchId[Number(matchId)].map((mr) => mr.id));
            }
        });
    }

    // need to make sure that betType is still combo because isSameMatchComboEligible can take some time
    const { betType } = getStoreValue(stores.sports.betSlipUserState);
    if (![BET_TYPE.COMBO, BET_TYPE.SYSTEM].includes(betType) || (betType === BET_TYPE.COMBO && isOpenbetAvailable())) {
        return;
    }

    marketsWithError.forEach((marketId) => {
        addBetslipError(marketId, COMBO_NOT_ALLOWED_ERROR);
    });
}

export const getBetslipQuickStakes = (limitAmountOfOption: number): number[] => {
    const currency = getActiveCurrency();
    const quickStakeChoices = {
        station: QUICK_STATION_STAKES,
        wynnbet: QUICK_WYNNBET_STAKES,
        default: QUICK_STAKES,
    };
    const allQuickStakes = quickStakeChoices[getClient()] || quickStakeChoices.default;
    return allQuickStakes[currency].slice(0, limitAmountOfOption);
};
export async function calculateTotalOddsWithoutOddsInStore(marketIds: number[], outcomeIds: number[]) {
    const currentOddsByOutcomeIds = await loadOddsByMarketIds(marketIds);
    const allOdds = outcomeIds.map((outcomeId) => currentOddsByOutcomeIds[outcomeId].value);
    return allOdds.reduce((totalOdds, oddsValue) => totalOdds * getPreciseOdds(convertOdds(oddsValue)), 1);
}

export function getStakeValueByMarketId(marketId: string | typeof COMBO_MARKET_ID) {
    const { stakeByMarketId } = getStoreValue(stores.sports.betSlipUserState);
    const stakeValue = (stakeByMarketId as Record<string, number>)[String(marketId)];

    return stakeValue || 0;
}

export function calculateMaxWinByMarketId(marketId: string | typeof COMBO_MARKET_ID) {
    const stakeValue = getStakeValueByMarketId(marketId);
    const totalOdds = calculateTotalOdds(marketId);

    return payoutRound(totalOdds * stakeValue);
}
