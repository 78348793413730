import moment from 'moment';
import React, { MouseEvent, useMemo } from 'react';
import { getPromotionAnalyticsInfo } from '../../../services/cms/promotion';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { convertOdds } from '../../../services/odds-format';
import { useSportBettingContext } from '../../../services/sports/bet-context';
import { removeAllNonFrontendErrors } from '../../../services/sports/betslip-errors';
import { ODDS_STATUS } from '../../../services/sports/constants';
import { useBetslipOddsByOutcomeId } from '../../../services/sports/hooks';
import { SportOddsProps as Props } from '../../../services/sports/odds';
import { useOddsFormat } from '../../../services/sports/user-settings';
import { stores, useStoreWithSelector } from '../../../stores';
import Svg from '../../svg/Svg';
import UiPlaceholder from '../../ui/placeholder/UiPlaceholder';
import Wrapper from './styles';
import { getLine } from '../../../services/sports/market';
import { CategoryMatchMarketOutcome, MarketInfo } from '../../../services/sports/types';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { resetComboCardBetSlip } from '../../../services/sports/combo-cards';
import { getStoreValue } from '../../../stores/store/utils';
import { filterStyleProps } from '../../../styles/utils';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { useClearAllModal } from '../../../hooks/betslip-v2';
import { useSportsMatchContext } from '../../../services/sports/match-helpers';
import { useIsMobileCompactView } from '../../../hooks/useIsMobileCompactView';
import { isOpenbetAvailable } from '../../../services/bet-builder';

function SportOdds({
    children,
    isAmericanLayout,
    isAmericanLayoutTopMarkets,
    isBetslip,
    isDisabled,
    isBetbuilderOdds = false,
    isOutcomeNameVisible,
    light,
    market,
    outcomeId,
    small,
    promotion,
}: Props) {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const isMobileCompactView = useIsMobileCompactView();
    const [{ isPhone, isTablet }] = useStore(media);
    const bettingContext = useSportBettingContext();
    const clearAllModal = useClearAllModal();
    const [betSlipMarketIdToOutcomeId, setBetSlipMarketIdToOutcomeId] = useStore(
        stores.sports.betSlipMarketIdToOutcomeId,
    );
    const [, setBettingContextByOutcomeId] = useStore(stores.sports.bettingContextByOutcomeId);
    const [, setBetbuilderBetSlipMarketIdToOutcomeId] = useStore(
        stores.sports.customBetbuilder.betbuilderBetSlipMarketIdToOutcomeId,
    );

    const isLineMarket = market.view_type === 'line';
    useOddsFormat();
    const { isBetbuilderActive } = useSportsMatchContext();

    const [betslipMarketOutcomeId] = useStoreWithSelector(
        stores.sports.betSlipMarketIdToOutcomeId,
        (state) => state[market.id],
        [market.id],
    );

    const {
        currentOdds,
        isOddsValueIncreased,
        isOddsAvailable,
        isOddsValueDecreased,
        currentOddsValue,
        shouldDisplayOddsValue,
    } = useBetslipOddsByOutcomeId(outcomeId, market.in_play, market.provider, !!isBetslip);

    const outcome = useMemo(() => market.outcomes.find(({ id }) => id === outcomeId), [market, outcomeId]);

    function checkIsBetbuilderWithNoGlobalId(outcome: CategoryMatchMarketOutcome) {
        const noGlobalIdAvailable = !Boolean(outcome.global_id);
        return isBetbuilderOdds && isBetbuilderActive && noGlobalIdAvailable;
    }
    const outcomeDisabled =
        outcome?.status !== ODDS_STATUS.OPEN || checkIsBetbuilderWithNoGlobalId(outcome as CategoryMatchMarketOutcome);

    const marketDisabled =
        market.status !== ODDS_STATUS.OPEN ||
        (market.betting_end && !market.in_play && moment(market.betting_end as MarketInfo['betting_end']).isBefore());

    const isOddsDisabled = isDisabled || !isOddsAvailable || marketDisabled || outcomeDisabled;
    const isOddsTooBig = currentOddsValue ? parseFloat(`${currentOddsValue}`).toFixed(2).length > 4 : false;

    if (!currentOdds || !outcome) {
        return (
            <Wrapper $isCompactViewEnabled={isPhone} $isDisabled>
                {isOutcomeNameVisible && (
                    <div className="odds-outcome">
                        <span className="outcome-name">
                            <UiPlaceholder />
                        </span>
                    </div>
                )}
                <button className="odds-button">
                    <span className="odds-value">
                        <span className="outcome-value">
                            <Svg icon="lock" size={0.75} className="odds-locked" />
                        </span>
                    </span>
                </button>
            </Wrapper>
        );
    }

    function addRemoveBetSlip() {
        const { isLoading, needsConfirm } = getStoreValue(stores.sports.betSlipPlacingState);

        if (isLoading || needsConfirm) {
            return;
        }

        if (betslipMarketOutcomeId === outcomeId && !isBetslip) {
            setBetSlipMarketIdToOutcomeId((state) => {
                delete state[market.id];
            });
            setBetbuilderBetSlipMarketIdToOutcomeId((state) => {
                delete state[market.id];
            });
            return;
        }

        resetComboCardBetSlip(Boolean(cardsInBetslip.length));
        setBetSlipMarketIdToOutcomeId((state) => {
            state[market.id] = outcomeId;
        });
        setBettingContextByOutcomeId((state) => {
            if (bettingContext) {
                state[outcomeId] = bettingContext;
            } else {
                delete state[outcomeId];
            }
        });
        removeAllNonFrontendErrors();
    }

    function toggleOddsSelection(event: MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        if (isOpenbetAvailable() && market.view_type === 'bet_builder') {
            return;
        }

        if (Object.values(betSlipMarketIdToOutcomeId).length > 0 && Boolean(cardsInBetslip.length)) {
            clearAllModal.show(addRemoveBetSlip);
        } else {
            if (isOddsDisabled) {
                return;
            }
            if (promotion) {
                trackGoogleTagManagerEvent(
                    TAG_MANAGER_EVENT.MARKETING_PROMOTION_CLICK,
                    getPromotionAnalyticsInfo(promotion),
                );
            }
            addRemoveBetSlip();
        }
    }

    const styleProps = {
        $isActive: betslipMarketOutcomeId === outcomeId || (isOpenbetAvailable() && market.view_type === 'bet_builder'),
        $isDisabled: isOddsDisabled,
        $oddsDecreased: isOddsValueDecreased,
        $oddsIncreased: isOddsValueIncreased,
        $oddsTooBig: isOddsTooBig,
        $lineTooBig: market.view_type === 'line' && market.raw_line > 99,
        light,
        small,
        $isCompactViewEnabled: isPhone || isTablet,
        $isMobileCompactView: isMobileCompactView,
        $isAmericanLayoutTopMarkets: isAmericanLayoutTopMarkets,
        $isPromotion: !!promotion,
    };

    return (
        <Wrapper {...filterStyleProps(styleProps)}>
            {isOutcomeNameVisible && !promotion && (
                <div className="odds-outcome">
                    <span className="outcome-name">{outcome.name}</span>
                    {!isAmericanLayout && (
                        <span className="outcome-line">
                            <span className="raw-line">
                                {market.view_type === 'line' &&
                                getLine(market, outcome) === undefined &&
                                outcome.result_key !== 'Draw'
                                    ? market.raw_line
                                    : ''}
                                {getLine(market, outcome)}
                            </span>
                        </span>
                    )}
                </div>
            )}
            <button
                className="odds-button"
                data-test={isOddsDisabled ? 'button-odds-disabled' : `button-odds-${market.id}`}
                onClick={toggleOddsSelection}
            >
                <span className="odds-value">
                    {shouldDisplayOddsValue && isOddsDisabled && (
                        <Svg icon="lock" size={0.75} className="odds-locked with-odd-value" />
                    )}
                    <>{children}</>
                    {isLineMarket && isAmericanLayout && (
                        <span className="outcome-line">{getLine(market as BetSlipMinimalMarket, outcome)}</span>
                    )}
                    <span
                        className="outcome-value"
                        data-test={betslipMarketOutcomeId === outcomeId ? 'selected-outcome-value' : ''}
                    >
                        {shouldDisplayOddsValue ? (
                            convertOdds(currentOddsValue)
                        ) : (
                            <Svg icon="lock" size={0.75} className="odds-locked" />
                        )}
                    </span>
                </span>
            </button>
        </Wrapper>
    );
}

export default React.memo(
    SportOdds,
    (prevProps, nextProps) =>
        prevProps.outcomeId === nextProps.outcomeId &&
        prevProps.market === nextProps.market &&
        prevProps.market.status === nextProps.market.status &&
        prevProps.isAmericanLayout === nextProps.isAmericanLayout,
);
