import { Licence } from '@staycool/location';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { isUserVerified } from '../../../services/kyc';
import { isWithLicence } from '../../../services/licence';
import { useProviderRouting } from '../../../services/payments/provider-routing';
import { PaymentProvider, PROVIDER_TYPE, PROVIDERS } from '../../../services/payments/types';
import { canUserDeposit } from '../../../services/payments/utils';
import { getLimits } from '../../../services/responsible-gaming';
import { LIMIT_TYPE, UserLimit } from '../../../services/responsible-gaming/types';
import { getRoute, useRouter } from '../../../services/router';
import { getDecodedToken } from '../../../services/token';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import { media } from '../../../stores/media/media';
import DangerousHtml from '../../dangerous-html/DangerousHtml';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import UiGroup from '../../ui/group/UiGroup';
import PaymentAmountSelector from '../amount-selector/PaymentAmountSelector';
import PaymentBanner from '../banner/PaymentBanner';
import PaymentDepositDeviceFingerprint from '../deposit/device-fingerprint/PaymentDepositDeviceFingerprint';
import PaymentDepositProviderAuthipayAibmsCard from '../deposit/provider/authipay/aibms-card/PaymentDepositProviderAuthipayAibmsCard';
import PaymentDepositProviderBanklink from '../deposit/provider/banklink/PaymentDepositProviderBanklink';
import PaymentDepositProviderBlixtpay from '../deposit/provider/blixtpay/PaymentDepositProviderBlixtpay';
import PaymentDepositProviderBraintreePaypal from '../deposit/provider/braintree-paypal/PaymentDepositProviderBraintreePaypal';
import PaymentDepositProviderCardEye from '../deposit/provider/card-eye/PaymentDepositProviderCardEye';
import PaymentDepositProviderCoolpayCard from '../deposit/provider/coolpay/card/PaymentDepositProviderCoolpayCard';
import PaymentDepositProviderDevcodeCreditCard from '../deposit/provider/devcode/credit-card/PaymentDepositProviderDevcodeCreditCard';
import PaymentDepositProviderDevcodeEcoPayz from '../deposit/provider/devcode/eco-payz/PaymentDepositProviderDevcodeEcoPayz';
import PaymentDepositProviderDevcodeInterac from '../deposit/provider/devcode/interac/PaymentDepositProviderDevcodeInterac';
import PaymentDepositProviderDevcodeMuchBetter from '../deposit/provider/devcode/much-better/PaymentDepositProviderDevcodeMuchBetter';
import PaymentDepositProviderDevcodeNeosurfVoucher from '../deposit/provider/devcode/neosurf-voucher/PaymentDepositProviderDevcodeNeosurfVoucher';
import PaymentDepositProviderDirecta24Bank from '../deposit/provider/directa-24/bank/PaymentDepositProviderDirecta24Bank';
import PaymentDepositProviderDirecta24Vouchers from '../deposit/provider/directa-24/vouchers/PaymentDepositProviderDirecta24Vouchers';
import PaymentDepositProviderEproCard from '../deposit/provider/epro/card/PaymentDepositProviderEproCard';
import PaymentDepositProviderEproCashlib from '../deposit/provider/epro/cashlib/PaymentDepositProviderEproCashlib';
import PaymentDepositProviderEveryPayApplePay from '../deposit/provider/every-pay/apple-pay/PaymentDepositProviderEveryPayApplePay';
import PaymentDepositProviderEveryPayGooglePay from '../deposit/provider/every-pay/google-pay/PaymentDepositProviderEveryPayGooglePay';
import PaymentDepositProviderGlobalpay from '../deposit/provider/globalpay/PaymentDepositProviderGlobalpay';
import PaymentDepositProviderLuqapayCommunityBank from '../deposit/provider/luqapay/community-bank/PaymentDepositProviderLuqapayCommunityBank';
import PaymentDepositProviderLuqapayVoucherCard from '../deposit/provider/luqapay/voucher-card/PaymentDepositProviderLuqapayVoucherCard';
import PaymentDepositProviderNeteller from '../deposit/provider/neteller/PaymentDepositProviderNeteller';
import PaymentDepositProviderNuveiApplePay from '../deposit/provider/nuvei/apple-pay/PaymentDepositProviderNuveiApplePay';
import PaymentDepositProviderNuveiCard from '../deposit/provider/nuvei/card/PaymentDepositProviderNuveiCard';
import PaymentDepositProviderPayNearMe from '../deposit/provider/pay-near-me/PaymentDepositProviderPayNearMe';
import PaymentDepositProviderPayhound from '../deposit/provider/payhound/PaymentDepositProviderPayhound';
import PaymentDepositProviderSightline from '../deposit/provider/sightline/PaymentDepositProviderSightline';
import PaymentDepositProviderTruelayer from '../deposit/provider/truelayer/PaymentDepositProviderTruelayer';
import PaymentDepositProviderTrustlyUs from '../deposit/provider/trustly-us/PaymentDepositProviderTrustlyUs';
import PaymentDepositProviderTrustly from '../deposit/provider/trustly/PaymentDepositProviderTrustly';
import PaymentDepositProviderWorldpay from '../deposit/provider/worldpay/PaymentDepositProviderWorldpay';
import PaymentProviderInfo from '../provider-info/PaymentProviderInfo';
import PaymentProvidersSlider from '../providers-slider/PaymentProvidersSlider';
import Wrapper from './styles';

interface Props {
    providers: PaymentProvider[];
    onClose?: () => void;
}

export default function PaymentQuickDeposit({ providers, onClose }: Props) {
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [token] = useStore(stores.token);
    const [deviceHash, setDeviceHash] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeviceFingerprintSdkLoaded, setIsDeviceFingerprintSdkLoaded] = useState(false);
    const [amountEur, setAmountEur] = useState(0);
    const [depositLimits, setDepositLimits] = useState<UserLimit[]>([]);
    const [error, setError] = useState('');
    const [depositAmount, setDepositAmount] = useState<number>(0);
    const [selectedProvider, setSelectedProvider] = useState<PaymentProvider>(providers[0]);
    const { displayedProviders, routedProvider } = useProviderRouting({
        providers,
        amount: amountEur,
        selectedProvider,
        type: PROVIDER_TYPE.DEPOSIT,
    });

    const modalProvidersMap = {
        [PROVIDERS.AUTHIPAY_AIBMS_CARD]: (
            <PaymentDepositProviderAuthipayAibmsCard
                amount={depositAmount}
                disclaimer={routedProvider?.disclaimer}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.CARD_EYE]: (
            <PaymentDepositProviderCardEye
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.COOLPAY_CARD]: (
            <PaymentDepositProviderCoolpayCard
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_BAMBORA]: (
            <PaymentDepositProviderDevcodeCreditCard
                amount={depositAmount}
                selectedDepositProvider={routedProvider as PaymentProvider}
                deviceHash={deviceHash}
                provider={PROVIDERS.DEVCODE_BAMBORA}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_CLEANPAY]: (
            <PaymentDepositProviderDevcodeCreditCard
                amount={depositAmount}
                selectedDepositProvider={routedProvider as PaymentProvider}
                deviceHash={deviceHash}
                provider={PROVIDERS.DEVCODE_CLEANPAY}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_KLUWP_MASTERCARD]: (
            <PaymentDepositProviderDevcodeCreditCard
                amount={depositAmount}
                selectedDepositProvider={routedProvider as PaymentProvider}
                deviceHash={deviceHash}
                provider={PROVIDERS.DEVCODE_KLUWP_MASTERCARD}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_KLUWP_VISA]: (
            <PaymentDepositProviderDevcodeCreditCard
                amount={depositAmount}
                selectedDepositProvider={routedProvider as PaymentProvider}
                deviceHash={deviceHash}
                provider={PROVIDERS.DEVCODE_KLUWP_VISA}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_SECURE_TRADING]: (
            <PaymentDepositProviderDevcodeCreditCard
                amount={depositAmount}
                selectedDepositProvider={routedProvider as PaymentProvider}
                deviceHash={deviceHash}
                provider={PROVIDERS.DEVCODE_SECURE_TRADING}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.EVERY_PAY_APPLE_PAY]: (
            <PaymentDepositProviderEveryPayApplePay
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.EPRO_KWICK_N_GO]: (
            <PaymentDepositProviderEproCard
                amount={depositAmount}
                disclaimer={routedProvider?.disclaimer}
                deviceHash={deviceHash}
                provider={PROVIDERS.EPRO_KWICK_N_GO}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.EPRO_PASS_N_GO]: (
            <PaymentDepositProviderEproCard
                amount={depositAmount}
                disclaimer={routedProvider?.disclaimer}
                deviceHash={deviceHash}
                provider={PROVIDERS.EPRO_PASS_N_GO}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.LUQAPAY_VOUCHER_CARD]: (
            <PaymentDepositProviderLuqapayVoucherCard
                amount={depositAmount}
                disclaimer={routedProvider?.disclaimer}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.BLIXTPAY]: (
            <PaymentDepositProviderBlixtpay
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.BRAINTREE_PAYPAL]: (
            <PaymentDepositProviderBraintreePaypal
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_ECOPAYZ]: (
            <PaymentDepositProviderDevcodeEcoPayz
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_MUCHBETTER]: (
            <PaymentDepositProviderDevcodeMuchBetter
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DIRECTA24_BANK]: (
            <PaymentDepositProviderDirecta24Bank
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DIRECTA24_VOUCHERS]: (
            <PaymentDepositProviderDirecta24Vouchers
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.EVERY_PAY_GOOGLE_PAY]: (
            <PaymentDepositProviderEveryPayGooglePay
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.EPRO_CASHLIB]: (
            <PaymentDepositProviderEproCashlib
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.GLOBALPAY]: (
            <PaymentDepositProviderGlobalpay
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_INTERAC_COMBINED]: (
            <PaymentDepositProviderDevcodeInterac
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                provider={PROVIDERS.DEVCODE_INTERAC_COMBINED}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_INTERAC_E_TRANSFER]: (
            <PaymentDepositProviderDevcodeInterac
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                provider={PROVIDERS.DEVCODE_INTERAC_E_TRANSFER}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_INTERAC_ONLINE]: (
            <PaymentDepositProviderDevcodeInterac
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                provider={PROVIDERS.DEVCODE_INTERAC_ONLINE}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.DEVCODE_NEOSURF_VOUCHER]: (
            <PaymentDepositProviderDevcodeNeosurfVoucher
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.LHV]: (
            <PaymentDepositProviderBanklink
                amount={depositAmount}
                deviceHash={deviceHash}
                provider={PROVIDERS.LHV}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.LUQAPAY_COMMUNITY_BANK]: (
            <PaymentDepositProviderLuqapayCommunityBank
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
                setAmount={setDepositAmount}
            />
        ),
        [PROVIDERS.PAYHOUND_BITCOIN]: (
            <PaymentDepositProviderPayhound
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
                provider={PROVIDERS.PAYHOUND_BITCOIN}
                routedProvider={routedProvider}
            />
        ),
        [PROVIDERS.PAYHOUND_USDT]: (
            <PaymentDepositProviderPayhound
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
                provider={PROVIDERS.PAYHOUND_USDT}
                routedProvider={routedProvider}
            />
        ),
        [PROVIDERS.NETELLER]: (
            <PaymentDepositProviderNeteller
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.NUVEI_APPLE_PAY]: (
            <PaymentDepositProviderNuveiApplePay
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.NUVEI_CARD]: (
            <PaymentDepositProviderNuveiCard
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.PAY_NEAR_ME_VOUCHER]: (
            <PaymentDepositProviderPayNearMe
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                provider={PROVIDERS.PAY_NEAR_ME_VOUCHER}
            />
        ),
        [PROVIDERS.SEB]: (
            <PaymentDepositProviderBanklink
                amount={depositAmount}
                deviceHash={deviceHash}
                provider={PROVIDERS.SEB}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.SIGHTLINE]: (
            <PaymentDepositProviderSightline
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
            />
        ),
        [PROVIDERS.TRUELAYER]: (
            <PaymentDepositProviderTruelayer
                amount={depositAmount}
                deviceHash={deviceHash}
                onClose={() => setIsModalOpen(false)}
            />
        ),
        [PROVIDERS.TRUSTLY]: (
            <PaymentDepositProviderTrustly
                amount={depositAmount}
                deviceHash={deviceHash}
                selectedDepositProvider={routedProvider as PaymentProvider}
            />
        ),
        [PROVIDERS.TRUSTLY_US]: <PaymentDepositProviderTrustlyUs amount={depositAmount} deviceHash={deviceHash} />,
        [PROVIDERS.WORLDPAY]: (
            <PaymentDepositProviderWorldpay
                amount={depositAmount}
                deviceHash={deviceHash}
                disclaimer={routedProvider?.disclaimer}
                onClose={() => setIsModalOpen(false)}
                selectedDepositProvider={routedProvider as PaymentProvider}
            />
        ),
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setupDeviceFingerprinting();
    }, [isDeviceFingerprintSdkLoaded]);

    async function init() {
        const limits = await getLimits(LIMIT_TYPE.DEPOSIT);
        setDepositLimits(limits);
    }

    function setupDeviceFingerprinting() {
        if (!isDeviceFingerprintSdkLoaded) {
            return;
        }
        const { login_session_id } = getDecodedToken(token);
        seon.config({
            host: 'seondf.com',
            session_id: login_session_id,
            audio_fingerprint: true,
            canvas_fingerprint: true,
            webgl_fingerprint: true,
            onSuccess: () => {
                seon.getBase64Session((hash) => {
                    setDeviceHash(hash);
                });
            },
            onError: (message) => console.log(message),
        });
    }

    function isSwedishVerificationRequired() {
        return isWithLicence(Licence.SWEDEN) && !isUserVerified() && routedProvider?.slug !== PROVIDERS.TRUSTLY;
    }

    function tryDeposit() {
        const { error: validationError } = canUserDeposit({
            depositAmount,
            provider: routedProvider as PaymentProvider,
            depositLimits,
        });

        if (validationError) {
            setError(validationError);
            return;
        }

        setIsModalOpen(true);
    }

    if (!displayedProviders.length) {
        return (
            <>
                <PaymentBanner paymentType="deposit" />
                <p>{translate('No payment providers available', 'ui.account')}</p>
            </>
        );
    }

    return (
        <Wrapper>
            {!isModalOpen && (
                <>
                    <PaymentBanner paymentType="deposit" />
                    <h2 className="label">{translate('Payment providers', 'ui.account')}</h2>
                    <div className="providers-list">
                        {selectedProvider && <PaymentProviderInfo selectedProvider={selectedProvider} />}
                        <PaymentProvidersSlider
                            providers={displayedProviders}
                            selectedProvider={selectedProvider}
                            onProviderSelected={(provider) => {
                                setError('');
                                setSelectedProvider(provider);
                            }}
                            type={PROVIDER_TYPE.DEPOSIT}
                        />
                        <a
                            className="all-methods-link"
                            href={getRoute('deposit')}
                            onClick={(e) => {
                                e.preventDefault();
                                navigateTo(getRoute('deposit'));
                                onClose?.();
                            }}
                        >
                            {translate('All deposit methods', 'ui.account')}
                        </a>
                    </div>

                    {!selectedProvider?.hideAmountSelector && (
                        <>
                            <h2 className="label small-margin-bot">{translate('Deposit amount', 'ui.account')}</h2>
                            <PaymentAmountSelector
                                className="deposit-amount-selector"
                                onAmountChanged={(amount, amountEur) => {
                                    setError('');
                                    setDepositAmount(amount);
                                    setAmountEur(amountEur);
                                }}
                                selectedAmount={depositAmount}
                            />
                        </>
                    )}
                    {error && (
                        <UiAlert failure>
                            <DangerousHtml content={error} />
                        </UiAlert>
                    )}
                    <UiGroup vertical={isPhone}>
                        {isSwedishVerificationRequired() ? (
                            <UiButton
                                color="primary"
                                block={isPhone}
                                onClick={() => navigateTo(getRoute('account.verification'))}
                            >
                                {translate('Verify account', 'ui.account')}
                            </UiButton>
                        ) : (
                            <UiButton color="primary" block={isPhone} onClick={tryDeposit} className="deposit-button">
                                {translate('Continue', 'ui.account')}
                            </UiButton>
                        )}
                    </UiGroup>
                </>
            )}

            {(isModalOpen && routedProvider && modalProvidersMap[routedProvider.slug]) || null}
            <PaymentDepositDeviceFingerprint onScriptLoaded={() => setIsDeviceFingerprintSdkLoaded(true)} />
        </Wrapper>
    );
}
