import React from 'react';
import { FoComboCardMatch } from '@staycool/sports-types/fo-combo-card';
import Wrapper from './styles';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';
import { DatabaseComboCardType } from '@staycool/sports-types';

interface Props {
    matches: FoComboCardMatch[];
    type: DatabaseComboCardType;
}

export default function ComboCardsItemDetailsSingleSport({ matches, type }: Props) {
    return (
        <Wrapper $accentColor={type.bg_color}>
            {matches.map(({ markets, name }) => (
                <div key={name} className="dot-indicator">
                    <div className="match-name">{name}</div>

                    {markets.map(({ id, outcome_name }) => (
                        <DangerousHtml key={id} content={outcome_name} />
                    ))}
                </div>
            ))}
        </Wrapper>
    );
}
