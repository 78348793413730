import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    margin-bottom: var(--spacing-8);
    display: flex;
    font-size: var(--font-12);
    gap: 1rem;

    @media ${mediaQuery.isPhone} {
        justify-content: center;
        flex-wrap: wrap;
    }

    .payment-method-info {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem 1rem;
        justify-content: center;

        border: 2px solid var(--color-highlight);
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        opacity: 1;
        padding: 0.5rem;

        .info-box {
            display: flex;
            flex-direction: row;
            gap: var(--spacing-4);

            .title {
                font-weight: 500;
            }
            .content {
                color: var(--font-color-secondary);
            }
        }
    }

    .e-verification {
        border: 1px solid var(--color-info);
        background-color: var(--alert-info-bg);
        .info-box {
            color: var(--color-info);
        }
    }
`;
