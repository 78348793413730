import styled from 'styled-components';

export default styled.div`
    margin-top: 1rem;
    .description {
        color: var(--font-color-secondary);
        padding-bottom: 0.8rem;
        padding-top: 0.8rem;
        padding-left: 0.3rem;
    }
    em {
        font-weight: bold;
        font-style: normal;
        color: var(--font-color-primary);
    }
    .ssn-input {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
    .toggle {
        width: 180px;
        padding-left: 0.3rem;
        margin-bottom: 0.5rem;
    }
`;
