import styled from 'styled-components';
import { mediaQuery } from '../../../../../../styles/utils';

type StyledProps = {
    $isAmericanLayout?: boolean;
    $isMobileCompactView?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;

    position: relative;
    width: 100%;

    align-self: flex-end;
    @media ${mediaQuery.isPhone} {
        position: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'initial' : 'relative')};
        align-self: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'flex-start' : 'center')};
    }
    .match-market-name {
        position: absolute;
        top: -1.2rem;
        left: 50%;
        transform: translateX(-50%);

        color: var(--match-market-color);
        text-align: center;
        font-size: 0.65rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: 0.1px;
        user-select: none;
        width: 100%;
        opacity: 0;
        border-radius: 100px;
        padding: 0.2rem;
        &.line-market-name {
            padding-right: calc(25px + 0.4rem);
        }
        @media ${mediaQuery.isPhone} {
            display: none;
        }
    }
    .match-market-outcome {
        display: flex;
        align-items: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '' : 'flex-end')};
        flex-direction: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'column' : 'row')};
        width: 100%;
        min-width: 0;
        @media ${mediaQuery.isPhone} {
            width: 100%;
        }
        &.line-market {
            @media ${mediaQuery.isPhone} {
                margin-left: auto;
            }
        }
        > * {
            flex: 1;
            min-width: 55px;
            &:not(:last-of-type) {
                margin-bottom: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '0.2rem' : '')};
                margin-right: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '' : '0.2rem')};
                @media ${mediaQuery.isPhone} {
                    margin-bottom: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '0.4rem' : '')};
                }
            }
        }
        > .match-market-line {
            position: relative;

            display: flex;
            flex: 0 1 auto;
            justify-content: center;
            align-items: center;

            min-width: 35px;
            height: var(--odds-height);
            padding: 0 0.4rem;

            color: var(--match-secondary-color);
            font-size: 0.6rem;

            display: none;

            @media ${mediaQuery.isPhone} {
                height: var(--odds-height-mobile);
            }
        }
    }
    .market-line-select {
        position: relative;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        align-self: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '' : 'flex-end')};

        height: ${({ $isAmericanLayout }) =>
            $isAmericanLayout ? 'var(--odds-american-height)' : 'var(--odds-height)'};

        width: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '30px' : '20px')};
        min-width: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '30px' : '20px')};
        max-width: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '30px' : '20px')};

        background-color: var(--match-line-select-bg);
        border: var(--match-line-select-border);
        border-radius: var(--odds-radius);

        margin-left: 0.2rem;

        color: var(--font-color-subtle);
        user-select: none;
        @media ${mediaQuery.isLaptop} {
            display: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'flex' : 'none')};
        }
        &.selected {
            background-color: var(--button-active-bg) !important;
            border: var(--button-active-border) !important;
            color: var(--button-active-text-color) !important;
            &:hover {
                background-color: var(--button-active-bg-hover) !important;
                color: var(--button-active-text-color-hover) !important;
            }
        }
        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.4;
            box-shadow: none;
        }
        @media ${mediaQuery.isPhone} {
            height: ${({ theme, $isAmericanLayout }) =>
                $isAmericanLayout
                    ? theme.match.marketLineSelectHeightAmericanMobile
                    : theme.match.marketLineSelectHeightMobile};
            width: ${({ theme, $isAmericanLayout }) =>
                $isAmericanLayout ? '100%' : theme.match.marketLineSelectWidthMobile};
            max-width: ${({ theme, $isAmericanLayout }) =>
                $isAmericanLayout ? '100%' : theme.match.marketLineSelectWidthMobile};
            min-width: ${({ theme, $isAmericanLayout }) =>
                $isAmericanLayout ? '100%' : theme.match.marketLineSelectWidthMobile};

            border-radius: ${({ theme, $isAmericanLayout }) =>
                $isAmericanLayout ? '0 0 var(--ui-radius) var(--ui-radius)' : theme.match.marketLineSelectRadius};
            margin: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '0.2rem 0 0 0' : '0 0 0 0.2rem')};

            background-color: var(--match-line-select-bg);
            z-index: 5;
        }

        @media ${mediaQuery.isTablet} {
            height: ${({ theme, $isAmericanLayout }) =>
                $isAmericanLayout
                    ? theme.match.marketLineSelectHeightAmericanTablet
                    : theme.match.marketLineSelectHeightMobile};
        }
    }

    .market-lines-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 4;

        padding: 0.4rem;
        margin-top: 0.2rem;

        background-color: var(--match-line-list-bg);
        border: var(--match-line-list-border);
        border-radius: 0 0 var(--ui-radius) var(--ui-radius);
        box-shadow: rgb(0 0 0 / 56%) 0px 45px 50px 0;
        @media ${mediaQuery.isPhone} {
            width: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '100%' : `calc(100% - ( 25px + 0.2rem))`)};
            margin-top: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '-33px' : '')};
            padding-top: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'calc(32px + 0.8rem)' : '')};
        }
        .market-lines-list-odds {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            min-width: 0;
            > * {
                margin-right: 0.2rem;
                margin-bottom: 0.2rem;
                @media ${mediaQuery.isPhone} {
                    margin-right: 0.25rem;
                    margin-bottom: 0.25rem;
                }
            }
            button {
                font-size: 0.85rem;
                height: 32px;
                flex: 1;
            }
        }

        .close-button {
            margin: 0;
            padding: var(--spacing-12);
        }
    }
    .no-odds-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: ${({ $isMobileCompactView, $isAmericanLayout }) =>
            $isMobileCompactView && !$isAmericanLayout ? '1.6rem' : ''};
    }
    .no-odds-available {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 0.8rem;

        width: 100%;
        min-height: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'calc(80px + 0.2rem)' : 'var(--odds-height)')};
        color: var(--match-secondary-color);
        font-size: 0.7rem;
        text-align: center;
        cursor: pointer;

        background-color: var(--odds-bg);
        border: var(--odds-border);
        border-radius: var(--odds-radius);

        margin-top: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '' : '19px')};

        &:not(:last-of-type) {
            margin-bottom: 0.2rem;
            display: ${({ $isAmericanLayout }) => ($isAmericanLayout ? 'none' : '')};
            @media ${mediaQuery.isPhone} {
                margin-bottom: 0.4rem;
                display: flex;
            }
        }

        @media ${mediaQuery.isPhone} {
            min-height: ${({ $isAmericanLayout }) => ($isAmericanLayout ? '116px' : 'var(--odds-height-mobile)')};
            margin-top: initial;
        }
    }
`;
