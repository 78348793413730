import React from 'react';
import { getRoute, useRouter } from '../../../../services/router';
import { stores } from '../../../../stores';
import UiModal from '../../../ui/modal/UiModal';
import HorseRacingBetslip from '../../betslip/HorseRacingBetslip';
import HorseRacingSidebarRacebookClosed from '../../sidebar/racebook-closed/HorseRacingSidebarRacebookClosed';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { useStore } from '../../../../hooks/useStore';

export default function HorseRacingPhoneNavigation() {
    const { navigateTo } = useRouter();
    const [isBetslipOpen, setIsBetslipOpen] = useStore(stores.horseRacing.isBetslipOpen);
    const [{ totalCombinations }] = useStore(stores.horseRacing.betslip);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isRacebookClosed] = useStore(stores.horseRacing.isRacebookClosed);

    return (
        <Wrapper>
            <div onClick={() => setIsBetslipOpen(true)} className="safe-area">
                <div className="betslip-link">
                    <div className="bets-count">{totalCombinations <= 99 ? totalCombinations : '99+'}</div>
                </div>
            </div>
            {isAuthenticated && (
                <div onClick={() => navigateTo(getRoute('horse-racing.bet-history'))} className="safe-area">
                    <div className="bet-history-link">
                        <Svg icon="bethistory" size={1} />
                    </div>
                </div>
            )}
            <UiModal
                open={isBetslipOpen}
                onClose={() => setIsBetslipOpen(false)}
                onOpen={() => setIsBetslipOpen(true)}
                mode="drawer"
            >
                {isRacebookClosed ? (
                    <HorseRacingSidebarRacebookClosed />
                ) : (
                    <div className="betslip-box">
                        <HorseRacingBetslip />
                    </div>
                )}
            </UiModal>
        </Wrapper>
    );
}
