import React, { useState } from 'react';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import UiButton from '../../../../ui/button/UiButton';
import Ui2Form from '../../../../ui-2/form/Ui2Form';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiGroup from '../../../../ui/group/UiGroup';
import Wrapper from './styles';
import Ui2FormRadio from '../../../../ui-2/form/radio/Ui2FormRadio';
import PaymentProvidersCustomGlobalpayMethodDeletion from '../method-deletion/PaymentProvidersCustomGlobalpayMethodDeletion';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { GlobalpayMethod } from '../../../../../services/payments/globalpay';

interface Props {
    onDelete: (providerMethod: GlobalpayMethod) => Promise<void>;
    onSubmit: (values?: any) => Promise<void>;
    providerMethods: GlobalpayMethod[];
    setIsAddingAccount: React.Dispatch<React.SetStateAction<boolean>>;
    setIsChangingLimit?: React.Dispatch<React.SetStateAction<boolean>>;
    onClose: () => void;
    useFormMethods: UseFormReturn<FieldValues, any>;
}

export default function PaymentProvidersCustomGlobalpayExistingMethods({
    onDelete,
    onSubmit,
    providerMethods,
    setIsAddingAccount,
    setIsChangingLimit,
    onClose,
    useFormMethods,
}: Props) {
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState<GlobalpayMethod>();
    const shouldDisplayDeleteButton = providerMethods.length > 1;

    function displayMethodDeletion(method: GlobalpayMethod) {
        setSelectedMethod(method);
        setIsDeleting(true);
    }
    return (
        <Wrapper>
            <Ui2Form useFormMethods={useFormMethods} onSubmit={onSubmit}>
                {isDeleting && selectedMethod ? (
                    <PaymentProvidersCustomGlobalpayMethodDeletion
                        providerMethod={selectedMethod}
                        onDelete={onDelete}
                        setIsDeleting={setIsDeleting}
                    />
                ) : (
                    <UiFormGroup>
                        <Ui2FormRadio
                            className="methods"
                            radioNowrap={true}
                            radioRow={false}
                            name="providerMethod"
                            options={providerMethods?.map((method, index) => ({
                                label: `${method.bankName} - ****${method.accountNumber.slice(-4)}`,
                                value: method.accountNumber,
                                defaultChecked: index === 0,
                                editOptions: shouldDisplayDeleteButton && (
                                    <Svg
                                        icon="trash"
                                        size={1}
                                        className="icon icon-alignment"
                                        onClick={() => displayMethodDeletion(method)}
                                    />
                                ),
                            }))}
                        />
                        <Wrapper>
                            <UiGroup className="buttons">
                                <UiButton color="default" block onClick={onClose}>
                                    {translate('Back', 'ui.account')}
                                </UiButton>
                                <UiButton type="button" isFormSubmitButton color="primary" block>
                                    {translate('Continue', 'ui.account')}
                                </UiButton>
                            </UiGroup>
                        </Wrapper>
                        <UiButton color="default" block size="small" onClick={() => setIsAddingAccount(true)}>
                            {translate('Add new account', 'ui.account')}
                        </UiButton>
                        {setIsChangingLimit && (
                            <UiButton color="default" block size="small" onClick={() => setIsChangingLimit(true)}>
                                {translate('Change limits', 'ui.account')}
                            </UiButton>
                        )}
                    </UiFormGroup>
                )}
            </Ui2Form>
        </Wrapper>
    );
}
