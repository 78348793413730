import styled from 'styled-components';

export default styled.div`
    .button-container {
        max-width: 400px;
    }
    @media (min-width: 767px) {
        iframe {
            height: 400px;
        }
    }
`;
