import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { storageGet } from '../services/storage';
import { isProd } from '../services/environment';
import { RaceResults } from '@staycool/lvdc-types/race-results';
import { BetHistoryFilter, GetBetsHistoryResponse } from '@staycool/lvdc-types/bet-history';
import { BetPlacementResponse, TicketReceipt } from '@staycool/lvdc-types/bets';
import { TodaysTrack } from '@staycool/lvdc-types/track';
import { RaceCarryover } from '@staycool/lvdc-types/race';
import { getStoreValue } from '../stores/store/utils';
import { environment } from '../stores/environment/environment';
import {
    GetTrackRaceReplayFilter,
    GetTrackRaceReplayResponse,
    LivestreamDetails,
    RacePoolTotal,
    TrackRaceDetails,
} from './lvdc/types';
import { getCurrentDateString } from '../services/lvdc/utils';

const getUrl = (url) => getServiceUrl('lvdc-web', url);

export async function loadTodaysTracks() {
    try {
        const url = getUrl(`tracks`);
        const queryProps: { date: string; associationId?: number } = { date: getCurrentDateString() };

        return await httpGet<TodaysTrack[]>(url, queryProps);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadTodaysTracks', error);
        return [];
    }
}

export async function loadAdvanceWageringTracks() {
    try {
        const url = getUrl(`tracks/advance-wagering-tracks`);
        const queryProps: { date: string; associationId?: number } = { date: getCurrentDateString() };
        return await httpGet<TodaysTrack[]>(url, queryProps);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadAdvanceWageringTracks', error);
        return [];
    }
}

export async function loadTrackRaceDetails(nevadaTrackId: number, raceId: number, date: string) {
    try {
        const url = getUrl(`tracks/details`);
        const queryProps: { date: string; nevadaTrackId: number; raceId: number; associationId?: number } = {
            date,
            nevadaTrackId,
            raceId,
        };

        const trackDetails = await httpGet<TrackRaceDetails | undefined>(url, queryProps);
        if (!isProd() && storageGet('development.horse-racing-dev-mode')) {
            return {
                ...trackDetails,
                betTypes: trackDetails?.betTypes.map((betType) => ({ ...betType, is_betting_enabled: true })),
                state: {
                    ...trackDetails?.state,
                    isPastRace: false,
                },
            } as TrackRaceDetails;
        }
        return trackDetails;
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadTrackRaceDetails', error);
        return {} as TrackRaceDetails;
        // TODO: meaningful error that load failed
    }
}

export async function getBetHistory(filter: BetHistoryFilter) {
    try {
        const url = getUrl(`bets-history`);
        return await httpPost<GetBetsHistoryResponse>(url, filter);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getBetHistory', error);
        return null;
    }
}

export async function getFollowingRaces(nevadaTrackId: number | undefined, raceIds: number[], date: string) {
    if (!nevadaTrackId) {
        return [];
    }
    try {
        const url = getUrl('tracks/following-races');
        return await httpPost<TrackRaceDetails[]>(url, { date, nevadaTrackId, raceIds });
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getFollowingRaces', error);
        return [];
    }
}

export async function placeHorseRacingBet(betPlacingData) {
    const url = getUrl(`bets`);

    return httpPost<BetPlacementResponse>(url, {
        ...betPlacingData,
    });
}

export async function getTicketReceipt(ticketId: number) {
    const url = getUrl(`ticket/receipt/${ticketId}`);
    return httpGet<TicketReceipt>(url);
}

export async function loadCarryovers() {
    try {
        const url = getUrl(`race/${getCurrentDateString()}/carryovers/`);
        return await httpGet<RaceCarryover[]>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'loadCarryovers', error);
        return [];
    }
}

export async function getResults(nevadaTrackId: number, raceId: number, date?: string) {
    try {
        const url = getUrl(`race-results/${date ?? getCurrentDateString()}/${nevadaTrackId}/${raceId || 1}/`);
        return await httpGet<RaceResults>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getResults', error);
    }
}

export async function getTrackRaceReplayUrl(filter: GetTrackRaceReplayFilter) {
    try {
        const { RCN_REFERER } = getStoreValue(environment);
        const url = getUrl(`replay/fetch-url`);
        return await httpPost<GetTrackRaceReplayResponse>(url, { ...filter, RCN_REFERER });
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getTrackRaceReplayUrl', error);
        return null;
    }
}

export async function getRacePoolTotals(nevadaTrackId: number, raceId: number, date: string) {
    try {
        const url = getUrl(`/race-betting-pool/total/${date}/${nevadaTrackId}/${raceId}`);
        return await httpGet<RacePoolTotal[]>(url);
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getRacePoolTotals', error);
        return [];
    }
}

export async function getLivestreamDetails(nevadaTrackId: number, raceId: number) {
    try {
        const url = getUrl(`tracks/livestream-info`);
        const { livestream } = await httpGet<{ livestream: LivestreamDetails }>(url, {
            date: getCurrentDateString(),
            nevadaTrackId,
            raceId,
        });
        return livestream;
    } catch (error) {
        logger.error('LvdcWebMicroservice', 'getLivestreamDetails', error);
        return { code: null, is_stream_available: false };
    }
}
