import React, { useEffect } from 'react';
import Wrapper from './styles';
import SportBetslipWrapper from '../../betslip-wrapper/SportBetslipWrapper';
import SportSponsorshipBanner from '../../sponsorship-banner/SportSponsorshipBanner';
import { stores, useStoreWithSelector } from '../../../../stores';
import isEmpty from 'lodash/isEmpty';
import PromotionSlider from '../../../promotion/slider/PromotionSlider';
import { BetslipMode } from '../../../../services/sports/types';
import { getRoute, isActiveRoute } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import UiButton from '../../../ui/button/UiButton';
import SportCashoutList from '../../cashout/list/SportCashoutList';
import SportTicketList from '../../ticket/list/SportTicketList';
import SportBetslipMode from '../../betslip/mode/SportBetslipMode';
import { isFeatureAvailable } from '../../../../services/feature';
import SportParlayCardBetslip from '../../parlay-card/betslip/SportParlayCardBetslip';
import UiAnimate from '../../../ui/animate/UiAnimate';
import ComboCardsBetslip from '../../../combo-cards/betslip/ComboCardsBetslip';
import { PRODUCT } from '../../../../types/common';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import SportContestsBetslip from '../../contests/betslip/SportContestsBetslip';
import classnames from 'classnames';
import { isTestUser } from '../../../../services/user';

export default function SportSidebarRight() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipMode, setBetSlipMode] = useStore(stores.sports.betslipMode);
    const [receiptById] = useStoreWithSelector(stores.sports.betSlipPlacingState, (state) => state.receiptById);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const isBetslipEmpty = isEmpty(betSlipMarketIdToOutcomeId) && isEmpty(receiptById);
    const isParlayCardRoute = isActiveRoute(getRoute('sport.parlay-cards'), false);
    const isContestsRoute = isActiveRoute(getRoute('sport.contests'), false);
    const shouldDisplayNormalBetslip =
        ((isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser()) || isEmpty(cardsInBetslip)) &&
        !isParlayCardRoute &&
        !isContestsRoute;

    useEffect(() => {
        setBetSlipMode(BetslipMode.Betslip);
    }, [betSlipMarketIdToOutcomeId]);

    function renderCustomBetslip() {
        if (isParlayCardRoute) {
            return (
                <div className="betslip-box">
                    <SportParlayCardBetslip />
                </div>
            );
        }

        if (isContestsRoute) {
            return (
                <div className="betslip-box">
                    <SportContestsBetslip />
                </div>
            );
        }
    }

    return (
        <Wrapper>
            {isBetslipEmpty && isFeatureAvailable(FEATURE.PROMOTIONS) && (
                <>
                    <div className="promo-banner">
                        <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                            <PromotionSlider product={PRODUCT.SPORTSBOOK} />
                        </UiAnimate>
                    </div>
                </>
            )}

            {shouldDisplayNormalBetslip ? (
                <div
                    className={classnames({
                        'betslip-box': !(isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser()),
                        'betslip-box-v2': isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser(),
                    })}
                >
                    <SportBetslipWrapper />

                    {!(isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser()) && isBetslipEmpty && isAuthenticated && (
                        <>
                            <SportBetslipMode betslipMode={betSlipMode} onBetslipModeChanged={setBetSlipMode} />

                            {betSlipMode === BetslipMode.History && isAuthenticated && (
                                <div className="tickets-list">
                                    <SportTicketList isCompactView isBetHistory />
                                    <UiButton url={getRoute('sport.bet-history')} block className="show-all-button">
                                        {translate('Show All Tickets', 'ui.sportsbook')}
                                    </UiButton>
                                </div>
                            )}

                            {betSlipMode === BetslipMode.Cashout && isAuthenticated && (
                                <div className="tickets-list">
                                    <SportCashoutList />
                                </div>
                            )}
                        </>
                    )}
                </div>
            ) : (
                renderCustomBetslip()
            )}

            {/*COMBOCARDS MOVED UNDER NORMAL BETSLIP IN V2*/}
            {Boolean(cardsInBetslip.length) && !(isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser()) && (
                <div className="betslip-box">
                    <ComboCardsBetslip />
                </div>
            )}

            {isBetslipEmpty && isFeatureAvailable(FEATURE.SPONSORSHIP_BANNER) && (
                <div className="sponsorship-banner-box">
                    <SportSponsorshipBanner />
                </div>
            )}
        </Wrapper>
    );
}
