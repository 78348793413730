import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @media ${mediaQuery.isPhone} {
        margin-top: 0;
    }
    .slick-slider .slick-list {
        padding: 0 0.5rem;
    }
`;
