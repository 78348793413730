import { ContestLegMarket } from '../types/components/contests/types';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from './date';

function getTeamNameByOutcomeResultKey(resultKey: string, market: ContestLegMarket) {
    if (resultKey === '[Home]') {
        return market.home_team_name;
    }

    if (resultKey === '[Away]') {
        return market.away_team_name;
    }
}

function getTeamNameByOutcomeName(outcomeName: string, market: ContestLegMarket) {
    if (outcomeName === '[Away]') {
        return market.away_team_name;
    }

    if (outcomeName === '[Home]') {
        return market.home_team_name;
    }

    return '';
}

function formatMarketMatchNameForTicket(marketMatchName: string) {
    return marketMatchName.replace('-', '@');
}

function getLineByResultKey(resultKey: string, line: number) {
    if (line === 0) {
        return '';
    }

    if (resultKey === '[Home]') {
        return line > 0 ? `+${line}` : line;
    }

    if (resultKey === '[Away]') {
        return line > 0 ? `-${line}` : `+${-line}`;
    }
}

function getLineByOutcomeName(outcomeName: string, line) {
    if (line === 0) {
        return 0;
    }

    if (line < 0) {
        return outcomeName === '[Home]' ? line : `+${-line}`;
    }

    if (line > 0) {
        return outcomeName === '[Home]' ? `+${line}` : -line;
    }
}

function getMatchTimeStart(market: ContestLegMarket) {
    return getFormattedDate({
        date: market.match_start,
        format: DATE_YEAR_TIME_FORMAT,
    });
}

function formatMarketName(marketName: string) {
    return marketName
        .replace('Handicap', 'Spread')
        .replace('1X2', 'Home-Draw-Away')
        .replace('Teaser ', '')
        .replace('Parlay ', '');
}

export {
    getTeamNameByOutcomeName,
    getTeamNameByOutcomeResultKey,
    getLineByOutcomeName,
    getLineByResultKey,
    formatMarketMatchNameForTicket,
    formatMarketName,
    getMatchTimeStart,
};
