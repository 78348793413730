import React, { useEffect } from 'react';
import Wrapper from './styles';
import { CategoryMatch } from '../../../../services/sports/types';
import SportMatchBetbuilderSportcast from './sportcast/SportMatchBetbuilderSportcast';
import { useSportsMatchContext } from '../../../../services/sports/match-helpers';
import { environment } from '../../../../stores/environment/environment';
import { useStore } from '../../../../hooks/useStore';
import UiAlert from '../../../ui/alert/UiAlert';
import { translate } from '../../../../services/translate';

interface Props {
    match: CategoryMatch;
}

export default function SportMatchBetbuilder({ match }: Props) {
    const [{ BETBUILDER_WIDGET_URL, BETBUILDER_WIDGET_KEY }] = useStore(environment);
    const isBetbuilderConfigured = Boolean(BETBUILDER_WIDGET_URL && BETBUILDER_WIDGET_KEY);
    const { setContext } = useSportsMatchContext();

    useEffect(() => {
        setContext?.({ isBetbuilderFilter: true });
    }, []);

    return (
        <Wrapper>
            {isBetbuilderConfigured ? (
                <SportMatchBetbuilderSportcast matchBetbuilderId={Number(match.betbuilder_id)} />
            ) : (
                <UiAlert warning className="alert">
                    {translate('sb.betbuilder-not-available')}
                </UiAlert>
            )}
        </Wrapper>
    );
}
