import styled from 'styled-components';

export default styled.div`
    text-align: left;
    h3 {
        margin: 0;
        font-size: 1.25rem;
        font-weight: var(--font-weight-bold);
        padding-right: var(--spacing-8);
    }
    .steps {
        color: var(--font-color-secondary);
        line-height: 1.2rem;
        padding: 0 0 0 var(--spacing-16);

        li:not(:last-child) {
            margin-bottom: var(--spacing-8);
        }

        .bank-example {
            font-weight: 600;
            color: var(--font-color-primary);
        }
    }
`;
