import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
    margin-bottom: var(--spacing-16);
    @media ${mediaQuery.isPhone} {
        margin-bottom: 1rem;
    }
    div {
        white-space: normal;
    }
    input {
        margin: 0;
        text-align: center;
        height: 60px;
        font-size: 1.25rem;
    }
    .amount-selector {
        max-width: 300px;
        @media ${mediaQuery.isPhone} {
            max-width: initial;
            margin: auto;
        }
    }
    .amount-container {
        display: flex;
        align-items: center;
        @media ${mediaQuery.isPhone} {
            align-items: flex-end;
        }
        .amount-font {
            font-size: 1rem;
        }
        .currency-font {
            margin-left: var(--spacing-4);
            font-size: 70%;
            padding: 2px 0;
        }
    }
    .custom-amount {
        width: 100%;
        margin-bottom: var(--spacing-12);
        input {
            background-color: transparent;
            border: none;
            border-bottom: var(--betslip-stake-border);
            border-radius: 0;
            color: var(--betslip-stake-label-color);
            font-size: 2.5rem;
            text-align: left;
            font-weight: var(--font-weight-bold);
            margin-top: 0;
            @media ${mediaQuery.isPhone} {
                text-align: center;
            }
        }
        &-input input {
            text-align: center;
        }
    }
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
`;
