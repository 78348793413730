export interface CasinoFilterProperties {
    studios: number[];
    types: number[];
    rtps: number[];
    maxWinMultipliers: number[];
    volatilities: string[];
    features: number[];
    minBets: number[];
    operatingLanguages: number[];
    jackpots: string[];
    gameTypes?: number[];
    themes?: number[];
}

export interface CasinoFilter {
    id?: string;
    name: string;
    filter: CasinoFilterProperties;
}

export const placeholderCasinoFilter: CasinoFilter = {
    id: '',
    name: '',
    filter: {
        features: [],
        maxWinMultipliers: [],
        rtps: [],
        studios: [],
        types: [],
        volatilities: [],
        minBets: [],
        operatingLanguages: [],
        gameTypes: [],
        themes: [],
        jackpots: [],
    },
};

export const liveFiltersStudios = (isProd) => ({
    PRAGMATIC: 68,
    ONAIR: 61,
    EVOLUTION: 24,
    EZUGI: 25,
    AUTHENTICGAMING: 151,
    STAKELOGIC_LIVE: isProd ? 112 : 110,
    BOMBAY_LIVE: isProd ? 105 : 149,
});
