import React from 'react';
import { useStore } from '../../../hooks/useStore';
import { isFeatureAvailable } from '../../../services/feature';
import { FEATURE } from '../../../services/types';
import { stores } from '../../../stores';
import asyncComponent from '../../async-component/AsyncComponent';

const AuthProfileValidatorList = asyncComponent(
    () => import('./list/AuthProfileValidatorList' /* webpackChunkName: 'AuthProfileValidatorList'*/),
    { isSilent: true },
);

export default function AuthProfileValidator() {
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    if (!isFeatureAvailable(FEATURE.AUTH_PROFILE_VALIDATOR) || !isAuthenticated) {
        return null;
    }

    return <AuthProfileValidatorList />;
}
