import React, { MouseEvent } from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';

interface Props {
    isOpen: boolean;
    className?: string;
    onDrawerClick: (value: boolean) => void;
}

export default function SportMenuV2Drawer({ isOpen, className, onDrawerClick }: Props) {
    function handleDrawerClick(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        event.stopPropagation();

        onDrawerClick(!isOpen);
    }

    return (
        <Wrapper
            size="small"
            color="ghost"
            icon={<Svg icon="drawer" rotate={isOpen ? -90 : 90} />}
            selected={false}
            className={className}
            onClick={handleDrawerClick}
        />
    );
}
