import React, { PropsWithChildren, useState } from 'react';
import Wrapper from './styles';
import SportMenuV2Label from './label/SportMenuV2Label';
import classNames from 'classnames';

interface Props extends PropsWithChildren {
    label: string;
}

export default function SportMenuV2({ label, children }: Props) {
    const [isMenuVisible, setIsMenuVisible] = useState(true);

    return (
        <Wrapper>
            <SportMenuV2Label isMenuVisible={isMenuVisible} onDrawerClick={setIsMenuVisible}>
                {label}
            </SportMenuV2Label>
            <div className={classNames({ ['menu-list-hidden']: !isMenuVisible })}>{children}</div>
        </Wrapper>
    );
}
