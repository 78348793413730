import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

type StyledProps = {
    $isAmericanBetslipInputFormat?: boolean;
};

export default styled.div<StyledProps>`
    position: relative;
    max-width: 340px;
    margin: 0 auto;
    z-index: 1;

    .cashout-status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.5rem;

        border-radius: 111px;
        color: var(--button-primary-bg);
        border: 1px solid var(--button-primary-bg);
        padding: var(--spacing-4) var(--spacing-8);
        text-transform: uppercase;
        letter-spacing: 1px;
        width: fit-content;
        margin-top: var(--spacing-8);
        svg {
            margin-left: var(--spacing-4);
        }
    }

    .retail-betslip-maintenance {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .betslip-selections {
        > *:not(:last-of-type) {
            margin-bottom: var(--spacing-4);
        }
        .progress-bar-label {
            display: none;
        }
    }
    .total-stake-singles {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        font-size: 0.5rem;
        color: var(--betslip-stake-label-color);
        text-transform: uppercase;
        letter-spacing: 1px;

        padding-top: 0.8rem;
        @media ${mediaQuery.isPhone} {
            font-size: 0.7rem;
        }
        .total-stake-amount {
            font-size: 150%;
            text-align: center;
            color: var(--betslip-stake-label-color);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            letter-spacing: normal;

            width: 90px;
            @media ${mediaQuery.isPhone} {
                width: 100px;
            }
        }
    }
    .accept-odds-change,
    .keep-my-selections {
        label {
            margin: 0.8rem 0;
        }
    }

    .stake-input {
        position: relative;

        width: 100%;
        margin-top: var(--spacing-16);

        &.input-to-win input {
            background-color: transparent;
            border: var(--betslip-stake-towin-border);
        }

        input {
            margin: 0;
            padding: 0.2rem;
            height: 3.5rem;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            outline: none;

            background-color: transparent;

            border: var(--betslip-stake-border);
            border-radius: var(--ui-radius);

            color: var(--font-color-primary);
            font-size: 1.2rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: ${({ $isAmericanBetslipInputFormat }) => ($isAmericanBetslipInputFormat ? 'left' : 'center')};
            padding-left: ${({ $isAmericanBetslipInputFormat }) =>
                $isAmericanBetslipInputFormat ? 'var(--spacing-20)' : ''};

            @media ${mediaQuery.isPhone} {
                height: 56px;
            }

            &:focus {
                background-color: var(--betslip-stake-bg-focused);
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &[disabled] {
                opacity: 0.4;
                pointer-events: none;
            }
        }
        &.input-to-win input {
            background-color: transparent;
            border: var(--betslip-stake-towin-border);
        }

        .stake-currency {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: var(--spacing-8);
            font-size: var(--font-14);
        }

        .stake-input-label {
            position: absolute;
            top: -7px;
            left: ${({ $isAmericanBetslipInputFormat }) =>
                $isAmericanBetslipInputFormat ? 'var(--spacing-12)' : '50%'};
            transform: ${({ $isAmericanBetslipInputFormat }) =>
                $isAmericanBetslipInputFormat ? '' : 'translate(-50%, 0)'};
            z-index: 1;
            padding: 0.2rem 0.4rem;
            background: var(--betslip-bg);

            color: var(--betslip-stake-label-color);
            font-size: 0.625rem;
            white-space: nowrap;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            &.label-to-win {
                color: var(--betslip-stake-towin-label-color);
            }
            &.single-label {
                background: var(--surface-level-2-bg);
            }
        }
    }

    .available-balance {
        position: absolute;
        bottom: 0.4rem;
        left: 0.4rem;

        display: flex;
        align-items: center;

        color: var(--font-color-secondary);
        font-size: 0.75rem;

        svg {
            width: 12px;
            margin-right: 0.4rem;
        }
    }

    .remove-stake-button {
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        padding: var(--spacing-12);

        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--font-color-subtle);
        cursor: pointer;
        svg {
            width: 1.25rem;
        }
    }
    .alerts-container {
        margin-top: var(--spacing-8);
    }

    .quick-stake-buttons {
        display: flex;
        align-items: center;
        width: 100%;

        margin: 0.4rem 0;

        > * {
            flex: 1;
        }

        > *:not(:last-child) {
            margin-right: 0.2rem;
        }
    }

    .wager-to-win-toggle {
        position: absolute;
        bottom: 2px;
        left: 5px;
        opacity: 0.5;
    }

    .wager-to-win {
        position: relative;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > *:first-child {
            margin-right: 0.4rem;
        }
        > *:not(:last-of-type) {
            flex: 1 0 45%;
        }
        > *:last-of-type {
            flex: 1;
        }
    }

    .system-stake {
        display: flex;
        flex-direction: column;
        .stake-input {
            margin-top: 0;
        }

        .system-stake-label {
            display: flex;
            align-items: center;
            margin: 0.8rem 0;
            letter-spacing: 1px;

            .help-bubble {
                height: 18px;
                @media ${mediaQuery.isPhone} {
                    height: 24px;
                }
            }
            svg {
                color: ${({ theme }) => theme.betslip.systemStakeInfoBubbleColor};
                margin-right: 0.8rem;
            }
        }
    }

    .system-stake-input-label {
        font-size: 0.725rem;
        color: var(--betslip-label-general-color);
        text-transform: uppercase;
        text-align: right;
        letter-spacing: 1px;
        @media ${mediaQuery.isPhone} {
            font-size: 0.7rem;
        }
    }
    .apply-to-all {
        display: block;
        padding: 0.4rem 0;

        text-transform: uppercase;
        font-size: 0.5rem;
        letter-spacing: 1px;
        text-align: center;
        cursor: pointer;

        span {
            border-bottom: 1px dotted;
        }
    }
    .potential-return,
    .total-odds,
    .total-stake,
    .extra-win,
    .total-win {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.4rem;
        margin: 0;

        font-size: 0.55rem;
        color: var(--betslip-label-general-color);
        text-align: right;
        text-transform: uppercase;
        white-space: nowrap;

        @media ${mediaQuery.isPhone} {
            font-size: 0.55rem;
        }
        .stake-input-value {
            font-size: 1.2rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: right;
        }
    }
    .stake-input-label {
        line-height: 1;
        color: var(--font-color-secondary);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
    }
    .potential-return .stake-input-value {
        color: var(--betslip-return-label-color);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        &.animated-value {
            animation: flipInX 500ms linear;
        }
    }

    .total-odds .stake-input-value {
        color: var(--betslip-label-odds-color);
    }
    .total-stake .stake-input-value {
        color: var(--betslip-stake-label-color);
    }
    .extra-win {
        .stake-input-value {
            color: var(--betslip-stake-label-color);
        }
        svg {
            width: 18px;
        }
    }
    .total-win {
        border-top: 1px dashed var(--ticket-selection-separator);
        padding-top: 0.8rem;
        color: var(--betslip-return-label-color);
    }

    .total-odds {
        border-bottom: 1px solid var(--surface-level-1-line-color);
    }

    .info-icon,
    .help-bubble {
        &:hover {
            color: var(--betslip-stake-label-color);
        }
    }
    .tooltip {
        max-width: 180px;
    }

    .bet-ticket-id {
        font-size: 0.7rem;
        color: var(--font-color-subtle);
        text-align: left;
        padding: 0.8rem 0;
    }
    .back-to-sports-button {
        margin-top: 0.4rem;
    }

    .replay-selections {
        margin: 0.5rem 0;
    }

    .qr-code-nav {
        display: flex;
        gap: 5px;
        color: var(--font-color-primary);
        width: 100%;
        justify-content: space-between;
        .drawer-icon {
            cursor: pointer;
            width: 2em;
        }
    }

    .pagination-counter {
        padding: 1rem 0rem;
        text-align: center;
        font-weight: bold;
    }

    .action-btn {
        margin-top: 1rem;
    }
`;
