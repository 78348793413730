import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    display: grid;
    margin: 0.4rem auto;
    gap: 1px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 28px;

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        grid-auto-rows: 40px;
    }

    & .normal-btn {
        border-radius: 0;
        font-size: 0.8rem;

        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            font-size: 1.2rem;
        }
    }

    & .backspace-btn {
        grid-column: 4 / 4;
        grid-row: 1 / 1;
    }

    & .ok-btn {
        grid-column: 4 / 4;
        grid-row: 2 / 5;
        font-size: 1rem;
    }

    & .zero-button {
        grid-column: 1 / 3;
    }
`;
