import { useEffect } from 'react';
import { loadIsCasinoBlocked } from '../microservices/casino';
import { getLiveLobbyMetaData } from '../microservices/casino-live-lobby';
import { loadFilters } from '../microservices/casino-preferences';
import { onRaceEnd, onRaceMessageUpdate, onRacePoints, onRaceStart } from '../microservices/pusher';
import {
    getGamesByRaceGameGroupIds,
    raceEndEvent,
    raceMessageEvent,
    racePointsEvent,
    raceStartEvent,
} from '../services/casino/casino-race';
import { initCasino, loadCasino } from '../services/casino/init';
import { isFeatureAvailable } from '../services/feature';
import { setLastActiveProduct } from '../services/router';
import { useSubscription } from '../services/subscription';
import { FEATURE } from '../services/types';
import { stores } from '../stores';
import { casino } from '../stores/casino';
import { PRODUCT } from '../types/common';
import { useStore } from './useStore';
import { useInterval } from '../services/hooks';
import { CasinoGame } from '../services/casino/types';
import { manageLiveLobbyUpdates } from '../services/casino/live-lobby';

interface Props {
    isLobby: boolean;
}

export default function useCasinoInit({ isLobby }: Props) {
    const [shouldReload] = useStore(casino.shouldReload);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [appType] = useStore(stores.appType);
    const [races] = useStore(stores.casinoRace.active.races);
    const raceGames: CasinoGame[] = [];
    Object.values(races).forEach((race) => raceGames.concat(getGamesByRaceGameGroupIds(race)));

    useEffect(() => {
        isLobby && isFeatureAvailable(FEATURE.CASINO_FILTERS) && loadCasinoFilters();
    }, [isAuthenticated]);

    useEffect(() => {
        setLastActiveProduct(PRODUCT.CASINO);
        initCasino(appType);
        // TODO: move this check higher, customer should not end up here if casino is blocked
        loadIsCasinoBlocked();
    }, []);

    useEffect(() => {
        if (shouldReload) {
            loadCasino(appType);
        }
    }, [shouldReload]);

    useInterval(
        async () => {
            if (isLobby) {
                manageLiveLobbyUpdates(await getLiveLobbyMetaData(), raceGames);
            }
        },
        30 * 1000,
        [isLobby],
    );

    useSubscription(onRaceStart, raceStartEvent);
    useSubscription(onRaceEnd, raceEndEvent);
    useSubscription(onRacePoints, racePointsEvent);
    useSubscription(onRaceMessageUpdate, raceMessageEvent);

    async function loadCasinoFilters() {
        if (isAuthenticated) {
            await loadFilters();
        } else {
            casino.userPreferences.filters.set([]);
        }
    }
}
