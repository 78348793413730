import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: var(--spacing-24) var(--spacing-8) var(--spacing-16);
    padding-right: var(--spacing-4);
    font-size: var(--font-14);
    line-height: 1.2;
    color: var(--sport-category-title-color);
    font-weight: var(--font-weight-bold);
    ${({ theme }) => theme.optimizeFontRendering};
    user-select: none;
`;
