import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { getProviderRoutings } from '../../microservices/payments';
import { getMyStats } from '../../microservices/warehouse-api';
import { MyStats } from '../warehouse-api/types';
import { stores } from '../../stores';
import { getActiveCurrency } from '../currency';
import { logger } from '../logger';
import { PaymentProvider, ProviderRouting, PROVIDER_TYPE } from './types';
import { useStore } from '../../hooks/useStore';

export function useProviderRouting({
    providers = [],
    selectedProvider,
    type,
    amount,
}: {
    providers?: PaymentProvider[];
    selectedProvider?: PaymentProvider;
    type: PROVIDER_TYPE;
    amount: number;
}) {
    const [displayedProviders, setDisplayedProviders] = useState<PaymentProvider[]>([]);
    const [routedProvider, setRoutedProvider] = useState<PaymentProvider>();
    const [routingsByProviderId, setRoutingsByProviderId] = useState<RoutingsByProviderId>();
    const [stats, setStats] = useState<MyStats>();
    const [user] = useStore(stores.user);
    const [wallet] = useStore(stores.wallet);
    const context = { user, wallet, payment: { amount }, stats };

    useEffect(() => {
        getMyStats().then(setStats);
    }, []);

    useEffect(() => {
        loadRoutings();
    }, [type]);

    useEffect(() => {
        setDisplayedProviders(getDisplayedProviders(providers, routingsByProviderId));
    }, [providers, routingsByProviderId]);

    useEffect(() => {
        setRoutedProvider(getRoutedProvider(providers, context, routingsByProviderId, selectedProvider));
    }, [selectedProvider, routingsByProviderId, providers, context]);

    async function loadRoutings() {
        try {
            const routings = await getProviderRoutings({ market: user?.market, type });
            const routedByProviderId = routings.reduce<RoutingsByProviderId>((routingsByProviderId, routing) => {
                routing.configs.forEach((config) => (routingsByProviderId[config.destinationProviderId] = routing));
                return routingsByProviderId;
            }, {});
            setRoutingsByProviderId(routedByProviderId);
        } catch (error) {
            logger.error('PaymentsProviderRoutingService', 'loadRoutings', error);
        }
    }

    return {
        displayedProviders,
        routedProvider,
    };
}

function getRoutedProvider(
    providers: PaymentProvider[],
    context: RoutingContext,
    routingsByProviderId?: RoutingsByProviderId,
    selectedProvider?: PaymentProvider,
) {
    if (!selectedProvider || !routingsByProviderId) {
        return selectedProvider;
    }
    const routing = routingsByProviderId[selectedProvider.id];
    let routedProvider: PaymentProvider | undefined;
    if (routing) {
        const routedProviderId = routing.configs.find((config) =>
            config.conditions.every((condition) => {
                try {
                    return operatorFunction[condition.operator](get(context, condition.field), condition.value);
                } catch {
                    return false;
                }
            }),
        )?.destinationProviderId;
        if (routedProviderId) {
            routedProvider = providers.find((provider) => provider.id === routedProviderId);
        }
    }
    return routedProvider || selectedProvider;
}

function getDisplayedProviders(providers: PaymentProvider[], routingsByProviderId?: RoutingsByProviderId) {
    if (!routingsByProviderId) {
        return providers;
    }
    const currency = getActiveCurrency();
    const displayedProviders: PaymentProvider[] = [];
    const providersByRoutingId: Record<ProviderRouting['id'], PaymentProvider[]> = {};

    providers.forEach((provider) => {
        const routing = routingsByProviderId[provider.id];
        if (routing) {
            providersByRoutingId[routing.id] = (providersByRoutingId[routing.id] || []).concat(provider);
        } else {
            displayedProviders.push(provider);
        }
    });

    Object.values(providersByRoutingId).forEach((providers) => {
        const { minAmounts, maxAmounts, aux } = providers.reduce(
            (acc, provider) => {
                acc.minAmounts.push(provider.minAmount[currency]);
                acc.maxAmounts.push(provider.maxAmount[currency]);
                Object.assign(acc.aux, provider.aux);
                return acc;
            },
            { minAmounts: new Array<number>(), maxAmounts: new Array<number>(), aux: {} },
        );
        displayedProviders.push({
            ...providers[0],
            minAmount: { [currency]: Math.min(...minAmounts) },
            maxAmount: { [currency]: Math.max(...maxAmounts) },
            aux,
        });
    });

    return displayedProviders.sort((p1, p2) => p1.order - p2.order);
}

enum Operator {
    EQUAL = '=',
    GREATER_THAN_OR_EQUAL_TO = '>=',
    LESS_THAN_OR_EQUAL_TO = '<=',
    GREATER_THAN = '>',
    LESS_THAN = '<',
}

const operatorFunction: Record<Operator, (a: any, b: any) => boolean> = {
    [Operator.EQUAL]: (a, b) => String(a) === String(b),
    [Operator.GREATER_THAN_OR_EQUAL_TO]: (a, b) => Number(a) >= Number(b),
    [Operator.LESS_THAN_OR_EQUAL_TO]: (a, b) => Number(a) <= Number(b),
    [Operator.GREATER_THAN]: (a, b) => Number(a) > Number(b),
    [Operator.LESS_THAN]: (a, b) => Number(a) < Number(b),
};

type RoutingsByProviderId = Record<PaymentProvider['id'], ProviderRouting>;
interface RoutingContext {
    user: any;
    wallet: any;
    payment: { amount: number };
}
