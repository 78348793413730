import { getTicketReceipt, placeHorseRacingBet } from '../../microservices/lvdc-web';
import { getAssociationMapping } from '../lvdc/lvdc';
import { stores } from '../../stores';
import { translate } from '../translate';
import round from 'lodash/round';
import { storageGet } from '../storage';
import {
    addRacebookBetslipError,
    BetslipError,
    clearRacebookBetslipErrors,
    racebookBetslipErrorPrefix,
    translationKeysByCode,
} from './betslip-errors';
import { verifyGeoLocation } from '../geocomply/geocomply';
import { GEOCOMPLY_FAILED_ERROR } from '../sports/constants';
import { requestLogin } from '../auth';
import { getStoreValue } from '../../stores/store/utils';
import { getUserSignupProperty } from '../../microservices/auth';
import { PlaceBetPayload } from '@staycool/lvdc-types/dist/features/bets/types';
import { RaceBetType } from '../../microservices/lvdc/types';
import { settingsByBetType } from '../lvdc/utils';

const MODULO_ROUNDING_MULTIPLIER = 1000;

export async function placeBet() {
    const betslip = getStoreValue(stores.horseRacing.betslip);
    const betslipPlacingState = getStoreValue(stores.horseRacing.betslipPlacingState);
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const deviceId = storageGet('uuid');

    clearRacebookBetslipErrors();
    stores.horseRacing.betslipPlacingState.set((state) => ({ ...state, isLoading: true }));
    try {
        if (!isAuthenticated) {
            requestLogin();
            return;
        }

        const totalPositions = getTotalPositions();
        if (
            betslip.runnerMaps.filter((runner) => Boolean(runner)).length !== totalPositions ||
            betslip.runnerMaps.some((runners) => !runners?.length)
        ) {
            addRacebookBetslipError(translate(translationKeysByCode[1020], racebookBetslipErrorPrefix));
            return;
        }
        const { raceId, runnerMaps, betStake, totalStake, betType, nevadaTrackId, races } = betslip;
        await verifyGeoLocation('BET');

        const data = {
            date: betslip.date,
            race: raceId,
            runner_maps: runnerMaps,
            bet_amount: round(betStake, 2),
            ticket_cost: round(totalStake, 2),
            bet_type: betType.code,
            nevada_track_id: nevadaTrackId,
            involved_race_ids: races,
            deviceId,
        } as PlaceBetPayload;

        const { signupProperty } = await getUserSignupProperty();

        data['associationId'] = getAssociationMapping(signupProperty);

        const { ticket_id } = await placeHorseRacingBet({ ...data, total_combinations: betslip.totalCombinations });

        const receipt = await getTicketReceipt(ticket_id);
        stores.horseRacing.betslipPlacingState.set({
            ...betslipPlacingState,
            receipt,
        });
        return true;
    } catch (error: any) {
        if (error.code === GEOCOMPLY_FAILED_ERROR) {
            stores.horseRacing.betslipErrors.set((state) => [...state, 'GeoComply validation failed']);
        } else {
            addRacebookBetslipError(error as BetslipError | string, true);
        }
        return false;
    } finally {
        stores.horseRacing.betslipPlacingState.set((state) => ({ ...state, isLoading: false }));
    }
}

export function getTotalPositions() {
    const { betType } = getStoreValue(stores.horseRacing.betslip);
    return settingsByBetType[betType?.code]?.selectionsCount ?? 1;
}

interface CheckForStakeErrorParams {
    stake: number;
    minValue: number;
    maxValue: number;
    betType: RaceBetType;
    userBalance: number | undefined;
    totalStake: number;
}

export function validateBetslipStake(params: CheckForStakeErrorParams) {
    if (!params.betType) {
        return [];
    }
    const {
        stake,
        minValue,
        maxValue,
        betType: {
            is_multiple_of_min,
            is_multiple_of_fiftycent,
            is_pennies_allowed,
            is_min_amount_only,
            is_min_on_bet_cost,
        },
        userBalance,
        totalStake,
    } = params;
    /**The order of checks is important (by priority) :
     * 1. is_min_amount_only
     * 2. is_min_on_bet_cost
     * 3. min/max
     * 4. is_multiple_of_min
     * 5. is_multiple_of_fiftycent
     * 6. is_pennies_allowed
     */
    const errors: string[] = [];
    if (is_min_amount_only) {
        const isInvalid = !Boolean(stake === minValue);
        if (isInvalid) {
            errors.push(translate(`Only minimum stake is allowed: $ {{ minValue }}`, 'ui.betslip', { minValue }));
        }
    }

    if (is_min_on_bet_cost) {
        const isInvalid = !Boolean(totalStake === minValue);
        if (isInvalid) {
            errors.push(
                translate('Ticket total cost can only be min of bet stake: $ {{ minValue }}', 'ui.betslip', {
                    minValue,
                }),
            );
        }
    }

    const isStakeRangeValidErrorCheck = stake < minValue || stake > maxValue;
    if (isStakeRangeValidErrorCheck) {
        errors.push(
            translate(`Stake must be between $ {{ minValue }} and $ {{ maxValue }}`, 'ui.betslip', {
                minValue,
                maxValue,
            }),
        );
    }

    if (is_multiple_of_min) {
        const isInvalid = Boolean((stake * MODULO_ROUNDING_MULTIPLIER) % (minValue * MODULO_ROUNDING_MULTIPLIER) !== 0);
        if (isInvalid) {
            errors.push(translate(`Total stake must be a multiple of $ {{ minValue }}`, 'ui.betslip', { minValue }));
        }
    }

    if (is_multiple_of_fiftycent && !is_multiple_of_min) {
        const isInvalid = Boolean((stake * MODULO_ROUNDING_MULTIPLIER) % (0.5 * MODULO_ROUNDING_MULTIPLIER) !== 0);
        if (isInvalid) {
            errors.push(translate(`Stake must be a multiple of 50¢`, 'ui.betslip'));
        }
    }

    if (!is_pennies_allowed) {
        const isInvalid = Boolean((stake * MODULO_ROUNDING_MULTIPLIER) % (1 * MODULO_ROUNDING_MULTIPLIER) !== 0);
        if (isInvalid) {
            errors.push(translate(`Stake must be a whole number`, 'ui.betslip'));
        }
    }

    if (userBalance !== undefined && totalStake > userBalance) {
        errors.push(translate(`Your bet is exceeding your available balance`, 'ui.betslip'));
    }

    return errors;
}
