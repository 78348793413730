import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useClearAllModal } from '../../../../hooks/betslip-v2';
import { useStore } from '../../../../hooks/useStore';
import { getMarketInfoByOutcomeId } from '../../../../microservices/sbgate';
import {
    calculateAndFormatBoostedOdds,
    calculateAndFormatComboCardOdds,
    convertOdds,
} from '../../../../services/odds-format';
import { clearBetslipSelection } from '../../../../services/sports/betslip';
import { getMarketIdsFromComboCardMatches, removeSelectionFromBetslip } from '../../../../services/sports/combo-cards';
import { initialBetSlipPlacingState } from '../../../../services/sports/constants';
import { BET_TYPE, BetslipMode } from '../../../../services/sports/types';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import Wrapper from './styles';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';
import round from 'lodash/round';
import { loadOddsByMarketIds } from '../../../../microservices/sb-odds';
import {
    subscribeToOddsChangesByMarketIds,
    unsubscribeFromOddsChangesByMarketIds,
} from '../../../../microservices/pusher';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItemFooter({ card }: Props) {
    const { id: cardId, matches, odds_boost } = card;
    const markets = matches.flatMap(({ markets }) => markets);
    const outcomeIds = markets.map(({ outcome_id }) => outcome_id);

    const [cardsInBetslip, setCardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [betSlipComboCardMarketIdToOutcomeId, setBetSlipComboCardMarketIdToOutcomeId] = useStore(
        stores.sports.betSlipComboCardMarketIdToOutcomeId,
    );

    const [oddsByOutcomeId] = useOddsByOutcomeIds(outcomeIds);

    const originalOdds = useMemo(() => calculateAndFormatComboCardOdds(markets, oddsByOutcomeId), [oddsByOutcomeId]);
    const boostedOdds = useMemo(
        () => calculateAndFormatBoostedOdds(markets, oddsByOutcomeId, odds_boost),
        [oddsByOutcomeId],
    );

    const [, setBetSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [{ receiptById }] = useStore(stores.sports.betSlipPlacingState);
    const cardIdsWithReceipt = Object.keys(receiptById);
    const cardAlreadyInBetslip = cardsInBetslip.find(
        ({ id }) => id === cardId && !cardIdsWithReceipt.includes(cardId.toString()),
    );

    const clearAllModal = useClearAllModal();

    useEffect(() => {
        if (!matches.length) {
            return;
        }
        const marketIds = getMarketIdsFromComboCardMatches(matches);
        loadOddsByMarketIds(marketIds);
        subscribeToOddsChangesByMarketIds(marketIds);
        return () => unsubscribeFromOddsChangesByMarketIds(marketIds);
    }, []);

    function clearPreviousSelections() {
        if (Object.values(betSlipComboCardMarketIdToOutcomeId).length > 0 && !cardAlreadyInBetslip) {
            clearAllModal.show(handleComboCardSelection);
        } else {
            handleComboCardSelection();
        }
    }

    async function handleComboCardSelection() {
        if (!cardsInBetslip.length) {
            clearBetslipSelection(BetslipMode.Betslip, false, true);
        }

        setBetSlipPlacingState(initialBetSlipPlacingState);
        if (!cardAlreadyInBetslip) {
            setBetSlipUserState((prev) => ({
                ...prev,
                userBetTypeSelection: BET_TYPE.COMBO_CARD,
                betType: BET_TYPE.COMBO_CARD,
            }));
            setCardsInBetslip([card]);

            const markets = matches.flatMap(({ markets }) =>
                markets.map(({ id, outcome_id }) => ({ [id]: outcome_id })),
            );
            const marketIdToOutcomeId: Record<number, number> = Object.assign({}, ...markets);
            const outcomeIds = Object.values(marketIdToOutcomeId);
            setBetSlipComboCardMarketIdToOutcomeId(marketIdToOutcomeId);
            await Promise.all(outcomeIds.map((outcomeId) => getMarketInfoByOutcomeId(outcomeId)));
        } else {
            removeSelectionFromBetslip(cardId);
        }
    }

    return (
        <Wrapper>
            <UiButton
                className={classNames('selection-btn', { active: cardAlreadyInBetslip })}
                color="primary"
                size="small"
                block
                onClick={clearPreviousSelections}
            >
                {round(boostedOdds, 2) > round(originalOdds, 2) ? (
                    <>
                        <span className="original-odds">{convertOdds(originalOdds)}</span>
                        <span className="arrow">{' » '}</span>
                        <span className="boosted-odds">{convertOdds(boostedOdds)}</span>
                    </>
                ) : (
                    <span className="boosted-odds">{convertOdds(boostedOdds)}</span>
                )}
            </UiButton>
        </Wrapper>
    );
}
