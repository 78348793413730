import { logger } from '../logger';
import { fetchTopMarketsByMarketTypeAndMatch } from '../../microservices/sbgate';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import orderBy from 'lodash/orderBy';
import { fakeTopMarketTypesWithMarket } from './skeleton-mocks';
import { stores, useStoreWithSelector } from '../../stores';
import { useMemo } from 'react';
import { getHiddenMarketsCount } from './match-helpers';
import { CategoryMatch, MarketTypeCategory, OddsByOutcomeIdStore } from './types';
import random from 'lodash/random';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';
import { FoCategoryMatchMarket } from '@staycool/sbgate-types';
import pick from 'lodash/pick';

function oddsDiff(outcomes, oddsByOutcomeId) {
    const oddsHome = oddsByOutcomeId[outcomes[0].id];
    const oddsAway = oddsByOutcomeId[outcomes[1].id];
    return oddsHome?.status === 'OPEN' && oddsAway?.status === 'OPEN' && oddsHome.value > 1 && oddsAway.value > 1
        ? Math.abs(oddsHome.value - oddsAway.value)
        : 9999;
}

const bestLineAlgoByOutcomeLen = {
    2: oddsDiff,
    3: (outcomes, oddsByOutcomeId) => (oddsByOutcomeId[outcomes[1].id] || {}).value || 9999,
};

interface MarketForBestLine {
    raw_line: number;
    outcomes: { id: number }[];
}

function handleBestLineSort<T extends MarketForBestLine>(
    sortingAlgo: any,
    markets: T[],
    oddsByOutcomeId: OddsByOutcomeIdStore,
) {
    const primarySort = (market: MarketForBestLine) => sortingAlgo(market.outcomes, oddsByOutcomeId);
    const secondarySort = (market: MarketForBestLine) => isHalfLine(Number(market.raw_line));
    const tertiarySort = (market: MarketForBestLine) => Math.abs(Number(market.raw_line));
    const quaternarySort = (market: MarketForBestLine) => Number(market.raw_line);

    return orderBy(markets, [primarySort, secondarySort, tertiarySort, quaternarySort], ['asc', 'desc', 'asc', 'desc']);
}

export function findBestLine<T extends MarketForBestLine>(
    markets: T[],
    viewType: string,
    oddsByOutcomeId: OddsByOutcomeIdStore,
    preferHalfLine: boolean,
) {
    if (viewType !== 'line') {
        return markets[0];
    }

    const sortBestLineAlgo = bestLineAlgoByOutcomeLen[markets[0]?.outcomes?.length];
    if (!sortBestLineAlgo) {
        return markets[0];
    }

    try {
        const marketsSortedByBestLine = handleBestLineSort(sortBestLineAlgo, markets, oddsByOutcomeId);
        if (!preferHalfLine) {
            return marketsSortedByBestLine[0];
        }

        const halfLineMarkets = marketsSortedByBestLine.filter((market) => isHalfLine(market.raw_line));
        return halfLineMarkets[0] || marketsSortedByBestLine[0];
    } catch (err) {
        logger.error('SportsTopMarketsService', 'findBestLine', err);
        return markets[0];
    }
}

const isHalfLine = (line: number) => {
    return Math.abs(line % 1) === 0.5;
};

export const fetchTopMarketsByMarketTypeAndMatchDebounced = AwesomeDebouncePromise(
    fetchTopMarketsByMarketTypeAndMatch,
    random(500, 1500),
    { key: (matchId, marketTypeId) => `${matchId}_${marketTypeId}` },
);

export const amountOfMarketsByDevice = {
    isPhone: 3,
    isTablet: 2,
    isLaptop: 3,
    isDesktop: 3,
};

export const useTopMarketTypes = (categoryId: number = 0) =>
    useStoreWithSelector(stores.sports.topMarketTypesByCategory, (state) => state[categoryId], [categoryId]);

export const useHiddenMarketsCount = (match: CategoryMatch, numMarketsToShow = 3) => {
    const [topMarketTypes = fakeTopMarketTypesWithMarket] = useTopMarketTypes(match.category_id);
    const topTypes = topMarketTypes.slice(0, numMarketsToShow);
    const hiddenMarketsCount = useMemo(() => getHiddenMarketsCount(topTypes, match), [topTypes, match]);
    return { topMarketTypes: topTypes, hiddenMarketsCount };
};

export const useHiddenMarketsCountWithDevice = (match: CategoryMatch) => {
    const [{ deviceType }] = useStore(media);
    const amountOfMt = amountOfMarketsByDevice[deviceType];
    return useHiddenMarketsCount(match, amountOfMt);
};

export const useTurnoverMarket = (match?: CategoryMatch) => {
    const leagueCategoryId = match?.category_id;
    const [topMarketTypes] = useTopMarketTypes(leagueCategoryId);
    const outcomeIds = useMemo(
        () => match?.markets?.map((lineMarket) => lineMarket.outcomes.map((outcome) => outcome.id)).flat() || [],
        [match?.markets],
    );
    const [marketOddsByOutcomeId] = useStoreWithSelector(
        stores.sports.oddsByOutcomeId,
        (state) => pick(state, outcomeIds),
        [outcomeIds],
    );
    return useMemo(() => {
        if (!match || match.status !== 'OPEN' || !match.markets?.length || !topMarketTypes?.length) {
            return;
        }
        const {
            status: matchStatus,
            markets,
            inplay: in_play,
            id: matchId,
            name: match_name,
            match_type,
            sport_category: sport_category_id,
            sport: sport_id,
            betting_end,
        } = match;
        const market = findTopMarket(markets, topMarketTypes, match, marketOddsByOutcomeId);
        if (!market) {
            return;
        }
        return {
            ...market,
            category_id: leagueCategoryId,
            in_play,
            match_id: matchId,
            match_name,
            match_status: matchStatus,
            match_type,
            singles_only: false,
            sport_category_id,
            sport_id,
            same_match_combo_allowed: true,
            betting_end,
        };
    }, [match?.markets, match?.status, topMarketTypes, marketOddsByOutcomeId]);
};

const findTopMarket = (
    markets: FoCategoryMatchMarket[],
    topMarketTypes: MarketTypeCategory[],
    match: CategoryMatch,
    oddsByOutcomeId?: OddsByOutcomeIdStore,
) => {
    const topMarkets = markets.filter((market) => market.market_type_id === topMarketTypes[0].id);
    if (!topMarkets.length) {
        return;
    }
    const viewType = topMarkets[0].view_type;
    return findBestLine(topMarkets, viewType, oddsByOutcomeId || {}, match.prefer_half_lines);
};
