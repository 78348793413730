import React, { useState } from 'react';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import { depositWithProvider } from '../../../../../../services/payments/payments';
import { logger } from '../../../../../../services/logger';
import { PROVIDERS } from '../../../../../../services/payments/types';
import { translate } from '../../../../../../services/translate';
import Snippet from '../../../../../snippet/Snippet';
import UiGroup from '../../../../../ui/group/UiGroup';
import UiButton from '../../../../../ui/button/UiButton';
import { media } from '../../../../../../stores/media/media';
import { useStore } from '../../../../../../hooks/useStore';
interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderDevcodeNeosurfVoucher({ amount, deviceHash, onClose }: Props) {
    const [isError, setIsError] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [{ isPhone }] = useStore(media);

    async function deposit() {
        setIsInProgress(true);
        try {
            const { url, parameters } = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.DEVCODE_NEOSURF_VOUCHER,
                providerParams: {},
            });

            const form = document.createElement('form');
            form.method = 'post';
            form.action = url;

            for (const key in parameters) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = parameters[key];
                form.appendChild(hiddenField);
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            logger.error('PaymentDepositProviderDevcodeNeosurfVoucher', 'deposit', error);
            setIsError(true);
            setIsInProgress(false);
        }
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <>
            <Snippet snippetKey="payments.deposit.devcode-neosurf-voucher-info-text" />
            <UiGroup expand horizontallyCentered>
                <UiButton block={isPhone} onClick={onClose}>
                    {translate('Back', 'ui.common')}
                </UiButton>
                <UiButton
                    block={isPhone}
                    color="primary"
                    disabled={isInProgress}
                    isLoading={isInProgress}
                    onClick={deposit}
                >
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </UiGroup>
        </>
    );
}
