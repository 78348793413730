import { parse } from 'query-string';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { AppType } from './mobile-app/types';

export const enum NativeMessageEventType {
    APPSFLYER = 'appsflyer_event',
    BALANCE_UPDATE = 'balance_update',
    DEPOSIT = 'deposit',
    GAME_CLOSED = 'game_closed',
    GAME_LAUNCH = 'game_launch',
    GEOCOMPLY_ERROR = 'geocomply_error',
    IN_APP_PURCHASE = 'iap',
    LOGIN = 'login',
    LOGOUT = 'logout',
    PAGE_LOADED = 'page_loaded',
    PAYMENT_GLOBALPAY = 'payment_globalpay',
    PAYMENT_TRUSTLY_US = 'payment_trustly_us',
    REGISTER = 'register',
    REGISTRATION_COMPLETED = 'registration_completed',
    REQUEST_CAMERA = 'request_camera',
    SESSION_REMINDER = 'session_reminder',
    BTOB_REGISTRATION_COMPLETED = 'btob_registration_completed',
    LINK_WITH_APPLE = 'link_with_apple',
    LINK_WITH_FACEBOOK = 'link_with_facebook',
    LINK_WITH_GOOGLE = 'link_with_google',
    REGISTER_WITH_APPLE = 'register_with_apple',
    REGISTER_WITH_FACEBOOK = 'register_with_facebook',
    REGISTER_WITH_GOOGLE = 'register_with_google',
}

export type NativeMessageEvent = { type: NativeMessageEventType } & Record<string, any>;

export function isMobileApp(): boolean {
    const appType = getStoreValue(stores.appType);
    return [AppType.ANDROID, AppType.IOS].includes(appType);
}

/**
 * Sends a message/event to a given native app platform
 * @param event NativeMessageEventType
 */
export function sendNativeEvent(event: NativeMessageEvent) {
    if (!isMobileApp()) {
        return;
    }

    const messageBus = window.webkit?.messageHandlers?.native || window.native;

    if (messageBus) {
        messageBus.postMessage(JSON.stringify(event));
    } else {
        console.log('Message was not sent, messageBus is missing');
    }

    // TODO: remove after airbridge migration is done
    sendAirBridgeEvent(event);
}

/**
 * @deprecated sending airbridge events will be decommissioned and replaced width native events
 */
function sendAirBridgeEvent(event: NativeMessageEvent) {
    const encodedEvent = encodeURI(JSON.stringify(event));

    console.log(`airbridge:${encodedEvent}`);
    window.location.href = `airbridge:${encodedEvent}`;
}

export function checkSourceType(): string | null {
    const queryParams = parse(window.location.search);
    if (queryParams.appSource) {
        return queryParams.appSource?.toString()?.toUpperCase();
    }
    return null;
}
