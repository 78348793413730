import React, { useEffect, useState } from 'react';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';
import { isProduction } from '../../../../../../services/util';

interface Props {
    accessId: string;
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentDepositProviderPayNearMeScripts({ onError, onScriptsLoaded, accessId }: Props) {
    const SITE_NAME = isProduction() ? 'paynearme' : 'paynearme-sandbox';
    const SDK_URL = `https://www.${SITE_NAME}.com/api/cf/${accessId}/v1/paynearme.js`;
    const [isPayNearMeScriptLoaded, setIsPayNearMeScriptLoaded] = useState(false);
    useEffect(() => {
        if (isPayNearMeScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isPayNearMeScriptLoaded]);
    return <ScriptLoader url={SDK_URL} onError={onError} onLoad={() => setIsPayNearMeScriptLoaded(true)} />;
}
