import React from 'react';
import { GEOCOMPLY_SERVICE_ERROR, GeocomplyServiceError } from '../../../../../services/geocomply/types';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import UiGroup from '../../../../ui/group/UiGroup';
import Wrapper from './styles';

interface Props {
    serviceError: GeocomplyServiceError;
}

export default function GeocomplyFeedbackErrorsServiceError({ serviceError }: Props) {
    if (!serviceError) {
        return null;
    }

    return (
        <Wrapper>
            <UiGroup vertical>
                <div className="service-error">
                    {serviceError.troubleshooter
                        ? translate('GeoComply Troubleshooter', 'ui.geocomply')
                        : serviceError.name}
                </div>

                {serviceError.troubleshooter && (
                    <div className="troubleshooter-hints">
                        {serviceError.troubleshooter.map((hint, index) => (
                            <div className="hint" key={index}>
                                <div className="hint-icon">
                                    <Svg icon="info" size={1} />
                                </div>

                                <div className="hint-content">
                                    <span className="hint-message">{hint.message}</span>

                                    {hint.help && (
                                        <a className="hint-help" href={hint.help}>
                                            {translate('HELP', 'ui.geocomply')}
                                        </a>
                                    )}

                                    {hint.optin && (
                                        <a className="hint-optin" href={hint.optin}>
                                            OPT-IN
                                        </a>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                <div className="service-error-description">
                    {serviceError.code === GEOCOMPLY_SERVICE_ERROR.GEOPACKET_IP_ADDRESS_MISMATCH_ERROR &&
                        translate('geopacket-ip-address-mismatch-error', 'ui.geocomply')}
                </div>
            </UiGroup>
        </Wrapper>
    );
}
