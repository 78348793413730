import React, { useMemo } from 'react';
import { getRoute } from '../../../services/router';
import { stores } from '../../../stores';
import compact from 'lodash/compact';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import UiPlaceholder from '../../ui/placeholder/UiPlaceholder';
import SportSelect from '../select/SportSelect';
import { translate } from '../../../services/translate';
import SportCategoryIcon from '../category-icon/SportCategoryIcon';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

export default function SportNavigationB() {
    const [liveMatchesCount] = useStore(stores.sports.liveMatchesCount);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [tree] = useStore(stores.sports.prematchTree);
    const fakeParentCategories: any = [1, 2, 3, 4];
    const [{ isPhone }] = useStore(media);

    const links = useMemo(
        () =>
            compact([
                {
                    label: 'Featured',
                    route: getRoute('sport.recommendations'),
                    icon: 'top-sport',
                },
                {
                    label: 'Live',
                    route: getRoute('sport.live'),
                    icon: 'live-stream',
                },
            ]),
        [isAuthenticated],
    );

    const linksExtra = useMemo(
        () =>
            compact([
                {
                    label: translate('My Favorites', 'ui.sportsbook'),
                    route: getRoute('sport.favorites'),
                    icon: 'star',
                },
                isAuthenticated &&
                    !isPhone && {
                        label: translate('Bet history', 'ui.account'),
                        route: getRoute('sport.bet-history'),
                        icon: 'bethistory',
                    },
            ]),
        [isAuthenticated],
    );

    return (
        <Wrapper className="navigation-b">
            <SportSelect />
            {links.map(({ route, icon, label }) => {
                const isLiveCard = route.includes(`${getRoute('sport.live')}`);
                return (
                    <UiButton
                        url={route}
                        key={route}
                        type="card"
                        icon={<Svg icon={icon} />}
                        badge={isLiveCard ? liveMatchesCount : 0}
                        size={isPhone ? 'tiny' : 'small'}
                    >
                        {label}
                    </UiButton>
                );
            })}
            {(tree.children || fakeParentCategories).slice(0, 5).map((child, index) => (
                <UiButton
                    url={`${getRoute('sport')}/${child.fullSlug}`}
                    type="card"
                    icon={<SportCategoryIcon icon={child.icon} size={isPhone ? 1.5 : 1.75} />}
                    size={isPhone ? 'tiny' : 'small'}
                    key={index}
                >
                    {child.name || <UiPlaceholder />}
                </UiButton>
            ))}
            <div className="extra-links">
                {linksExtra.map((product) => (
                    <UiButton
                        url={product.route}
                        key={product.route}
                        type="card"
                        icon={<Svg icon={product.icon} />}
                        size={isPhone ? 'tiny' : 'small'}
                    >
                        {product.label}
                    </UiButton>
                ))}
            </div>
        </Wrapper>
    );
}
