import React, { useEffect, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';
import { stores, useStoreWithSelector } from '../../../../stores';
import SportBetslipTeasersValidationMarket from './market/SportBetslipTeasersValidationMarket';
import { BET_TYPE, CategoryMatch, MarketInfo } from '../../../../services/sports/types';
import { useNumberArrayEffect } from '../../../../hooks/useNumberArrayEffect';
import { fetchMatchMarketsAll, findTeaserMarkets, getTeasersPayoutOdds } from '../../../../microservices/sports';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    betSlipMarketIds: string[];
    betSlipOutcomeIds: number[];
    categoryIds: number[];
}

function SportBetslipTeasersValidation({ betSlipMarketIds, betSlipOutcomeIds, categoryIds }: Props) {
    const [betType] = useStoreWithSelector(stores.sports.betSlipUserState, (state) => state.betType);
    const [selectedMarkets] = useStoreWithSelector(
        stores.sports.marketInfoById,
        (state) => (pick(state, betSlipMarketIds) || {}) as unknown as Record<number, MarketInfo>,
        [betSlipMarketIds],
    );
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [, setTeaserStatusDisabledMarketIds] = useStore(stores.sports.teaserStatusDisabledMarketIds);
    const [matchById, setMatchById] = useState<Record<number, CategoryMatch>>({});

    const matchIds = useMemo(
        () => uniq(Object.values(selectedMarkets).map((market) => market.match_id)),
        [selectedMarkets],
    );

    const marketTypeIds = useMemo(
        () => uniq(Object.values(selectedMarkets).map((market) => market.market_type_id)),
        [selectedMarkets],
    );

    useNumberArrayEffect(() => {
        if (categoryIds?.length) {
            setTeaserPayoutOdds(categoryIds);
        }
    }, categoryIds);

    useEffect(() => {
        if (betType !== BET_TYPE.TEASER) {
            return;
        }
        if (!teaserSelectedPoint || !betSlipMarketIds.length || !betSlipOutcomeIds.length) {
            setTeaserStatusDisabledMarketIds([]);
            return;
        }
        checkTeaserMarketsStatuses(betSlipOutcomeIds, teaserSelectedPoint);
    }, [betSlipOutcomeIds, teaserSelectedPoint]);

    async function setTeaserPayoutOdds(categoryIds: number[]) {
        const payoutOdds = await getTeasersPayoutOdds(categoryIds);
        stores.sports.teaserPayouts.set(payoutOdds);
    }

    async function checkTeaserMarketsStatuses(outcomeIds: number[], teaserPoints: number) {
        const teaserOutcomesByMasterOutcomeId = await findTeaserMarkets(outcomeIds, teaserPoints);
        const disabledOutcomeIds = outcomeIds.filter(
            (outcomeId) => !teaserOutcomesByMasterOutcomeId[outcomeId]?.is_open,
        );
        const marketIdsThatHaveStatusNotOpen = Object.values(selectedMarkets)
            .filter((market) => market.outcomes.find((outcome) => disabledOutcomeIds.includes(outcome.id)))
            .map((market) => market.id);
        setTeaserStatusDisabledMarketIds(marketIdsThatHaveStatusNotOpen);
    }

    useNumberArrayEffect(() => {
        const existingMatchesIds = Object.keys(matchById).map((id) => Number(id));
        const missingMatchesIds = matchIds.filter((matchId) => !existingMatchesIds.includes(matchId));
        if (missingMatchesIds.length) {
            loadMatches();
        }
    }, matchIds);

    useNumberArrayEffect(() => {
        let marketTypeNotInSomeMatch = false;
        Object.values(matchById).forEach((match) => {
            const matchMts = match.markets.map((m) => m.market_type_id);
            if (difference(marketTypeIds, matchMts).length) {
                marketTypeNotInSomeMatch = true;
            }
        });

        if (marketTypeNotInSomeMatch) {
            loadMatches();
        }
    }, marketTypeIds);

    async function loadMatches() {
        const marketTypeIds = uniq(Object.values(selectedMarkets).map((market) => market.market_type_id));
        const matchIds = uniq(Object.values(selectedMarkets).map((market) => market.match_id));
        const { matches } = await fetchMatchMarketsAll(matchIds, marketTypeIds);
        setMatchById({ ...matchById, ...keyBy(matches, 'id') });
    }

    return (
        <>
            {Object.values(selectedMarkets).map((selectedMarket) => {
                if (!matchById[selectedMarket.match_id]) {
                    return null;
                }
                return (
                    <SportBetslipTeasersValidationMarket
                        key={selectedMarket.id}
                        selectedMarket={selectedMarket}
                        match={matchById[selectedMarket.match_id]}
                        betType={betType}
                    />
                );
            })}
        </>
    );
}

export default React.memo(SportBetslipTeasersValidation);
