import React, { useEffect, useState } from 'react';
import { depositPackage } from '../../../../../microservices/payments';
import { logger } from '../../../../../services/logger';
import { depositWithProvider, getDepositReturnRoute } from '../../../../../services/payments/payments';
import { DepositStatus, PROVIDERS } from '../../../../../services/payments/types';
import { useRouter } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import Snippet from '../../../../snippet/Snippet';
import UiAlert from '../../../../ui/alert/UiAlert';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import PaymentDepositProviderPayNearMeScripts from './scripts/PaymentDepositProviderPayNearMeScripts';
import Wrapper from './styles';

interface Props {
    amount: number;
    deviceHash: string;
    provider: PROVIDERS;
    disclaimer?: string;
    packageId?: number;
}

enum PayNearMeClientNotificationType {
    COMPLETE = 'complete',
    AUTHORIZED = 'authorized',
    INFO = 'info',
    EXIT = 'exit',
    ERROR = 'error',
}

export default function PaymentDepositProviderPayNearMe({
    amount,
    provider,
    deviceHash,
    disclaimer,
    packageId,
}: Props) {
    const [accessId, setAccessId] = useState('');
    const [isError, setIsError] = useState(false);
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);
    const [isSetupFinished, setIsSetupFinished] = useState(false);
    const [clientToken, setClientToken] = useState('');
    const [error, setError] = useState('');
    const { navigateTo } = useRouter();

    const actionsByProvider = {
        [PROVIDERS.PAY_NEAR_ME_CARD]: {
            action: 'pay',
            debit: true,
            credit: true,
            header: 'Debit/Credit Card',
            payment_field_fixed: 'true',
            payment_amount: amount,
        },
        [PROVIDERS.PAY_NEAR_ME_VOUCHER]: {
            action: 'pay',
            cash: true,
        },
    };

    useEffect(() => {
        startDepositTransaction();
    }, []);

    useEffect(() => {
        if (hasScriptsLoaded && clientToken && accessId) {
            loadClient();
        }
    }, [hasScriptsLoaded, clientToken, accessId]);

    async function loadClient() {
        // https://pro.paynearme-sandbox.com/mp/api/embedded/v3.0/js?site_id=3553
        try {
            await PNM.init({
                order_token: clientToken,
                callback: handleClientSideNotification,
                target: 'modal',
                auto_resize: true,
                actions: {
                    'pnm-button': actionsByProvider[provider],
                },
            });
            await PNM.launch('pnm-button');
            setIsSetupFinished(true);
        } catch (error) {
            logger.error('PaymentDepositProviderPayNearMe', 'loadClient', error);
            setIsError(true);
        }
    }

    async function handleClientSideNotification(notification) {
        if (notification.status === PayNearMeClientNotificationType.COMPLETE) {
            navigateTo(getDepositReturnRoute(DepositStatus.COMPLETED));
            await PNM.close();
        }
        if (
            (notification.status === PayNearMeClientNotificationType.INFO &&
                notification.error === 'Modal closed by user') ||
            notification.status === PayNearMeClientNotificationType.EXIT
        ) {
            const status = provider === PROVIDERS.PAY_NEAR_ME_VOUCHER ? DepositStatus.PENDING : DepositStatus.CANCELLED;
            navigateTo(getDepositReturnRoute(status));
            await PNM.close();
        }
        if (notification.status === PayNearMeClientNotificationType.ERROR) {
            navigateTo(getDepositReturnRoute(DepositStatus.FAILED));
            await PNM.close();
        }
    }

    async function startDepositTransaction() {
        try {
            let token: string;
            let error: string;
            let accessId: string;
            let snippetKey: string | undefined;
            if (packageId) {
                ({ token, error, accessId, snippetKey } = await depositPackage({ packageId, provider }));
            } else {
                ({ token, error, accessId } = await depositWithProvider({
                    amount,
                    deviceHash,
                    provider,
                    providerParams: {},
                }));
            }

            if (error && snippetKey) {
                navigateTo(getDepositReturnRoute(DepositStatus.FAILED, snippetKey));
                return;
            }

            if (error) {
                handleValidationError(error);
                return;
            }

            setClientToken(token);
            setAccessId(accessId);
        } catch (error: any) {
            logger.error('PaymentDepositProviderPayNearMe', 'startDepositTransaction', error);
            if (error.message) {
                handleValidationError(error.message);
                return;
            }
            setIsError(true);
        }
    }

    function handleValidationError(message: string) {
        setError(translate(message, 'ui.payments'));
        setIsSetupFinished(true);
    }

    if (isError) {
        return <PaymentProviderNotAvailable />;
    }

    return (
        <Wrapper>
            {error && <UiAlert failure>{error}</UiAlert>}
            {accessId && (
                <PaymentDepositProviderPayNearMeScripts
                    accessId={accessId}
                    onError={() => setIsError(true)}
                    onScriptsLoaded={() => setHasScriptsLoaded(true)}
                />
            )}
            <div>
                {disclaimer && <Snippet snippetKey={disclaimer} />}
                {!isSetupFinished && <UiDotsLoader />}
                <div id="pnm-button" />
            </div>
        </Wrapper>
    );
}

declare let PNM: any;
