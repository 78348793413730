import styled from 'styled-components';

export default styled.div`
    & .modal-container {
        padding: var(--spacing-8) var(--spacing-16);
        margin: 0 calc(-1 * var(--spacing-24));

        & .title {
            font-size: var(--font-16);
        }

        & .social-media-container {
            display: flex;
            justify-content: center;
            margin-top: var(--spacing-24);
            gap: var(--spacing-12);
            max-width: 100%;
            overflow: auto;

            & .box {
                cursor: pointer;
                margin-right: var(--spacing-8);

                & .copy-icon {
                    display: block;
                }
            }
        }

        & .pointer {
            cursor: pointer;
        }
    }
`;
