import React, { useState } from 'react';
import Wrapper from './styles';
import UiButton from '../button/UiButton';
import Svg from '../../svg/Svg';
import UiModal from '../modal/UiModal';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from 'react-share';
import { isTestUser } from '../../../services/user';
import { environment } from '../../../stores/environment/environment';
import { trackGoogleTagManagerEvent } from '../../../services/analytics';
import { ShareLinkType } from '../../../services/analytics/types';
import { translate } from '../../../services/translate';
import { logger } from '../../../services/logger';

interface Props {
    buttonDescription: string;
    modalTitle: string;
    contentMessage: string;
    urlToShare?: string;
    analytic: ShareLinkType;
    disabled?: boolean;
    onShareButtonClick?: () => Promise<string | undefined>;
}

export default function UiShareButton({
    buttonDescription,
    modalTitle,
    contentMessage,
    urlToShare = '',
    analytic,
    disabled,
    onShareButtonClick,
}: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState(urlToShare);
    const [isLoading, setIsLoading] = useState(false);

    const windowHeight = 800;
    const windowWidth = 1200;
    const windowPosition = 'windowCenter';

    const [{ isPhone }] = useStore(media);
    const [{ FACEBOOK_CLIENT_ID }] = useStore(environment);

    async function handleShareButtonClick() {
        try {
            setIsLoading(true);
            const fetchedPostfix = await onShareButtonClick?.();
            fetchedPostfix && setUrl(`${window.location.host}${fetchedPostfix}`);

            if (navigator.share && isPhone) {
                navigator.share({
                    url: fetchedPostfix ?? urlToShare,
                    title: contentMessage,
                });
            } else {
                setIsOpen(true);
            }
        } catch (err) {
            logger.error('UiShareButton', 'handleShareButtonClick', err);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Wrapper>
            <UiButton
                block
                color="default"
                size="default"
                icon={<Svg icon="share" size={0.75} />}
                className="betslip-share-button"
                isLoading={isLoading}
                disabled={disabled}
                onClick={handleShareButtonClick}
            >
                {buttonDescription}
            </UiButton>
            <UiModal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="new-modal"
                mode={isPhone ? 'drawer' : 'default'}
                minWidth={320}
                maxWidth={510}
            >
                <Wrapper>
                    <div className="modal-container">
                        <span className="title">{modalTitle}</span>

                        <div className="social-media-container">
                            <div className="box">
                                <CopyToClipboard
                                    text={url}
                                    onCopy={() => {
                                        toast.success(translate('url-copied', 'ui.common'));
                                        trackGoogleTagManagerEvent(analytic.LINK_SHARE_CLICKED);
                                    }}
                                >
                                    <div>
                                        <Svg className="copy-icon" icon="copy" size={2} />
                                        <div>URL</div>
                                    </div>
                                </CopyToClipboard>
                            </div>

                            <div className="box">
                                <TwitterShareButton
                                    url={url}
                                    title={contentMessage}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() => trackGoogleTagManagerEvent(analytic.TWITTER_SHARE_CLICKED)}
                                >
                                    <XIcon size={32} round />
                                    <div>X</div>
                                </TwitterShareButton>
                            </div>

                            {FACEBOOK_CLIENT_ID && isTestUser() && (
                                <div className="box">
                                    <FacebookMessengerShareButton
                                        url={url}
                                        appId={FACEBOOK_CLIENT_ID}
                                        content={contentMessage}
                                        onClick={() =>
                                            trackGoogleTagManagerEvent(analytic.FACEBOOK_MESSENGER_SHARE_CLICKED)
                                        }
                                    >
                                        <FacebookMessengerIcon size={32} round />
                                        <div>Messenger</div>
                                    </FacebookMessengerShareButton>
                                </div>
                            )}

                            <div className="box">
                                <WhatsappShareButton
                                    url={url}
                                    title={contentMessage}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() => trackGoogleTagManagerEvent(analytic.WHATS_APP_SHARE_CLICKED)}
                                >
                                    <WhatsappIcon size={32} round />
                                    <div>Whatsapp</div>
                                </WhatsappShareButton>
                            </div>
                            <div className="box">
                                <TelegramShareButton
                                    url={url}
                                    title={contentMessage}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() => trackGoogleTagManagerEvent(analytic.TELEGRAM_SHARE_CLICKED)}
                                >
                                    <TelegramIcon size={32} round />
                                    <div>Telegram</div>
                                </TelegramShareButton>
                            </div>
                            <div className="box">
                                <FacebookShareButton
                                    url={url}
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    windowPosition={windowPosition}
                                    onClick={() => trackGoogleTagManagerEvent(analytic.FACEBOOK_SHARE_CLICKED)}
                                >
                                    <FacebookIcon size={32} round />
                                    <div>Facebook</div>
                                </FacebookShareButton>
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </UiModal>
        </Wrapper>
    );
}
