import { Licence } from '@staycool/location';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { withContextProvider } from '../../../higher-order-components/context/provider/ContextProvider';
import { useStore } from '../../../hooks/useStore';
import { getMatchOfTheDay } from '../../../microservices/match-of-the-day';
import { MatchOfTheDay } from '../../../microservices/match-of-the-day/types';
import { DATE_TIME_FORMAT, getFormattedDate } from '../../../services/date';
import { isFeatureAvailable } from '../../../services/feature';
import { isWithLicence } from '../../../services/licence';
import { SportsMatchContext, useSportsMatchContext } from '../../../services/sports/match-helpers';
import { useSportsUserSettings } from '../../../services/sports/user-settings';
import { storageGet, storageSet } from '../../../services/storage';
import { translate } from '../../../services/translate';
import { FEATURE } from '../../../services/types';
import { stores } from '../../../stores';
import { media } from '../../../stores/media/media';
import Snippet from '../../snippet/Snippet';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import SportCategoryIcon from '../category-icon/SportCategoryIcon';
import SportMatchMarketsLoad from '../match/markets/load/SportMatchMarketsLoad';
import SportMatchName from '../match/name/SportMatchName';
import SportMatchTurnover from '../match/turnover/SportMatchTurnover';
import SportOddsLoad from '../odds/load/SportOddsLoad';
import Wrapper from './styles';

function SportMatchOfTheDay() {
    const [matchOfTheDay, setMatchOfTheDay] = useState<MatchOfTheDay>();
    const [isRulesVisible, setIsRulesVisible] = useState(false);
    const [isSideBetsOpen, setIsSideBetsOpen] = useState(false);
    const [isMatchExpanded, setIsMatchExpanded] = useState(true);
    const [{ isPhone }] = useStore(media);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const { turnoverVisible, setContext } = useSportsMatchContext();
    const [isBoringMatchRefundVisible, setIsBoringMatchRefundVisible] = useState(false);
    const { layout } = useSportsUserSettings();

    useEffect(() => {
        const isMatchOfTheDayExpanded =
            storageGet('matchOfTheDayVisible') !== undefined ? storageGet('matchOfTheDayVisible') : true;
        setIsMatchExpanded(Boolean(isMatchOfTheDayExpanded));
        fetchMatchOfTheDayInfo();
        setIsBoringMatchRefundVisible(!isWithLicence(Licence.SWEDEN));
    }, [isAuthenticated]);

    useEffect(() => {
        fetchMatchOfTheDayInfo();
    }, [layout]);

    async function fetchMatchOfTheDayInfo() {
        const matchOfTheDay = await getMatchOfTheDay();
        if (matchOfTheDay) {
            setMatchOfTheDay(matchOfTheDay);
        }
    }

    function toggleMatchVisibility() {
        setIsMatchExpanded(!isMatchExpanded);
        storageSet('matchOfTheDayVisible', !isMatchExpanded);
    }

    if (!matchOfTheDay?.match) {
        return null;
    }
    const market = {
        ...matchOfTheDay?.market,
        in_play: matchOfTheDay.match.inplay,
        betting_end: matchOfTheDay.match.betting_end,
    };

    return (
        <Wrapper>
            <div className="match-of-day">
                <div className="motd-header" onClick={toggleMatchVisibility}>
                    <Snippet className="mod-title animated fadeIn" snippetKey="mod.title" />
                    {isPhone && (
                        <div
                            className="rules-link animated fadeInRight"
                            onClick={() => setIsRulesVisible(!isRulesVisible)}
                        >
                            <div className="rules-icon">
                                <Svg icon="info" size={1.3} />
                            </div>
                        </div>
                    )}
                </div>

                <div className="motd-match  animated fadeIn">
                    <div className="match-info">
                        <div className="match-name" onClick={() => setIsSideBetsOpen(!isSideBetsOpen)}>
                            <SportMatchName match={matchOfTheDay?.match} isCompact />
                        </div>
                        <div className="motd-odds">
                            {matchOfTheDay?.market?.outcomes.map((outcome) => (
                                <SportOddsLoad
                                    key={outcome.id}
                                    outcomeId={outcome.id}
                                    market={market}
                                    isOutcomeNameVisible
                                />
                            ))}
                        </div>
                    </div>

                    <div className="match-category">
                        <SportCategoryIcon icon={matchOfTheDay?.match?.sport_icon} />
                        <span>{matchOfTheDay?.match?.categoryName}</span>
                        <span>
                            {getFormattedDate({
                                date: matchOfTheDay?.match?.match_start,
                                format: DATE_TIME_FORMAT,
                                useMonthLetters: true,
                            })}
                        </span>
                        <div className="motd-match-extra">
                            {isFeatureAvailable(FEATURE.MATCH_TURNOVER) && (
                                <div
                                    onClick={() => setContext?.({ turnoverVisible: !turnoverVisible })}
                                    className={classNames('turnover-icon', { selected: turnoverVisible })}
                                >
                                    <Svg icon="turnover" size={1.5} />
                                </div>
                            )}
                            {matchOfTheDay?.match?.markets_total_count && matchOfTheDay.match.markets_total_count > 1 && (
                                <div className="motd-sidebets-count" onClick={() => setIsSideBetsOpen(!isSideBetsOpen)}>
                                    +{matchOfTheDay.match.markets_total_count - 1}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {matchOfTheDay?.motd?.isEligibleForBoringMatch && (
                    <div className="motd-info  animated fadeInUp" onClick={() => setIsRulesVisible(!isRulesVisible)}>
                        <div className="desc-box">
                            {matchOfTheDay?.motd?.isEligibleForMultiplier && (
                                <div className="info-title">
                                    <span className="offer-icon">{matchOfTheDay?.motd?.multiplier}%</span>
                                    {translate('multiplier-bonus', 'sports.mod')}
                                </div>
                            )}

                            {isBoringMatchRefundVisible && (
                                <div className="info-title">
                                    <span className="offer-icon">
                                        <Svg icon="shield" size={isPhone ? 1.3 : 1.5} />
                                    </span>
                                    {translate('sports.mod.usp2')}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {matchOfTheDay?.motd?.isEligibleForBoringMatch && !isPhone && (
                    <div className="rules-link animated fadeInRight" onClick={() => setIsRulesVisible(!isRulesVisible)}>
                        <div className="rules-icon">
                            <Svg icon="info" size={1.5} />
                        </div>
                    </div>
                )}
            </div>

            {isRulesVisible && matchOfTheDay?.motd?.isEligibleForBoringMatch && (
                <div className="motd-rules">
                    <UiButton block size="small" onClick={() => setIsRulesVisible(!isRulesVisible)}>
                        {translate('Close', 'ui.common')}
                    </UiButton>
                    <Snippet className="rules" snippetKey="mod.rules" />
                </div>
            )}

            {turnoverVisible && <SportMatchTurnover mainMarket={market} matchId={matchOfTheDay?.match?.id} />}

            {isSideBetsOpen && (
                <div className="motd-sidebets-container">
                    <UiButton block size="small" onClick={() => setIsSideBetsOpen(!isSideBetsOpen)}>
                        {translate('Close', 'ui.common')}
                    </UiButton>
                    <SportMatchMarketsLoad matchId={matchOfTheDay?.match?.id} match={matchOfTheDay?.match} />
                </div>
            )}
        </Wrapper>
    );
}

export default withContextProvider(SportMatchOfTheDay, SportsMatchContext);
