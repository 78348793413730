import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import { subscribeToOddsChangesByMarketIds, unsubscribeFromOddsChangesByMarketIds } from '../../microservices/pusher';
import { loadOddsByMarketIds } from '../../microservices/sb-odds';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { apiRequestsOnGoing } from '../api';
import { logger } from '../logger';
import { withUsageMonitoringById } from '../subscription';
import { getRoute } from '../router';
import { PromotionTypes } from '../cms-promotion';
import { getMarketIdsFromComboCardMatches } from './combo-cards';
import { MarketStatus, MarketViewType, OutcomeStatus, Serialize } from '@staycool/sports-types';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { MarketInfo } from './types';

export const ODDS_CHANGE = {
    DECREASE: 'DECREASE',
    INCREASE: 'INCREASE',
};

let isLoadingQueuedOdds = false;
let marketIdsToLoad: number[] = [];
const isLoadingOddsForMarketByMarketId = {};

function setMarketIdsStatusLoading(marketIds) {
    marketIds.forEach((marketId) => {
        isLoadingOddsForMarketByMarketId[marketId] = true;
    });
}

function setMarketIdsStatusNotLoading(marketIds) {
    marketIds.forEach((marketId) => {
        delete isLoadingOddsForMarketByMarketId[marketId];
    });
}

async function loadOddsByQueuedMarketIds() {
    const copyOfMarketIdsToLoad = uniq(marketIdsToLoad);

    try {
        marketIdsToLoad = [];
        setMarketIdsStatusLoading(copyOfMarketIdsToLoad);
        await loadOddsByMarketIds(copyOfMarketIdsToLoad);
        setMarketIdsStatusNotLoading(copyOfMarketIdsToLoad);
    } catch (error) {
        setMarketIdsStatusNotLoading(copyOfMarketIdsToLoad);
        marketIdsToLoad = [...marketIdsToLoad, ...copyOfMarketIdsToLoad];
        logger.error('SportsOddsService', 'loadOddsByQueuedMarketIds', error);
    }
}

const loadOddsByQueuedMarketIdsDebounced = debounce(loadOddsByQueuedMarketIds, 10);

export async function loadOddsForOutcomeId(outcomeId: number, marketId: number) {
    const oddsByOutcomeId = getStoreValue(stores.sports.oddsByOutcomeId);

    if (isLoadingOddsForMarketByMarketId[marketId] && !oddsByOutcomeId[outcomeId]) {
        return;
    }

    if (!oddsByOutcomeId[outcomeId]) {
        marketIdsToLoad.push(marketId);

        if (!isLoadingQueuedOdds) {
            isLoadingQueuedOdds = true;
            await loadOddsByQueuedMarketIdsDebounced();
            isLoadingQueuedOdds = false;
        }
    }
}

export function isOddsOpen(outcome, oddsByOutcomeId) {
    if (!outcome) {
        return;
    }
    const odds = oddsByOutcomeId[outcome.id];
    return !odds || (odds.status === 'OPEN' && odds.value > 1);
}

function cleanOddsStoreIfNotUsed(usageCountById) {
    if (apiRequestsOnGoing > 0 || window.location.pathname.includes(getRoute('sport.betslip'))) {
        return;
    }
    if (getStoreValue(stores.sports.currentlyOpenMatchIds).length) {
        return;
    }
    if (isEmpty(getStoreValue(stores.sports.oddsByOutcomeId))) {
        return;
    }
    if (getStoreValue(stores.sports.isHotCardWithOdds)) {
        return;
    }
    const betslip = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const comboCards = getStoreValue(stores.sports.comboCard.comboCards);

    const allComboCardsMarketIds = comboCards.flatMap(({ matches }) => getMarketIdsFromComboCardMatches(matches));
    stores.sports.oddsByOutcomeId.set((state) => {
        Object.values(state).forEach((odds) => {
            if (!odds) {
                return;
            }

            const { market_id, outcome_id } = odds;

            const isNotUsedInComboCardBetslip = !allComboCardsMarketIds.includes(market_id);
            const isNotUsedInBetslip = !betslip[market_id];

            if ((usageCountById[market_id] || 0) <= 0 && isNotUsedInBetslip && isNotUsedInComboCardBetslip) {
                delete state[outcome_id];
            }
        });
    });
}
export const {
    subscribeWithMonitor: queuedSubscribeOddsByMarketId,
    unsubscribeWithMonitor: queuedUnsubscribeOddsByMarketId,
    instanceCountsById: oddsUsageByMarketId,
} = withUsageMonitoringById(subscribeToOddsChangesByMarketIds, unsubscribeFromOddsChangesByMarketIds, 60);

let oddsCleanupIdleCb;
setInterval(() => {
    if ('requestIdleCallback' in window && !oddsCleanupIdleCb) {
        oddsCleanupIdleCb = (window as any).requestIdleCallback(
            () => {
                cleanOddsStoreIfNotUsed(oddsUsageByMarketId);
                oddsCleanupIdleCb = null;
            },
            { timeout: 60000 },
        );
        return;
    }
    cleanOddsStoreIfNotUsed(oddsUsageByMarketId);
}, 20000);

type SportOddsMarketOutcome = {
    id: number;
    name: string;
    result_key: string;
    status: OutcomeStatus;
};

type SportOddsMarket = {
    betting_end?: string | Serialize<Date | undefined> | Date; // needed due to strictNullCheck differences? string | Date should be correct?
    id: number;
    in_play: boolean;
    outcomes: SportOddsMarketOutcome[];
    provider: number | null;
    raw_line: number;
    status: MarketStatus;
    view_type: MarketViewType | string;
};

export type SportOddsProps = {
    children?: React.ReactChildren | React.ReactElement | undefined;
    isAmericanLayout?: boolean;
    isAmericanLayoutTopMarkets?: boolean;
    isBetslip?: boolean;
    isDisabled?: boolean;
    isBetbuilderOdds?: boolean;
    isOutcomeNameVisible?: boolean;
    light?: boolean;
    market: SportOddsMarket | BetSlipMinimalMarket | MarketInfo;
    matchId?: number;
    outcomeId: number;
    small?: boolean;
    promotion?: PromotionTypes; // TODO: remove?
};
