import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { logger } from '../../services/logger';
import { fetchAllComboCards } from '../../microservices/sbgate';
import ComboCardsItem from './item/ComboCardsItem';
import { stores } from '../../stores';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { useSocketSubscribeUnsubscribe, useSocketTopicEvents } from '../../microservices/pusher';
import { getUserCountry } from '../../services/users/country';
import { useStore } from '../../hooks/useStore';
import { getStoreValue } from '../../stores/store/utils';
import differenceBy from 'lodash/differenceBy';
import uniq from 'lodash/uniq';
import { withErrorBoundary } from '../../higher-order-components/error-boundary';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import SportMatchOfTheDay from '../sport/match-of-the-day/SportMatchOfTheDay';
import SportMatchOfTheDayCard from '../sport/match-of-the-day-card/SportMatchOfTheDayCard';
import { getRoute, isActiveRoute } from '../../services/router';

function ComboCards() {
    const isComboCardsNotAvailable = !isFeatureAvailable(FEATURE.COMBO_CARDS);
    const isMatchOfTheDayAvailable = isFeatureAvailable(FEATURE.MATCH_OF_THE_DAY);
    const isNotRecommendationsPage = !isActiveRoute(getRoute('sport.recommendations'), false);
    const [language] = useStore(stores.language);
    const [ineligibleCardIds, setIneligibleCardIds] = useStore(stores.sports.comboCard.ineligibleCardIds);
    const [comboCards, setComboCards] = useStore(stores.sports.comboCard.comboCards);
    const [isLoading, setIsLoading] = useState(true);

    const noComboCardsToShow = !comboCards.length && !isLoading;

    useSocketSubscribeUnsubscribe('public', {
        params: {
            service: 'sports',
            channel: `combo-card-updater-${getUserCountry()}`,
        },
        resubscribeOnReconnect: true,
    });

    useSocketTopicEvents('combo-card-update', loadAllComboCards);

    useEffect(() => {
        loadAllComboCards();
    }, []);

    useEffect(() => {
        updateBetslipComboCards(comboCards);
    }, [comboCards]);

    function updateBetslipComboCards(newlyFetchedCards: FoComboCardWithOdds[]) {
        const betslipCards = getStoreValue(stores.sports.comboCard.cardsInBetslip);
        if (!betslipCards.length) {
            return;
        }
        const nowIneligibleCardIds = differenceBy(betslipCards, newlyFetchedCards, 'id').map(({ id }) => id);
        setIneligibleCardIds(uniq([...ineligibleCardIds, ...nowIneligibleCardIds]));
    }

    async function loadAllComboCards() {
        try {
            setIsLoading(true);
            const fetchedComboCards = await fetchAllComboCards(language);
            setComboCards(fetchedComboCards);
        } catch (error) {
            logger.error('ComboCards', 'loadAllComboCards', error);
        } finally {
            setIsLoading(false);
        }
    }

    if (isNotRecommendationsPage) {
        return null;
    }

    if (noComboCardsToShow || isComboCardsNotAvailable) {
        return isMatchOfTheDayAvailable && <SportMatchOfTheDay />;
    }

    return (
        <Wrapper>
            {isMatchOfTheDayAvailable && <SportMatchOfTheDayCard />}
            {comboCards.map((card) => (
                <ComboCardsItem card={card} key={card.id} />
            ))}
        </Wrapper>
    );
}

export default withErrorBoundary(ComboCards);
