import { Country } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { InAppPurchaseResult, InAppPurchaseResultBody } from '../services/payments/in-app-purchase';
import {
    ExistingPaymentMethod,
    PaymentProvider,
    PnpSettings,
    PROVIDER_TYPE,
    ProviderRouting,
    PROVIDERS,
    Transaction,
    TransactionState,
} from '../services/payments/types';
import { TransactionsPageTransaction } from '../services/types';
import { toBase64 } from '../services/util';

const getUrl = (url) => getServiceUrl('payments2', url);

export async function getTransactionById(id: string) {
    const url = getUrl(`transactions/${id}`);
    return httpGet<Transaction>(url);
}

export async function getFilteredPaymentTransactions(filter) {
    const url = getUrl('transactions/filter');
    return httpPost<Transaction[]>(url, filter);
}

export async function cancelWithdrawalRequest(transactionId: string) {
    const url2 = getUrl('transactions/withdrawal-decision/cancel');
    return httpPost(url2, { transactionId });
}

export async function lockWithdrawalRequest(transactionId: string) {
    const url2 = getUrl('transactions/withdrawal-decision/lock');
    return httpPost(url2, { transactionId });
}

export async function depositMoney(params: DepositParams) {
    const url = getUrl('transactions/deposit');
    return httpPost<any>(url, params);
}

export async function depositPackage(params: PaymentPackageDepositParams) {
    const url = getUrl('transactions/deposit/package');
    return httpPost<any>(url, params);
}

export function depositWithPnp(params: {
    currency: string;
    amount: number;
    country: string;
    bonusCode?: string;
    language: string;
}) {
    const url = getUrl('transactions/deposit/pnp');
    return httpPost<{ url: string }>(url, params);
}

export async function pollDeposit(provider: PROVIDERS, pollStartDate: string, transactionId?: string) {
    const url = getUrl('transactions/deposit/poll');
    return httpGet<TransactionState>(url, { provider, pollStartDate, transactionId });
}

export async function transactionNotification(provider: PROVIDERS, body: InAppPurchaseResultBody) {
    const url = getUrl(`transactions/notification/${provider}`);
    return httpPost<TransactionNotificationResponse>(url, body);
}

export async function requestProviderPayout({
    amount,
    methodId,
    provider,
    providerParams,
}: {
    amount: number;
    methodId?: string;
    provider: string;
    providerParams?: any;
}) {
    const url = getUrl('transactions/withdrawal-request');
    return httpPost<{ url?: string; data?: Record<string, unknown> }>(url, {
        amount,
        methodId,
        provider,
        providerParams,
    });
}

export async function getPaymentProviders(params: PaymentProvidersParams) {
    const url2 = getUrl('providers');
    return httpGet<{ hasDepositsTurnedOver?: boolean; providers: PaymentProvider[] }>(url2, params);
}

export async function getProviderPaymentMethods(provider: PROVIDERS, providerType: PROVIDER_TYPE) {
    const url = getUrl('accounts');
    return httpGet<ExistingPaymentMethod[]>(url, {
        provider,
        providerType,
    });
}

export async function getMyCardCount() {
    const url = getUrl('payment-methods/my-card-count');
    return httpGet<{ current: number; limit: number }>(url);
}

export async function providerAction(props: ProviderActionsParams) {
    const url = getUrl('transactions/pre-payment');
    return httpPost<any>(url, props);
}

export async function getProviderMethods(provider, amount, providerType) {
    const url = getUrl('provider/methods');
    return httpGet<any>(url, {
        provider,
        amount,
        providerType,
    });
}

export async function getProviderMethodAmounts(provider, amount, method) {
    const url = getUrl('provider/method/amounts');
    return httpGet(url, { provider, amount, method });
}

export function getProviderRoutings(filters: Partial<Pick<ProviderRouting, 'market' | 'type'>> = {}) {
    const url = getUrl('provider-routings');
    return httpGet<ProviderRouting[]>(url, filters);
}

export async function getUserPaymentsSettings() {
    const url = getUrl('user-settings');
    return httpGet<{ id: number; isThirdPartyConditionsConfirmed: boolean; isDepositLocked: boolean }>(url);
}

export async function saveUserPaymentsSettings(settings) {
    const url2 = getUrl('user-settings');
    return httpPost(url2, settings);
}

export async function getPayAndPlaySettingsByCountry(country: Country) {
    const url = getUrl(`pay-and-play-settings/${country}/public`);
    return httpGet<PnpSettings | undefined>(url);
}

export async function getExchangeRate(provider: PROVIDERS, currency: string) {
    const url = getUrl('exchange-rate');
    return httpGet<string>(url, { provider, currency });
}

export function returnFromPayment(provider: PROVIDERS, payload) {
    const url = getUrl(`transactions/return/${toBase64(provider)}`);
    return httpPost(url, payload);
}

export async function getPaymentsDeposits(filter: {
    created__ge?: string;
    created__lt?: string;
    page_size?: number;
    page: number;
}) {
    const url = getUrl('transactions/deposit/user');
    return httpGet<{ transactions: TransactionsPageTransaction[]; hasNextPage: boolean }>(url, filter);
}

export type TransactionNotificationResponse = {
    result: InAppPurchaseResult;
    message?: string;
};

type DepositParams = {
    amount: number;
    language: string;
    methodId?: string;
    provider: string;
    providerParams?: any;
};

type PaymentProvidersParams = {
    type: PROVIDER_TYPE;
    market?: Country;
    slug?: string;
};

type PaymentPackageDepositParams = {
    packageId: number;
    isAppPurchase?: boolean;
    provider: PROVIDERS;
    bonusCode?: string;
    providerParams?: Record<string, string>;
};

type ProviderActionsParams = {
    provider: string;
    providerParams: any;
};
