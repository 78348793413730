import { createStores } from '../store/utils';
import { PromotionSource } from './types';
import { UpcomingTournament } from '../../microservices/poker/types';

export const poker = createStores({
    isClosePopup: false,
    loginInProgress: false,
    isLoggedIn: false,
    isPokerBlocked: false,
    isLoadingPokerBlocked: false,
    isFirstRenderLoading: true,
    hasDownloadClientAuthBeenActivated: false,
    upcomingTournaments: {
        [PromotionSource.CARDROOM]: [] as UpcomingTournament[],
        [PromotionSource.NETWORK]: [] as UpcomingTournament[],
    },
});
