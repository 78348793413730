import styled from 'styled-components';

export default styled.div`
    display: flex;
    justify-content: space-between;
    background: var(--surface-level-1-bg);
    border-top-left-radius: var(--ui-radius);
    border-top-right-radius: var(--ui-radius);

    & > div {
        display: flex;
        justify-content: center;
        flex: 1;
        text-transform: uppercase;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-12);
        line-height: var(--font-12);
        height: 44px;

        &:not(.active) {
            color: var(--font-color-secondary);
        }

        .betslip-title {
            display: inline-flex;
            align-items: center;
            gap: var(--spacing-8);
        }

        .bets-count {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 1px solid currentColor;
            border-radius: 10px;
            padding: 0 var(--spacing-4);
            min-width: 20px;
            height: 20px;

            & > span {
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                color: white;
                text-align: center;
            }

            &:empty {
                display: none;
            }
        }
    }
`;
