import { createStores } from '../store/utils';
import { LimitPeriod, ResponsibleGamingProduct } from '../../services/responsible-gaming/types';
import { ResponsibleGamingStore } from './types';

export const responsibleGaming = createStores<ResponsibleGamingStore>({
    annualReport: null,
    askDepositLimit: undefined,
    askLoginDurationLimit: undefined,
    askLossLimit: undefined,
    isAnnualReportLoaded: null,
    isMaltaPanelVisible: false,
    isPanicTimeoutActive: false,
    isResponsibleGamingPanelVisible: false,
    isSelfExclusionTimeoutActive: false,
    isUnapprovedSessionReminderLoaded: false,
    limitConfig: {
        deposit: { max: { [LimitPeriod.DAY]: 0, [LimitPeriod.WEEK]: 0, [LimitPeriod.MONTH]: 0 }, min: 0 },
        individualBet: { max: 0, min: 0 },
        loss: { max: { [LimitPeriod.DAY]: 0, [LimitPeriod.WEEK]: 0, [LimitPeriod.MONTH]: 0 }, min: 0 },
        wager: { max: { [LimitPeriod.DAY]: 0, [LimitPeriod.WEEK]: 0, [LimitPeriod.MONTH]: 0 }, min: 0 },
        selfExclusionOptions: [],
        timeoutOptions: {},
    },
    loginDurationLimitEndDate: null,
    panicTimeoutEndDate: new Date(),
    restrictions: [],
    selfExclusionTimeoutEndDate: new Date(),
    selfExclusionUntilFurtherNotice: undefined,
    timeout: {
        [ResponsibleGamingProduct.CASINO]: undefined,
        [ResponsibleGamingProduct.HORSE_RACING]: undefined,
        [ResponsibleGamingProduct.POKER]: undefined,
        [ResponsibleGamingProduct.SPORTSBOOK]: undefined,
    },
    unApprovedSessionReminder: null,
});
