import styled from 'styled-components';
import { ClientName } from '../../../services/utils/types';

export default styled.div`
    .prompt-title {
        margin-top: 0;
    }

    .prompt-message {
        color: var(--font-color-primary);
    }

    .logo-${ClientName.IVC} {
        display: none;
    }
`;
