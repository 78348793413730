import React from 'react';
import SportBetslip from '../betslip/SportBetslip';
import SportBetslipV2Container from '../betslip-v-2/container/SportBetslipV2Container';
import { FEATURE } from '../../../services/types';
import { isFeatureAvailable } from '../../../services/feature';
import { isTestUser } from '../../../services/user';

export default function SportBetslipWrapper() {
    return (
        <>{isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser() ? <SportBetslipV2Container /> : <SportBetslip />}</>
    );
}
