import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    overflow: auto;
    padding: var(--spacing-8) 0;
    margin: 0 !important;
    > *:not(:first-child) {
        margin-left: 0.4rem;
    }
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        justify-content: flex-start;
        padding: 0 0 var(--spacing-8) 0;
    }
`;
