import React, { useEffect, useMemo, useState } from 'react';
import { useNumberArrayEffect } from '../../../../hooks/useNumberArrayEffect';
import { formatLine } from '../../../../services/sports/market';
import { stores } from '../../../../stores';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import Wrapper from './styles';
import orderBy from 'lodash/orderBy';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { useStore } from '../../../../hooks/useStore';

export default function SportBetslipTeaserSelect() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [selectedPointIndex, setSelectedPointIndex] = useState(0);
    const [teaserPayouts] = useStore(stores.sports.teaserPayouts);
    const { default_points, payout_odds = [] } = teaserPayouts;
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [position] = useStore(stores.sports.betSlipPosition);
    const [betslipCollection] = useStore(stores.sports.betslipCollection);
    const [isByodOrReplay, setIsByodOrReplay] = useStore(stores.sports.isByodOrReplay);

    const markets = Object.keys(betSlipMarketIdToOutcomeId)
        .map((marketId) => marketInfoById?.[Number(marketId)])
        .filter(Boolean);

    const sportCategories = orderBy(
        uniqBy(
            markets.map((mr) => ({ id: mr.sport_category_id, icon: mr.sport_icon })),
            'id',
        ),
    );

    const points = useMemo(() => {
        return uniq(payout_odds.map(({ points }) => points));
    }, [teaserPayouts]);

    const preserveTeaserPoint = isByodOrReplay && teaserSelectedPoint && !points.length;

    useNumberArrayEffect(() => {
        if (preserveTeaserPoint) {
            return;
        }
        const defaultPoints = isByodOrReplay && teaserSelectedPoint ? teaserSelectedPoint : default_points;
        const index = points.findIndex((point) => point === defaultPoints);
        setSelectedPointIndex(index || 0);
    }, points);

    useEffect(() => {
        if (!preserveTeaserPoint) {
            stores.sports.teaserSelectedPoint.set(points[selectedPointIndex]);
        }
    }, [selectedPointIndex]);

    useEffect(() => {
        if ((!betslipCollection.length && !isByodOrReplay) || teaserSelectedPoint === undefined) {
            return;
        }
        const index = points.findIndex((point) => point === teaserSelectedPoint);
        setSelectedPointIndex(index || 0);
        setIsByodOrReplay(false);
    }, [position, isByodOrReplay]);

    function displaySelectedPoints() {
        if (sportCategories.length > 1) {
            return (
                <div>
                    {sportCategories.map(({ id, icon }) => {
                        return (
                            <div className="multiple-teaser-points" key={id}>
                                <SportCategoryIcon icon={icon} /> {displayTeaserPoint(id)}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return sportCategories[0] && displayTeaserPoint(sportCategories[0].id);
    }

    function displayTeaserPoint(sportCategoryId: number) {
        const selectedPoint = points[selectedPointIndex];
        const teaserPoints =
            teaserPayouts.alternative_points?.find(
                (ap) => ap.sport_category_id === sportCategoryId && ap.original_points === selectedPoint,
            )?.alternative_points || selectedPoint;
        if (teaserPoints > 0) {
            return `+${formatLine(teaserPoints)}`;
        }
        return formatLine(teaserPoints);
    }

    if (!payout_odds.length) {
        return null;
    }

    return (
        <Wrapper>
            <div className="point-picker">
                <UiButton
                    disabled={selectedPointIndex === 0}
                    onClick={() => setSelectedPointIndex(selectedPointIndex - 1)}
                    icon={<Svg icon="drawer" size={1.25} />}
                />
                <div className="active-point">
                    <div className="value">{displaySelectedPoints()}</div>
                    <div className="title">Teaser points</div>
                </div>
                <UiButton
                    icon={<Svg icon="drawer" size={1.25} rotate={180} />}
                    onClick={() => setSelectedPointIndex(selectedPointIndex + 1)}
                    disabled={selectedPointIndex === points.length - 1}
                />
            </div>
        </Wrapper>
    );
}
