import React from 'react';
import Wrapper from './styles';
import SportTicketType from '../type/SportTicketType';
import { useCashoutDoneRefreshTicket } from '../../../../services/sports/cashout';
import { TICKET_TYPE } from '../../../../services/bet-history';
import SportBadgeTicketStatus from '../../badge/ticket-status/SportBadgeTicketStatus';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { Ticket } from '../../../../services/sports/types';
import SportCategoryIcon from '../../category-icon/SportCategoryIcon';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    ticket: Ticket;
    isCompactView?: boolean;
    isLeaderboard?: boolean;
    isPublic?: boolean;
}

export default function SportTicketMatchInfo({ ticket: propsTicket, isCompactView, isLeaderboard, isPublic }: Props) {
    const [{ isPhone }] = useStore(media);
    const localTicket = useCashoutDoneRefreshTicket(propsTicket);

    const ticket = localTicket || propsTicket;
    const firstMatch = ticket.first_match;
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: firstMatch.sport_category_id });
    const isCompactViewEnabled = isCompactView || isPhone;
    const isBetbuilderTicket = ticket.has_betbuilder_selection;
    const isComboCardTicket = ticket.ticket_type === TICKET_TYPE.COMBOCARD;

    if (!propsTicket) {
        return null;
    }

    function getBetbuilderMarketName(marketName) {
        return marketName.split(' And ').join(', ');
    }

    function renderMatchName(match) {
        if (!match.away_team || !match.home_team) {
            return <span className="match-name">{`${match.match_name}`}</span>;
        }
        return isAmericanLayout ? (
            <span className="match-name">{`${match.away_team} @ ${match.home_team}`}</span>
        ) : (
            <span className="match-name">{`${match.home_team} - ${match.away_team}`}</span>
        );
    }

    return (
        <Wrapper $isCompactViewEnabled={isCompactViewEnabled}>
            {isCompactViewEnabled && (
                <div className="ticket-meta">
                    {!isLeaderboard && ticket.display_id && <span className="ticket-id">#{ticket.display_id}</span>}
                    <SportTicketType
                        ticket={ticket}
                        isLeaderboard={isLeaderboard}
                        isCompactView={isCompactView}
                        className="bet-type"
                        hasBetBuilderSelections={isBetbuilderTicket}
                    />
                    {!isPublic && <SportBadgeTicketStatus ticket={ticket} className="bet-status" />}
                </div>
            )}
            <div className="match-sport">
                <span className="match-league">{firstMatch.categoryName || firstMatch.league_name}</span>
                {isCompactViewEnabled && <SportCategoryIcon icon={firstMatch.sport_icon} />}
            </div>
            <div className="match-info">
                {renderMatchName(firstMatch)}

                {ticket.num_bets_total > 1 && (
                    <div className="match-stats">
                        (&nbsp;{ticket.num_bets_won}&nbsp;/&nbsp;{ticket.num_bets_lost}&nbsp;/&nbsp;
                        {ticket.num_bets_total}&nbsp;)
                    </div>
                )}
            </div>

            {firstMatch && (
                <>
                    <div className="selected-market">
                        {isBetbuilderTicket ? (
                            <div className="market-name">
                                {getBetbuilderMarketName(firstMatch.marketTypeName || firstMatch.market_name)}
                            </div>
                        ) : isComboCardTicket ? (
                            <span className="market-outcome">{firstMatch.outcome_name}</span>
                        ) : (
                            <>
                                <div className="market-name">{firstMatch.marketTypeName || firstMatch.market_name}</div>
                                <span>»</span>
                                <span className="market-outcome">{firstMatch.outcome_name}</span>
                            </>
                        )}
                    </div>
                </>
            )}
            {!isCompactViewEnabled && (
                <div className="ticket-meta">
                    {!isLeaderboard && ticket.display_id && <span className="ticket-id">#{ticket.display_id}</span>}
                    <SportTicketType
                        ticket={ticket}
                        isLeaderboard={isLeaderboard}
                        isCompactView={isCompactView}
                        className="bet-type"
                        hasBetBuilderSelections={isBetbuilderTicket}
                    />
                </div>
            )}
        </Wrapper>
    );
}
