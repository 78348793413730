import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { TicketType } from '../../../services/sports/types';
import { isBYOD } from '../../../services/environment';

interface Props {
    type: string;
    extraInfo?: string;
    has_betbuilder_selection?: boolean;
}

export default function SportBadge({ type, extraInfo, has_betbuilder_selection = false }: Props) {
    if (!type) {
        return null;
    }

    function formatType() {
        if (type === TicketType.SINGLE && has_betbuilder_selection) {
            return isBYOD() ? 'SGP' : translate('Betbuilder', 'ui.sportsbook');
        }
        return translate(type, 'sport.badge');
    }

    return (
        <Wrapper type={type} className="sport-badge">
            {`${formatType()} ${extraInfo ? `- ${extraInfo}` : ''}`}
        </Wrapper>
    );
}
