import forEach from 'lodash/forEach';
import { useEffect, useState } from 'react';
import { createSocketSubscribeTopics, socket, useSocketTopicEvents } from '../../microservices/pusher';
import { getTicketDetailsByTickedId } from '../../microservices/sbgate';
import { stores } from '../../stores';
import { logger } from '../logger';
import {
    ACCOUNT_CLOSED_ERROR,
    ACCOUNT_CLOSED_ERROR_MESSAGE,
    GEOCOMPLY_FAILED_ERROR,
    GEOCOMPLY_FAILED_ERROR_MESSAGE,
    LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR,
    LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE,
} from './constants';
import { Ticket } from './types';
import { useStore } from '../../hooks/useStore';

export const CASHOUT_AMOUNT_CHANGE_ERROR = 1158;

export const errorCodes = {
    1107: 'cashout_odds_changed',
    1151: 'cashout_not_available',
    1152: 'cashout_not_available',
    1153: 'cashout_not_available',
    1154: 'cashout_not_available',
    1155: 'cashout_disabled',
    1156: 'technical-error',
    1157: 'technical-error',
    [CASHOUT_AMOUNT_CHANGE_ERROR]: 'cashout_amount_wrong',
    1159: 'technical-error',
    1160: 'technical-error',
    1161: 'cashout_odds_closed',
    1162: 'cashout_amount_too_low',
    1164: 'cashout_not_available',
    1165: 'cancel_wallet_transaction_not_supported',
    [GEOCOMPLY_FAILED_ERROR]: GEOCOMPLY_FAILED_ERROR_MESSAGE.toLowerCase(),
    3000: 'player_not_authenticated',
    [LOGGED_IN_TIME_DURATION_EXCEEDED_ERROR]: LOGGED_IN_TIME_DURATION_EXCEEDED_MESSAGE.toLowerCase(),
    [ACCOUNT_CLOSED_ERROR]: ACCOUNT_CLOSED_ERROR_MESSAGE.toLocaleLowerCase(),
    5000: 'technical-error',
};

export function useCashoutListeners({
    cashoutTickets = [],
    setTickets,
}: {
    cashoutTickets?: Ticket[];
    setTickets: (tickets: Ticket[]) => void;
}) {
    useEffect(() => {
        if (!cashoutTickets || !cashoutTickets.length) {
            return;
        }
        const ticketIds = cashoutTickets.filter((ticket) => ticket.cashoutEnabled).map((ticket) => ticket.id);
        const { topicSubscribe, topicUnsubscribe } = createSocketSubscribeTopics('cashOutSums');
        socket.emit(topicSubscribe, ticketIds);
        return () => {
            socket.emit(topicUnsubscribe, ticketIds);
        };
    }, [cashoutTickets.map((ticket) => ticket.id).join(',')]);

    useSocketTopicEvents(
        'cashout-change',
        (amountChangesByTicketId) => {
            if (!cashoutTickets) {
                return;
            }
            const newTickets = [...cashoutTickets];
            forEach(amountChangesByTicketId, (ticketCashoutAmountUpdate, ticketId) => {
                const ticketToMutate = newTickets.find((ticket) => ticket.id === ticketId);
                if (!ticketToMutate) {
                    return;
                }
                ticketToMutate.cashoutAmount = ticketCashoutAmountUpdate;
            });
            setTickets(newTickets);
        },
        [cashoutTickets],
    );
}

export function useCashoutDoneRefreshTicket(propsTicket) {
    const [cashoutsDoneTicketIds] = useStore(stores.sports.cashoutsDoneTicketIds);
    const [localTicket, setLocalTicket] = useState();
    useEffect(() => {
        if (localTicket) {
            return;
        }
        if (propsTicket && cashoutsDoneTicketIds.includes(propsTicket.id)) {
            getTicketDetailsByTickedId(propsTicket.id)
                .then(({ ticket }) => setLocalTicket({ ...propsTicket, ...ticket }))
                .catch((e) => logger.error('SportsCashoutService', 'useCashoutDoneRefreshTicket', e));
        }
    }, [cashoutsDoneTicketIds, propsTicket && propsTicket.id]);
    return localTicket;
}
