import React from 'react';
import Wrapper from './styles';
import SportPhoneSelectSportSidebar from '../phone/select-sport-sidebar/SportPhoneSelectSportSidebar';
import { SwipeableDrawer } from '@mui/material';
import { stores } from '../../../stores';
import Svg from '../../svg/Svg';
import { translate } from '../../../services/translate';
import { isFeatureAvailable } from '../../../services/feature';
import { useStore } from '../../../hooks/useStore';
import { FEATURE } from '../../../services/types';

export default function SportSelect() {
    const [isSportSideMenuOpen, setIsSportSideMenuOpen] = useStore(stores.sports.isSportSideMenuOpen);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    function toggleSportSideMenuOpen() {
        setIsSportSideMenuOpen(!isSportSideMenuOpen);
    }
    return (
        <Wrapper $isLayoutB={isLayoutB}>
            <div className="sport-select" onClick={() => stores.sports.isSportSideMenuOpen.set(!isSportSideMenuOpen)}>
                <div className="select-sport-icon">
                    <Svg icon="select-sport" size={1.5} />
                </div>
                <div className="select-sport-title">{translate('All sports', 'sports.tabs')}</div>
            </div>

            <SwipeableDrawer
                disableEnforceFocus
                open={isSportSideMenuOpen}
                onOpen={toggleSportSideMenuOpen}
                onClose={toggleSportSideMenuOpen}
                anchor={isLayoutB ? 'left' : 'right'}
                disableDiscovery={true}
                swipeAreaWidth={0}
            >
                <SportPhoneSelectSportSidebar />
            </SwipeableDrawer>
        </Wrapper>
    );
}
