import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';
export default styled.div`
    position: relative;
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    background: var(--surface-level-2-bg);
    padding: 0.8rem;
    .remove-selection {
        cursor: pointer;
        color: var(--font-color-subtle);
        margin-left: var(--spacing-12);
        align-items: flex-start;
        &:hover {
            color: var(--match-primary-color);
        }
    }
    .bet-builder-header {
        display: flex;
        align-items: center;
        font-weight: var(--match-name-weight);
        color: var(--match-primary-color);
        margin-right: var(--spacing-8);
        font-size: 0.875rem;
        ${({ theme }) => theme.optimizeFontRendering};
        margin-bottom: var(--spacing-4);
        @media ${mediaQuery.isPhone} {
            font-size: var(--match-name-size-mobile);
        }
    }
    .outcome-odds {
        min-width: 56px;
        margin-left: auto;
    }
    .market-error {
        margin-top: 0.4rem;
        margin-bottom: 0;
    }
    .betbuilder-markets-list {
        margin-top: var(--spacing-4);
        .betbuilder-market {
            &:last-child {
                border-bottom: none;
                &:after {
                    display: none;
                }
            }
        }
    }
    .betbuilder-market {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: var(--spacing-8) 0;
        margin-left: var(--spacing-16);
        border-bottom: 1px dashed var(--surface-level-3-line-color);
        font-size: 0.75rem;
        color: var(--match-secondary-color);
        line-height: 130%;
        em {
            font-style: normal;
            color: var(--color-odds);
        }
        &:before {
            position: absolute;
            top: 50%;
            left: -19px;
            transform: translateY(-50%);
            content: ' ';
            border: 1px solid var(--surface-level-3-line-color);
            background: var(--surface-level-3-bg);
            border-radius: 7px;
            width: 7px;
            height: 7px;
        }
        &:after {
            position: absolute;
            top: 56%;
            left: -16px;
            height: 100%;
            width: 1px;
            background-color: var(--surface-level-3-line-color);
            content: ' ';
        }
        .info {
            display: flex;
            justify-content: space-between;
        }
        .outcome {
            color: var(--color-odds);
        }
        .name {
            display: flex;
            flex-direction: column;
        }
        .action {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;
