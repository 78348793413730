import React, { useEffect, useState } from 'react';
import { useStore } from '../../../../../../hooks/useStore';
import { logger } from '../../../../../../services/logger';
import { depositWithProvider } from '../../../../../../services/payments/payments';
import { PROVIDERS } from '../../../../../../services/payments/types';
import { translate } from '../../../../../../services/translate';
import { media } from '../../../../../../stores/media/media';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';
import Snippet from '../../../../../snippet/Snippet';
import UiButton from '../../../../../ui/button/UiButton';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import UiGroup from '../../../../../ui/group/UiGroup';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

interface DepositInitParams {
    access_token: string;
    api_username: string;
    merchant_id: string;
    account_name: string;
}

interface DepositPaymentParams {
    payment_reference: string;
    country_code: string;
    currency_code: string;
    payment_link: string;
    total: {
        label: string;
        amount: string;
    };
}

export default function PaymentDepositProviderEveryPayApplePay({ amount, deviceHash, disclaimer, onClose }: Props) {
    const [{ isPhone }] = useStore(media);
    const [depositInitParams, setDepositInitParams] = useState<DepositInitParams>();
    const [depositPaymentParams, setDepositPaymentParams] = useState<DepositPaymentParams>();
    const [error, setError] = useState('');
    const [isDepositing, setIsDepositing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [sdk, setSdk] = useState<{ createApplePayPayment: (params) => void }>();

    useEffect(() => {
        startDeposit();
    }, []);

    useEffect(() => {
        if (isScriptLoaded && depositInitParams && Object.keys(depositInitParams).length) {
            setSdk(ApplePayClient.initialize(depositInitParams));
        }
    }, [depositInitParams, isScriptLoaded]);

    function deposit() {
        setIsDepositing(true);
        try {
            sdk?.createApplePayPayment(depositPaymentParams);
        } catch (error) {
            logger.error('PaymentDepositProviderEveryPayApplePay', 'deposit', error);
            setIsError(true);
        }
        setIsDepositing(false);
    }

    async function startDeposit() {
        if (!window.ApplePaySession?.canMakePayments()) {
            setError(translate('Apple Pay is not supported on your device', 'ui.payments'));
            setIsError(true);
            return;
        }

        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.EVERY_PAY_APPLE_PAY,
                providerParams: {},
            });
            setDepositPaymentParams(response.payment);
            setDepositInitParams(response.init);
        } catch (error: any) {
            logger.error('PaymentDepositProviderEveryPayApplePay', 'startDeposit', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    if (isError) {
        return <PaymentProviderNotAvailable message={error} onBack={onClose} />;
    }

    return (
        <Wrapper>
            <ScriptLoader
                url="https://pay.every-pay.eu/apple_pay/apple-pay-client-1.4.js"
                onLoad={() => setIsScriptLoaded(true)}
            />
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {(!isScriptLoaded || isLoading) && <UiDotsLoader />}
            {!isLoading && (
                <UiGroup expand>
                    <UiButton
                        className="apple-pay-button apple-pay-button-white"
                        block={isPhone}
                        color="primary"
                        disabled={isDepositing}
                        isLoading={isDepositing}
                        onClick={deposit}
                    />
                </UiGroup>
            )}
        </Wrapper>
    );
}

declare let ApplePayClient: any;
