import { isMobile } from './browser';
import { UAParser } from 'ua-parser-js';
import { storageGet } from './storage';
import { Platform } from './casino/types';
import { ObjectValues } from './ts-utils';
import { AppType } from './mobile-app/types';

const uaParser = new UAParser();

export const OPERATING_SYSTEM = {
    ANDROID: 'Android',
    APPLE_DESKTOP: 'Mac OS',
    APPLE_MOBILE: 'iOS',
    LINUX: 'Linux',
    WINDOWS_DESKTOP: 'Windows',
    WINDOWS_MOBILE: 'Windows Phone',
};

export function getOS() {
    return uaParser.getOS().name;
}

export function getDeviceType() {
    return (uaParser.getDevice().type || 'DESKTOP').toUpperCase();
}

export function getDeviceCategory(appType: AppType): ObjectValues<typeof Platform> {
    if (appType !== AppType.WEB) {
        return appType === AppType.ANDROID ? Platform.MOBILE : Platform.IOS;
    }
    return isMobile() ? Platform.MOBILE : Platform.DESKTOP;
}

export function isNativeApp() {
    return storageGet('isNativeApp', false);
}
