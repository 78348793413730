import styled from 'styled-components';

type StyledProps = {
    $size?: number;
};

export default styled.img<StyledProps>`
    width: ${({ $size }) => $size}px;
    min-width: ${({ $size }) => $size}px;
    max-width: initial;
`;
