import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../../services/translate';

interface Props {
    name: string;
    clearSelection: () => void;
}
export default function SportContestsBetslipHeader({ name, clearSelection }: Props) {
    return (
        <Wrapper>
            <div className="title">{name}</div>
            <button className="clear-all" onClick={clearSelection}>
                {translate('Clear all', 'ui.sportsbook')}
            </button>
        </Wrapper>
    );
}
