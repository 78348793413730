import React, { useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import Svg from '../../../svg/Svg';
import { SwipeableDrawer } from '@mui/material';
import SportPhoneSelectSportSidebar from '../select-sport-sidebar/SportPhoneSelectSportSidebar';
import { isFeatureAvailable } from '../../../../services/feature';
import UiModal from '../../../ui/modal/UiModal';
import SportBetslipWrapper from '../../betslip-wrapper/SportBetslipWrapper';
import SportTicketList from '../../ticket/list/SportTicketList';
import isEmpty from 'lodash/isEmpty';
import SportParlayCardBetslip from '../../parlay-card/betslip/SportParlayCardBetslip';
import { isB2B, isBYOD } from '../../../../services/environment';
import { isMobileApp } from '../../../../services/mobile-app';
import PushToAppInstall from '../../../push-to-app-install/PushToAppInstall';
import ComboCardsBetslip from '../../../combo-cards/betslip/ComboCardsBetslip';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';

export default function SportPhoneNavigation() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [parlayCardBetSlipMarketIdToOutcomeIds] = useStore(stores.sports.parlayCard.betSlipMarketIdToOutcomeIds);
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const numberOfBets = Object.values(betSlipMarketIdToOutcomeId).length;
    const numberOfParlayBets = Object.values(parlayCardBetSlipMarketIdToOutcomeIds).flat().length;
    const numberOfComboCardBets = cardsInBetslip.length;
    const [isSportSideMenuOpen, setIsSportSideMenuOpen] = useStore(stores.sports.isSportSideMenuOpen);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [isBetslipOpen, setIsBetslipOpen] = useStore(stores.isBetslipOpen);
    const [isBetHistoryOpen, setIsBetHistoryOpen] = useStore(stores.isBetHistoryOpen);
    const isBetslipNotEmpty = !isEmpty(betSlipMarketIdToOutcomeId);
    const isParlayBetslipEmpty = isEmpty(parlayCardBetSlipMarketIdToOutcomeIds);
    const isComboCardBetslipEmpty = isEmpty(cardsInBetslip);
    const [isParlayBetslipOpen, setIsParlayBetslipOpen] = useStore(stores.isParlayBetslipOpen);
    const [isComboCardBetslipOpen, setIsComboCardBetslipOpen] = useStore(
        stores.sports.comboCard.isComboCardBetslipOpen,
    );
    const isEdgeTierChatAvailable = isFeatureAvailable(FEATURE.EDGE_TIER);
    const isBetHistoryTabVisible = isAuthenticated && numberOfBets === 0 && numberOfParlayBets === 0 && !isB2B();
    const [isAppModalOpen, setIsAppModalOpen] = useState(false);

    function showPushToDownloadAppModal() {
        return isFeatureAvailable(FEATURE.PUSH_TO_APP_INSTALL) && !isMobileApp() && !isBYOD();
    }

    function showBetslip() {
        if (!isComboCardBetslipEmpty) {
            setIsComboCardBetslipOpen(true);
            return;
        }
        if (isBetslipNotEmpty || isParlayBetslipEmpty) {
            setIsBetslipOpen(true);
        } else {
            setIsParlayBetslipOpen(true);
        }
    }

    function onBetslipClickHandler() {
        if (showPushToDownloadAppModal()) {
            setIsAppModalOpen(true);
        } else {
            showBetslip();
        }
    }

    function toggleSportSideMenuOpen() {
        setIsSportSideMenuOpen(!isSportSideMenuOpen);
    }

    function getBetCount() {
        if (!isComboCardBetslipEmpty) {
            return numberOfComboCardBets;
        } else if (isBetslipNotEmpty) {
            return numberOfBets;
        } else {
            return numberOfParlayBets;
        }
    }

    return (
        <Wrapper $withChatBubble={isEdgeTierChatAvailable}>
            {!isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2) && isBetHistoryTabVisible ? (
                <div onClick={() => setIsBetHistoryOpen(true)} className="safe-area">
                    <div className="back-link">
                        <Svg icon="bethistory" size={0.95} />
                    </div>
                </div>
            ) : (
                (numberOfBets !== 0 || !isParlayBetslipEmpty || !isComboCardBetslipEmpty) && (
                    <div onClick={onBetslipClickHandler} className="safe-area">
                        <div className="betslip-link">
                            <div className="bets-count">{getBetCount()}</div>
                        </div>
                    </div>
                )
            )}

            {!isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2) && (
                <div className="safe-area" onClick={() => stores.sports.isSportSideMenuOpen.set(!isSportSideMenuOpen)}>
                    <div className="sport-link">
                        <Svg icon="select-sport" size={0.95} />
                    </div>
                </div>
            )}
            <SwipeableDrawer
                disableEnforceFocus
                open={isSportSideMenuOpen}
                onOpen={toggleSportSideMenuOpen}
                onClose={toggleSportSideMenuOpen}
                anchor={isLayoutB ? 'left' : 'right'}
                disableDiscovery={true}
                swipeAreaWidth={0}
            >
                <SportPhoneSelectSportSidebar />
            </SwipeableDrawer>

            <UiModal
                open={isBetslipOpen}
                onClose={() => setIsBetslipOpen(false)}
                onOpen={() => setIsBetslipOpen(true)}
                mode="drawer"
            >
                <div className="betslip-box">
                    <SportBetslipWrapper />
                </div>
            </UiModal>

            <UiModal
                open={isParlayBetslipOpen}
                onClose={() => setIsParlayBetslipOpen(false)}
                onOpen={() => setIsParlayBetslipOpen(true)}
                mode="drawer"
            >
                <div className="betslip-box">
                    <SportParlayCardBetslip />
                </div>
            </UiModal>

            <UiModal
                open={isComboCardBetslipOpen}
                onClose={() => setIsComboCardBetslipOpen(false)}
                onOpen={() => setIsComboCardBetslipOpen(true)}
                mode="drawer"
            >
                <div className="betslip-box">
                    <ComboCardsBetslip />
                </div>
            </UiModal>

            {!isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2) && (
                <UiModal
                    open={isBetHistoryOpen}
                    onClose={() => setIsBetHistoryOpen(false)}
                    onOpen={() => setIsBetHistoryOpen(true)}
                    mode="drawer"
                >
                    <div className="tickets-box">
                        <SportTicketList className="tickets-list" />
                    </div>
                </UiModal>
            )}

            <UiModal
                open={isAppModalOpen}
                onClose={() => setIsAppModalOpen(false)}
                onOpen={() => setIsAppModalOpen(true)}
            >
                <PushToAppInstall onClose={() => setIsAppModalOpen(false)} />
            </UiModal>
        </Wrapper>
    );
}
