import React, { useEffect, useState } from 'react';
import Wrapper from './styles';

import PaymentDepositProviderTrustlyUsScripts from './scripts/PaymentDepositProviderTrustlyUsScripts';
import { getProviderPaymentMethods } from '../../../../../microservices/payments';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../services/payments/types';
import { logger } from '../../../../../services/logger';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import { translate } from '../../../../../services/translate';
import UiGroup from '../../../../ui/group/UiGroup';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import { depositWithProvider } from '../../../../../services/payments/payments';
import UiButton from '../../../../ui/button/UiButton';
import { NativeMessageEventType, isMobileApp } from '../../../../../services/mobile-app';
import { sendNativeEvent } from '../../../../../services/mobile-app';
import { useRouter } from '../../../../../services/router';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
}

export default function PaymentDepositProviderTrustlyUs({ amount, deviceHash }: Props) {
    const action = new URLSearchParams(useRouter().location.search).get('action');
    const [accounts, setAccounts] = useState<{ description?: string; id: string; number: string; extra?: any }[]>([]);
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);
    const [isAddingNewAccount, setIsAddingNewAccount] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [{ isPhone }] = useStore(media);
    const [methodId, setMethodId] = useState<string>();

    const TrustlyOptions = {
        closeButton: false,
        dragAndDrop: false,
        widgetContainerId: 'widget-container-id', //Page element container for the widget
    };

    useEffect(() => {
        loadAccounts();
    }, []);

    useEffect(() => {
        if (hasScriptsLoaded && isAddingNewAccount) {
            loadClient();
        }
    }, [hasScriptsLoaded, isAddingNewAccount]);

    useEffect(() => {
        if (hasScriptsLoaded && accounts.length) {
            if (!accounts[0]?.extra[PROVIDERS.TRUSTLY_US].isTokenValid && action === 'refresh') {
                tryDeposit();
            }
        }
    }, [hasScriptsLoaded, accounts]);

    async function loadAccounts() {
        setIsLoading(true);
        try {
            const accounts = await getProviderPaymentMethods(PROVIDERS.TRUSTLY_US, PROVIDER_TYPE.DEPOSIT);
            if (accounts.length) {
                setAccounts(accounts);
                setMethodId(accounts[0].id);
                setIsAddingNewAccount(false);
            } else {
                setIsAddingNewAccount(true);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderTrustlyUs', 'loadAccounts', error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }

    async function loadClient() {
        setIsLoading(true);
        try {
            const establishData = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.TRUSTLY_US,
                providerParams: {},
            });
            sendNativeEvent({
                type: NativeMessageEventType.PAYMENT_TRUSTLY_US,
                data: establishData,
                paymentMethod: PROVIDERS.TRUSTLY_US,
            });
            if (!isMobileApp()) {
                await Trustly.selectBankWidget(establishData, TrustlyOptions);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderTrustlyUs', 'loadClient', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    async function tryDeposit() {
        setIsLoading(true);
        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                methodId,
                provider: PROVIDERS.TRUSTLY_US,
            });
            if (response.url) {
                window.location.href = response.url;
            } else {
                sendNativeEvent({
                    type: NativeMessageEventType.PAYMENT_TRUSTLY_US,
                    data: response,
                    paymentMethod: PROVIDERS.TRUSTLY_US,
                });
                if (!isMobileApp()) {
                    await Trustly.establish(response, TrustlyOptions);
                }
            }
        } catch (error) {
            logger.error('PaymentDepositProviderTrustlyUs', 'tryDeposit', error);
            setIsLoading(false);
            setIsError(true);
        }
    }

    return (
        <Wrapper>
            {isLoading && <UiDotsLoader />}
            {isError && <PaymentProviderNotAvailable />}
            {methodId && !isAddingNewAccount && (
                <>
                    <UiFormGroup>
                        <UiFormInput
                            requiresInteraction={false}
                            select
                            onValueChange={setMethodId}
                            label={translate('Choose account', 'ui.account')}
                        >
                            {accounts.map((account) => (
                                <option key={account.id} value={account.id}>
                                    {account.description ? account.description : account.number}
                                </option>
                            ))}
                        </UiFormInput>
                    </UiFormGroup>
                    <UiGroup>
                        <UiButton block={isPhone} onClick={() => setIsAddingNewAccount(true)}>
                            {translate('Add account', 'ui.account')}
                        </UiButton>

                        <UiButton type="button" isFormSubmitButton color="primary" block={isPhone} onClick={tryDeposit}>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                </>
            )}

            <div id="widget-container-id" />
            <PaymentDepositProviderTrustlyUsScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
        </Wrapper>
    );
}

declare let Trustly: any;
