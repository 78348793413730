import styled from 'styled-components';

export default styled.div`
    width: 24px;
    img {
        mask: url('/assets/images/t-shirt.svg') no-repeat center;
        mask-size: 20px;
        height: 20px;
        position: relative;
        display: block;
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: #ccc;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
`;
