import React, { useEffect, useState } from 'react';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { getDepositReturnRoute } from '../../../../../services/payments/payments';
import { DepositStatus, PROVIDERS, PROVIDER_TYPE } from '../../../../../services/payments/types';
import { useRouter } from '../../../../../services/router';
import PaymentCardFormModal from '../../../card-form-modal/PaymentCardFormModal';

interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderCardEye({ amount, deviceHash, onClose }: Props) {
    const [htmlContent, setHtmlContent] = useState('');
    const { navigateTo } = useRouter();

    const domElements: HTMLScriptElement[] = [];

    function onDeposit({
        status,
        renderHtml,
    }: {
        status: 'ok' | 'pending' | 'failed' | 'cust_verification_required';
        renderHtml: string;
    }) {
        if (status === 'cust_verification_required') {
            setHtmlContent(renderHtml);
        } else {
            const navigateToPage = {
                ok: DepositStatus.COMPLETED,
                pending: DepositStatus.PENDING,
                failed: DepositStatus.FAILED,
            };
            navigateTo(getDepositReturnRoute(navigateToPage[status]));
        }
    }

    const parseOptions = {
        replace({ name, children }) {
            if (name === 'script') {
                children?.forEach(({ data }) => {
                    const element = document.createElement('script');
                    element.text = data as string;
                    element.type = 'text/javascript';
                    element.charset = 'utf-8';
                    domElements.push(element);
                });
            }
        },
    };

    useEffect(() => {
        domElements.forEach((element) => {
            document.head.appendChild(element);
        });
        return () =>
            domElements.forEach((element) => {
                document.head.removeChild(element);
            });
    }, [domElements]);

    return (
        <>
            <PaymentCardFormModal
                amount={amount}
                deviceHash={deviceHash}
                provider={PROVIDERS.CARD_EYE}
                providerType={PROVIDER_TYPE.DEPOSIT}
                onClose={onClose}
                onDeposit={onDeposit}
            />
            {htmlContent && <div>{parse(htmlContent, parseOptions as HTMLReactParserOptions)}</div>}
        </>
    );
}
