import React, { ReactNode, useEffect, useState } from 'react';
import { stores } from '../../stores';
import { getIsProductRouteActive, getRoute, isActiveRoute } from '../../services/router';
import { initializeResponsibleGamingTimeoutStatuses, isDepositPageBlocked } from '../../services/responsible-gaming';
import ResponsibleGamingAlertSessionReminder from './alert/session-reminder/ResponsibleGamingAlertSessionReminder';
import ResponsibleGamingAlertProductBlocking from './alert/product-blocking/ResponsibleGamingAlertProductBlocking';
import ResponsibleGamingAlertLoginDurationLimitExceeded from './alert/login-duration-limit-exceeded/ResponsibleGamingAlertLoginDurationLimitExceeded';
import ResponsibleGamingAnnualReport from './annual-report/ResponsibleGamingAnnualReport';
import UiProductClosed from '../ui/product-closed/UiProductClosed';
import { getUserProductBlockReason } from '../../services/responsible-gaming';
import { PRODUCT } from '../../types/common';
import { useStore } from '../../hooks/useStore';
import { useSportsbookCountryBlock } from '../../hooks/useSportsbookCountryBlock';
import { isBlurredBlockedProduct } from '../../services/users/country';
import UiProductClosedBlurMessage from '../ui/product-closed/blur-message/UiProductClosedBlurMessage';

interface Props {
    children: ReactNode;
}

export default function ResponsibleGaming({ children }: Props) {
    const [blockedProducts] = useStore(stores.blockedProducts);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [shouldBlockDepositPage, setShouldBlockDepositPage] = useState(false);
    const isVirtualSportsRouteActive = getIsProductRouteActive(PRODUCT.VIRTUAL_SPORTS);
    const isSportsbookRouteActive = !isVirtualSportsRouteActive && getIsProductRouteActive(PRODUCT.SPORTSBOOK);
    const isBlockedCountry = useSportsbookCountryBlock();
    const isHorseRacingRouteActive = getIsProductRouteActive(PRODUCT.RACEBOOK);
    const isCasinoRouteActive = getIsProductRouteActive(PRODUCT.CASINO);
    const isPokerRouteActive = getIsProductRouteActive(PRODUCT.POKER);
    const isDepositRouteActive =
        isActiveRoute(getRoute('deposit'), false) || isActiveRoute(getRoute('purchase-packages'), false);

    const closedProductByCountry = getClosedProductByCountry();
    const blurredProduct = isBlurredBlockedProduct() && closedProductByCountry;

    useEffect(() => {
        if (isAuthenticated) {
            initializeResponsibleGamingTimeoutStatuses().then(() => setShouldBlockDepositPage(isDepositPageBlocked()));
        }
    }, [isAuthenticated]);

    if (closedProductByCountry && !isBlurredBlockedProduct()) {
        return <UiProductClosed product={closedProductByCountry} />;
    }

    const shouldDisplayProductClosedAlert =
        (isSportsbookRouteActive && Boolean(getUserProductBlockReason(PRODUCT.SPORTSBOOK))) ||
        (isHorseRacingRouteActive && Boolean(getUserProductBlockReason(PRODUCT.RACEBOOK))) ||
        (isCasinoRouteActive && Boolean(getUserProductBlockReason(PRODUCT.CASINO))) ||
        (isPokerRouteActive && Boolean(getUserProductBlockReason(PRODUCT.POKER))) ||
        (isVirtualSportsRouteActive && Boolean(getUserProductBlockReason(PRODUCT.VIRTUAL_SPORTS))) ||
        (isDepositRouteActive && shouldBlockDepositPage);

    const productClosedAlert = (
        <>
            {isSportsbookRouteActive && <ResponsibleGamingAlertProductBlocking product={PRODUCT.SPORTSBOOK} />}
            {isHorseRacingRouteActive && <ResponsibleGamingAlertProductBlocking product={PRODUCT.RACEBOOK} />}
            {isCasinoRouteActive && <ResponsibleGamingAlertProductBlocking product={PRODUCT.CASINO} />}
            {isPokerRouteActive && <ResponsibleGamingAlertProductBlocking product={PRODUCT.POKER} />}
            {isVirtualSportsRouteActive && <ResponsibleGamingAlertProductBlocking product={PRODUCT.VIRTUAL_SPORTS} />}
            {isDepositRouteActive && <ResponsibleGamingAlertProductBlocking product={PRODUCT.DEPOSIT} />}
        </>
    );

    function getClosedProductByCountry() {
        if (isSportsbookRouteActive && (blockedProducts[PRODUCT.SPORTSBOOK] || isBlockedCountry)) {
            return PRODUCT.SPORTSBOOK;
        }

        if (isVirtualSportsRouteActive && blockedProducts[PRODUCT.VS_HIGHLIGHT_GAMES]) {
            return PRODUCT.VS_HIGHLIGHT_GAMES;
        }
    }

    return (
        <>
            {isAuthenticated && (
                <>
                    <ResponsibleGamingAlertSessionReminder />
                    <ResponsibleGamingAnnualReport />
                </>
            )}
            <ResponsibleGamingAlertLoginDurationLimitExceeded />
            {shouldDisplayProductClosedAlert ? productClosedAlert : children}
            {blurredProduct && <UiProductClosedBlurMessage />}
        </>
    );
}
