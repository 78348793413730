import React, { useState } from 'react';
import { Country } from '@staycool/location';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';
import { translate } from '../../../../../../services/translate';
import { getUserCountry } from '../../../../../../services/users/country';
import PaymentFormModal from '../../../../form-modal/PaymentFormModal';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderDirecta24Bank({ amount, deviceHash, disclaimer, onClose }: Props) {
    const [bankName, setBankName] = useState('');
    const Bank = {
        [Country.CHILE]: {
            CI: 'Banco BCI',
            CE: 'Banco BICE',
            BX: 'Banco de Chile',
            BE: 'Banco Estado',
            LL: 'Banco Falabella',
            SC: 'Banco Santander',
            TY: 'Banco Security',
        },
        [Country.PERU]: {
            BP: 'BBVA',
            JA: 'Caja Arequipa',
            US: 'Caja Cusco',
            HC: 'Caja Huancayo',
            JI: 'Caja ICA',
            JP: 'Caja Piura',
            JT: 'Caja Tacna',
            JR: 'Caja Trujillo',
            IB: 'Interbank',
            ST: 'Scotia',
        },
        [Country.ECUADOR]: {
            GB: 'Banco Guayaquil',
            PX: 'Banco Pichincha',
        },
    }[getUserCountry() as Country];

    const fields = [
        {
            name: 'service',
            label: translate('Bank name', 'ui.payments'),
            options: Object.keys(Bank).map((key) => ({ label: Bank[key], value: key })),
        },
    ];

    const extraFields = [
        {
            defaultValue: bankName,
            name: 'bankName',
            label: translate('Bank name', 'ui.payments'),
            isHidden: true,
        },
        {
            defaultValue: bankName,
            name: 'number',
            label: 'number',
            isHidden: true,
        },
    ];

    function onServiceChange(service: string) {
        setBankName(Bank[service]);
    }

    return (
        <PaymentFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            extraFields={extraFields}
            fields={fields}
            isAnonymousHolder
            onClose={onClose}
            onFormChange={{ service: onServiceChange }}
            provider={PROVIDERS.DIRECTA24_BANK}
            providerType={PROVIDER_TYPE.DEPOSIT}
        />
    );
}
