import styled from 'styled-components';

export default styled.div`
    .pseudo-label {
        color: var(--font-color-secondary);
        font-size: 0.8rem;
        font-weight: normal;
        text-transform: none;
        letter-spacing: 0;
        line-height: 130%;

        //NOTE: Generic elements may appear from snippet context
        a {
            border-bottom: 1px dotted;
            color: var(--font-color-secondary);
            text-decoration: none;
        }
    }

    label {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin: 0.35rem 0;
        user-select: none;
        &:hover input ~ .pseudo-checkbox {
            background-color: var(--form-input-bg);
            transition: 150ms linear background-color, 150ms linear box-shadow;
        }

        &:hover input:checked ~ .pseudo-checkbox {
            box-shadow: none;
        }
    }

    input {
        appearance: none;
        opacity: 0;
        height: 0;
        width: 0;

        &:checked ~ .pseudo-checkbox {
            align-items: center;
            display: flex;
            justify-content: center;

            &:after {
                display: block;
            }
        }

        &:disabled ~ .pseudo-checkbox {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }

    .pseudo-checkbox {
        background-color: var(--form-input-bg);
        border: var(--form-input-border);
        border-radius: var(--ui-radius);
        height: 30px;
        margin: 0 0.6rem 0 0;
        position: relative;
        min-width: 30px;

        &:after {
            border-radius: 50%;
            color: ${({ theme }) => theme.buttons.primaryBackground};
            content: '\u2713'; //NOTE: ✓ (U+02713)
            display: none;
            font-size: 1rem;
            font-weight: bold;
            position: absolute;
        }
    }
`;
