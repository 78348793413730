import React, { useEffect, useState } from 'react';
import { logger } from '../../../services/logger';
import { getUserCountry } from '../../../services/users/country';
import { CmsGeneralSetting } from '../../../services/sports/types';
import { translate } from '../../../services/translate';
import random from 'lodash/random';
import Wrapper from './styles';
import { useCmsSettings } from '../../../services/cms';
import { CmsSettings, SponsorshipBanner } from '../../../services/cms/types';

export default function SportSponsorshipBanner() {
    const settings = useCmsSettings();
    const [sponsorshipBanner, setDisplaySponsorshipBanner] = useState<SponsorshipBanner>();
    const [isBannerImageUrlInvalid, setIsBannerImageUrlInvalid] = useState<boolean>(false);

    const userCountry = getUserCountry();

    useEffect(() => {
        if (!settings) {
            return;
        }

        const sponsorshipBannerSettingValue = getSponsorshipBannerSetting(settings);
        if (!sponsorshipBannerSettingValue) {
            return;
        }

        const sponsorshipImages = sponsorshipBannerSettingValue[userCountry];
        if (Array.isArray(sponsorshipImages) && sponsorshipImages?.length) {
            setDisplaySponsorshipBanner(randomlyPickBannerImageUrl(sponsorshipImages));
        }
    }, [settings, userCountry]);

    function randomlyPickBannerImageUrl(bannerImageUrls: SponsorshipBanner[]): SponsorshipBanner {
        return bannerImageUrls[random(0, bannerImageUrls.length - 1)];
    }

    function getSponsorshipBannerSetting(settings: CmsGeneralSetting[]): SponsorshipBanner[] | undefined {
        const sponsorshipBannerSetting = settings?.find(({ key }) => key === CmsSettings.SPONSORSHIP_BANNER);
        try {
            return sponsorshipBannerSetting?.value && JSON.parse(sponsorshipBannerSetting.value);
        } catch (error) {
            logger.error(
                'SportSponsorshipBanner',
                'getSponsorshipBannerSetting',
                `Failed to parse sponshor ship banner URLs: ${sponsorshipBannerSetting}`,
            );
            return undefined;
        }
    }
    if (isBannerImageUrlInvalid || !sponsorshipBanner) {
        return null;
    }

    return (
        <Wrapper>
            <div className="sponsorship-banner">
                <div className="banner-header-wrapper">
                    <div className="banner-header">{translate('ui.sport.sponsorshipbanner.header')}</div>
                </div>

                <a href={sponsorshipBanner.imageRedirectLink} target="_blank" rel="noreferrer">
                    <img
                        className="banner-image"
                        src={sponsorshipBanner.imageURL}
                        alt=""
                        onError={() => setIsBannerImageUrlInvalid(true)}
                    />
                </a>
            </div>
        </Wrapper>
    );
}
