import React, { useEffect, useState } from 'react';
import { getFilteredPaymentTransactions, returnFromPayment } from '../../../../../microservices/payments';
import { logger } from '../../../../../services/logger';
import { NativeMessageEventType, sendNativeEvent } from '../../../../../services/mobile-app';
import { depositWithProvider } from '../../../../../services/payments/payments';
import { PROVIDERS, TransactionState, TransactionType } from '../../../../../services/payments/types';
import { getLastActiveProductRoute, useRouter } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import { stores } from '../../../../../stores';
import Snippet from '../../../../snippet/Snippet';
import UiButton from '../../../../ui/button/UiButton';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import UiGroup from '../../../../ui/group/UiGroup';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import PaymentDepositProviderBraintreePaypalScripts from './scripts/PaymentDepositProviderBraintreePaypalScripts';
import Wrapper from './styles';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderBraintreePaypal({ amount, deviceHash, disclaimer, onClose }: Props) {
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [clientToken, setClientToken] = useState('');
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSetupFinished, setIsSetupFinished] = useState(false);
    const [paypalDeviceData, setPaypalDeviceData] = useState();
    const [remoteAmount, setRemoteAmount] = useState(0);
    const [remoteCurrency, setRemoteCurrency] = useState(0);
    const [transactionId, setTransactionId] = useState('');

    const [user] = useStore(stores.user);

    useEffect(() => {
        startDepositTransaction();
    }, []);

    useEffect(() => {
        if (hasScriptsLoaded && clientToken) {
            loadClient();
        }
    }, [hasScriptsLoaded, clientToken]);

    async function startDepositTransaction() {
        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.BRAINTREE_PAYPAL,
                providerParams: {},
            });
            setRemoteAmount(response.amount);
            setRemoteCurrency(response.currency);
            setTransactionId(response.id);
            setClientToken(response.token);
        } catch (error) {
            setIsError(true);
        }
    }

    async function loadClient() {
        try {
            const braintreeClient = await createBraintreeClient();
            await collectPaypalDeviceData(braintreeClient);
            const checkoutClient = await createCheckoutClient(braintreeClient);
            const paypalClient = await createPaypalClient(checkoutClient);
            await renderPaypalButton(paypalClient);
            setIsSetupFinished(true);
        } catch (error) {
            setIsError(true);
        }
    }

    function createBraintreeClient() {
        return braintree.client.create({
            authorization: clientToken,
        });
    }

    async function collectPaypalDeviceData(client) {
        const dataCollector = await braintree.dataCollector.create({ client });
        setPaypalDeviceData(dataCollector.deviceData);
        dataCollector.teardown();
    }

    function createCheckoutClient(client) {
        return braintree.paypalCheckout.create({
            client,
        });
    }

    function createPaypalClient(client) {
        return client.loadPayPalSDK({
            currency: remoteCurrency,
            intent: 'capture',
        });
    }

    async function renderPaypalButton(client) {
        await paypal
            .Buttons({
                style: {
                    height: isPhone ? 50 : 40,
                },
                fundingSource: paypal.FUNDING.PAYPAL,
                createOrder: () => {
                    return client.createPayment({
                        flow: 'checkout',
                        amount: remoteAmount,
                        currency: remoteCurrency,
                        intent: 'capture',
                    });
                },
                onApprove: async function (data) {
                    const { nonce } = await client.tokenizePayment(data);
                    await deposit(nonce);
                },
                onError: function () {
                    navigateTo(
                        `${getLastActiveProductRoute()}?error=${translate(
                            'Your deposit did not go through.',
                            'ui.account',
                        )}`,
                    );
                },
            })
            .render('#paypal-button');
    }

    async function deposit(nonce: string) {
        try {
            await returnFromPayment(PROVIDERS.BRAINTREE_PAYPAL, {
                transactionId,
                paypalDeviceData,
                paymentMethodNonce: nonce,
            });
            const userTransactions = await getFilteredPaymentTransactions({
                where: {
                    type: TransactionType.DEPOSIT,
                },
                limit: 2,
            });
            if (userTransactions[0].state === TransactionState.COMPLETED) {
                sendNativeEvent({
                    type: NativeMessageEventType.DEPOSIT,
                    playerId: user?.id,
                    amount: userTransactions[0].amountUc,
                    currency: userTransactions[0].currency,
                    paymentMethod: PROVIDERS.BRAINTREE_PAYPAL,
                    returningDeposit: userTransactions.length > 1,
                });
            }
            navigateTo(`${getLastActiveProductRoute()}?pending=${translate('Your deposit is pending.', 'ui.account')}`);
        } catch (error) {
            logger.error('PaymentDepositProviderBraintreePaypal', 'deposit', error);
            setIsError(true);
        }
    }

    if (isError) {
        return <PaymentProviderNotAvailable />;
    }

    return (
        <Wrapper>
            <PaymentDepositProviderBraintreePaypalScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
            <div>
                {disclaimer && <Snippet snippetKey={disclaimer} />}
                {!isSetupFinished && <UiDotsLoader />}
                <UiGroup expand horizontallyCentered>
                    {isSetupFinished && <UiButton onClick={onClose}>{translate('Back', 'ui.common')}</UiButton>}
                    <div id="paypal-button" className={`${isSetupFinished ? '' : 'loading'}`} />
                </UiGroup>
            </div>
        </Wrapper>
    );
}

declare let braintree: any;
declare let paypal: any;
