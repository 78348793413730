import React from 'react';
import Wrapper from './styles';

interface Props {
    homeTeamLogoName: string | undefined;
}

export default function SportTeamShirtLogo({ homeTeamLogoName }: Props) {
    return (
        <Wrapper>
            <img src={`/assets/images/flags/4x3/${homeTeamLogoName}.svg`} alt={homeTeamLogoName} />
        </Wrapper>
    );
}
