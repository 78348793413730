import pick from 'lodash/pick';
import { useEffect, useMemo } from 'react';
import { areOddsValidForOnDemandBet } from '../../../../../services/parlay-card/parlay-card';
import {
    useTopMarketBestLine,
    useTopMarketSocketUpdatesInView,
} from '../../../../../services/sports/top-markets-hooks';
import { BET_TYPE, CategoryMatch, MarketInfo, Odds } from '../../../../../services/sports/types';
import { stores, useStoreWithSelector } from '../../../../../stores';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    selectedMarket: MarketInfo;
    match: CategoryMatch;
    betType: BET_TYPE;
}

export default function SportBetslipTeasersValidationMarket({ selectedMarket, match, betType }: Props) {
    const [teaserMainLineErrorMarketIds, setTeaserMainLineErrorMarketIds] = useStore(
        stores.sports.teaserMainLineErrorMarketIds,
    );
    const [teaserInvalidMainLineErrorMarketIds, setTeaserInvalidMainLineErrorMarketIds] = useStore(
        stores.sports.teaserInvalidMainLineErrorMarketIds,
    );
    const { markets, setMarkets } = useTopMarketSocketUpdatesInView(
        match.id,
        selectedMarket.market_type_id,
        !!match.isFake,
    );

    const outcomeIds = useMemo(() => selectedMarket.outcomes.map((outcome) => outcome.id) || [], [selectedMarket]);

    const [marketOddsByOutcomeId] = useStoreWithSelector(
        stores.sports.oddsByOutcomeId,
        (state) => pick(state, outcomeIds),
        [outcomeIds],
    );

    useEffect(() => {
        return () => {
            removeTeaserMainLineErrorMarket(selectedMarket.id);
            removeTeaserInvalidMainLineErrorMarket(selectedMarket.id);
        };
    }, []);

    useEffect(() => {
        const marketTypeMarkets = match.markets.filter(
            (market) => market.market_type_id === selectedMarket.market_type_id,
        );
        setMarkets(marketTypeMarkets);
    }, [match, selectedMarket.market_type_id]);

    const { market: mainMarket } = useTopMarketBestLine(
        markets,
        selectedMarket.view_type,
        !!match.isFake,
        match.prefer_half_lines,
    );

    useEffect(() => {
        if (!mainMarket?.raw_line || !selectedMarket?.raw_line) {
            return;
        }

        if (selectedMarket.raw_line !== mainMarket.raw_line) {
            addTeaserMainLineErrorMarket(selectedMarket.id);
        } else {
            removeTeaserMainLineErrorMarket(selectedMarket.id);
            const odds = (mainMarket.outcomes.map((outcome) => marketOddsByOutcomeId[outcome.id]) || []) as Odds[];
            const areOddsValid = odds.length === mainMarket.outcomes.length && areOddsValidForOnDemandBet(odds);
            if (!areOddsValid) {
                addTeaserInvalidMainLineErrorMarket(selectedMarket.id);
            } else {
                removeTeaserInvalidMainLineErrorMarket(selectedMarket.id);
            }
        }
    }, [selectedMarket, mainMarket, betType, marketOddsByOutcomeId]);

    function addTeaserMainLineErrorMarket(marketId: number) {
        if (!teaserMainLineErrorMarketIds.includes(marketId)) {
            setTeaserMainLineErrorMarketIds((state) => [...state, marketId]);
        }
    }

    function removeTeaserMainLineErrorMarket(marketId: number) {
        setTeaserMainLineErrorMarketIds((state) => {
            return [...state.filter((id) => id !== marketId)];
        });
    }

    function addTeaserInvalidMainLineErrorMarket(marketId: number) {
        if (!teaserInvalidMainLineErrorMarketIds.includes(marketId)) {
            setTeaserInvalidMainLineErrorMarketIds((state) => [...state, marketId]);
        }
    }

    function removeTeaserInvalidMainLineErrorMarket(marketId: number) {
        setTeaserInvalidMainLineErrorMarketIds((state) => {
            return [...state.filter((id) => id !== marketId)];
        });
    }

    return null;
}
