import { storageGet } from '../storage';
import { stores } from '../../stores';
import { initialHorseRacingBetslipPlacingState } from '../horse-racing/horse-racing';
import { ticketHandlerByBetType } from './handlers';
import { isMobile } from '../browser';
import { loadCarryovers, loadTodaysTracks, loadTrackRaceDetails } from '../../microservices/lvdc-web';
import round from 'lodash/round';
import clone from 'lodash/clone';
import moment from 'moment';
import { clearRacebookBetslipErrors } from '../horse-racing/betslip-errors';
import { getStoreValue } from '../../stores/store/utils';
import { logger } from '../logger';
import { environment } from '../../stores/environment/environment';
import { RaceBetType } from '../../microservices/lvdc/types';
import { getCurrentDateString } from './utils';

export const MAX_DISPLAYED_RACES = isMobile() ? 3 : 5;

export const lvdcBettingOffChannel = `${getCurrentDateString()}-betting-off`;
export const lvdcRaceEndingChannel = `${getCurrentDateString()}-race-ending`;

const allBetStakesVariants = [
    0.1, 0.2, 0.3, 0.4, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 2500,
];

export const startingNowUpperBound = 16;
export const startingNowLowerBound = -10;
export const startingSoonUpperBound = 45;

export function setFavouriteTracksFromStore() {
    const favoriteTracks = storageGet('favoriteTracks', []);
    stores.horseRacing.favouriteTrackIds.set(favoriteTracks);
}

export const getTicketBetsCombinations = (betType: string, runnerMaps: number[][]) => {
    if (runnerMaps.length === 0) {
        return 0;
    }
    const getBetsCountHandler = ticketHandlerByBetType[betType];
    if (getBetsCountHandler) {
        const runnerMapsNormalized: number[][] = [];
        for (let i = 0; i < runnerMaps.length; i++) {
            runnerMapsNormalized.push(runnerMaps[i] ?? []);
        }

        return getBetsCountHandler(runnerMapsNormalized, betType);
    }

    return 0;
};

export function clearBetslip() {
    stores.horseRacing.betslip.set((prevState) => ({
        ...prevState,
        totalStake: 0,
        card: 0,
        totalCombinations: 0,
        runnerMaps: [],
    }));
    clearRacebookBetslipErrors();
}

export function clearReceipt() {
    stores.horseRacing.betslipPlacingState.set(initialHorseRacingBetslipPlacingState);
}

export function clearBetslipAndReceipt() {
    clearBetslip();
    clearReceipt();
}

export async function getTodaysTracks() {
    const tracks = await loadTodaysTracks();
    setStoreMinutesToPost(tracks.filter(Boolean).map((track) => parseInt(`${track.post_time}`, 10)));
    return tracks;
}

export async function getCarryovers() {
    const carryovers = await loadCarryovers();
    setStoreMinutesToPost(carryovers.map((carryover) => carryover.post_time));
    return carryovers;
}

export async function getTrackRaceDetails(nevadaTrackId: number, raceId: number, date: string) {
    const trackDetails = await loadTrackRaceDetails(nevadaTrackId, raceId, date);
    if (!trackDetails) {
        return;
    }
    setStoreMinutesToPost(
        trackDetails.raceList.filter((race) => !!race.post_time).map((race) => Number(race.post_time)),
    );
    return trackDetails;
}

function setStoreMinutesToPost(postTimes: number[]) {
    const minutesToPostMap = clone(stores.horseRacing.minutesToPostByTimestamp.state);
    postTimes
        .filter((time) => !isNaN(time))
        .forEach((postTime) => {
            if (postTime === undefined) {
                return;
            }

            if (!minutesToPostMap.has(postTime)) {
                const millisecondsToPost = moment(postTime).endOf('minute').diff(moment());
                const minutesToPost = round(moment.duration(millisecondsToPost).asMinutes());
                minutesToPostMap.set(postTime, minutesToPost);
            }
        });
    stores.horseRacing.minutesToPostByTimestamp.set(minutesToPostMap);
}

export function getBetTypeMinMaxStake(betType: RaceBetType, totalCombinations: number) {
    if (!betType) {
        return { minStake: 0, maxStake: 0 };
    }
    const {
        special_min_combos,
        special_min_amount,
        standard_min_amount,
        special_max_combos,
        standard_max_amount,
        special_max_amount,
    } = betType;

    const shouldUseSpecialMinAmount =
        (totalCombinations === 0 && special_min_combos === 1) || totalCombinations >= special_min_combos;
    const minStake = shouldUseSpecialMinAmount ? special_min_amount : standard_min_amount;
    const maxStake = totalCombinations >= special_max_combos ? special_max_amount : standard_max_amount;
    return { minStake, maxStake };
}

export function getPredefinedStakes(minStake: number, maxStake: number, betType?: RaceBetType): number[] {
    if (!betType) {
        return [0];
    }

    const { is_min_amount_only, is_min_on_bet_cost, is_pennies_allowed } = betType;

    if (is_min_amount_only || is_min_on_bet_cost) {
        return [minStake];
    }

    let stakes: number[] = allBetStakesVariants.filter((stake) => stake >= minStake && stake <= maxStake);
    if (!is_pennies_allowed) {
        return stakes.filter((stake) => stake >= 1);
    }

    if (minStake === 0.5) {
        stakes = [0.5, 1, 1.5, 2, 2.5, ...stakes.filter((stake) => stake >= 3)];
    } else if (minStake === 0.2) {
        stakes = [0.2, 0.4, 0.6, 0.8, ...stakes.filter((stake) => stake >= 1)];
    }
    return stakes;
}

export function getAssociationMapping(signupProperty: string) {
    try {
        const signupPropertyUpper = signupProperty.toUpperCase();
        const { LVDC_ASSOCIATION_MAPPING = {} } = getStoreValue(environment);
        if (!Object.keys(LVDC_ASSOCIATION_MAPPING).length) {
            logger.error('LvdcLvdcService', 'getAssociationMapping', 'Failed to get associationId');
            return;
        }

        return LVDC_ASSOCIATION_MAPPING[signupPropertyUpper];
    } catch (e) {
        logger.error('LvdcLvdcService', 'getAssociationMapping', `Failed to get associationId , ${e}`);
        return;
    }
}
