import React, { useEffect, useState } from 'react';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';

interface Props {
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentDepositProviderBraintreePaypalScripts({ onError, onScriptsLoaded }: Props) {
    const [isBraintreeScriptLoaded, setIsBraintreeScriptLoaded] = useState(false);
    const [isPaypalScriptLoaded, setIsPaypalScriptLoaded] = useState(false);
    const [isPaypalDeviceCollectorLoaded, setIsPaypalDeviceCollectorLoaded] = useState(false);

    useEffect(() => {
        if (isBraintreeScriptLoaded && isPaypalDeviceCollectorLoaded && isPaypalScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isBraintreeScriptLoaded, isPaypalDeviceCollectorLoaded, isPaypalScriptLoaded]);

    return (
        <>
            <ScriptLoader
                url="https://js.braintreegateway.com/web/3.85.3/js/client.min.js"
                onError={onError}
                onLoad={() => setIsBraintreeScriptLoaded(true)}
            />
            <ScriptLoader
                url="https://js.braintreegateway.com/web/3.85.3/js/paypal-checkout.min.js"
                onError={onError}
                onLoad={() => setIsPaypalScriptLoaded(true)}
            />
            <ScriptLoader
                url="https://js.braintreegateway.com/web/3.85.3/js/data-collector.min.js"
                onError={onError}
                onLoad={() => setIsPaypalDeviceCollectorLoaded(true)}
            />
        </>
    );
}
