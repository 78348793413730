import { stores } from '../stores';
import { media } from '../stores/media/media';
import { useStore } from './useStore';

export function useIsMobileCompactView() {
    const [isMobileCompactView] = useStore(stores.sports.isMobileCompactView);
    const [{ isPhone }] = useStore(media);

    return isMobileCompactView && isPhone;
}
