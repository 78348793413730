import { Country, Market } from '@staycool/location';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getMe, validateAlias } from '../microservices/users';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from './logger';
import { storageGet, storageSet } from './storage';
import { translate } from './translate';
import { User, UserQueryParameters } from './types';
import { useStore } from '../hooks/useStore';
import { environment } from '../stores/environment/environment';
import { getUserCountry } from './users/country';

const STORAGE_ALIAS_KEY = 'userAlias';

export async function loadProfile(options?: { isThrowingOnError: boolean }) {
    try {
        const user = await getMe();
        stores.user.set(user);
        storageSet(STORAGE_ALIAS_KEY, user.alias);
        return user;
    } catch (error) {
        logger.error('UserService', 'loadProfile', error);
        if (options?.isThrowingOnError) {
            throw error;
        }
    }
}

export async function getUserWithExtra(query: UserQueryParameters): Promise<User | undefined> {
    try {
        const user = await getMe(query);
        return user;
    } catch (error) {
        logger.error('UserService', 'getUserWithExtra', error);
        return undefined;
    }
}

export function getUnderageError(country: Country) {
    return translate('Sorry! You must be at least {{ minAges }} years old in order to sign up.', 'ui.registration', {
        minAges: getSignupAgeLimit(country),
    });
}

export function setPromotionsPageLastVisit() {
    storageSet('promotionsPageLastVisit', new Date().getTime().toString());
}

export function getPromotionsPageLastVisit() {
    return parseInt(storageGet('promotionsPageLastVisit')) || 0;
}

export function isTestUser() {
    const user = getStoreValue(stores.user);
    return Boolean(user && user.isTest);
}

export function isExistingUser() {
    return storageGet(STORAGE_ALIAS_KEY);
}

export function getUserMarketCode(): Market {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    if (isAuthenticated && user) {
        return user.market;
    }
    const { AVAILABLE_MARKETS: availableMarkets } = getStoreValue(environment);

    if (availableMarkets.length === 1) {
        return availableMarkets[0];
    }

    const country = getUserCountry();

    if (availableMarkets.includes(country as unknown as Market)) {
        return country as unknown as Market;
    }
    return Market.REST_OF_THE_WORLD;
}

export function getUserProvince() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    if (isAuthenticated && user) {
        return user.province;
    }
}

export function getFullName() {
    const user = getStoreValue(stores.user);
    return `${user?.firstName} ${user?.lastName}`;
}

export function getPhoneNumber() {
    const user = getStoreValue(stores.user);
    return `${user?.phonePrefix}${user?.phoneNumber}`;
}

export function isValidUser() {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    const user = getStoreValue(stores.user);
    const askDepositLimit = getStoreValue(stores.responsibleGaming.askDepositLimit);
    const askLoginDurationLimit = getStoreValue(stores.responsibleGaming.askLoginDurationLimit);
    const askLossLimit = getStoreValue(stores.responsibleGaming.askLossLimit);

    if (!user || !isAuthenticated) {
        return false;
    }

    const hasAcceptedTerms = user.acceptTermsAndConditions;
    const isValidProfile =
        !user.askPersonalId &&
        ![askDepositLimit, askLoginDurationLimit, askLossLimit].includes(undefined) &&
        !askDepositLimit &&
        !askLoginDurationLimit;

    return hasAcceptedTerms && isValidProfile;
}

export async function isFieldsValid({ alias }) {
    return (await validateAlias(alias)).result;
}

export function getSignupAgeLimit(country: Country) {
    const signupLegalAgeByCountry = {
        [Country.CANADA]: 19,
    };
    return (signupLegalAgeByCountry[country] as number) || 18;
}

export function captureUserActivity() {
    stores.lastActivityTime.set(moment().toISOString());
}

export function getTranslatedBackendErrors(
    errors: Record<string, [message: string, replacements?: Record<string, string | number>, context?: string]>,
) {
    return mapValues(errors, ([message, replacements, context = 'ui.registration']) => ({
        error: translate(message, context, replacements),
    }));
}

export function useIsTestUser() {
    const [isTest, setIsTest] = useState(false);
    const [user] = useStore(stores.user);
    useEffect(() => {
        setIsTest(isTestUser());
    }, [user]);

    return isTest;
}
