import styled from 'styled-components';

export default styled.div`
    width: inherit;

    input {
        letter-spacing: 1px;
    }

    .number-input input {
        font-size: 1.1rem;
    }

    .card-expiration-date-and-cvc-container {
        justify-content: space-between;
    }

    .expiration-date-and-cvc-inputs {
        width: 45%;
    }

    .buttons {
        margin-top: 1rem;
    }
`;
