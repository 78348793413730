import styled from 'styled-components';

export default styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: var(--spacing-4);
    margin-bottom: var(--spacing-8);

    &.wrap {
        flex-wrap: wrap;
        > * {
            margin-bottom: 0.4rem;
        }
    }

    .motd .card-name a {
        flex-shrink: unset;
    }
`;
