import React, { useEffect } from 'react';
import Wrapper from './styles';
import SportHotEvents from '../../hot-events/SportHotEvents';
import SportTreeMenu from '../../tree/menu/SportTreeMenu';
import SportSidebarSoonCampaigns from '../../sidebar/soon/campaigns/SportSidebarSoonCampaigns';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import SportSettingsMenuButton from '../../settings-menu/button/SportSettingsMenuButton';
import { isFeatureAvailable } from '../../../../services/feature';
import SportBonusBetList from '../../bonus-bet/list/SportBonusBetList';
import { FEATURE } from '../../../../services/types';
import SportHotEventsV2 from '../../hot-events-v-2/SportHotEventsV2';
import SportCampaignsListV2 from '../../campaigns/list-v-2/SportCampaignsListV2';
import SportTreeMenuV2 from '../../tree/menu-v-2/SportTreeMenuV2';

export default function SportPhoneSelectSportSidebar() {
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    useEffect(() => {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_MOBILE_MENU_OPENED);
    }, []);

    return (
        <Wrapper>
            <SportSettingsMenuButton />

            <div className="sport-menu">
                {!isLayoutB && <SportBonusBetList />}
                {isMainNavigationV2 ? <SportCampaignsListV2 /> : <SportSidebarSoonCampaigns />}
                {!isLayoutB &&
                    (isMainNavigationV2 ? <SportHotEventsV2 isVertical /> : <SportHotEvents wrap type="menu" />)}
                {isMainNavigationV2 ? <SportTreeMenuV2 /> : <SportTreeMenu />}
            </div>
        </Wrapper>
    );
}
