import styled from 'styled-components';

type StyledProps = {
    $height: number;
    $color?: string;
};

export default styled.div<StyledProps>`
    @keyframes move {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 3.125rem 3.125rem;
        }
    }
    text-align: center;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: ${({ $height }) => `${$height}px`};
    color: ${({ theme, $color }) => $color ?? theme.colors.primary};
    background-color: ${({ theme }) => theme.surfacesLevel2.background};

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: var(--form-input-bg);
        box-shadow: var(--ui-shadow);
    }

    .progress-bar {
        position: absolute;
        height: inherit;
        width: inherit;
        background-color: ${({ theme, $color }) => $color ?? theme.colors.primary};
        transition: max-width 0.3s ease-out;
        overflow: hidden;
        box-shadow: var(--ui-shadow);
    }

    .progress-label {
        display: inline;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 0.7rem;
        line-height: 1;
        color: ${({ theme }) => theme.typography.fontColorPrimary};
        padding: 0 0.25rem;
        border-radius: 0.5rem;
        background-color: ${({ theme }) => theme.forms.inputBackground};
        z-index: 1;

        &:empty {
            display: none;
        }
    }

    &.animated .progress-bar:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        right: 1px;
        background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent
        );
        background-size: 3.125rem 3.125rem;
        animation: move 2s linear infinite;
    }

    &.rounded,
    &.rounded .progress-bar,
    &.animated .progress-bar:after {
        border-radius: ${({ $height }) => `${$height / 2}px`};
    }
`;
