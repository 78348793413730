import React from 'react';
import Wrapper from '../../styles-default';
import UiAnimate from '../../../../ui/animate/UiAnimate';
import Svg from '../../../../svg/Svg';
import { translate } from '../../../../../services/translate';
import { getActiveCurrency } from '../../../../../services/currency';
import UiButton from '../../../../ui/button/UiButton';
import { bonusLabelByType, isBonusEligibleAndSelected } from '../../../../../services/sports/bonus-bets';
import { BonusBet } from '../../../../../services/bonuses/types';

interface Props {
    bonus: BonusBet;
    onBonusToggle: () => void;
}

export default function SportBonusBetFreeBetsDefaultLayout({ bonus, onBonusToggle }: Props) {
    return (
        <Wrapper>
            {!isBonusEligibleAndSelected(bonus) && bonus.bonusType && (
                <UiAnimate animationIn="flipInX" animationOut="flipOutX" isVisible={true}>
                    <div className="campaign-bet">
                        <div className="campaign-icon">
                            <Svg icon="freebet" alt="freebet-icon" className="freebet-icon" />
                        </div>

                        <div className="campaing-description">
                            {translate(`${bonusLabelByType[bonus.bonusType]}`, 'ui.bonus', {
                                amount: bonus.amount || '',
                                minAmount: bonus.minAmount || '',
                                currency: getActiveCurrency(),
                            })}
                            <br />
                        </div>
                        <UiButton color="primary" size="small" onClick={onBonusToggle}>
                            {translate('Apply?', 'ui.bonus')}
                        </UiButton>
                    </div>
                </UiAnimate>
            )}
            {isBonusEligibleAndSelected(bonus) && bonus.bonusType && (
                <UiAnimate animationIn="flipInX" animationOut="flipOutX" isVisible={true}>
                    <div className="campaign-bet selected">
                        <div className="campaign-icon">
                            <Svg icon="freebet" alt="freebet-icon" className="freebet-icon" />
                        </div>

                        <div className="campaing-description">
                            {translate(`${bonusLabelByType[bonus.bonusType]}`, 'ui.bonus', {
                                amount: bonus.amount || '',
                                minAmount: bonus.minAmount || '',
                                currency: getActiveCurrency(),
                            })}
                            <br />
                            {translate('ui.bonus.is-applied')}
                        </div>
                        <UiButton size="small" onClick={onBonusToggle}>
                            {translate('Remove', 'ui.bonus')}
                        </UiButton>
                    </div>
                </UiAnimate>
            )}
        </Wrapper>
    );
}
