import styled from 'styled-components';

export default styled.div`
    background: var(--surface-level-2-bg);
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    padding: var(--spacing-12);
    .track-name {
        color: var(--match-primary-color);
        font-size: var(--font-14);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        display: flex;
        justify-content: space-between;

        margin-bottom: var(--spacing-8);

        .date {
            font-weight: 400;
            opacity: 0.7;
        }
    }

    .clear-all {
        display: flex;
        justify-content: flex-end;
    }
`;
