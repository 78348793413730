import every from 'lodash/every';
import fromPairs from 'lodash/fromPairs';
import moment from 'moment';
import { postCampaignBet, getCampaigns } from '../../microservices/sb-campaigns';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getActiveCurrency } from '../currency';
import { translate } from '../translate';
import { createBetPlaceDataObjectFromStoreByMarketId, placeBetWrap } from './betslip';
import { CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE, initialBetSlipPlacingState } from './constants';
import { getUserMarketCode } from '../user';
import { logger } from '../logger';
import { BET_TYPE, BetAmount, Campaign } from './types';

export function getSlug(campaign) {
    return translate(`ui.campaign.slug.${campaign.id}`);
}

export function getCampaignFromStoreBySlug(currentSlug) {
    const campaignsList = getStoreValue(stores.sports.campaigns).list;
    return campaignsList.find((campaign) => getSlug(campaign) === currentSlug);
}

function getNextDayResultTime(campaign, sportSettings) {
    if (sportSettings.override_next_day_match_result_time) {
        return campaign.end_date;
    }

    if (!sportSettings.next_day_match_result_time) {
        return;
    }

    return moment()
        .utc()
        .startOf('day')
        .hours(sportSettings.next_day_match_result_time)
        .add(1, 'day')
        .subtract(1, 'minutes')
        .format();
}

export function getBetslipAmount(campaign: Campaign): number {
    const { balance_uc: userBalance } = getStoreValue(stores.wallet) || {};
    const { stakeByMarketId, betType } = getStoreValue(stores.sports.betSlipUserState);

    const currency = getActiveCurrency();

    let betAmount: BetAmount = {
        min: 0,
        max: 0,
        default: 0,
    };

    let betAmountValue = 0;

    if (campaign.is_first_day) {
        betAmount = campaign.bet_amounts[currency];
    } else if (campaign.recurring_amount_field === 'stake' && campaign.previous_day_bet?.stake) {
        betAmountValue = campaign.previous_day_bet.stake;
    } else if (campaign.previous_day_bet?.won_amount) {
        betAmountValue = campaign.previous_day_bet.won_amount;
    }
    const { min, max } = betAmount;
    if (min) {
        if (userBalance) {
            betAmountValue = betAmount.default || min;
            if (betAmountValue > userBalance) {
                betAmountValue = Math.max(userBalance, min);
            }
        }
    }

    if (betType !== BET_TYPE.COMBO) {
        const key = Object.keys(stakeByMarketId)[0];
        const currentStake = stakeByMarketId[key] as number;

        if (currentStake >= min && currentStake <= max) {
            return currentStake;
        }
    }

    return betAmountValue;
}

async function placeCampaignBetRequest(marketId) {
    const currency = getActiveCurrency();
    const { selected: selectedCampaign } = getStoreValue(stores.sports.campaigns);
    const betPlacingData = createBetPlaceDataObjectFromStoreByMarketId(marketId);
    const { receiptById } = getStoreValue(stores.sports.betSlipPlacingState);
    betPlacingData['ticketsBettedForSameCampaign'] = Object.values(receiptById);
    return postCampaignBet({ campaignId: selectedCampaign?.id, currency, bets: betPlacingData });
}

export async function placeCampaignBet(marketId) {
    const wasInterrupted = await placeBetWrap(placeCampaignBetRequest)(marketId);
    if (!wasInterrupted) {
        loadCampaigns();
    }
    return wasInterrupted;
}

export async function loadCampaigns() {
    const language = getStoreValue(stores.language);
    const market = getUserMarketCode();
    try {
        const response = await getCampaigns({ locale: language, market });
        stores.sports.campaigns.set((state) => ({ ...state, list: response }));
    } catch (error) {
        logger.error('SportsCampaignsService', 'loadCampaigns', error);
    }
}

export function toggleCampaignSelected(campaign: Campaign, forceSelect = false) {
    let { selected: selectedCampaign } = getStoreValue(stores.sports.campaigns);
    selectedCampaign = forceSelect ? undefined : selectedCampaign;
    stores.sports.campaigns.set((state) => ({ ...state, selected: selectedCampaign === campaign ? null : campaign }));
    if (selectedCampaign === campaign) {
        // clear betslip errors when un-selecting campaign
        stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
        return;
    }
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const betSlipMarketIds = Object.keys(betSlipMarketIdToOutcomeId);
    stores.sports.betSlipUserState.set((state) => {
        state.stakeByMarketId = fromPairs(
            [null, ...betSlipMarketIds].map((marketId) => [marketId, getBetslipAmount(campaign)]),
        );
    });
}

export function isCampaignEligibleForBetSlip(campaign) {
    if (!campaign?.bet_amounts[getActiveCurrency()]) {
        return false;
    }

    const marketIds = Object.keys(getStoreValue(stores.sports.betSlipMarketIdToOutcomeId));
    const marketInfoById = getStoreValue(stores.sports.marketInfoById);
    return every(marketIds, (marketId) => isMarketAllowed(campaign, marketInfoById[marketId]));
}

function isMarketAllowed(campaign, market) {
    const { betType } = getStoreValue(stores.sports.betSlipUserState);
    if (!market) {
        return false;
    }
    if ([BET_TYPE.SYSTEM, BET_TYPE.TEASER].includes(betType)) {
        return false;
    }
    if (!campaign.sport_requirements) {
        return true;
    }
    let sportCategoryOK;
    if (
        !campaign.sport_requirements.sport_category_ids ||
        campaign.sport_requirements.sport_category_ids.length === 0
    ) {
        sportCategoryOK = true;
    } else {
        sportCategoryOK = campaign.sport_requirements.sport_category_ids.includes(market.sport_category_id);
    }
    let categoryOK;
    if (!campaign.sport_requirements.category_ids || campaign.sport_requirements.category_ids.length === 0) {
        categoryOK = true;
    } else {
        categoryOK = campaign.sport_requirements.category_ids.includes(market.category_id);
    }
    let marketTypeOK;
    if (!campaign.sport_requirements.market_type_ids || campaign.sport_requirements.market_type_ids.length === 0) {
        marketTypeOK = true;
    } else {
        marketTypeOK = campaign.sport_requirements.market_type_ids.includes(market.market_type_id);
    }
    const matchProduct = matchStatusToSbCampaign[market.match_status];
    const requiredProduct = campaign.sport_requirements.product;
    const productOK =
        !requiredProduct || !market.match_status || requiredProduct === matchProduct || requiredProduct === 'BOTH';
    let matchIdsOK;
    if (!campaign.sport_requirements?.match_ids?.length) {
        matchIdsOK = true;
    } else {
        matchIdsOK = campaign.sport_requirements.match_ids.includes(market.match_id);
    }
    let matchResultTimeOk;
    const validResultTime = getNextDayResultTime(campaign, campaign.sport_requirements);
    if (validResultTime && market.expected_result_time) {
        matchResultTimeOk = moment(market.expected_result_time).isSameOrBefore(moment(validResultTime));
    } else {
        matchResultTimeOk = true;
    }
    let betTypeOk;
    const betTypeString = campaign.sport_requirements?.bet_type;
    if (betTypeString?.length && betType === BET_TYPE.BETBUILDER && market?.view_type !== 'bet_builder') {
        const validBetTypes = betTypeString.split(',');
        // If the betslip's bet type is BET_TYPE.BETBUILDER and a customer tries to bet a single bet,
        // the campaign has to be available for both BETBUILDER and SINGLE for it to be shown on the betslip
        betTypeOk =
            validBetTypes.includes(CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE.BETBUILDER) &&
            validBetTypes.includes(CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE.SINGLE);
    } else {
        betTypeOk = true;
    }
    return sportCategoryOK && categoryOK && marketTypeOK && productOK && matchIdsOK && matchResultTimeOk && betTypeOk;
}

const matchStatusToSbCampaign = { OPEN: 'PREMATCH', LIVE: 'LIVEBET' };
