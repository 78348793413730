import styled from 'styled-components';

export default styled.div`
    height: inherit;
    background: var(--sport-category-bg);
    &,
    & .combo-card-content {
        display: flex;
        flex-direction: column;
    }
    .combo-card-content {
        flex: 1;
        padding: 0.5rem;
        min-height: 0;
        background: var(--sport-category-bg);
        gap: var(--spacing-8);
    }
`;
