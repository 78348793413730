import styled, { css } from 'styled-components';

export default styled.div`
    .pick-bets {
        position: relative;
        font-size: 0.8rem;
        color: var(--font-color-secondary);
        text-align: center;
        margin: var(--spacing-24) 0 var(--spacing-12) 0;

        .pick-bets-ico {
            margin-bottom: -19px;
            svg {
                margin: auto;
            }
        }

        .pick-bets-text {
            position: relative;
            z-index: 2;
            padding: var(--spacing-12);
            background-color: var(--betslip-bg);
        }
    }

    .pick-bets-arrows {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: var(--spacing-12) 0 var(--spacing-24) 0;
        color: var(--font-color-secondary);
        > div:not(:last-child) {
            margin-right: var(--spacing-12);
        }
        > svg {
            animation: fadeInRight 500ms ease-in;
            animation-fill-mode: both;
        }
    }

     {
        ${[1, 2, 3].map(
            (i) => css`
                .pick-bets-arrows > svg:nth-child(${i}) {
                    animation-delay: ${i * 200}ms;
                }
            `,
        )}
    }
`;
