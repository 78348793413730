import styled from 'styled-components';

export default styled.div`
    .stake-input.stake-input-v2 {
        margin-top: 0;

        .remove-stake-button {
            transform: none;
            right: var(--spacing-8);
            padding: 0;
        }

        .stake-input-wrapper {
            display: flex;
            gap: var(--spacing-4);
            align-items: center;
            border-radius: 6px;
            border: 1px solid var(--color-secondary);

            .stake-currency {
                position: relative;
                height: 32px;
                padding-left: var(--spacing-8);
                left: 0;
                top: 0;
                transform: none;
                display: flex;
                align-items: center;
            }
        }

        input {
            height: 2rem;
            font-size: var(--font-12);
            font-weight: var(--font-weight-normal);
            text-align: left;
            border: none;
            padding-left: 0;
        }

        &.input-to-win {
            .stake-input-wrapper {
                border: 1px solid var(--color-primary);
            }

            input {
                border: none;
            }
        }

        .stake-input-label {
            position: relative;
            left: 0;
            top: 0;
            display: inline-block;
            font-size: var(--font-12);
            text-shadow: none;
            transform: none;
            letter-spacing: normal;
            padding: 0 0 var(--spacing-4) 0;
            text-transform: none;
            background: none;
            font-weight: var(--font-weight-normal);
        }
    }
`;
