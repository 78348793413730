import React, { MouseEvent, ReactNode } from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import Svg from '../../../svg/Svg';
import UiBadge from '../../../ui/badge/UiBadge';
import UiFlag from '../../../ui/flag/UiFlag';

interface Props {
    to?: string;
    icon: string | null;
    label?: string;
    isActive?: boolean;
    isFlag?: boolean;
    badge?: number;
    postfix?: ReactNode;
    onClick?: (event: MouseEvent<HTMLLinkElement>) => void;
}

export default function SportMenuV2NavLink({ to, icon, label, isActive, isFlag, badge, postfix, onClick }: Props) {
    return (
        <Wrapper
            to={to}
            onClick={onClick}
            className={classNames({
                ['sport-nav-link-selected']: isActive,
            })}
        >
            {!isFlag && icon && <Svg icon={icon} size={1.125} />}
            {isFlag && icon && <UiFlag flag={icon} size={18} shouldForceRenderFlag />}
            {label && <span className="sport-nav-link-label">{label}</span>}
            {Boolean(badge) && <UiBadge badge={badge} className="sport-nav-link-badge" />}
            {postfix && <div className="sport-nav-link-postfix">{postfix}</div>}
        </Wrapper>
    );
}
