import React, { useEffect, useMemo, useState } from 'react';
import Wrapper from './styles';
import UiAnimate from '../../../ui/animate/UiAnimate';
import Svg from '../../../svg/Svg';
import ComboCardsBetslipStakeInput from '../stake-input/ComboCardsBetslipStakeInput';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';
import SportBetslipValidations from '../../../sport/betslip/validations/SportBetslipValidations';
import ComboCardsItemDetails from '../../item/details/ComboCardsItemDetails';
import { isInfo, isWarning } from '../../../../services/sports/betslip';
import UiAlert from '../../../ui/alert/UiAlert';
import { stores } from '../../../../stores';
import {
    COMBO_CARD_BETSLIP_MIN_MAX_STAKE,
    COMBO_CARD_NOT_AVAILABLE,
    COMBO_CARD_NOT_CASHOUT_ELIGIBLE,
    COMBO_MARKET_ID,
} from '../../../../services/sports/constants';
import { addBetslipError, removeBetslipError } from '../../../../services/sports/betslip-errors';
import { translate } from '../../../../services/translate';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { getActiveCurrency } from '../../../../services/currency';
import { DatabaseComboCardStakeSettings } from '@staycool/sports-types/dist/types';
import { useStore } from '../../../../hooks/useStore';
import { useCampaignOrNormalBetSlipFunctions } from '../../../../hooks/useCampaignOrNormalBetslipFunctions';
import { calculateAndFormatBoostedOdds } from '../../../../services/odds-format';

interface Props {
    card: FoComboCardWithOdds;
    clearSelections: (id?: number) => void;
}
export default function ComboCardsBetslipSelection({ card, clearSelections }: Props) {
    const { id: cardId, name, matches, disable_cashout: disableCashout, odds_boost, combo_card_stake_settings } = card;
    const markets = matches.flatMap(({ markets }) => markets);

    const [oddsByOutcomeId] = useOddsByOutcomeIds(markets.map(({ outcome_id }) => outcome_id));
    const [{ acceptAnyOddsChanges }] = useStore(stores.sports.betSlipUserState);

    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
    const { getErrorMessageByError } = useCampaignOrNormalBetSlipFunctions();
    const [stakeByCardId] = useStore(stores.sports.comboCard.stakeByCardId);
    const [ineligibleCardIds] = useStore(stores.sports.comboCard.ineligibleCardIds);
    const isComboCardNowIneligible = ineligibleCardIds.includes(cardId);
    const [isOddsChanged, setIsOddsChanged] = useState(false);

    useEffect(() => {
        validateComboCardStakeSettings(stakeByCardId[cardId], combo_card_stake_settings);
    }, [stakeByCardId]);

    function validateComboCardStakeSettings(currentStake: number, stakeSettings: DatabaseComboCardStakeSettings[]) {
        const currentStakeSetting = stakeSettings.find(({ currency }) => currency === getActiveCurrency());
        if (!currentStakeSetting) {
            return;
        }

        const { min_stake: minStake, max_stake: maxStake, currency } = currentStakeSetting;
        const stakeError = { code: COMBO_CARD_BETSLIP_MIN_MAX_STAKE, minStake, maxStake };
        removeBetslipError(COMBO_MARKET_ID, stakeError);
        if (minStake > currentStake || maxStake < currentStake || currency !== getActiveCurrency()) {
            addBetslipError(COMBO_MARKET_ID, stakeError);
        }
    }

    const totalOdds = useMemo(() => {
        const isStakeSet = Boolean(stakeByCardId[cardId]);
        setIsOddsChanged(!acceptAnyOddsChanges && isStakeSet);
        return calculateAndFormatBoostedOdds(markets, oddsByOutcomeId, odds_boost);
    }, [oddsByOutcomeId]);

    useEffect(() => {
        if (acceptAnyOddsChanges) {
            setIsOddsChanged(false);
        }
    }, [acceptAnyOddsChanges]);

    return (
        <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true} key={cardId}>
            <Wrapper>
                <div className="selection-header">
                    <div className="card-name">{name}</div>
                    <Svg
                        icon="trash"
                        size={1}
                        onClick={() => clearSelections(cardId)}
                        className="remove-selection pointer"
                    />
                </div>
                <ComboCardsItemDetails card={card} />
                <SportBetslipValidations />
                <ComboCardsBetslipStakeInput cardId={cardId} totalOdds={totalOdds} />
                {isComboCardNowIneligible && (
                    <UiAlert failure>
                        {translate([COMBO_CARD_NOT_AVAILABLE, 'Combo card is not available'], 'ui.betslip')}
                    </UiAlert>
                )}
                {disableCashout && (
                    <UiAlert warning>{translate(COMBO_CARD_NOT_CASHOUT_ELIGIBLE, 'ui.betslip')}</UiAlert>
                )}
                {isOddsChanged && <UiAlert warning>{translate(['', 'Odds changed'], 'ui.betslip')}</UiAlert>}
                {genericErrors.map((genericError) => (
                    <UiAlert
                        className="market-error"
                        info={isInfo(genericError)}
                        warning={isWarning(genericError)}
                        failure={!isWarning(genericError) && !isInfo(genericError)}
                        key={genericError}
                    >
                        {getErrorMessageByError(genericError)}
                    </UiAlert>
                ))}
            </Wrapper>
        </UiAnimate>
    );
}
