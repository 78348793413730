import React from 'react';
import SportCardHeader from '../../../sport/card/header/SportCardHeader';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import SportCategoryIcon from '../../../sport/category-icon/SportCategoryIcon';
import UiNavLink from '../../../ui/nav-link/UiNavLink';
import { getRoute } from '../../../../services/router';

interface Props {
    card: FoComboCardWithOdds;
    isMultiSport?: boolean;
}

export default function ComboCardsItemHeader({ card, isMultiSport = false }: Props) {
    const { betting_end, matches, type, name } = card;
    const iconName = isMultiSport ? 'promotions' : `${matches[0]?.sport_icon}`;
    const numberOfOutcomes = matches.reduce((acc, match) => acc + match.markets.length, 0);
    const isOnlyMatchInCard = Boolean(matches.length === 1);

    return (
        <SportCardHeader
            type={type}
            icon={<SportCategoryIcon color="white" icon={iconName} />}
            numberOfOutcomes={numberOfOutcomes}
            bettingEnd={betting_end.toString()}
            title={
                isOnlyMatchInCard ? (
                    <UiNavLink to={`${getRoute('sport.match')}/${matches[0].id}`}>{name}</UiNavLink>
                ) : (
                    name
                )
            }
        />
    );
}
