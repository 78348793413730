import React from 'react';
import Wrapper from './styles';
import { useSportLobbyCategoriesLinks } from '../../../../hooks/main-navigation-v2';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import { getRoute, isActiveRoute } from '../../../../services/router';
import SportLobbyCategoriesV2MobileButton from './button/SportLobbyCategoriesV2MobileButton';
import { translate } from '../../../../services/translate';

export default function SportLobbyCategoriesV2Mobile() {
    const [liveMatchesCount] = useStore(stores.sports.liveMatchesCount);
    const [isSportSideMenuOpen, setIsSportSideMenuOpen] = useStore(stores.sports.isSportSideMenuOpen);
    const isSportPage = isActiveRoute(getRoute('sport'), false);
    const links = useSportLobbyCategoriesLinks();

    return (
        <Wrapper>
            {isSportPage && (
                <>
                    <SportLobbyCategoriesV2MobileButton
                        icon="sport-menu"
                        label={translate('Sport menu', 'ui.sportsbook')}
                        isSelected={isSportSideMenuOpen}
                        onClick={() => setIsSportSideMenuOpen(!isSportSideMenuOpen)}
                    />
                    <div className="sport-menu-divider" />
                </>
            )}
            {links.map(({ route, icon, label }) => {
                const isLiveCard = route.includes(`${getRoute('sport.live')}`);
                const isSelected = isActiveRoute(route, false);

                return (
                    <SportLobbyCategoriesV2MobileButton
                        key={route}
                        icon={icon}
                        label={label}
                        isSelected={isSelected}
                        url={route}
                        {...(isLiveCard && liveMatchesCount > 0 && { liveMatchesCount })}
                    />
                );
            })}
        </Wrapper>
    );
}
