import React from 'react';
import Wrapper from './styles';

interface Props {
    onClick?: (event?) => void;
    icon?: any;
    className?: any;
    children?: any;
    selected?: boolean;
    removeIcon?: any;
}

export default function UiTag({ onClick, children, icon, selected, removeIcon }: Props) {
    const onClickHandler = onClick ? onClick : () => {};

    return (
        <Wrapper onClick={onClickHandler} selected={selected}>
            {icon && <div className="icon">{icon}</div>}
            <div className="content">{children}</div>
            {removeIcon && <div className="suffix-icon">{removeIcon}</div>}
        </Wrapper>
    );
}
