import { BetType } from '@staycool/lvdc-types/bets';
import uniq from 'lodash/uniq';
import { factorial, getBoxedBetsCount, getCombinations } from './combinatorics';
import times from 'lodash/times';
import { settingsByBetType } from './utils';

const winPlaceShowHandler = (runnerMaps: number[][], betType: BetType) => {
    const betPoolsCount = settingsByBetType[betType]?.bet_pools_count ?? 1;
    return runnerMaps[0]?.length * betPoolsCount;
};

const straightPriceHandler = (runnersArrLength: number) => (runnerMaps: number[][]) => {
    const combinations = getCombinations(runnerMaps, runnersArrLength);
    return combinations.filter((arr) => uniq(arr).length === arr.length).length;
};

const boxPriceHandler = (runnersArrLength: number) => (runnerMaps: number[][]) => {
    const [runners] = runnerMaps;
    if (!runners || !runners.length || runners.length < runnersArrLength) {
        return 0;
    }
    return factorial(runners.length) / factorial(runners.length - runnersArrLength);
};

const quinellaTicketCostHandler = (runnerMaps: number[][]) => {
    return getBoxedBetsCount(runnerMaps, true);
};

const quinellaBoxPriceHandler = (runnerMaps: number[][]) => {
    return getBoxedBetsCount(
        times(2, () => runnerMaps[0]),
        true,
    );
};

const pickNHandler = (runnersArrLength: number) => (runnerMaps: number[][]) => {
    let totalCombinations = 1;
    for (let i = 0; i < runnersArrLength; i++) {
        totalCombinations *= runnerMaps[i]?.length || 0;
    }
    return totalCombinations;
};

const keyBoxHandler = (runnersArrLength: number) => (runnerMaps: number[][]) => {
    if (runnerMaps.length !== 2) {
        return 0;
    }
    let totalCombinations = 0;
    const [keys, boxSelections] = runnerMaps;
    keys.forEach((key) => {
        const keyCombination = [[key], ...times(runnersArrLength - 1, () => boxSelections)];
        const combinations = getBoxedBetsCount(keyCombination);
        totalCombinations += combinations;
    });
    return totalCombinations;
};

type TicketCostHandler = (runnerMaps: number[][], betType: BetType) => number;

export const ticketHandlerByBetType: Partial<Record<BetType, TicketCostHandler>> = {
    WIN: winPlaceShowHandler,
    WP: winPlaceShowHandler,
    WS: winPlaceShowHandler,
    WPS: winPlaceShowHandler,
    PLC: winPlaceShowHandler,
    PS: winPlaceShowHandler,
    SHW: winPlaceShowHandler,
    EXA: straightPriceHandler(2),
    EBX: boxPriceHandler(2),
    TRI: straightPriceHandler(3),
    TBX: boxPriceHandler(3),
    TRK: keyBoxHandler(3),
    QNL: quinellaTicketCostHandler,
    QBX: quinellaBoxPriceHandler,
    DBL: pickNHandler(2),
    PK3: pickNHandler(3),
    PK4: pickNHandler(4),
    PK5: pickNHandler(5),
    PK6: pickNHandler(6),
    PK7: pickNHandler(7),
    PK8: pickNHandler(8),
    PK9: pickNHandler(9),
    P10: pickNHandler(10),
    SFC: straightPriceHandler(4),
    SFX: boxPriceHandler(4),
    SFK: keyBoxHandler(4),
    E05: straightPriceHandler(5),
    E5X: boxPriceHandler(5),
    E5K: keyBoxHandler(5),
    // GSL // TODO perform testing to see how bets are generated
};
