import { getParlayCard } from '../../microservices/sports';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { getLine } from '../sports/market';
import {
    BetSlipPlacingState,
    Odds,
    ParlayCard,
    ParlayCardMarket,
    ParlayCardMarketOutcome,
    ParlayCardWithMarket,
} from '../sports/types';
import sortBy from 'lodash/sortBy';
import round from 'lodash/round';
import { stores } from '../../stores';
import { getStoreValue } from '../../stores/store/utils';
import { getOddsForFoLine } from '../../microservices/sb-odds';
import { FOParlayCardMarket } from '@staycool/sports-types/parlay-card';
import isEmpty from 'lodash/isEmpty';

export async function loadParlayCardData(parlayCardId: number) {
    const parlayCard = await getParlayCard(parlayCardId);
    if (!parlayCard || !parlayCard?.markets?.length) {
        const existingParlayCardState = getStoreValue(stores.sports.parlayCard.parlayCard);
        if (!isEmpty(existingParlayCardState)) {
            stores.sports.parlayCard.parlayCard.set((state) => {
                state.status = 'DISABLED';
            });
        }
        return;
    }

    const marketIds = parlayCard.markets.map((market) => [market.id]);
    const oddsByOutcomeId = await getOddsForFoLine(marketIds);
    stores.sports.parlayCard.parlayCard.set(parlayCard);
    stores.sports.parlayCard.oddsByOutcomeId.set((state) => ({ ...state, ...oddsByOutcomeId }));
    return { parlayCard, oddsByOutcomeId };
}

export function areOddsValidForOnDemandBet(odds: Odds[]) {
    if (odds.some((o) => o.status !== 'OPEN')) {
        return false;
    }
    const maxOddsRatio = 1.23;
    const [smallerOdds, biggerOdds] = sortBy(odds.map((x) => x.value));
    const oddsRatio = round(biggerOdds / Math.max(smallerOdds, 1), 3);
    return oddsRatio < maxOddsRatio;
}

export function getParlayCardOutcomeLine(
    market: ParlayCardMarket,
    outcome: ParlayCardMarketOutcome,
    teaserPoints: number,
) {
    const { id, view_type, line } = market;

    const betSlipMiniMarket = {
        id,
        in_play: false,
        status: outcome?.is_open ? 'OPEN' : 'DISABLED',
        view_type,
        raw_line: line,
    } as BetSlipMinimalMarket;

    return getLine(betSlipMiniMarket, outcome, false, teaserPoints);
}

export function getMarketTypeInfo(result_keys: string[], view_type: string): { isTotal: boolean; isHandicap: boolean } {
    const resultKeys = result_keys.map((r) => r.toLowerCase());
    const isTotal = resultKeys.some((r) => r.includes('over') || r.includes('under')) && view_type === 'line';
    const isHandicap = resultKeys.some((r) => r.includes('home') || r.includes('away')) && view_type === 'line';

    return { isTotal, isHandicap };
}

export function getParlayCardOutcomeName(
    market: ParlayCardMarket,
    outcome: ParlayCardMarketOutcome,
    teaserPoints: number,
    displayHandicapLine = true,
) {
    const outcomeLine = getParlayCardOutcomeLine(market, outcome, teaserPoints);
    const { isTotal, isHandicap } = getMarketTypeInfo(market.result_keys, market.view_type);

    const getTeamName = () => {
        return outcome?.name === '[Home]' ? market.home_team_name : market.away_team_name;
    };

    if (isTotal) {
        return `${outcome?.name} ${outcomeLine}`;
    }

    if (isHandicap && displayHandicapLine) {
        return `${getTeamName()} ${outcomeLine}`;
    }

    return getTeamName();
}

export function getParlayCardMaxSelections(parlayCard: ParlayCard) {
    if (!parlayCard?.payouts?.length) {
        return;
    }
    return Math.max(...parlayCard.payouts.filter((p) => p.odds > 1).map((p) => p.selections_amount));
}

export function getParlayCardMaxOdds(parlayCard: ParlayCard) {
    if (!parlayCard?.payouts?.length) {
        return;
    }
    return Math.max(...parlayCard.payouts.filter((p) => p.odds > 1).map((p) => p.odds));
}

export function displayPayoutOddsInFractionalFormat(decimalOdds: number) {
    if (decimalOdds < 2) {
        return 'N/A';
    }
    const denominator = 1;
    const numerator = decimalOdds - 1;

    return `${round(numerator, 3)}-${denominator}`;
}

export function removeOutcomeFromBetslip(outcomeId: number, marketId: number) {
    stores.sports.parlayCard.betSlipMarketIdToOutcomeIds.set((state) => {
        const outcomeIds = state[marketId];
        if (outcomeIds.length > 1) {
            state[marketId] = outcomeIds.filter((id) => id !== outcomeId);
            return;
        }
        delete state[marketId];
    });
}

export type ParlayCardTab = 'main' | 'payout' | 'terms';

export interface GroupedParlayCardMarkets {
    date: string;
    league: string;
    markets: ParlayCardMarket[];
}

export interface GroupedParlayCardMarketsByMatchId {
    matchId: number;
    markets: ParlayCardMarket[];
}

export interface ParlayUserState {
    parlayCard?: ParlayCardWithMarket;
    stake?: number;
    betSlipPlacingState?: BetSlipPlacingState;
    betSlipErrors?: any[];
    oddsByOutcomeId?: { [outcomeId: number]: Odds };
    manualAcceptanceStake?: number;
}

export type ParlayCardBetslipOutcome = FOParlayCardMarket['outcomes'][0] & { marketId: number; matchId: number };
