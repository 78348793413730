import React, { MutableRefObject, useRef, useState } from 'react';
import { stores } from '../../../../../stores';
import Wrapper from './styles';
import classNames from 'classnames';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import UiGroup from '../../../../ui/group/UiGroup';
import SportBetslipStakeKeyboard from '../../../../sport/betslip/stake-keyboard/SportBetslipStakeKeyboard';
import { isMobile } from '../../../../../services/browser';
import { isFeatureAvailable } from '../../../../../services/feature';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
import { FEATURE } from '../../../../../services/types';
import { RaceBetType } from '../../../../../microservices/lvdc/types';

interface Props {
    betType: RaceBetType;
    handleChange: (stake?: string) => void;
}

export default function HorseRacingRaceStakeBetslip({ betType, handleChange }: Props) {
    const inputRef = useRef();
    const elementRef = useRef<HTMLDivElement>();
    const betStakeInputId = `betStakeInputId-betslip-${betType?.code}`;
    const [{ isPhone }] = useStore(media);
    const [isKeyboardOpened, setIsKeyboardOpened] = useState(false);
    const [betslip] = useStore(stores.horseRacing.betslip);
    const { betStake } = betslip;

    const inputProps = {
        type: 'text',
        onFocus: handleInputFocus,
        onBlur: () => stores.sports.isBottomNavigationVisible.set(true),
        onChange: (event) => handleChange(event.target.value),
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'off',
        spellCheck: 'false',
        'data-gramm_editor': 'false',
        'data-gramm': 'false',
    } as const;

    function handleInputFocus() {
        setIsKeyboardOpened(true);

        if (isPhone) {
            const stakeElement = elementRef.current;
            if (stakeElement) {
                stakeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        stores.sports.isBottomNavigationVisible.set(false);
    }

    return (
        <Wrapper>
            <>
                <UiGroup>
                    <div className="stake-input" ref={elementRef as MutableRefObject<HTMLDivElement>}>
                        {isFeatureAvailable(FEATURE.DISPLAY_DOLLAR_SIGN) && <div className="stake-currency">$</div>}
                        <label
                            htmlFor={betStakeInputId}
                            className={classNames('stake-input-label', {
                                'single-label': true,
                            })}
                        >
                            {translate('Your stake', 'ui.sportsbook')}
                        </label>
                        <div>
                            <input
                                id={betStakeInputId}
                                value={betStake || ''}
                                ref={inputRef as any}
                                readOnly={isMobile()}
                                {...inputProps}
                            />
                        </div>

                        {betStake > 0 && !isKeyboardOpened && (
                            <div className="remove-stake-button" onClick={() => handleChange()}>
                                <Svg icon="backspace" />
                            </div>
                        )}
                    </div>
                </UiGroup>
                {isKeyboardOpened && (
                    <SportBetslipStakeKeyboard
                        inputRef={inputRef}
                        value={betStake}
                        onChange={handleChange}
                        onClose={() => setIsKeyboardOpened(false)}
                    />
                )}
            </>
        </Wrapper>
    );
}
