import React from 'react';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import ComboCardsItemDetailsSingleSport from './single-sport/ComboCardsItemDetailsSingleSport';
import ComboCardsItemDetailsMultiSport from './multi-sport/ComboCardsItemDetailsMultiSport';

interface Props {
    card: FoComboCardWithOdds;
    isMultiSport?: boolean;
}

export default function ComboCardsItemDetails({ card: { matches, type }, isMultiSport = false }: Props) {
    return isMultiSport ? (
        <ComboCardsItemDetailsMultiSport matches={matches} type={type} />
    ) : (
        <ComboCardsItemDetailsSingleSport matches={matches} type={type} />
    );
}
