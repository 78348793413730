import React from 'react';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';
import PaymentCardFormModal from '../../../../card-form-modal/PaymentCardFormModal';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderCoolpayCard({ amount, deviceHash, disclaimer, onClose }: Props) {
    return (
        <PaymentCardFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            onClose={onClose}
            provider={PROVIDERS.COOLPAY_CARD}
            providerType={PROVIDER_TYPE.DEPOSIT}
            isCvcRequiredForSavedCard={false}
        />
    );
}
