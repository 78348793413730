import styled from 'styled-components';

export default styled.div`
    .aligned-text {
        text-align: center;
        font-size: var(--font-12);
        font-weight: var(--font-weight-bold);
        padding-bottom: var(--spacing-16);
        padding-top: var(--spacing-16);
    }

    .terms-conditions-checkbox {
        padding-bottom: var(--spacing-16);
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
`;
