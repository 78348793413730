import React, { useEffect, useState } from 'react';
import { withContextProvider } from '../../../higher-order-components/context/provider/ContextProvider';
import { getMatchOfTheDay } from '../../../microservices/match-of-the-day';
import { SportsMatchContext } from '../../../services/sports/match-helpers';
import { stores } from '../../../stores';
import { useSportsUserSettings } from '../../../services/sports/user-settings';
import { useStore } from '../../../hooks/useStore';
import SportCardHeader from '../card/header/SportCardHeader';
import SportMatchOfTheDayCardContent from './content/SportMatchOfTheDayCardContent';
import SportCard from '../card/SportCard';
import { MatchOfTheDay } from '../../../microservices/match-of-the-day/types';
import SportCategoryIcon from '../category-icon/SportCategoryIcon';
import SportTeamShirtLogo from '../team-shirt-logo/SportTeamShirtLogo';
import UiNavLink from '../../ui/nav-link/UiNavLink';
import { getRoute } from '../../../services/router';

function SportMatchOfTheDayCard() {
    const [matchOfTheDay, setMatchOfTheDay] = useState<MatchOfTheDay>();
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const { layout } = useSportsUserSettings();

    useEffect(() => {
        fetchMatchOfTheDayInfo();
    }, [layout, isAuthenticated]);

    async function fetchMatchOfTheDayInfo() {
        const matchOfTheDay = await getMatchOfTheDay();
        if (matchOfTheDay) {
            setMatchOfTheDay(matchOfTheDay);
        }
    }

    if (!matchOfTheDay?.match) {
        return null;
    }
    const { home_team_name, away_team_name, sport_icon, home_team_logo_name, away_team_logo_name } =
        matchOfTheDay.match;
    const market = {
        ...matchOfTheDay.market,
        in_play: matchOfTheDay.match.inplay,
        betting_end: matchOfTheDay.match.betting_end,
    };

    return (
        <SportCard isMotd>
            <SportCardHeader
                bettingEnd={market.betting_end.toString()}
                title={
                    <>
                        <UiNavLink to={`${getRoute('sport.match')}/${matchOfTheDay.match.id}`}>
                            <SportCategoryIcon icon={`${sport_icon}`} />
                            <div className="team-name">
                                {/*{shouldDisplayTeamLogo && <TeamLogo width="18px" teamName={home_team_logo_name} />}*/}
                                {home_team_name}
                                {home_team_logo_name && <SportTeamShirtLogo homeTeamLogoName={home_team_logo_name} />}
                            </div>
                            <span>-</span>
                            <div className="team-name">
                                {/*{shouldDisplayTeamLogo && <TeamLogo width="18px" teamName={away_team_logo_name} />}*/}
                                {away_team_logo_name && <SportTeamShirtLogo homeTeamLogoName={away_team_logo_name} />}
                                {away_team_name}
                            </div>
                        </UiNavLink>
                    </>
                }
            />
            <SportMatchOfTheDayCardContent market={market} motd={matchOfTheDay.motd} matchId={matchOfTheDay.match.id} />
        </SportCard>
    );
}

export default withContextProvider(SportMatchOfTheDayCard, SportsMatchContext);
