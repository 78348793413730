import React, { useEffect, useState } from 'react';
import { Country } from '@staycool/location';
import { getDepositReturnRoute } from '../../../../../../services/payments/payments';
import { DepositStatus, PROVIDERS, PROVIDER_TYPE, PaymentProvider } from '../../../../../../services/payments/types';
import { useRouter } from '../../../../../../services/router';
import { isProduction } from '../../../../../../services/util';
import { stores } from '../../../../../../stores';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';
import PaymentCardFormModal from '../../../../card-form-modal/PaymentCardFormModal';
import { useStore } from '../../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
    provider: PROVIDERS;
    selectedDepositProvider: PaymentProvider;
    onClose: () => void;
}
export default function PaymentDepositProviderDevcodeCreditCard({
    amount,
    deviceHash,
    provider,
    selectedDepositProvider,
    onClose,
}: Props) {
    const [encrypterUrl, setEncrypterUrl] = useState<string>();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user] = useStore(stores.user);
    const { navigateTo } = useRouter();

    useEffect(() => {
        init();
    }, []);

    function init() {
        try {
            const env = isProduction() ? 'prod' : 'test';
            const merchantId = selectedDepositProvider.aux.MID[env];
            const subDomain = isProduction() ? 'api' : 'test-api';
            setEncrypterUrl(`https://${subDomain}.paymentiq.io/paymentiq/api/viq/jscardencrypter/${merchantId}`);
        } catch (e) {
            setIsError(true);
        }
    }

    function onDeposit({
        method,
        parameters,
        url,
    }: {
        method: string;
        parameters: Record<string, string>;
        url: string;
    }) {
        if (!method || !parameters || !url) {
            navigateTo(getDepositReturnRoute(DepositStatus.COMPLETED));
            return;
        }
        const form = document.createElement('form');
        form.method = method;
        form.action = url;

        for (const key in parameters) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = parameters[key];
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
    }

    function onScriptError() {
        setIsError(true);
    }

    function onScriptLoad() {
        setIsLoading(false);
    }

    if (isError) {
        return <PaymentProviderNotAvailable />;
    }

    return (
        <>
            {encrypterUrl && <ScriptLoader url={encrypterUrl} onError={onScriptError} onLoad={onScriptLoad} />}
            {isLoading && <UiDotsLoader />}
            {!isLoading && (
                <PaymentCardFormModal
                    amount={amount}
                    deviceHash={deviceHash}
                    disclaimer={selectedDepositProvider?.disclaimer}
                    isAllowedToGoOverMaxCardsLimit={true}
                    onClose={onClose}
                    onDeposit={onDeposit}
                    onEncryption={(window as any).encryptData}
                    provider={provider}
                    providerType={PROVIDER_TYPE.DEPOSIT}
                    isHolderEditAllowed={user?.country === Country.CANADA}
                />
            )}
        </>
    );
}
