import React from 'react';
import Wrapper from './styles';
import { Country } from '@staycool/location';
import { isB2B } from '../../../services/environment';
import { filterStyleProps } from '../../../styles/utils';
import { useStore } from '../../../hooks/useStore';
import { environment } from '../../../stores/environment/environment';

interface Props {
    flag: string;
    size?: number;
    className?: string;
    onClick?: () => void;
    shouldForceRenderFlag?: boolean;
}

// TODO: rename or unify, since this is not a flag anymore
export default function UiFlag({ flag = '', shouldForceRenderFlag = false, size = 14, ...rest }: Props) {
    const [{ IMAGE_BASE_URL }] = useStore(environment);
    const shouldDisplayEuropeanFlag = !isB2B() && flag === Country.NORWAY.toLowerCase() && !shouldForceRenderFlag;
    const imagePath = `${IMAGE_BASE_URL}sportsbook/team-logos/${
        shouldDisplayEuropeanFlag ? 'eu' : flag
    }.png?auto=format&w=${size * 2}`;

    if (!flag) {
        return null;
    }

    return (
        <Wrapper
            {...filterStyleProps({ ...rest })}
            $size={size}
            src={imagePath}
            alt=""
            onError={(event) => (event.currentTarget.style.visibility = 'hidden')}
        />
    );
}
