import styled from 'styled-components';

type StyledProps = {
    $accentColor: string;
};

export default styled.div<StyledProps>`
    counter-reset: order;
    font-size: var(--font-12);
    flex: 1;
    overflow: auto;
    gap: var(--spacing-8);

    &,
    & .dot-indicator {
        display: flex;
        flex-direction: column;
    }
    .dot-indicator {
        position: relative;
        padding-left: var(--spacing-16);
        gap: 0.125rem;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0.125rem;
        }

        &:after {
            border-radius: 50%;
            background-color: ${({ $accentColor }) => $accentColor};
            width: 0.5625rem;
            height: 0.5625rem;
        }

        &:before {
            width: var(--spacing-4);
            border-right: 1px solid var(--surface-level-2-line-color);
            box-sizing: content-box;
            bottom: var(--spacing-4);
        }

        & :first-child {
            font-weight: 800;
            margin-bottom: var(--spacing-4);
        }
    }

    .match-name {
        display: flex;
        gap: var(--spacing-4);
    }
`;
