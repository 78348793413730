import React from 'react';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';
import PaymentFormModal from '../../../../form-modal/PaymentFormModal';

interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderEproCashlib({ amount, deviceHash, onClose }: Props) {
    return (
        <PaymentFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer="payments.deposit.cashlib-info-text"
            isRedirectProvider
            onClose={onClose}
            provider={PROVIDERS.EPRO_CASHLIB}
            providerType={PROVIDER_TYPE.DEPOSIT}
        />
    );
}
