import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Wrapper from './styles';

interface Props {
    children: ReactNode;
    isMotd?: boolean;
}
export default function SportCard({ children, isMotd }: Props) {
    return <Wrapper className={classNames({ motd: isMotd })}>{children}</Wrapper>;
}
