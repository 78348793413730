import { useEffect } from 'react';
import { getActiveCurrency } from '../../../../services/currency';
import { calculateTotalOdds, getAllOdds, isBetSlipLoadingMarkets } from '../../../../services/sports/betslip';
import { addBetslipError, removeAllBetslipErrorsOfCode } from '../../../../services/sports/betslip-errors';
import {
    CAMPAIGN_BET_TYPE_ERROR,
    CAMPAIGN_MAX_BET_PER_PERIOD_ERROR,
    CAMPAIGN_MAX_SELECTIONS_ERROR,
    CAMPAIGN_MIN_ODDS_ERROR,
    CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
    CAMPAIGN_MIN_SELECTIONS_ERROR,
    CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
    CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE,
    CAMPAIGN_WRONG_STAKE_ERROR,
    COMBO_MARKET_ID,
} from '../../../../services/sports/constants';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';
import { BET_TYPE as BET_TYPES } from '../../../../services/sports/types';

export default function SportCampaignsBetslipValidations() {
    const [campaigns] = useStore(stores.sports.campaigns);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipUserState] = useStore(stores.sports.betSlipUserState);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [oddsByOutcomeId] = useOddsByOutcomeIds(Object.values(betSlipMarketIdToOutcomeId));
    const { stakeByMarketId, betType } = betSlipUserState;
    const marketId = betType === BET_TYPES.COMBO ? String(COMBO_MARKET_ID) : Object.keys(betSlipMarketIdToOutcomeId)[0];
    const currentStake: any = stakeByMarketId[marketId];
    const { selected } = campaigns;
    const [oddsFormat] = useOddsFormat();

    useEffect(() => {
        removeAllBetslipErrorsOfCode([
            CAMPAIGN_MIN_ODDS_ERROR,
            CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR,
            CAMPAIGN_MAX_BET_PER_PERIOD_ERROR,
            CAMPAIGN_WRONG_STAKE_ERROR,
            CAMPAIGN_BET_TYPE_ERROR,
            CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR,
            CAMPAIGN_MAX_SELECTIONS_ERROR,
            CAMPAIGN_MIN_SELECTIONS_ERROR,
        ]);
        if (!selected || isBetSlipLoadingMarkets()) {
            return;
        }
        const numSelectionsCurrent = Object.keys(betSlipMarketIdToOutcomeId).length;
        if (
            (betType === BET_TYPES.SINGLE && numSelectionsCurrent > 1) ||
            [BET_TYPES.SYSTEM, BET_TYPES.TEASER].includes(betType)
        ) {
            addBetslipError(COMBO_MARKET_ID, CAMPAIGN_NO_MULTIPLE_SINGLES_ERROR);
        }

        if (selected.is_first_day) {
            const campaignBetAmounts = selected.bet_amounts[getActiveCurrency()];
            if (
                campaignBetAmounts &&
                (currentStake < campaignBetAmounts.min || currentStake > campaignBetAmounts.max)
            ) {
                addBetslipError(COMBO_MARKET_ID, CAMPAIGN_WRONG_STAKE_ERROR);
            }
        } else if (selected.require_previous_bet) {
            let betAmount = selected.previous_day_bet.won_amount;
            if (selected.recurring_amount_field === 'stake') {
                betAmount = selected.previous_day_bet.stake;
            }
            if (currentStake !== betAmount) {
                addBetslipError(COMBO_MARKET_ID, CAMPAIGN_WRONG_STAKE_ERROR);
            }
        }

        if (marketId) {
            const currentOdds = calculateTotalOdds(marketId);
            if (currentOdds < selected.bet_min_odds) {
                addBetslipError(COMBO_MARKET_ID, CAMPAIGN_MIN_ODDS_ERROR);
            }
        }

        const allOddsValues = getAllOdds(marketId);

        allOddsValues.forEach((odd) => {
            if (odd < Number(selected.min_odd_per_selection)) {
                addBetslipError(COMBO_MARKET_ID, CAMPAIGN_MIN_ODD_PER_SELECTION_ERROR);
            }
        });

        const { bet_type, selections = {} } = selected.sport_requirements || {};
        if (!isBetTypeValidForCampaign(marketId, bet_type, betType)) {
            addBetslipError(COMBO_MARKET_ID, CAMPAIGN_BET_TYPE_ERROR);
        }

        if (numSelectionsCurrent) {
            if (selections.max && numSelectionsCurrent > selections.max) {
                addBetslipError(COMBO_MARKET_ID, CAMPAIGN_MAX_SELECTIONS_ERROR);
            } else if (selections.min && numSelectionsCurrent < selections.min) {
                addBetslipError(COMBO_MARKET_ID, CAMPAIGN_MIN_SELECTIONS_ERROR);
            }
        }
    }, [selected, betSlipMarketIdToOutcomeId, currentStake, betType, oddsByOutcomeId, marketInfoById, oddsFormat]);

    function isBetTypeValidForCampaign(
        marketId: string,
        betTypeInCampaign: string,
        betTypeInBetSlip: BET_TYPES,
    ): boolean {
        if (!marketId || !betTypeInCampaign) {
            return true;
        } else if (betTypeInCampaign === CAMPAIGN_SPORT_REQUIREMENTS_BET_TYPE.BOTH) {
            // TODO: 'BOTH' will be deprecated as a bet type in a campaign's sport requirements.
            // When no campaign uses 'BOTH' in its sport_requirements.bet_type, this if block should be removed
            return [BET_TYPES.SINGLE, BET_TYPES.COMBO].includes(betTypeInBetSlip);
        }

        const betTypesInCampaign = betTypeInCampaign.split(',').map((betType) => betType.toLowerCase());
        return betTypesInCampaign.includes(betTypeInBetSlip.toLowerCase());
    }

    return null;
}
