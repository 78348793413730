import { isFeatureAvailable } from '../services/feature';
import { FEATURE } from '../services/types';
import { parse } from 'query-string';
import { getSharedBetslipInfo } from '../microservices/bets';
import { copyTicketToBetslipByOutcomeIds } from '../services/sports/betslip';
import { getRoute, useRouter } from '../services/router';
import { useStore } from './useStore';
import { stores } from '../stores';
import { fetchAllComboCards } from '../microservices/sbgate';
import { loadSportsLimits } from '../services/sports/limits';

export function useBetslipShare() {
    const { navigateTo } = useRouter();
    const [, setUserState] = useStore(stores.sports.betSlipUserState);
    const [language] = useStore(stores.language);
    const [, setCardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [, setComboCards] = useStore(stores.sports.comboCard.comboCards);

    async function handleBetslipShare() {
        if (!isFeatureAvailable(FEATURE.BETSLIP_SHARE)) {
            return;
        }

        const { betslipCode } = parse(window.location.search);

        if (!betslipCode) {
            return;
        }

        const sharedBetslipInfo = await getSharedBetslipInfo(betslipCode as string);

        if (!sharedBetslipInfo) {
            return;
        }

        await loadSportsLimits();

        const { outcomeIds, betType, comboCardId, code } = sharedBetslipInfo;

        if (comboCardId) {
            const allComboCards = await fetchAllComboCards(language);
            setComboCards(allComboCards);

            const foundComboCard = allComboCards.find((card) => card.id === comboCardId);
            if (foundComboCard) {
                setCardsInBetslip([foundComboCard]);
            }
        }

        await copyTicketToBetslipByOutcomeIds(outcomeIds, null);

        setUserState((userState) => ({
            ...userState,
            betType,
            userBetTypeSelection: betType,
            betslipCode: code,
        }));

        navigateTo(getRoute('sport.recommendations'));
    }

    return { handleBetslipShare };
}
