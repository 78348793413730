import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    margin: 0 auto;
    align-items: center;

    @media ${mediaQuery.isLargerThanPhone} {
        align-items: start;
    }

    &,
    .providers-list {
        width: min-content;
        display: flex;
        flex-direction: column;
    }

    .providers-list {
        gap: 0.5rem;
        align-items: start;
    }

    .all-methods-link {
        margin-top: 0.5rem;
        text-decoration: underline;
        font-size: 14px;
    }
    .amount-selector > .custom-amount + div {
        display: none;
    }

    div:has(iframe) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: var(--spacing-32) 0 0 0;

        iframe {
            margin: 0 auto;
        }
    }
`;
