import React from 'react';
import { isActiveRouteBySlug } from '../../../../../services/router';
import Wrapper from './styles';
import SportTreePrematchAdvancedItem from './item/SportTreePrematchAdvancedItem';
import { PreMatchTree } from '../../../../../services/sports/types';

interface Props {
    tree: PreMatchTree;
    isDisplayingAll: boolean;
    initialVisibleCategoryCount: number;
}

export default function SportTreePrematchAdvanced({ tree, isDisplayingAll, initialVisibleCategoryCount }: Props) {
    const activeParent = tree.children && tree.children.find((child) => isActiveRouteBySlug(child.slug, child));
    const activeInAlwaysVisible =
        activeParent &&
        tree.children &&
        tree.children.slice(0, initialVisibleCategoryCount).find((child) => isActiveRouteBySlug(child.slug));

    const fakeParentCategories: any = [1, 2, 3, 4];
    return (
        <Wrapper>
            {(tree.children || fakeParentCategories)
                .slice(0, isDisplayingAll ? undefined : initialVisibleCategoryCount)
                .filter((child) => isDisplayingAll || activeInAlwaysVisible || !isActiveRouteBySlug(child.slug))
                .map((child) => (
                    <SportTreePrematchAdvancedItem category={child} key={child.id || child} />
                ))}

            {activeParent && !isDisplayingAll && !activeInAlwaysVisible && (
                <SportTreePrematchAdvancedItem category={activeParent} />
            )}
        </Wrapper>
    );
}
