import React from 'react';
import { Tooltip } from 'react-tooltip';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import classNames from 'classnames';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

import { SportsBetslipInputFormat } from '../../../../../services/types';

interface Props {
    key: number;
}

export default function SportSettingsMenuOptionWagerToWin({ key }: Props) {
    const [{ isPhone }] = useStore(media);
    const { betslipInputFormat, toggleBetslipInputFormat } = useSportsUserSettings();

    return (
        <div className="setting-item item-row" key={key}>
            <div className="item-label">
                <div className="label-text">{translate('Wager To Win', 'sport.settings')}</div>
                <Tooltip
                    id="stake-input-tooltip"
                    variant="light"
                    place={isPhone ? 'bottom' : 'top'}
                    className="tooltip"
                >
                    <span>{translate('Enables American style stake input', 'sport.settings')}</span>
                </Tooltip>
                <Svg
                    icon="info"
                    className="help-bubble"
                    data-tooltip-id="stake-input-tooltip"
                    size={isPhone ? 1.3 : 1}
                />
            </div>
            <div>
                <UiFormGroup className="toggle">
                    <UiFormInput
                        className={classNames({
                            'toggle-enabled': betslipInputFormat === SportsBetslipInputFormat.AMERICAN,
                        })}
                        toggle
                        value={betslipInputFormat === SportsBetslipInputFormat.AMERICAN}
                        name="american-view"
                        onChange={toggleBetslipInputFormat}
                        nowrap
                    />
                </UiFormGroup>
            </div>
        </div>
    );
}
