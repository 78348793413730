import React, { useEffect, useState } from 'react';
import { getIsMaxCardsReached } from '../../../../../services/payments/payments';
import { PROVIDERS, PROVIDER_TYPE, PaymentProvider } from '../../../../../services/payments/types';
import { getLastActiveProductRoute, useRouter } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import Snippet from '../../../../snippet/Snippet';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import PaymentDepositProviderWorldpayScripts from './scripts/PaymentDepositProviderWorldpayScripts';
import Wrapper from './styles';
import { getProviderPaymentMethods } from '../../../../../microservices/payments';
import { depositWithProvider } from '../../../../../services/payments/payments';
import UiAlert from '../../../../ui/alert/UiAlert';
import { logger } from '../../../../../services/logger';
import PaymentCardsSlider from '../../../cards-slider/PaymentCardsSlider';
import UiGroup from '../../../../ui/group/UiGroup';
import { stores } from '../../../../../stores';
import { isProduction } from '../../../../../services/util';
import UiButton from '../../../../ui/button/UiButton';
import { getStoreValue } from '../../../../../stores/store/utils';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
import { useForm } from 'react-hook-form';
import Ui2FormTextInput from '../../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2Form from '../../../../ui-2/form/Ui2Form';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    selectedDepositProvider: PaymentProvider;
    onClose: () => void;
}

export default function PaymentDepositProviderWorldpay({
    amount,
    deviceHash,
    disclaimer,
    selectedDepositProvider,
    onClose,
}: Props) {
    const provider = PROVIDERS.WORLDPAY;
    const providerType = PROVIDER_TYPE.DEPOSIT;
    const isAllowedToGoOverMaxCardsLimit = true;
    const isCvcRequiredForSavedCard = true;
    const INVALID_CARD_NUMBER_RESPONSE_CODES = ['871', '872', '873', '874', '876'];
    const INVALID_CVC_NUMBER_RESPONSE_CODES = ['881', '882', '883'];
    const { navigateTo } = useRouter();
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSetupFinished, setIsSetupFinished] = useState(false);
    const [eProtectiframeClient, setEProtectiframeClient] = useState<any>({});
    const [numberError, setNumberError] = useState('');
    const [cvcError, setCvcError] = useState('');
    const [isMaxCardsReached, setIsMaxCardsReached] = useState(false);
    const [cards, setCards] = useState<
        {
            id: string;
            number: string;
            expiration_date?: string;
            description?: string;
            extra?: Record<string, unknown>;
        }[]
    >([]);
    const [methodId, setMethodId] = useState<string>();
    const [isAddingNewCard, setIsAddingNewCard] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [{ isPhone }] = useStore(media);
    const [isInProgress, setIsInProgress] = useState(false);
    const [error, setError] = useState('');
    const user = getStoreValue(stores.user);

    useEffect(() => {
        loadCards();
    }, []);

    useEffect(() => {
        if (hasScriptsLoaded) {
            loadClient();
        }
    }, [hasScriptsLoaded]);

    async function loadCards() {
        try {
            const cards = await getProviderPaymentMethods(provider, providerType);
            setIsMaxCardsReached(await getIsMaxCardsReached({ raiseLimitBy: +isAllowedToGoOverMaxCardsLimit }));
            if (cards.length) {
                setCards(cards);
                setMethodId(cards[0].id);
            } else {
                setIsAddingNewCard(true);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderWorldpay', 'loadCards', error);
        }
        setIsLoading(false);
    }

    function setSelectedCard(card) {
        setMethodId(card.id);
    }

    const defaultValues = {
        cvc: '',
        expirationDate: '',
        holder: '',
        number: '',
    };

    const useFormMethods = useForm({ defaultValues, mode: 'onBlur' });
    const { errors } = useFormMethods.formState;

    function addCard() {
        if (isMaxCardsReached) {
            setError(translate('MAX_CARDS_REGISTERED_REACHED', 'ui.account'));
            return;
        }
        clearErrors();
        setMethodId(undefined);
        setIsAddingNewCard(true);
    }

    function clearErrors() {
        setError('');
        setNumberError('');
        setCvcError('');
    }

    async function eProtectiframeClientCallback(eProtectCallBackResponse) {
        if (eProtectCallBackResponse.timeout) {
            setIsError(true);
            logger.error(
                'PaymentDepositProviderWorldpay',
                'eProtectiframeClientCallback: Transaction timed out from worldpay',
                eProtectCallBackResponse,
            );
        } else {
            if (eProtectCallBackResponse.response === '870') {
                await deposit(eProtectCallBackResponse);
            } else if (INVALID_CARD_NUMBER_RESPONSE_CODES.includes(eProtectCallBackResponse.response)) {
                setNumberError(translate('Invalid card number', 'ui.payments'));
            } else if (INVALID_CVC_NUMBER_RESPONSE_CODES.includes(eProtectCallBackResponse.response)) {
                setCvcError(translate('Invalid CVC', 'ui.payments'));
            } else if (eProtectCallBackResponse.response === '884') {
                setIsError(true);
                logger.error(
                    'PaymentDepositProviderWorldpay',
                    'eProtectiframeClientCallback: iFrame failed to load',
                    eProtectCallBackResponse,
                );
            } else {
                setIsError(true);
            }
        }
        setIsInProgress(false);
    }

    function getSdkConfig() {
        const env = isProduction() ? 'prod' : 'test';
        const paypageId = selectedDepositProvider.aux.MID[env];
        return {
            paypageId: paypageId,
            style: 'enhancedStyle1',
            reportGroup: 'RG1',
            timeout: 5000,
            div: 'eProtectiframe',
            callback: eProtectiframeClientCallback,
            maskAfterSuccessValue: 'X',
            showCvv: true,
            months: {
                1: 'January',
                2: 'February',
                3: 'March',
                4: 'April',
                5: 'May',
                6: 'June',
                7: 'July',
                8: 'August',
                9: 'September',
                10: 'October',
                11: 'November',
                12: 'December',
            },
            numYears: 8,

            tooltipText:
                'A CVV is the 3 digit code on the back of your Visa, Mastercard and Discover or a 4 digit code on the front of your American Express',

            tabIndex: {
                cvv: 1,
                accountNumber: 2,
                expMonth: 3,
                expYear: 4,
            },
            placeholderText: {
                cvv: 'CVV',
                accountNumber: 'Account Number',
                pin: 'PIN Placeholder',
            },
            inputsEmptyCallback: () => {},
            enhancedUxFeatures: {
                inlineFieldValidations: true,
                expDateValidation: false,
                enhancedUxVersion: 2,
            },
            minPanLength: 16,
        };
    }

    function loadClient() {
        try {
            createWorldpayClient();
            setIsSetupFinished(true);
        } catch (error) {
            logger.error('PaymentDepositProviderWorldpay', 'loadClient', error);
            setIsError(true);
        }
    }

    function createWorldpayClient() {
        if (typeof EprotectIframeClient === 'undefined') {
            setIsError(true);
        } else {
            const configObj = getSdkConfig();
            const eProtectiframeClient = new EprotectIframeClient(configObj);
            eProtectiframeClient.autoAdjustHeight();
            setEProtectiframeClient(eProtectiframeClient);
        }
    }

    async function depositExistingCard(values) {
        clearErrors();
        setIsInProgress(true);
        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.WORLDPAY,
                methodId: methodId,
                providerParams: values,
            });
            setIsInProgress(false);
            handleDepositResponseRedirects(response);
        } catch (error) {
            setIsError(true);
        }
    }

    async function deposit(eProtectCallBackResponse) {
        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.WORLDPAY,
                providerParams: {
                    paypageRegistrationId: eProtectCallBackResponse.paypageRegistrationId,
                    number: `${eProtectCallBackResponse.firstSix}******${eProtectCallBackResponse.lastFour}`,
                    expirationDate: `${eProtectCallBackResponse.expMonth}/${eProtectCallBackResponse.expYear}`,
                    externalTransactionId: eProtectCallBackResponse.vantivTxnId,
                    responseCode: eProtectCallBackResponse.response,
                },
            });
            setIsInProgress(false);
            handleDepositResponseRedirects(response);
        } catch (error) {
            setIsError(true);
        }
    }

    function handleDepositResponseRedirects(response) {
        if (response.status === 'success') {
            navigateTo(
                `${getLastActiveProductRoute()}?success=${translate('Your deposit was successful.', 'ui.account')}`,
            );
        } else if (response.status === 'fail') {
            setError(translate('Your deposit did not go through.', 'ui.account'));
        } else {
            navigateTo(`${getLastActiveProductRoute()}?pending=${translate('Your deposit is pending.', 'ui.account')}`);
        }
    }

    function goBack() {
        if (isAddingNewCard && cards.length) {
            setMethodId(cards[0].id);
            setIsAddingNewCard(false);
        } else {
            onClose();
        }
    }

    if (isError) {
        return <PaymentProviderNotAvailable />;
    }

    function submitEProtectIframe() {
        clearErrors();
        const id = `${user?.id}-${Date.now()}`;
        const orderId = `${user?.id}-${Date.now() + 1}`;
        const message = {
            id: id,
            orderId: orderId,
        };
        setIsInProgress(true);
        eProtectiframeClient.getPaypageRegistrationId(message);
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }
    return (
        <Wrapper>
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {error && <UiAlert failure>{error}</UiAlert>}
            {isAddingNewCard && (
                <>
                    <div>
                        {!isSetupFinished && <UiDotsLoader />}
                        <div className="button-container">
                            <div id="eProtectiframe" />
                        </div>
                    </div>
                    {cvcError && (
                        <UiAlert className="card-input-error" failure>
                            {cvcError}
                        </UiAlert>
                    )}
                    {numberError && (
                        <UiAlert className="card-input-error" failure>
                            {numberError}
                        </UiAlert>
                    )}
                    <UiGroup expand horizontallyCentered>
                        <UiButton onClick={goBack}>{translate('Back', 'ui.common')}</UiButton>
                        <UiButton
                            color="primary"
                            onClick={submitEProtectIframe}
                            disabled={isInProgress}
                            isLoading={isInProgress}
                        >
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>

                    <PaymentDepositProviderWorldpayScripts
                        onError={() => setIsError(true)}
                        onScriptsLoaded={() => setHasScriptsLoaded(true)}
                    />
                </>
            )}
            {!isAddingNewCard && (
                <>
                    <Ui2Form useFormMethods={useFormMethods} onSubmit={depositExistingCard}>
                        <PaymentCardsSlider cards={cards as any[]} onSelectCard={setSelectedCard} />

                        {providerType === PROVIDER_TYPE.DEPOSIT && isCvcRequiredForSavedCard && (
                            <>
                                <div className="cvc-input top-margin">
                                    <Ui2FormTextInput
                                        name="cvc"
                                        type="number"
                                        label={translate('CVC', 'ui.payments')}
                                        maxLength={4}
                                        error={errors.cvc}
                                    />
                                </div>

                                {cvcError && (
                                    <UiAlert className="card-input-error" failure>
                                        {cvcError}
                                    </UiAlert>
                                )}
                            </>
                        )}

                        <UiGroup className="buttons">
                            {
                                <UiButton block={isPhone} disabled={isInProgress} onClick={addCard}>
                                    {translate('Add card', 'ui.account')}
                                </UiButton>
                            }

                            <UiButton
                                type="button"
                                block={isPhone}
                                color="primary"
                                isFormSubmitButton
                                isLoading={isInProgress}
                                disabled={isInProgress}
                            >
                                {translate('Continue', 'ui.account')}
                            </UiButton>
                        </UiGroup>
                    </Ui2Form>
                </>
            )}
        </Wrapper>
    );
}

declare let EprotectIframeClient: any;
