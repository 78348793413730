import styled from 'styled-components';

export default styled.div`
    z-index: 1000;

    .clear-all-modal {
        h1 {
            text-align: center;
            font-size: var(--font-16);
            margin: var(--spacing-8) 0;
        }

        p {
            width: 100%;
            text-align: center;
            font-size: var(--font-14);
        }

        &-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: var(--spacing-8);
        }
    }
`;
