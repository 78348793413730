import camelCase from 'lodash/camelCase';
import forOwn from 'lodash/forOwn';
import isEmpty from 'lodash/isEmpty';
import {
    getLimitsByType,
    getProductsTimeoutStatuses,
    getResponsibleGamingRestrictions,
    getUnapprovedReminders,
    getUserAskResponsibleGaming,
} from '../microservices/responsible-gaming';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { PRODUCT } from '../types/common';
import { logout } from './auth';
import { getActiveCurrency } from './currency';
import { isFeatureAvailable } from './feature';
import { openKycModal } from './kyc';
import { LocalStorage } from './local-storage/types';
import { logger } from './logger';
import { NativeMessageEventType, isMobileApp, sendNativeEvent } from './mobile-app';
import {
    LimitPeriod,
    ResponsibleGamingProduct,
    ResponsibleGamingReminder,
    TimeoutItemSource,
    UserLimit,
} from './responsible-gaming/types';
import { storageSet } from './storage';
import { translate } from './translate';
import { ObjectValues } from './ts-utils';
import { FEATURE } from './types';

export const CLOSURE_REASON = {
    CLOSED: 'CLOSED',
    COUNTRY: 'COUNTRY',
    SUPPORT: 'SUPPORT',
    TIMEOUT: 'TIMEOUT',
    HAMPI: 'HAMPI',
    UNDERAGE: 'UNDERAGE',
    MAINTENANCE: 'MAINTENANCE',
    MINCETUR: 'MINCETUR',
    LIMIT: 'LIMIT',
    SPELPAUS: 'SPELPAUS',
    BALANCE: 'BALANCE',
    PID_MISSING: 'PID_MISSING',
};

export async function initializeResponsibleGamingTimeoutStatuses() {
    const [{ casino, sportsbook, horse_racing, poker, source, firstEndDate, selfExclusionUntilFurtherNotice }] =
        await Promise.all([getProductsTimeoutStatuses(), loadAskLimit()]);
    stores.responsibleGaming.timeout.set({
        [ResponsibleGamingProduct.CASINO]: casino?.end_date ? new Date(casino.end_date) : undefined,
        [ResponsibleGamingProduct.HORSE_RACING]: horse_racing?.end_date ? new Date(horse_racing.end_date) : undefined,
        [ResponsibleGamingProduct.POKER]: poker?.end_date ? new Date(poker.end_date) : undefined,
        [ResponsibleGamingProduct.SPORTSBOOK]: sportsbook?.end_date ? new Date(sportsbook.end_date) : undefined,
    });

    if (source === TimeoutItemSource.PANIC_BUTTON) {
        stores.responsibleGaming.isPanicTimeoutActive.set(true);
        stores.responsibleGaming.panicTimeoutEndDate.set(new Date(firstEndDate));
    } else if (source === TimeoutItemSource.SELF_EXCLUSION) {
        stores.responsibleGaming.isSelfExclusionTimeoutActive.set(true);
        stores.responsibleGaming.selfExclusionTimeoutEndDate.set(new Date(firstEndDate));
        stores.responsibleGaming.selfExclusionUntilFurtherNotice.set(selfExclusionUntilFurtherNotice);
    }
}

export async function loadAskLimit() {
    const { askDepositLimit, askLoginDurationLimit, askLossLimit } = await getUserAskResponsibleGaming();
    stores.responsibleGaming.askDepositLimit.set(askDepositLimit);
    stores.responsibleGaming.askLoginDurationLimit.set(askLoginDurationLimit);
    stores.responsibleGaming.askLossLimit.set(askLossLimit);
}

export async function loadRestrictions() {
    try {
        const currency = getActiveCurrency();
        const response = await getResponsibleGamingRestrictions(currency);
        stores.responsibleGaming.restrictions.set(response.restrictions);
        stores.responsibleGaming.limitConfig.set(response.config);
    } catch (error) {
        logger.error('ResponsibleGamingService', 'loadRestrictions', error);
    }
}

export function resetResponsibleGamingTimeoutStatuses() {
    stores.responsibleGaming.timeout.set({
        [ResponsibleGamingProduct.CASINO]: undefined,
        [ResponsibleGamingProduct.SPORTSBOOK]: undefined,
        [ResponsibleGamingProduct.POKER]: undefined,
        [ResponsibleGamingProduct.HORSE_RACING]: undefined,
    });

    stores.responsibleGaming.isPanicTimeoutActive.set(false);
    stores.responsibleGaming.isSelfExclusionTimeoutActive.set(false);
    stores.responsibleGaming.panicTimeoutEndDate.set(new Date());
    stores.responsibleGaming.selfExclusionTimeoutEndDate.set(new Date());
    stores.responsibleGaming.selfExclusionUntilFurtherNotice.set(undefined);
    stores.responsibleGaming.unApprovedSessionReminder.set(null);
    stores.responsibleGaming.isUnapprovedSessionReminderLoaded.set(false);
    stores.modals.isUnapprovedSessionReminderOpen.set(false);
    stores.responsibleGaming.annualReport.set(null);
    stores.responsibleGaming.isAnnualReportLoaded.set(null);
    stores.modals.isResponsibleGamingAnnualReportOpen.set(false);
    storageSet(LocalStorage.SHOW_ANNUAL_REPORT, true);
}

export async function initializeResponsibleGaming(isAuthenticated: boolean) {
    try {
        await initializeResponsibleGamingTimeoutStatuses();
        await loadUnapprovedReminders(isAuthenticated);
    } catch (error) {
        logger.error('ResponsibleGamingService', 'initializeResponsibleGaming', error);
    }
}

async function loadUnapprovedReminders(isAuthenticated: boolean) {
    if (!isAuthenticated) {
        return;
    }

    const response = await getUnapprovedReminders();
    processReminder(response.length > 0 ? response[0] : ({} as ResponsibleGamingReminder));
}

export function processReminder(responsibleGamingReminder: ResponsibleGamingReminder) {
    if (isMobileApp() && ['open', 'close'].includes(responsibleGamingReminder.action)) {
        sendNativeEvent({
            type: NativeMessageEventType.SESSION_REMINDER,
            currency: getActiveCurrency(),
            ...getUnApprovedSessionReminder(responsibleGamingReminder),
        });
    } else if (responsibleGamingReminder.action === 'open') {
        stores.modals.isUnapprovedSessionReminderOpen.set(!isEmpty(responsibleGamingReminder));
        stores.responsibleGaming.unApprovedSessionReminder.set(responsibleGamingReminder);
    } else if (responsibleGamingReminder.action === 'close') {
        stores.responsibleGaming.unApprovedSessionReminder.set(null);
    }

    if (responsibleGamingReminder.action === 'block-user-login-duration' && responsibleGamingReminder.limit_end) {
        stores.responsibleGaming.loginDurationLimitEndDate.set(new Date(responsibleGamingReminder.limit_end));
    }

    if (responsibleGamingReminder.action === 'master-session-logout') {
        logout(responsibleGamingReminder.reason);
        stores.responsibleGaming.unApprovedSessionReminder.set(null);
        if (responsibleGamingReminder.kycToken) {
            openKycModal(responsibleGamingReminder.kycToken, responsibleGamingReminder.kycTokenExpiry);
        }

        if (responsibleGamingReminder.reason) {
            stores.modals.isMultipleSessionModalOpen?.set(true);
        }
    }

    stores.responsibleGaming.isUnapprovedSessionReminderLoaded.set(true);
}

function getUnApprovedSessionReminder(responsibleGamingReminder) {
    const initialProductTransactions = {
        sportsbook: 0,
        casino: 0,
        poker: 0,
        virtual_sports: 0,
    };

    const currentProductTransactions = responsibleGamingReminder?.wallet_data.reduce((result, transaction) => {
        const { product, amount_uc } = transaction;
        result[product.toLowerCase()] = amount_uc;
        return result;
    }, {});

    const walletTransactionsByProduct = { ...initialProductTransactions, ...currentProductTransactions };
    return { ...responsibleGamingReminder, wallet_data: walletTransactionsByProduct };
}

function getListFromLimitsDictionary(limitsDictionary) {
    const normalizedLimits: UserLimit[] = [];

    forOwn(limitsDictionary, (limit, limitPeriod) => {
        if (isEmpty(limit)) {
            return;
        }

        const normalizedLimit: any = {};

        if (limit && limit.current_limit) {
            normalizedLimit.activeLimit = { period: limitPeriod, ...limit.current_limit };
        }

        if (limit && limit.next_limit) {
            normalizedLimit.upcomingLimit = { period: limitPeriod, ...limit.next_limit };
        }

        normalizedLimits.push(normalizedLimit);
    });

    return normalizedLimits;
}

export function isDepositPageBlocked() {
    return Boolean(hasAllProductTimeout() || userHasGamblingRestriction());
}

export function hasAllProductTimeout() {
    const timeout = getStoreValue(stores.responsibleGaming.timeout);
    if (isFeatureAvailable(FEATURE.CASINO) && !Boolean(timeout[ResponsibleGamingProduct.CASINO])) {
        return false;
    }
    if (isFeatureAvailable(FEATURE.HORSE_RACING) && !Boolean(timeout[ResponsibleGamingProduct.HORSE_RACING])) {
        return false;
    }
    if (isFeatureAvailable(FEATURE.POKER) && !Boolean(timeout[ResponsibleGamingProduct.POKER])) {
        return false;
    }
    if (isFeatureAvailable(FEATURE.SPORTSBOOK) && !Boolean(timeout[ResponsibleGamingProduct.SPORTSBOOK])) {
        return false;
    }
    return true;
}

export function getLimitPeriodTranslation(period: LimitPeriod) {
    return {
        [LimitPeriod.DAY]: translate('24 hours', 'ui.account'),
        [LimitPeriod.WEEK]: translate('week', 'ui.account'),
        [LimitPeriod.MONTH]: translate('month', 'ui.account'),
    }[period];
}

export async function getLimits(type): Promise<UserLimit[]> {
    const limitsByPeriod = await getLimitsByType(type, getActiveCurrency());
    return getListFromLimitsDictionary(limitsByPeriod);
}

export async function logoutByStopSession() {
    return logout('stop session');
}

export function userHasGamblingRestriction() {
    const user = getStoreValue(stores.user);
    const gamblingRestrictions = [
        CLOSURE_REASON.SPELPAUS,
        CLOSURE_REASON.HAMPI,
        CLOSURE_REASON.PID_MISSING,
        CLOSURE_REASON.MINCETUR,
    ];
    const products = [PRODUCT.CASINO, PRODUCT.POKER, PRODUCT.SPORTSBOOK, PRODUCT.VIRTUAL_SPORTS].map(camelCase);

    if (!user) {
        return false;
    }

    const reasons = products.filter((product) =>
        Boolean(user[`${product}Closed`] && gamblingRestrictions.includes(user[`${product}ClosedReason`])),
    );

    return Boolean(reasons.length === products.length);
}

export function getUserProductBlockReason(product: ObjectValues<typeof PRODUCT>) {
    const user = getStoreValue(stores.user);
    if ((product === PRODUCT.CASINO || product === PRODUCT.VIRTUAL_SPORTS) && user?.casinoClosed) {
        return user?.casinoClosedReason || CLOSURE_REASON.SUPPORT;
    }
    if (product === PRODUCT.POKER && user?.pokerClosed) {
        return user?.pokerClosedReason || CLOSURE_REASON.SUPPORT;
    }
    if (product === PRODUCT.SPORTSBOOK && user?.sportsbookClosed) {
        return user?.sportsbookClosedReason || CLOSURE_REASON.SUPPORT;
    }
    const userProductToResponsibleGamingProduct = {
        [PRODUCT.CASINO]: ResponsibleGamingProduct.CASINO,
        [PRODUCT.RACEBOOK]: ResponsibleGamingProduct.HORSE_RACING,
        [PRODUCT.POKER]: ResponsibleGamingProduct.POKER,
        [PRODUCT.SPORTSBOOK]: ResponsibleGamingProduct.SPORTSBOOK,
        [PRODUCT.VIRTUAL_SPORTS]: ResponsibleGamingProduct.CASINO,
    };
    const timeout = getStoreValue(stores.responsibleGaming.timeout);
    if (timeout[userProductToResponsibleGamingProduct[product]]) {
        return CLOSURE_REASON.TIMEOUT;
    }
}
