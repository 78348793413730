import React from 'react';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import UiMultiToggle from '../../../../ui/multi-toggle/UiMultiToggle';
import { SportsTimeFormat } from '../../../../../microservices/sbgate';

interface Props {
    key: number;
}

export default function SportSettingsMenuOptionTimeFormat({ key }: Props) {
    const TIME_FORMAT_OPTIONS = [
        {
            text: '12H',
            value: SportsTimeFormat.AMERICAN,
        },
        {
            text: '24H',
            value: SportsTimeFormat.DEFAULT,
        },
    ];
    const { isAmericanTimeFormat, updateUserSettings } = useSportsUserSettings();

    function toggleTimeFormat(value) {
        updateUserSettings({
            is_american_time_format: value === SportsTimeFormat.AMERICAN,
        });
    }

    return (
        <div className="setting-item item-row" key={key}>
            <UiMultiToggle
                options={TIME_FORMAT_OPTIONS}
                selected={isAmericanTimeFormat ? SportsTimeFormat.AMERICAN : SportsTimeFormat.DEFAULT}
                onChange={toggleTimeFormat}
            />
        </div>
    );
}
