import { getServiceUrl, httpGet } from '../services/api';
import { getUserCountry } from '../services/users/country';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { logger } from '../services/logger';
import { MatchOfTheDay } from './match-of-the-day/types';

const getUrl = (url) => getServiceUrl('match-of-the-day', url);

export async function getMatchOfTheDay() {
    try {
        const language = getStoreValue(stores.language);
        const country = getUserCountry();
        const { layout } = getStoreValue(stores.sports.userSettings) || { layout: 'EUROPEAN' };
        return await httpGet<MatchOfTheDay>(getUrl('today'), {
            language,
            country,
            layout,
            isAuthenticated: getStoreValue(stores.isAuthenticated),
        });
    } catch (error) {
        logger.error('MatchOfTheDayMicroservice', 'getMatchOfTheDay', error);
    }
}
