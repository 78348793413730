import React from 'react';
import Wrapper from './styles';
import UiTabsItem from '../../../ui/tabs/item/UiTabsItem';
import { translate } from '../../../../services/translate';
import { BetslipMode } from '../../../../services/sports/types';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';
import { requestLogin } from '../../../../services/auth';
import classnames from 'classnames';

type BetslipTab = {
    id: string;
    mode?: BetslipMode;
    title: string | React.ReactNode;
    action: () => void;
    className?: string;
};
export default function SportBetslipV2Header() {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [betSlipMode, setBetSlipMode] = useStore(stores.sports.betslipMode);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const numberOfBets = cardsInBetslip.length || Object.values(betSlipMarketIdToOutcomeId).length;

    const tabs: BetslipTab[] = [
        {
            id: 'betslip',
            mode: BetslipMode.Betslip,
            title: (
                <div className="betslip-title">
                    <span>{translate('Betslip', 'ui.sportsbook')}</span>
                    <span className="bets-count">{numberOfBets > 0 ? <span>{numberOfBets}</span> : ''}</span>
                </div>
            ),
            action: () => {
                setBetSlipMode(BetslipMode.Betslip);
            },
        },
        {
            id: 'my-bets',
            title: translate('My bets', 'ui.sportsbook'),
            mode: BetslipMode.History,
            action: () => {
                if (!isAuthenticated) {
                    requestLogin();
                    return;
                }
                setBetSlipMode(BetslipMode.History);
            },
        },
    ];

    return (
        <Wrapper>
            {tabs.map(({ id, mode, title, className, action }) => (
                <UiTabsItem
                    className={classnames(className, { active: mode === betSlipMode })}
                    onClick={action}
                    selected={mode === betSlipMode}
                    key={id}
                >
                    {title}
                </UiTabsItem>
            ))}
        </Wrapper>
    );
}
