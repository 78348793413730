import React, { useEffect, useState } from 'react';
import { useGlobalModal } from '../../../../hooks/useGlobalModal';
import { useStore } from '../../../../hooks/useStore';
import { getPaymentProviders } from '../../../../microservices/payments';
import { isB2B } from '../../../../services/environment';
import { isFeatureAvailable } from '../../../../services/feature';
import { logger } from '../../../../services/logger';
import { PaymentProvider, PROVIDER_TYPE } from '../../../../services/payments/types';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { FEATURE } from '../../../../services/types';
import { isTestUser } from '../../../../services/user';
import { stores } from '../../../../stores';
import { media } from '../../../../stores/media/media';
import CrmInboxIcon from '../../../crm/inbox-icon/CrmInboxIcon';
import LoyaltyMeter from '../../../loyalty-meter/LoyaltyMeter';
import PaymentQuickDeposit from '../../../payment/quick-deposit/PaymentQuickDeposit';
import Svg from '../../../svg/Svg';
import UiButton from '../../../ui/button/UiButton';
import CoolbetHeaderAuthenticatedClock from './clock/CoolbetHeaderAuthenticatedClock';
import CoolbetHeaderAuthenticatedMenu from './menu/CoolbetHeaderAuthenticatedMenu';
import Wrapper from './styles';

export default function CoolbetHeaderAuthenticated() {
    const [{ isPhone }] = useStore(media);
    const [dynamicPromotionsCount] = useStore(stores.dynamicPromotionsCount);
    const [isLoadingProviders, setIsLoadingProviders] = useState(false);
    const [quickDepositProviders, setQuickDepositProviders] = useState<PaymentProvider[]>([]);
    const { navigateTo } = useRouter();
    const { showModal, hideModal } = useGlobalModal();

    useEffect(() => {
        getQuickDepositProviders();
    }, []);

    async function getQuickDepositProviders() {
        if (!isFeatureAvailable(FEATURE.QUICK_DEPOSIT) || !isTestUser()) {
            return;
        }
        try {
            setIsLoadingProviders(true);
            const { providers } = await getPaymentProviders({ type: PROVIDER_TYPE.DEPOSIT });
            setQuickDepositProviders(providers.filter((provider) => provider.isQuickDepositEnabled));
        } catch (error) {
            logger.error('CoolbetHeaderAuthenticated', 'getQuickDepositProviders', error);
        }
        setIsLoadingProviders(false);
    }

    function handleDepositClick() {
        if (quickDepositProviders.length > 0) {
            showModal(PaymentQuickDeposit, {
                params: {
                    providers: quickDepositProviders,
                    onClose: hideModal,
                },
                onClose: hideModal,
                minWidth: isPhone ? 300 : 500,
            });
        } else {
            navigateTo(getRoute('deposit'));
        }
    }

    return (
        <Wrapper>
            {!isB2B() && <CoolbetHeaderAuthenticatedClock />}

            {isFeatureAvailable(FEATURE.INBOX) && <CrmInboxIcon />}

            {!isB2B() && isPhone && (
                <UiButton
                    size="small"
                    className="header-button"
                    url={getRoute('promotions.list')}
                    badge={dynamicPromotionsCount}
                    icon={<Svg icon="bonus-promotions" />}
                />
            )}

            {isFeatureAvailable(FEATURE.LOYALTY_PROGRAM) && <LoyaltyMeter />}

            <CoolbetHeaderAuthenticatedMenu />

            {isFeatureAvailable(FEATURE.DEPOSIT) && (
                <UiButton
                    color="primary"
                    size="small"
                    isLoading={isLoadingProviders}
                    selected={false}
                    onClick={handleDepositClick}
                    icon={<Svg icon="plus-sign" className="icon" size={1.125} />}
                >
                    {!isPhone && translate('Deposit', 'ui.account')}
                </UiButton>
            )}

            {isFeatureAvailable(FEATURE.PAYMENT_PACKAGES) && (
                <UiButton
                    color="primary"
                    size="small"
                    selected={false}
                    url={getRoute('purchase-packages')}
                    icon={<Svg icon="plus-sign" className="icon" size={1.125} />}
                >
                    {!isPhone && translate('Purchase packages', 'ui.payment-packages')}
                </UiButton>
            )}
        </Wrapper>
    );
}
