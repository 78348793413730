import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    visibility: hidden;
    width: 100%;
    @media (hover: hover) {
        &:hover,
        &:focus {
            visibility: visible;
            transition: visibility 0.2s;
        }
    }
    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        visibility: unset;
    }

    .tabs {
        display: flex;
        align-items: center;
        width: 100%;

        border-bottom: var(--tabs-line);
        visibility: visible;
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            visibility: unset;
            width: max-content;
        }
    }
`;
