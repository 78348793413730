import styled from 'styled-components';

export default styled.div`
    #truelayer-container-id {
        margin: calc(-1 * var(--spacing-24));
        height: 600px;
    }
    .account-select {
        max-height: 45vh;
        overflow: auto;
    }
`;
