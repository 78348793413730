import React, { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { getFilteredPaymentTransactions, getProviderPaymentMethods } from '../../../../../microservices/payments';
import { logger } from '../../../../../services/logger';
import {
    ExistingPaymentMethod,
    PROVIDERS,
    PROVIDER_TYPE,
    TransactionState,
    TransactionType,
} from '../../../../../services/payments/types';
import { getRoute, useRouter } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import { stores } from '../../../../../stores';
import round from 'lodash/round';
import Snippet from '../../../../snippet/Snippet';
import Ui2FormToggle from '../../../../ui-2/form/toggle/Ui2FormToggle';
import Ui2FormTextInput from '../../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2Form from '../../../../ui-2/form/Ui2Form';
import UiAlert from '../../../../ui/alert/UiAlert';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';
import { depositWithProvider } from '../../../../../services/payments/payments';
import UiButton from '../../../../ui/button/UiButton';
import { NativeMessageEventType, sendNativeEvent } from '../../../../../services/mobile-app';
import { formattedAmountWithCurrency } from '../../../../../services/currency';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
}

export default function PaymentDepositProviderSightline({ amount, deviceHash, disclaimer }: Props) {
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDepositing, setIsDepositing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState<ExistingPaymentMethod>();
    const useFormMethods = useForm();
    const watchIsUsingExistingBalance = useFormMethods.watch('isUsingExistingBalance');
    const { errors } = useFormMethods.formState;

    const [user] = useStore(stores.user);

    useEffect(() => {
        loadAccount();
    }, []);

    async function loadAccount() {
        try {
            const [method] = await getProviderPaymentMethods(PROVIDERS.SIGHTLINE, PROVIDER_TYPE.DEPOSIT);
            if (method?.extra?.balance) {
                setPaymentMethod(method);
            } else if (!disclaimer) {
                await deposit();
                return;
            }
        } catch (error) {
            logger.error('PaymentDepositProviderSightline', 'loadAccount', error);
        }
        setIsLoading(false);
    }

    async function deposit() {
        setErrorMessage('');
        setIsDepositing(true);
        try {
            const { isUsingExistingBalance, ssn } = useFormMethods.getValues();
            const depositAmount = getDepositAmount(isUsingExistingBalance);
            const methodId =
                isUsingExistingBalance && amount <= paymentMethod?.extra?.balance ? paymentMethod?.id : undefined;

            const depositResponse = await depositWithProvider({
                amount: depositAmount,
                deviceHash,
                methodId: methodId,
                provider: PROVIDERS.SIGHTLINE,
                providerParams: { ssn },
            });
            const userTransactions = await getFilteredPaymentTransactions({
                where: {
                    type: TransactionType.DEPOSIT,
                },
                limit: 2,
            });
            if (userTransactions[0].state === TransactionState.COMPLETED) {
                sendNativeEvent({
                    type: NativeMessageEventType.DEPOSIT,
                    paymentMethod: PROVIDERS.SIGHTLINE,
                    playerId: user?.id,
                    amount: userTransactions[0].amountUc,
                    currency: userTransactions[0].currency,
                    returningDeposit: userTransactions.length > 1,
                });
            }
            setIsLoading(true);
            if (depositResponse.url) {
                window.location.href = depositResponse.url;
            } else {
                navigateTo(`${getRoute('transactions')}`);
            }
        } catch (error: any) {
            if (error.field === 'ssn') {
                setErrorMessage(error.message);
            } else {
                logger.error('PaymentDepositProviderSightline', 'deposit', error);
                setIsError(true);
            }
        }
        setIsDepositing(false);
    }

    // TODO: check if this can be removed
    function getDepositAmount(isUsingExistingBalance: boolean) {
        if (!isUsingExistingBalance || amount <= paymentMethod?.extra?.balance) {
            return amount;
        }
        return round(amount - paymentMethod?.extra?.balance, 2);
    }

    // TODO: check if this can be removed
    function getAmountToFund() {
        const { isUsingExistingBalance } = useFormMethods.getValues();
        if (!isUsingExistingBalance) {
            return round(amount, 2);
        }
        return round(Math.max(amount - paymentMethod?.extra?.balance, 0), 2);
    }

    if (isError) {
        return <PaymentProviderNotAvailable />;
    }
    if (isLoading) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper>
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            <div className="description">
                {translate('Play+ balance', 'ui.payments')}:{' '}
                <em>{formattedAmountWithCurrency(paymentMethod?.extra?.balance)}</em>
            </div>
            <div className="description">
                {translate('Amount to fund', 'ui.payments')}: <em>{formattedAmountWithCurrency(getAmountToFund())}</em>
            </div>
            <Ui2Form onSubmit={deposit} useFormMethods={useFormMethods}>
                <Ui2FormToggle
                    disabled={isDepositing}
                    className="toggle"
                    name="isUsingExistingBalance"
                    label="Use existing balance"
                />
                {watchIsUsingExistingBalance && getAmountToFund() === 0 && (
                    <Ui2FormTextInput
                        className="ssn-input"
                        disabled={isDepositing}
                        error={errors.ssn as FieldError}
                        name="ssn"
                        label={translate('Please enter the last four digits of your SSN', 'ui.payments')}
                        minLength={4}
                        maxLength={4}
                        type="text"
                    />
                )}
                {errorMessage && <UiAlert failure>{translate(errorMessage, 'ui.payments')}</UiAlert>}
                <UiButton
                    type="button"
                    block={isPhone}
                    color="primary"
                    isLoading={isDepositing}
                    disabled={isDepositing}
                    isFormSubmitButton
                >
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </Ui2Form>
        </Wrapper>
    );
}
