import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getProviderPaymentMethods } from '../../../../../../microservices/payments';
import { getFilteredTransactions } from '../../../../../../microservices/wallet';
import { logger } from '../../../../../../services/logger';
import { quickSignup } from '../../../../../../services/muchbetter';
import { depositWithProvider, getDepositReturnRoute } from '../../../../../../services/payments/payments';
import {
    DepositStatus,
    ExistingPaymentMethod,
    PROVIDERS,
    PROVIDER_TYPE,
} from '../../../../../../services/payments/types';
import { useRouter } from '../../../../../../services/router';
import { translate } from '../../../../../../services/translate';
import { getPhoneNumber } from '../../../../../../services/user';
import { getFieldValidators } from '../../../../../../services/validators';
import Snippet from '../../../../../snippet/Snippet';
import Ui2Form from '../../../../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../../../../ui-2/form/text-input/Ui2FormTextInput';
import UiAlert from '../../../../../ui/alert/UiAlert';
import UiButton from '../../../../../ui/button/UiButton';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import UiFormGroup from '../../../../../ui/form/group/UiFormGroup';
import UiFormLabel from '../../../../../ui/form/label/UiFormLabel';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';

interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderDevcodeMuchBetter({ amount, deviceHash, onClose }: Props) {
    const TRACKING_CODE = 'UG85MTgwMTM';
    const { navigateTo } = useRouter();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isInProgress, setIsInProgress] = useState(false);
    const [hasUsedBefore, setHasUsedBefore] = useState(false);
    const [account, setAccount] = useState<ExistingPaymentMethod>();
    const [isDepositing, setIsDepositing] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState('');

    const useFormMethods = useForm({
        defaultValues: {
            number: getPhoneNumber(),
        },
    });
    const { formState } = useFormMethods;

    useEffect(() => {
        initDevcodeDeposit();
    }, []);

    async function initDevcodeDeposit() {
        try {
            const [account] = await getProviderPaymentMethods(PROVIDERS.DEVCODE_MUCHBETTER, PROVIDER_TYPE.DEPOSIT);
            if (account && isPhoneNumberValid(account.number)) {
                setAccount(account);
                useFormMethods.setValue('number', `+${account.number}`);
                setHasUsedBefore(true);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderDevcodeMuchBetter', 'initDevcodeDeposit', error);
            setIsError(true);
        }

        setIsLoading(false);
    }

    async function deposit() {
        setIsDepositing(true);
        setPhoneNumberError('');
        try {
            await depositWithProvider({
                amount,
                deviceHash,
                methodId: account?.id,
                provider: PROVIDERS.DEVCODE_MUCHBETTER,
                providerParams: useFormMethods.getValues(),
            });
            setIsInProgress(true);
            pollDeposit({
                created__gt: moment().subtract(1, 'm').toISOString(),
                type: 'DEVCODE_MUCHBETTER_DEPOSIT',
            });
        } catch (error: any) {
            if (error.field === 'number') {
                setIsDepositing(false);
                setPhoneNumberError(error.message);
            } else {
                navigateTo(getDepositReturnRoute(DepositStatus.FAILED));
            }
        }
    }

    async function pollDeposit(filters) {
        try {
            const transactions = await getFilteredTransactions(filters);
            if (
                transactions.data.length &&
                transactions.data[0].description === useFormMethods.getValues().number.substring(1)
            ) {
                navigateTo(getDepositReturnRoute(DepositStatus.COMPLETED));
                return;
            }
        } catch (error) {
            logger.error('PaymentDepositProviderDevcodeMuchBetter', 'pollDeposit', error);
        }

        setTimeout(() => {
            pollDeposit(filters);
        }, 3000);
    }

    function isPhoneNumberValid(number: string) {
        return getPhoneNumber() === `+${number}`;
    }

    if (isError) {
        return <PaymentProviderNotAvailable />;
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    if (isInProgress) {
        return (
            <Wrapper>
                <UiAlert success>
                    <Snippet snippetKey="payments.deposit.much-better-inprogress-text" />
                </UiAlert>
                {!hasUsedBefore && (
                    <UiButton color="primary" block onClick={() => quickSignup(TRACKING_CODE)}>
                        {translate('Quick Signup', 'ui.payments')}
                    </UiButton>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {!hasUsedBefore && <Snippet snippetKey="payments.deposit.much-better-info-text" />}
            <Ui2Form onSubmit={deposit} useFormMethods={useFormMethods}>
                <br />
                <UiFormGroup>
                    <UiFormLabel>{translate('Mobile phone', 'ui.registration')}</UiFormLabel>
                    <Ui2FormTextInput
                        name="number"
                        validator={getFieldValidators(['mobilePhone'])}
                        error={formState.errors.number}
                    />
                    {phoneNumberError && <UiAlert failure>{phoneNumberError}</UiAlert>}
                </UiFormGroup>
                <br />

                <div className="form-actions">
                    {!hasUsedBefore ? (
                        <UiButton color="primary" block onClick={() => quickSignup(TRACKING_CODE)}>
                            {translate('Quick Signup', 'ui.payments')}
                        </UiButton>
                    ) : (
                        <UiButton block onClick={onClose}>
                            {translate('Back', 'ui.common')}
                        </UiButton>
                    )}
                    <UiButton type="button" color="primary" block isFormSubmitButton isLoading={isDepositing}>
                        {translate('Continue', 'ui.account')}
                    </UiButton>
                </div>
            </Ui2Form>
        </Wrapper>
    );
}
