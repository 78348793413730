import React from 'react';
import { GeoComplyMobileClient, GeoComplyMobileHint } from '../../../../services/geocomply/types';
import UiGroup from '../../../ui/group/UiGroup';
import GeocomplyMobileHintHandlerAppNotOpened from './handler/app-not-opened/GeocomplyMobileHintHandlerAppNotOpened';
import GeocomplyMobileHintHandlerAppRequiredLocationPermission from './handler/app-required-location-permission/GeocomplyMobileHintHandlerAppRequiredLocationPermission';
import GeocomplyMobileHintHandlerAppRequiredNotificationPermission from './handler/app-required-notification-permission/GeocomplyMobileHintHandlerAppRequiredNotificationPermission';
import GeocomplyMobileHintHandlerAppRequired from './handler/app-required/GeocomplyMobileHintHandlerAppRequired';
import GeocomplyMobileHintHandlerTrueLocationBrowser from './handler/true-location-browser/GeocomplyMobileHintHandlerTrueLocationBrowser';
import Wrapper from './styles';

interface Props {
    hint: GeoComplyMobileHint;
    onDismiss: () => void;
}

export default function GeocomplyMobileHint({ hint, onDismiss }: Props) {
    if (!hint) {
        return null;
    }

    const GeoComplyMobileClient = (window as any).CoolbetGeoComplyMobileClient as GeoComplyMobileClient;

    return (
        <Wrapper>
            <UiGroup vertical>
                <div className="hint">{hint.message} </div>

                {hint.reason === GeoComplyMobileClient.HINTS.APP_NOT_OPENED.reason && (
                    <GeocomplyMobileHintHandlerAppNotOpened hint={hint} />
                )}

                {hint.reason === GeoComplyMobileClient.HINTS.TRUE_LOCATION_BROWSER.reason && (
                    <GeocomplyMobileHintHandlerTrueLocationBrowser hint={hint} onDismiss={onDismiss} />
                )}

                {hint.reason === GeoComplyMobileClient.HINTS.APP_REQUIRED.reason && (
                    <GeocomplyMobileHintHandlerAppRequired hint={hint} />
                )}

                {hint.reason === GeoComplyMobileClient.HINTS.APP_REQUIRED_LOCATION_PERMISSION.reason && (
                    <GeocomplyMobileHintHandlerAppRequiredLocationPermission hint={hint} />
                )}

                {hint.reason === GeoComplyMobileClient.HINTS.APP_REQUIRED_NOTIFICATION_PERMISSION.reason && (
                    <GeocomplyMobileHintHandlerAppRequiredNotificationPermission hint={hint} />
                )}
            </UiGroup>
        </Wrapper>
    );
}
