import fromPairs from 'lodash/fromPairs';
import invert from 'lodash/invert';
import React, { useEffect } from 'react';

import { removeAllNonFrontendErrors } from '../../../../services/sports/betslip-errors';
import {
    bonusLabelByType,
    getActiveEligibleBonus,
    getBetslipEligibleBonusesForBonusType,
} from '../../../../services/sports/bonus-bets';
import { initialBetSlipPlacingState } from '../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../services/sports/manual-acceptance-helpers';
import { stores } from '../../../../stores';
import { getStoreValue } from '../../../../stores/store/utils';
import SportBonusBetFreeBetsDesignedLayout from './designed-layout/SportBonusBetFreeBetsDesignedLayout';
import SportBonusBetFreeBetsDefaultLayout from './default-layout/SportBonusBetFreeBetsDefaultLayout';
import { isFeatureAvailable } from '../../../../services/feature';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import { BonusBet, BonusType } from '../../../../services/bonuses/types';
import { ObjectValues } from '../../../../services/ts-utils';

export default function SportBonusBetFreeBets() {
    const [bonusBets] = useStore(stores.sports.bonusBets);
    const [bonusBetsSelection, setBonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const [activeBonusId, setActiveBonusId] = useStore(stores.sports.selectedBonusId);
    const [activeUserBonusId, setActiveUserBonusId] = useStore(stores.sports.selectedUserBonusId);

    useEffect(setFixedStakeIfBonusSelectedAndEligible, [
        bonusBets,
        bonusBetsSelection,
        betSlipMarketIdToOutcomeId,
        marketInfoById,
    ]);

    useEffect(clearBetSlipErrorOnUnselectBonus, [bonusBetsSelection]);

    const shouldDisableInputByBonusBetType = { [BonusType.FreeBet]: true, [BonusType.FreeBetV2]: true };

    function setFixedStakeIfBonusSelectedAndEligible() {
        const { disableInputs } = getStoreValue(stores.sports.betSlipUserState);
        const activeBonus = getActiveEligibleBonus();
        const activeType = invert(bonusBetsSelection).true;
        const hasActiveBonus = Boolean(activeBonus);
        const disableInputs1 = shouldDisableInputByBonusBetType[activeType] && hasActiveBonus;
        if (disableInputs1 !== Boolean(disableInputs)) {
            stores.sports.betSlipUserState.set((state) => ({
                ...state,
                disableInputs: disableInputs1,
            }));
        }
        if (!activeBonus || hasBetslipManualAcceptanceError()) {
            return;
        }
        const freeBetFixedAmount = activeBonus.amount;

        if (!freeBetFixedAmount) {
            return;
        }

        const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
        const betSlipMarketIds = Object.keys(betSlipMarketIdToOutcomeId);
        stores.sports.betSlipUserState.set((state) => {
            state.stakeByMarketId = fromPairs(
                [null, ...betSlipMarketIds].map((marketId) => [marketId, freeBetFixedAmount]),
            );
        });
    }

    function toggleSelected(bonus: BonusBet) {
        if (activeBonusId !== bonus.id || bonus.userBonusId !== activeUserBonusId) {
            setActiveBonusId(bonus.id);
            setActiveUserBonusId(bonus.userBonusId ?? '');
            setBonusBetsSelection({ [bonus.bonusType as string]: true });
        } else {
            setActiveBonusId('');
            setActiveUserBonusId('');
            setBonusBetsSelection({ [bonus.bonusType as string]: false });
        }
    }

    function clearBetSlipErrorOnUnselectBonus() {
        removeAllNonFrontendErrors();
        stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
    }

    const bonuses = (Object.keys(bonusLabelByType) as ObjectValues<typeof BonusType>[])
        .map(getBetslipEligibleBonusesForBonusType)
        .flat();

    return (
        <>
            {bonuses.map((bonus) => (
                <>
                    {isLayoutB ? (
                        <SportBonusBetFreeBetsDesignedLayout
                            bonus={bonus}
                            key={bonus.userBonusId ?? bonus.id}
                            onBonusToggle={() => toggleSelected(bonus)}
                        />
                    ) : (
                        <SportBonusBetFreeBetsDefaultLayout
                            bonus={bonus}
                            key={bonus.id}
                            onBonusToggle={() => toggleSelected(bonus)}
                        />
                    )}
                </>
            ))}
        </>
    );
}
