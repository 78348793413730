import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import { casino } from '../../stores/casino';
import { getStoreValue } from '../../stores/store/utils';
import { isTestUser } from '../user';
import { CasinoGame, CasinoProvider, CasinoProviders, CasinoSubProvider } from './types';

export const CasinoProviderIntegrationNames = {
    [CasinoProviders.PLAYGO]: 'playgo',
    [CasinoProviders.YGGDRASIL]: 'yggdrasil',
    [CasinoProviders.MICROGAMING]: 'microgaming',
    [CasinoProviders.EVOLUTION]: 'evolution',
    // [CasinoProviders.NETENT_LIVE]: 'netent',
    // [CasinoProviders.NETENT]: 'netent',
    [CasinoProviders.HUB88]: 'hub88',
    [CasinoProviders.ELK_STUDIOS]: 'elk',
    [CasinoProviders.PRAGMATIC_PLAY]: 'pragmaticplay',
    [CasinoProviders.ISOFTBET]: 'isoftbet',
    [CasinoProviders.RELAXGAMING]: 'relaxgaming',
    [CasinoProviders.PUSHGAMING]: 'pushgaming',
    [CasinoProviders.NOLIMIT_CITY]: 'nolimitcity',
    [CasinoProviders.QUICKSPIN]: 'quickspin',
    [CasinoProviders.SPINOMENAL]: 'spinomenal',
    [CasinoProviders.HACKSAW]: 'hacksaw',
    [CasinoProviders.SUPERRGS]: 'superrgs',
    [CasinoProviders.STAKELOGIC]: 'stakelogic',
    [CasinoProviders.LIGHTNWONDER]: 'lightnwonder',
    [CasinoProviders.PLAYTECH]: 'playtech',
};

export const CasinoSubproviderIntegrationNames = {
    [CasinoSubProvider.AUTHENTIC_GAMING]: 'authentic-gaming',
    [CasinoSubProvider.BOMBAY_LIVE]: 'live-88',
    [CasinoSubProvider.PRAGMATIC_PLAY_LIVE]: 'pragmatic-play',
    [CasinoSubProvider.EVOLUTION]: 'evolution',
    [CasinoSubProvider.PLAYTECH_LIVE]: 'playtech-live',
    [CasinoSubProvider.STAKELOGIC_LIVE]: 'stakelogic-live',
    [CasinoSubProvider.ON_AIR]: 'on-air-lobby',
};

export const ONAIR_ENTERTAINMENT_ORIGIN_STAGE = 'https://uat1.onairent.com';
export const ONAIR_ENTERTAINMENT_ORIGIN_PROD = 'https://live.onairent.com';

export const ONAIR_ENTERTAINMENT_EVENTS = {
    LAUNCH_GAME: 'oac_launch_game',
};

export const casinoProviderDisplaySettings = {
    minGames: 45,
};

export function mapCasinoProviders(): void {
    let providers = cloneDeep(getStoreValue<CasinoProvider[]>(casino.providers));
    const games = getStoreValue<CasinoGame[]>(casino.filteredGames).filter((game) => game.isPublic || isTestUser());
    // TODO: dont loop all games 30 times. It takes only 10ms all together, but still it's a lot.
    providers.forEach((provider) => {
        let totalGames = 0;
        games.forEach((game) => {
            if (provider.type === 'provider' && !game.subProviderId && game.providerId === provider.id) {
                totalGames += 1;
            } else if (provider.type === 'subProvider' && game.subProviderId === provider.id) {
                totalGames += 1;
            }
        });
        provider.totalGames = totalGames;
    });
    providers = providers.filter((provider) => provider.totalGames > 0);
    casino.providers.set(providers);
    casino.providersById.set(
        keyBy(
            providers.filter((provider) => provider.type === 'provider'),
            'id',
        ),
    );
    casino.subProvidersById.set(
        keyBy(
            providers.filter((provider) => provider.type === 'subProvider'),
            'id',
        ),
    );
    casino.providersBySlug.set(keyBy(providers, (provider) => getCasinoProviderSlug(provider.name)));
}

export function getCasinoProviderSlug(name: string): string {
    return name
        .toLowerCase()
        .replace(/#|_|:|\.|;|,|!|^|\*|'|`|’/g, '')
        .split(' ')
        .join('-');
}
