import React from 'react';
import Wrapper from './styles';
import { ContestLegMarket, ContestMarketOutcome } from '../../../../../types/components/contests/types';
import UiCloseButton from '../../../../ui/close-button/UiCloseButton';
import {
    formatMarketMatchNameForTicket,
    formatMarketName,
    getLineByResultKey,
    getTeamNameByOutcomeResultKey,
} from '../../../../../services/contests';

interface Props {
    selection: {
        market: ContestLegMarket;
        selectedOutcome: ContestMarketOutcome;
    };
    onRemove: () => void;
}
export default function SportContestsBetslipSelection({ selection, onRemove }: Props) {
    return (
        <Wrapper>
            <UiCloseButton size={30} className="selection-remove" onClick={onRemove} />
            <div className="selection">
                <div className="selection-number">{+selection.selectedOutcome.position + 1}</div>
                <div className="selection-description">
                    <div>
                        {`${getTeamNameByOutcomeResultKey(
                            selection.selectedOutcome.result_key,
                            selection.market,
                        )} ${getLineByResultKey(selection.selectedOutcome.result_key, selection.market.line)}`}
                    </div>
                    <span>{formatMarketName(selection.market.market_name)}</span>
                    <span>{formatMarketMatchNameForTicket(selection.market.match_name)}</span>
                </div>
            </div>
        </Wrapper>
    );
}
