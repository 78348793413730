import styled, { css } from 'styled-components';
import { MATCH_STATUS, MATCH_TYPE } from '../../../services/sports/constants';
import { TICKET_TYPE } from '../../../services/bet-history';
import { BET_TYPE } from '../../../services/sports/types';

type StyledProps = {
    type?: string;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 1.125rem;
    border-radius: 100px;
    font-size: 0.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;

    padding: 1px 0.4rem 0;
    border: 1px solid;

    ${({ type }) =>
        type === (BET_TYPE.SINGLE || TICKET_TYPE.SINGLE) &&
        css`
            color: var(--ticket-badge-single-text-color);
            border: var(--ticket-badge-single-border);
            background-color: var(--ticket-badge-single-bg);
        `}
    ${({ type }) =>
        type === (BET_TYPE.COMBO || TICKET_TYPE.COMBO) &&
        css`
            border: var(--ticket-badge-combo-border);
            color: var(--ticket-badge-combo-text-color);
            background-color: var(--ticket-badge-combo-bg);
        `}
    ${({ type }) =>
        type === TICKET_TYPE.SYSTEM &&
        css`
            border: var(--ticket-badge-system-border);
            color: var(--ticket-badge-system-text-color);
            background-color: var(--ticket-badge-system-bg);
        `}
    ${({ type }) =>
        type === MATCH_TYPE.LIVEBET &&
        css`
            border: var(--ticket-badge-livebet-border);
            color: var(--ticket-badge-livebet-text-color);
            background-color: var(--ticket-badge-livebet-bg);
        `}
    ${({ type }) =>
        type === (MATCH_STATUS.LIVE || TICKET_TYPE.LIVE) &&
        css`
            ::before {
                content: '';
                background-color: var(--ticket-badge-live-text-color);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                margin-right: 4px;
                margin-bottom: 1px;
            }
            border: var(--ticket-badge-live-border);
            color: var(--ticket-badge-live-text-color);
            background-color: var(--ticket-badge-live-bg);
        `}
`;
