export type GlobalpayMethod = {
    accountNumber: string;
    bankName: string;
    methodId?: string;
    routingNumber: string;
};

export enum GlobalpayActions {
    ADD_BANK = 'addBank',
    DELETE_BANK = 'deleteBank',
    ENROLLMENT = 'enrollment',
    GET_LINK_TOKEN = 'getLinkToken',
    LIMIT_CHANGE = 'limitChange',
}
