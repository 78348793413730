import React from 'react';
import Svg from '../../../../svg/Svg';
import { translate } from '../../../../../services/translate';
import UiDottedArrow from '../../../../ui/dotted-arrow/UiDottedArrow';
import Wrapper from '../styles';

export default function SportBetslipUiEmpty() {
    return (
        <Wrapper>
            <div className="pick-bets">
                <div className="pick-bets-ico animated bounceInDown">
                    <Svg icon="betslip" size={2.6} />
                </div>
                <div className="pick-bets-text animated fadeIn">{translate('Pick your bets', 'ui.sportsbook')}</div>
            </div>
            <div className="pick-bets-arrows">
                <UiDottedArrow size={8} rotate={180} />
                <UiDottedArrow size={8} rotate={180} />
                <UiDottedArrow size={8} rotate={180} />
            </div>
        </Wrapper>
    );
}
