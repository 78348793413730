import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';

type StyledProps = {
    $isActive: boolean;
};

const isActive = (props: StyledProps) =>
    props.$isActive &&
    css`
        opacity: 1;
    `;

export default styled.div<StyledProps>`
    min-height: 114px;
    min-width: 180px;
    max-width: 180px;
    border-radius: var(--ui-radius);
    overflow: hidden;
    box-shadow: var(--ui-shadow);
    background-image: linear-gradient(345deg, ${darken(0.3, '#00bfff')}, ${lighten(0.15, '#00bfff')});
    margin-right: 1.5rem;
    opacity: 0.5;
    transition: 200ms transform ease-out, 200ms opacity ease-out;
    cursor: pointer;
    position: relative;
    outline: none;
    ${isActive}
    &:hover {
        opacity: 1;
    }
    .method-icon {
        display: flex;
        padding: 1rem 1rem 0.5rem;
        justify-content: center;
        img {
            min-height: 32px;
            max-height: 32px;
        }
    }
    .method-info {
        color: var(--font-color-primary);
        display: flex;
        flex-direction: column;
        padding-top: 0.3rem;
        > div {
            padding: 0.15rem 0;
            text-align: center;
            &.expiration-date {
                color: var(--font-color-secondary);
                font-size: 0.75rem;
            }
        }
    }
`;
