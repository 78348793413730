import React from 'react';
import Wrapper from './styles';
import { PROVIDERS, PaymentProvider } from '../../../services/payments/types';
import { getActiveCurrency } from '../../../services/currency';
import { translate } from '../../../services/translate';
import { formattedAmountWithCurrency } from '../../../services/currency';
import Svg from '../../svg/Svg';
import { useStore } from '../../../hooks/useStore';
import { stores } from '../../../stores';
import { EVerifiedStatus } from '../../../services/kyc/types';

interface Props {
    selectedProvider: PaymentProvider;
}

export default function PaymentProviderInfo({ selectedProvider }: Props) {
    const [user] = useStore(stores.user);
    const { minAmount, maxAmount, time, feePercent, isFeeRequired, slug } = selectedProvider;
    const isShowingEVerifyInfo =
        [PROVIDERS.TRUSTLY, PROVIDERS.CLEO].includes(slug) && user?.eVerified === EVerifiedStatus.NOT_CHECKED;
    const currency = getActiveCurrency();

    return (
        <Wrapper>
            <div className="payment-method-info">
                <div className="info-box">
                    <div className="title">{translate('Min', 'ui.payments')}:</div>
                    <div className="content">{formattedAmountWithCurrency(minAmount[currency])}</div>
                </div>
                <div className="info-box">
                    <div className="title">{translate('Max', 'ui.payments')}:</div>
                    <div className="content">{formattedAmountWithCurrency(maxAmount[currency])}</div>
                </div>
                <div className="info-box">
                    <div className="title">{translate('Time', 'ui.account')}:</div>
                    <div className="content">{translate(time, 'ui.account')}</div>
                </div>
                {isFeeRequired && (
                    <div className="info-box">
                        <div className="title">{translate('Fee', 'ui.account')}:</div>
                        <div className="content">{`${feePercent}%`}</div>
                    </div>
                )}
            </div>
            {isShowingEVerifyInfo && (
                <div className="payment-method-info e-verification">
                    <div className="info-box">
                        <Svg icon="info" size={1} />
                        {translate('Effortless verification method', 'ui.account')}
                    </div>
                </div>
            )}
        </Wrapper>
    );
}
