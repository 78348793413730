import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
    getErrorMessage,
    getMarketErrorStatusByErrorsArray,
    isInfo,
    isWarning,
} from '../../../../services/sports/betslip';
import { stores, useStoreWithSelector } from '../../../../stores';
import UiAlert from '../../../ui/alert/UiAlert';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { useStore } from '../../../../hooks/useStore';
import { getLine } from '../../../../services/sports/market';
import SportOddsLoad from '../../odds/load/SportOddsLoad';
import { isMobile } from '../../../../services/browser';
import { removeAllNonFrontendErrors } from '../../../../services/sports/betslip-errors';
import { getBetbuilderBetPrice } from '../../../../microservices/openbet';
import { getMarketInfoByOutcomeId } from '../../../../microservices/sbgate';
import { logger } from '../../../../services/logger';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

interface Props {
    matchId: string;
}

export default function SportBetslipBetbuilderMarket({ matchId }: Props) {
    const customBetbuilderStore = stores.sports.customBetbuilder;
    const betSlipMarketIdToOutcomeIdStore = stores.sports.betSlipMarketIdToOutcomeId;

    const [, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);

    const [marketIds] = useStoreWithSelector(
        customBetbuilderStore.betslipMarketIdsByMatchId,
        (state) => state[matchId],
    );

    const [isMarketRemovedRedundant, setIsMarketRemovedRedundant] = useState(false);
    const [betbuilderRedundantMarketIdsByMatchId, setBetbuilderRedundantMarketIdsByMatchId] = useStore(
        customBetbuilderStore.betbuilderRedundantMarketIdsByMatchId,
    );
    const [comboBetMarketIdByMatchId, setComboBetMarketIdByMatchId] = useStore(
        customBetbuilderStore.comboBetMarketIdByMatchId,
    );

    const [marketInfoById, setMarketInfoById] = useStoreWithSelector(
        stores.sports.marketInfoById,
        (state) => pick(state, marketIds),
        [marketIds],
    );
    const [, setBetbuilderMarketInfoById] = useStore(customBetbuilderStore.betbuilderMarketInfoById);

    const [betSlipMarketIdToOutcomeId, setBetSlipMarketIdToOutcomeId] = useStore(betSlipMarketIdToOutcomeIdStore);
    const [betSlipMarketIdToOutcomeIdForMatch] = useStoreWithSelector(
        betSlipMarketIdToOutcomeIdStore,
        (state) => pick(state, marketIds),
        [marketIds],
    );
    const betslipOutcomeIdsForMatch = Object.values(betSlipMarketIdToOutcomeIdForMatch).join();

    const [, setBetbuilderBetSlipMarketIdToOutcomeId] = useStore(
        customBetbuilderStore.betbuilderBetSlipMarketIdToOutcomeId,
    );

    const [oddsMarketInfo, setOddsMarketInfo] = useState<BetSlipMinimalMarket>();
    const [oddsOutcomeId, setOddsOutcomeId] = useState<number>();
    const [, setBetbuilderMarketOddsByMarketId] = useStore(customBetbuilderStore.betbuilderMarketOddsByMarketId);

    const [betSlipErrorByMarketId] = useStoreWithSelector(
        stores.sports.betSlipErrorByMarketId,
        (state) => pick(state, marketIds),
        [marketIds],
    );
    const [betbuilderErrors] = useStoreWithSelector(
        customBetbuilderStore.betbuilderErrorsByMatchId,
        (state) => state[matchId],
    );
    const currentMarketBetslipErrors = [];
    const { isBetslipWarning, isBetslipError } = getMarketErrorStatusByErrorsArray(currentMarketBetslipErrors);

    useEffect(() => {
        if (shouldSkipOddsUpdate()) {
            return;
        }
        getMatchOdds();
    }, [betslipOutcomeIdsForMatch]);

    function shouldSkipOddsUpdate() {
        if (isMarketRemovedRedundant) {
            setIsMarketRemovedRedundant(false);
            return true;
        }
        const isSomeDataMissing = marketIds.some(
            (marketId) => !betSlipMarketIdToOutcomeIdForMatch[marketId] || !marketInfoById[marketId],
        );
        const isOnlyOneMarketPresent =
            marketIds.length === 1 && oddsOutcomeId === betSlipMarketIdToOutcomeIdForMatch[marketIds[0]];

        return isSomeDataMissing || isOnlyOneMarketPresent;
    }

    async function getMatchOdds() {
        try {
            setBetSlipPlacingState((state) => ({ ...state, isLoading: true }));
            resetMatchOddsData();
            clearMatchRedundantMarkets();
            clearMarketFromComboBetMarketIdByMatchId(matchId);

            if (marketIds.length === 1) {
                handleSingleMarket(marketIds[0]);
            } else {
                await handleMultipleMarkets(marketIds);
            }
        } catch (error) {
            logger.error('SportBetslipBetbuilderMarket', 'getMatchOdds', error);
        } finally {
            setBetSlipPlacingState((state) => ({ ...state, isLoading: false }));
        }
    }

    function clearMarketFromComboBetMarketIdByMatchId(matchId: string) {
        const comboMarketid = comboBetMarketIdByMatchId[matchId];
        if (!comboMarketid) {
            return;
        }
        setBetbuilderBetSlipMarketIdToOutcomeId((state) => delete state[comboMarketid] && state);
        setComboBetMarketIdByMatchId((state) => delete state[matchId] && state);
    }

    function resetMatchOddsData() {
        if (oddsMarketInfo?.id) {
            setBetbuilderBetSlipMarketIdToOutcomeId((state) => delete state[oddsMarketInfo.id] && state);
        }

        setOddsMarketInfo(undefined);
        setOddsOutcomeId(undefined);
    }

    function handleSingleMarket(marketId: string) {
        const marketInfo = marketInfoById[marketId];

        const outcomeId = betSlipMarketIdToOutcomeIdForMatch[marketId];
        setComboBetMarketIdByMatchId((state) => ({
            ...state,
            [matchId]: marketId,
        }));
        setBetbuilderBetSlipMarketIdToOutcomeId((state) => ({
            ...state,
            [marketId]: outcomeId,
        }));
        setBetbuilderMarketInfoById((state) => ({
            ...state,
            [marketId]: marketInfo,
        }));
        setOddsMarketInfo(marketInfo);
        setOddsOutcomeId(outcomeId);
    }

    async function handleMultipleMarkets(marketIds: string[]) {
        const betbuilderEligibleMarketIds = marketIds.filter((id) => marketInfoById[id]?.betbuilder_eligible);
        if (betbuilderEligibleMarketIds.length < 2) {
            return;
        }

        const betbuilderEligibleOutcomeIds = betbuilderEligibleMarketIds.map(
            (marketId) => betSlipMarketIdToOutcomeIdForMatch[marketId],
        );
        const betbuilderPriceResponse = await getBetbuilderBetPrice(
            parseInt(matchId, 10),
            betbuilderEligibleOutcomeIds,
            true,
            1,
        );

        const betbuilderLegsOutcomeIds = betbuilderPriceResponse
            ? Object.keys(betbuilderPriceResponse.legs).map(Number)
            : [];

        let redundantMarketIds: number[] | null = null;
        if (betbuilderLegsOutcomeIds.length) {
            redundantMarketIds = [];
            betbuilderEligibleMarketIds.forEach((marketId) => {
                if (!betbuilderLegsOutcomeIds.includes(betSlipMarketIdToOutcomeIdForMatch[marketId])) {
                    (redundantMarketIds as number[]).push(Number(marketId));
                }
            });
        }
        setBetbuilderRedundantMarketIdsByMatchId((state) => ({
            ...state,
            [matchId]: redundantMarketIds,
        }));

        const marketIdFromResponse = betbuilderPriceResponse?.market_id;
        if (!marketIdFromResponse) {
            return;
        }

        const betbuilderMarketInfo = await getMarketInfoByOutcomeId(Number(betbuilderPriceResponse.outcome_id), false);
        setBetbuilderMarketInfoById((betbuilderMarketInfoById) => {
            betbuilderMarketInfoById[betbuilderMarketInfo.id] = betbuilderMarketInfo;
        });

        setBetbuilderMarketOddsByMarketId((state) => ({
            ...state,
            [marketIdFromResponse]: betbuilderPriceResponse?.decimalPrice,
        }));

        setComboBetMarketIdByMatchId((state) => ({
            ...state,
            [matchId]: marketIdFromResponse,
        }));

        if (betbuilderPriceResponse.outcome_id) {
            setBetbuilderBetSlipMarketIdToOutcomeId((state) => ({
                ...state,
                [marketIdFromResponse]: betbuilderPriceResponse.outcome_id,
            }));
        }

        setOddsMarketInfo(betbuilderMarketInfo);
        setOddsOutcomeId(Number(betbuilderPriceResponse.outcome_id));
    }

    function clearMatchRedundantMarkets() {
        setBetbuilderRedundantMarketIdsByMatchId((state) => {
            delete state[matchId];
            return state;
        });
    }

    function removeMarketFromBetslip(marketId: string) {
        const redundantMarketIds: number[] = betbuilderRedundantMarketIdsByMatchId[matchId] || [];
        const isRedundantMarket = redundantMarketIds.includes(Number(marketId));
        setIsMarketRemovedRedundant(isRedundantMarket);

        removeAllNonFrontendErrors();
        setBetSlipMarketIdToOutcomeId((state) => omit(state, marketId));
        setMarketInfoById((state) => omit(state, marketId));
        setBetbuilderBetSlipMarketIdToOutcomeId((state) => omit(state, marketId));
        setBetbuilderMarketInfoById((state) => omit(state, marketId));

        const isMarketIdToOutcomeIdEmpty = Object.entries(betSlipMarketIdToOutcomeId).length === 0;
        if (isMarketIdToOutcomeIdEmpty && isMobile()) {
            stores.isBetslipOpen.set(false);
        }
    }

    return (
        <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <Wrapper
                data-test="sport-betslip-market"
                className={classnames({ warning: isBetslipWarning, error: isBetslipError })}
            >
                <div className="bet-builder-header">
                    {marketInfoById[marketIds[0]]?.match_name}
                    <div className="outcome-odds">
                        {oddsMarketInfo && oddsOutcomeId && (
                            <SportOddsLoad
                                isDisabled={false}
                                key={oddsOutcomeId}
                                outcomeId={oddsOutcomeId}
                                market={oddsMarketInfo}
                                isBetslip
                                isOutcomeNameVisible={false}
                                isAmericanLayout={false}
                                small
                            />
                        )}
                    </div>
                </div>
                <div className="betbuilder-markets-list">
                    {marketIds.map((marketId) => {
                        const marketInfo = marketInfoById[marketId];
                        const outcomeId = betSlipMarketIdToOutcomeIdForMatch[marketId];
                        const outcome = marketInfo?.outcomes.find((o) => o.id === outcomeId);
                        if (!outcome) {
                            return;
                        }
                        const selectionErrors = betSlipErrorByMarketId[marketId] || [];
                        return (
                            <div key={marketId} className="betbuilder-market">
                                <div className="info">
                                    <div className="name">
                                        <span>{marketInfo.marketName}</span>
                                        <span className="outcome">
                                            {outcome.name} {getLine(marketInfo, outcome, false)}
                                        </span>
                                    </div>
                                    <div className="action">
                                        <Svg
                                            icon="trash"
                                            size={1}
                                            onClick={() => removeMarketFromBetslip(marketId)}
                                            className="remove-selection"
                                        />
                                    </div>
                                </div>
                                {selectionErrors.map((betbuilderSelectionError) => (
                                    <UiAlert
                                        className="market-error"
                                        info={isInfo(betbuilderSelectionError)}
                                        warning={isWarning(betbuilderSelectionError)}
                                        failure={
                                            !isWarning(betbuilderSelectionError) && !isInfo(betbuilderSelectionError)
                                        }
                                        key={`${betbuilderSelectionError}-${marketId}`}
                                    >
                                        {getErrorMessage(betbuilderSelectionError)}
                                    </UiAlert>
                                ))}
                            </div>
                        );
                    })}
                </div>
                {(betbuilderErrors || []).map((betbuilderError) => (
                    <UiAlert
                        className="market-error"
                        info={isInfo(betbuilderError)}
                        warning={isWarning(betbuilderError)}
                        failure={!isWarning(betbuilderError) && !isInfo(betbuilderError)}
                        key={`${betbuilderError}-${matchId}`}
                    >
                        {getErrorMessage(betbuilderError)}
                    </UiAlert>
                ))}
            </Wrapper>
        </UiAnimate>
    );
}
