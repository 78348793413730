import React, { PropsWithChildren, useMemo } from 'react';
import Wrapper from './styles';
import MainLayout from '../main/MainLayout';
import { getRoute } from '../../services/router';
import Navigator from '../../components/navigator/Navigator';
import TournamentSidebar from '../../components/tournament/sidebar/TournamentSidebar';
import { useStore } from '../../hooks/useStore';
import { media } from '../../stores/media/media';
import { FEATURE } from '../../services/types';
import { isFeatureAvailable } from '../../services/feature';
import SportLobbyCategoriesV2Mobile from '../../components/sport/lobby-categories-v-2/mobile/SportLobbyCategoriesV2Mobile';

export default function LeagueLayout({ children }: PropsWithChildren) {
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);
    const [{ isDesktop, isLaptop, isPhone, isTablet }] = useStore(media);
    const leftSidebarComponents = useMemo(() => getLeftSidebarComponents(), []);

    function getLeftSidebarComponents() {
        return [
            {
                route: getRoute('league'),
                component: TournamentSidebar,
            },
        ];
    }

    return (
        <MainLayout>
            <Wrapper>
                {isMainNavigationV2 && (isDesktop || isLaptop) && (
                    <div className="league-menu">
                        <Navigator routes={leftSidebarComponents} noRedirect />
                    </div>
                )}
                <div>
                    {isMainNavigationV2 && (isPhone || isTablet) && <SportLobbyCategoriesV2Mobile />}
                    {children}
                </div>
            </Wrapper>
        </MainLayout>
    );
}
