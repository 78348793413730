import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import React from 'react';
import SportCard from '../../sport/card/SportCard';
import ComboCardsItemDetails from './details/ComboCardsItemDetails';
import ComboCardsItemFooter from './footer/ComboCardsItemFooter';
import ComboCardsItemHeader from './header/ComboCardsItemHeader';
import Wrapper from './styles';
import { checkIsCardMultiSport } from '../../../services/sports/combo-cards';

interface Props {
    card: FoComboCardWithOdds;
}

export default function ComboCardsItem({ card }: Props) {
    const isMultiSportCard = checkIsCardMultiSport(card.matches);

    return (
        <SportCard>
            <Wrapper>
                <ComboCardsItemHeader card={card} isMultiSport={isMultiSportCard} />
                <div className="combo-card-content">
                    <ComboCardsItemDetails card={card} isMultiSport={isMultiSportCard} />
                    <ComboCardsItemFooter card={card} />
                </div>
            </Wrapper>
        </SportCard>
    );
}
