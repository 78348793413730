import { Licence } from '@staycool/location';
import { getActiveCurrency } from '../currency';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../date';
import { isWithLicence } from '../licence';
import { UserLimit } from '../responsible-gaming/types';
import { getRoute } from '../router';
import { translate } from '../translate';
import { Currency } from '../wallet/types';
import { PaymentProvider } from './types';

function isDepositAmountSet(depositAmount: number) {
    return depositAmount && !Number.isNaN(depositAmount) && depositAmount > 0;
}

function isUnderMinLimit(depositAmount: number, provider: PaymentProvider, currency: Currency) {
    return depositAmount < provider?.minAmount[currency];
}
function isOverMaxLimit(depositAmount: number, provider: PaymentProvider, currency: Currency) {
    return depositAmount > provider?.maxAmount[currency];
}

function isDepositLimitRequired(depositLimits: UserLimit[]) {
    return isWithLicence(Licence.SWEDEN) && !depositLimits.length;
}

function isExceedingDepositLimit(depositAmount: number, depositLimits: UserLimit[]) {
    return depositLimits.some((depositLimit) => depositAmount > depositLimit.activeLimit.remaining_amount);
}

export function canUserDeposit({
    provider,
    depositAmount,
    depositLimits,
}: {
    provider: PaymentProvider;
    depositAmount: number;
    depositLimits: UserLimit[];
}) {
    const currency = getActiveCurrency();

    if (isDepositLimitRequired(depositLimits)) {
        return {
            error: translate('Please <a href="%1">set a deposit limit</a> before depositing.', 'ui.account', [
                getRoute('responsible-gaming.set-limits'),
            ]),
        };
    }
    if (provider?.hideAmountSelector) {
        return { error: '' };
    }
    if (!isDepositAmountSet(depositAmount)) {
        return { error: translate('Oops! Seems you forgot to enter amount', 'ui.account') };
    }
    if (isUnderMinLimit(depositAmount, provider, currency)) {
        return {
            error: translate('Minimum deposit amount is %1 %2.', 'ui.account', [
                provider?.minAmount[currency],
                currency,
            ]),
        };
    }
    if (isOverMaxLimit(depositAmount, provider, currency)) {
        return {
            error: translate('Maximum deposit amount is %1 %2.', 'ui.account', [
                provider?.maxAmount[currency],
                currency,
            ]),
        };
    }
    if (isExceedingDepositLimit(depositAmount, depositLimits)) {
        const exceededLimit = depositLimits.find(
            (depositLimit) => depositAmount > depositLimit.activeLimit.remaining_amount,
        );

        const { remaining_amount, current_period_end_date } = (exceededLimit as UserLimit).activeLimit;

        return {
            error: translate(
                'You have reached your deposit limit! {{ amount }} {{ currency }} remaining until {{ limitEndDate }}',
                'ui.responsible-gaming',
                {
                    amount: remaining_amount,
                    currency,
                    limitEndDate: getFormattedDate({
                        date: current_period_end_date,
                        format: DATE_YEAR_TIME_FORMAT,
                    }),
                },
            ),
        };
    }
    return { error: '' };
}
