import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: var(--spacing-12);
    gap: var(--spacing-12);
    overflow: auto;

    & .sport-menu-divider {
        align-self: start;
        margin-top: var(--spacing-16);
        height: var(--spacing-24);
        border-left: 2px solid var(--surface-level-1-line-color);
    }
`;
