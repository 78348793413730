import { useEffect, useState } from 'react';

export const useFakeProgress = ({ duration, steps = 20 }: { duration?: number; steps?: number }): number => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (!duration) {
            return;
        }
        const startTime = Date.now();
        const interval = setInterval(() => {
            const elapsedTime = Date.now() - startTime;
            const factor = 3;
            const newProgress = Math.floor(100 * (1 - Math.exp((-factor * elapsedTime) / duration)));

            setValue(Math.min(100, newProgress));

            if (elapsedTime >= duration) {
                clearInterval(interval);
                setValue(99);
            }
        }, duration / steps);

        return () => clearInterval(interval);
    }, [duration, steps]);

    return value;
};
