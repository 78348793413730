import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import UiModal from '../../../ui/modal/UiModal';
import SportBetslipWrapper from '../../betslip-wrapper/SportBetslipWrapper';
import { convertOdds } from '../../../../services/odds-format';
import { calculateTotalOdds, calculateTotalOddsWithoutOddsInStore } from '../../../../services/sports/betslip';
import { isNativeApp } from '../../../../services/device';
import { isBYOD } from '../../../../services/environment';
import { useStore } from '../../../../hooks/useStore';

export default function SportPhoneNavigationC() {
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const numberOfBets = cardsInBetslip.length || Object.values(betSlipMarketIdToOutcomeId).length;
    const [isBetslipOpen, setIsBetslipOpen] = useStore(stores.isBetslipOpen);
    const [totalOdds, setTotalOdds] = useState(0);
    const [oddsByOutcomeId] = useStore(stores.sports.oddsByOutcomeId);
    const isApp = isNativeApp();
    const isByod = isBYOD();
    const fixedToBottom = isApp || isByod;

    useEffect(() => {
        if (numberOfBets && Object.keys(oddsByOutcomeId).length < 1) {
            updateTotalOddsWithoutOddsInStore();
            return;
        }
        updateTotalOdds();
    }, []);

    useEffect(() => {
        updateTotalOdds();
    }, [numberOfBets]);

    function updateTotalOdds() {
        if (numberOfBets) {
            setTotalOdds(calculateTotalOdds(null));
        }
    }

    async function updateTotalOddsWithoutOddsInStore() {
        setTotalOdds(
            await calculateTotalOddsWithoutOddsInStore(
                Object.keys(betSlipMarketIdToOutcomeId).map(Number),
                Object.values(betSlipMarketIdToOutcomeId),
            ),
        );
    }

    if (!numberOfBets && !isBetslipOpen) {
        return null;
    }

    return (
        <Wrapper $fixedToBottom={fixedToBottom}>
            <div onClick={() => setIsBetslipOpen(true)} className="mobile-betslip">
                <div className="bets-count">{numberOfBets}</div>
                <div className="betslip-title">{translate('Betslip', 'ui.sportsbook')}</div>
                {numberOfBets && (
                    <div className="betslip-odds">
                        {translate('Total odds', 'ui.betslip')}:{' '}
                        <span className="odds-value">{convertOdds(totalOdds)}</span>
                    </div>
                )}
            </div>
            <UiModal
                open={isBetslipOpen}
                onClose={() => setIsBetslipOpen(false)}
                onOpen={() => setIsBetslipOpen(true)}
                mode="drawer"
            >
                <div className="betslip-box">
                    <SportBetslipWrapper />
                </div>
            </UiModal>
        </Wrapper>
    );
}
