import React from 'react';
import Wrapper from './styles';
import { SportCategoryNavigation } from '../../../../microservices/sbgate';
import SportCategoryListV2Button from './button/SportCategoryListV2Button';

interface Props {
    list: SportCategoryNavigation[];
}

export default function SportCategoryListV2({ list }: Props) {
    return (
        <Wrapper>
            {list.map(({ id, label, ...rest }) => (
                <SportCategoryListV2Button key={id} {...rest}>
                    {label}
                </SportCategoryListV2Button>
            ))}
        </Wrapper>
    );
}
