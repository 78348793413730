import { getServiceUrl, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { getActiveCurrency } from '../services/currency';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { GetBetbuilderPriceResponse as GetBetbuilderBetPriceResponse } from '@staycool/openbet-types/fo-betbuilder';

const getUrl = (url) => getServiceUrl('openbet', url);

export async function getBetbuilderBetPrice(
    matchId: number,
    outcomeIds: number[],
    saveBetSlip = true,
    stake: number = 1,
) {
    try {
        const currency = getActiveCurrency();
        const language = getStoreValue(stores.language);

        const url = getUrl(`fo-betbuilder/betbuilder-bet-price`);
        return await httpPost<GetBetbuilderBetPriceResponse>(url, {
            matchId,
            stake,
            outcomeIds,
            saveBetSlip,
            currency,
            language,
        });
    } catch (error) {
        logger.error('OpenbetMicroservice', 'getBetbuilderBetPrice', error);
    }
}
