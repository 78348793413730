import React, { useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import { useRouter } from '../../../../services/router';
import { useStore } from '../../../../hooks/useStore';
import { useContestPreSelectionState, useFetchContest } from '../../../../hooks/contests';
import { sports } from '../../../../stores/sports';
import { logger } from '@sentry/utils';
import UiButton from '../../../ui/button/UiButton';
import UiDotsLoader from '../../../ui/dots-loader/UiDotsLoader';
import UiAlert from '../../../ui/alert/UiAlert';
import { sendContestPrediction } from '../../../../microservices/tournaments';
import SportContestsBetslipHeader from './header/SportContestsBetslipHeader';
import SportContestsBetslipCounter from './counter/SportContestsBetslipCounter';
import SportContestsBetslipSelection from './selection/SportContestsBetslipSelection';
import { useGlobalModal } from '../../../../hooks/useGlobalModal';
import SportContestsBetslipResubmitModal from './resubmit-modal/SportContestsBetslipResubmitModal';

export default function SportContestsBetslip() {
    const [isDisabled, setIsDisabled] = useState(false);
    const { contestId } = useRouter().params;
    const { isLoading, contest } = useFetchContest({ contestId });
    const [entries, setEntries] = useStore(sports.contestSelections.entries);

    const {
        activeEntry,
        getSelections,
        selections,
        clearSelections,
        removeSelection,
        isResubmit,
        setInitialSelections,
        getPredictionsForSend,
    } = useContestPreSelectionState();

    const { showModal, hideModal } = useGlobalModal();

    if (!contestId || !contest || !activeEntry) {
        return null;
    }

    const entry = entries.find((e) => e.entry_id === activeEntry.entry_id);

    if (entry && entry.leg_predictions?.[contest.meta.active_leg - 1].market_selections.length > 0 && !isResubmit) {
        return null;
    }

    if (entry && entry.status === 'DROPPED_OUT') {
        return null;
    }

    const selectedOutcomesCount = getSelections().length;
    const minSelections = contest.legs[contest.meta.active_leg - 1]?.min_selections;

    function updateEntriesAfterSubmit() {
        const updatedEntries = [...entries];

        updatedEntries.map((entry) => {
            if (entry.entry_id !== activeEntry!.entry_id) {
                return entry;
            }

            const activeLegIndex = (contest!.meta.active_leg || 1) - 1;
            const selections = getSelections();

            selections.forEach(({ market, selectedOutcome }) => {
                const selectedOutcomeId = selectedOutcome.outcome_id;

                const outcome = market.outcomes.find((outcome) => outcome.outcome_id === selectedOutcomeId);
                if (outcome) {
                    outcome.is_selected = true;
                }
            });

            entry.leg_predictions[activeLegIndex].market_selections = selections.map((selection) => selection.market);
            return entry;
        });

        setEntries(updatedEntries);
    }

    async function sendPrediction() {
        try {
            setIsDisabled(true);
            const body = {
                leg_id: contest!.legs[contest!.meta.active_leg - 1].id,
                predictions: getPredictionsForSend(),
            };
            await sendContestPrediction({ body, isResubmit });
            updateEntriesAfterSubmit();
            setInitialSelections((prevState) => {
                if (activeEntry) {
                    prevState[activeEntry.entry_id] = selections[activeEntry.entry_id];
                }

                return prevState;
            });
            setIsDisabled(false);
        } catch (error) {
            logger.error('SportContestsBetslip', 'sendPrediction', error);
        }
    }

    return (
        <Wrapper>
            <SportContestsBetslipHeader name={contest.name} clearSelection={clearSelections} />
            {isLoading ? (
                <UiDotsLoader />
            ) : (
                <main>
                    <SportContestsBetslipCounter
                        selectedOutcomesCount={selectedOutcomesCount}
                        minSelections={minSelections}
                    />
                    <div className="selections">
                        {getSelections().map((selection) => (
                            <SportContestsBetslipSelection
                                selection={selection}
                                key={selection.selectedOutcome.outcome_id}
                                onRemove={() => {
                                    removeSelection(selection.market);
                                }}
                            />
                        ))}
                    </div>
                </main>
            )}
            <footer>
                {selectedOutcomesCount < minSelections && (
                    <UiAlert failure>
                        {translate(
                            ['min-selections', 'Minimum amount of selections is {{minSelections}}'],
                            'ui.sportsbook.contests',
                            { minSelections },
                        )}
                    </UiAlert>
                )}
                <UiButton
                    disabled={selectedOutcomesCount < minSelections || isDisabled}
                    block
                    size="large"
                    color="primary"
                    onClick={() =>
                        isResubmit
                            ? showModal(SportContestsBetslipResubmitModal, {
                                  params: {
                                      onConfirm: () => {
                                          sendPrediction();
                                          hideModal();
                                      },
                                  },
                                  onClose: hideModal,
                              })
                            : sendPrediction()
                    }
                >
                    {translate(`${isResubmit ? 'Resubmit' : 'Submit'} selection`, 'ui.sportsbook.contests')}
                </UiButton>
            </footer>
        </Wrapper>
    );
}
