import React from 'react';
import { useSportLobbyCategoriesLinks } from '../../../hooks/main-navigation-v2';
import { getRoute, isActiveRoute } from '../../../services/router';
import { stores } from '../../../stores';
import { useStore } from '../../../hooks/useStore';
import SportMenuV2NavLink from '../menu-v-2/nav-link/SportMenuV2NavLink';

export default function SportLobbyCategoriesV2() {
    const [liveMatchesCount] = useStore(stores.sports.liveMatchesCount);
    const links = useSportLobbyCategoriesLinks();

    return (
        <div>
            {links.map(({ route, icon, label }) => {
                const isLiveCard = route.includes(`${getRoute('sport.live')}`);

                return (
                    <SportMenuV2NavLink
                        key={route}
                        to={route}
                        icon={icon}
                        label={label}
                        isActive={isActiveRoute(route, false)}
                        {...(isLiveCard && { badge: liveMatchesCount })}
                    />
                );
            })}
        </div>
    );
}
