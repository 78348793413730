import React from 'react';
import SportHotEvents from '../../hot-events/SportHotEvents';
import SportSidebarSoonCampaigns from './campaigns/SportSidebarSoonCampaigns';
import Wrapper from './styles';
import SportTreeMenu from '../../tree/menu/SportTreeMenu';
import SportSettingsMenuButton from '../../settings-menu/button/SportSettingsMenuButton';
import { isFeatureAvailable } from '../../../../services/feature';
import SportBonusBetList from '../../bonus-bet/list/SportBonusBetList';
import { FEATURE } from '../../../../services/types';
import SportLobbyCategoriesV2 from '../../lobby-categories-v-2/SportLobbyCategoriesV2';
import SportHotEventsV2 from '../../hot-events-v-2/SportHotEventsV2';
import SportCampaignsListV2 from '../../campaigns/list-v-2/SportCampaignsListV2';
import SportTreeMenuV2 from '../../tree/menu-v-2/SportTreeMenuV2';
import { useScrollPosition } from '../../../../hooks/scroll/useScrollPosition';

export default function SportSidebarSoon() {
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    const scroll = useScrollPosition();

    return (
        <Wrapper $scroll={scroll}>
            {!isLayoutB && (
                <>
                    {isMainNavigationV2 && <SportLobbyCategoriesV2 />}
                    <SportSettingsMenuButton />
                    <SportBonusBetList />
                    {isMainNavigationV2 ? <SportCampaignsListV2 /> : <SportSidebarSoonCampaigns />}
                </>
            )}
            {isMainNavigationV2 ? <SportHotEventsV2 isVertical /> : <SportHotEvents />}
            {isMainNavigationV2 ? <SportTreeMenuV2 /> : <SportTreeMenu />}
        </Wrapper>
    );
}
