import React from 'react';
import { getBetslipEligibleBonusesForBonusType } from '../../../../services/sports/bonus-bets';
import { isFeatureAvailable } from '../../../../services/feature';
import SportBonusBetMultiplierDesignedLayout from './designed-layout/SportBonusBetMultiplierDesignedLayout';
import SportBonusBetMultiplierDefaultLayout from './default-layout/SportBonusBetMultiplierDefaultLayout';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import { BonusBet, BonusType } from '../../../../services/bonuses/types';

export default function SportBonusBetMultiplier() {
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const [, setBonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const [activeBonusId, setActiveBonusId] = useStore(stores.sports.selectedBonusId);
    const [activeUserBonusId, setActiveUserBonusId] = useStore(stores.sports.selectedUserBonusId);

    const bonuses = getBetslipEligibleBonusesForBonusType(BonusType.Multiplier).map((bonus) => {
        return {
            ...bonus,
            multiplierSettings: { ...bonus.multiplierSettings, multiplier: bonus.multiplierSettings?.multiplier || 0 },
        };
    });

    function toggleMultiplier(bonusId: string, userBonusId?: string) {
        if (activeBonusId !== bonusId || userBonusId !== activeUserBonusId) {
            setActiveBonusId(bonusId);
            setActiveUserBonusId(userBonusId ?? '');
            setBonusBetsSelection({ [BonusType.Multiplier]: true });
        } else {
            setActiveBonusId('');
            setActiveUserBonusId('');
            setBonusBetsSelection({ [BonusType.Multiplier]: false });
        }
    }

    return (
        <>
            {bonuses.map((bonus) => (
                <>
                    {isLayoutB ? (
                        <SportBonusBetMultiplierDesignedLayout
                            bonus={bonus as BonusBet}
                            key={bonus.userBonusId ?? bonus.id}
                            onBonusToggle={() => toggleMultiplier(bonus.id, bonus.userBonusId)}
                        />
                    ) : (
                        <SportBonusBetMultiplierDefaultLayout
                            bonus={bonus as BonusBet}
                            key={bonus.id}
                            onBonusToggle={() => toggleMultiplier(bonus.id)}
                        />
                    )}
                </>
            ))}
        </>
    );
}
