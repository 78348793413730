import styled from 'styled-components';

export default styled.div`
    @supports (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            -webkit-appearance: -apple-pay-button;
        }
        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }
    }

    @supports not (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            display: inline-block;
            background-size: 100% 60%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border-radius: 5px;
            padding: 0px;
            box-sizing: border-box;
            min-width: 200px;
            min-height: 32px;
            max-height: 64px;
        }
        .apple-pay-button-white {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }
    }
`;
