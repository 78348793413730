import styled, { css } from 'styled-components';

type StyledProps = {
    isActive: boolean;
};

export default styled.div<StyledProps>`
    width: 100%;
    border-radius: var(--ui-radius);
    overflow: hidden;
    box-shadow: var(--ui-shadow);
    background: var(--button-bg);
    border: var(--button-border);
    cursor: pointer;
    outline: none;
    padding: 0 var(--spacing-8);
    border: 2px solid transparent;
    ${({ isActive }) =>
        isActive &&
        css`
            border-color: var(--color-highlight);
        `};

    .method-cvc-field {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-top: 0.5rem;
        padding-right: 0.5rem;
        border-top: 1px dashed var(--color-highlight);

        .cvc-field {
            display: flex;
            align-items: center;
            width: 50%;
        }

        .cvc-field-error {
            width: 50%;
        }
    }

    .method-icon {
        height: 60px;
        width: 90px;
        margin-right: 0.5rem;

        &[src='/assets/images/payments/bank.png'] {
            width: 0;
            margin-right: 0;
        }
    }

    .method-info {
        width: 100%;
        color: var(--font-color-primary);
        display: flex;
        justify-content: space-between;
        align-items: end;
        > div {
            &.expiration-date {
                color: var(--font-color-secondary);
                font-size: 0.75rem;
            }
        }
    }
`;
