import React from 'react';
import Wrapper from './styles';
import HorseRacingUiTag from '../../ui/tag/HorseRacingUiTag';
import { stores } from '../../../../stores';
import HorseRacingBetslipRunnerMaps from '../runner-maps/HorseRacingBetslipRunnerMaps';
import { translate } from '../../../../services/translate';
import moment from 'moment';
import { useStore } from '../../../../hooks/useStore';

export default function HorseRacingBetslipSelection() {
    const [betslip] = useStore(stores.horseRacing.betslip);
    const {
        betType: { code: betTypeCode },
        runnerMaps,
        races,
        raceId,
        nevadaTrackName,
        breed,
        date,
    } = betslip;

    return (
        <Wrapper>
            <div className="track-info">
                <div className="track-name">
                    {nevadaTrackName} <span className="date">{moment(date).format('MMM DD')}</span>
                </div>
                <HorseRacingUiTag>
                    {translate('race', 'ui.racebook')} {raceId}
                </HorseRacingUiTag>{' '}
            </div>
            <HorseRacingBetslipRunnerMaps breed={breed} betType={betTypeCode} runnerMaps={runnerMaps} races={races} />
        </Wrapper>
    );
}
