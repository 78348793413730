import React, { useEffect, useRef } from 'react';
import { getCmsImageUrl } from '../../../../services/cms-image';
import { getPaymentProviderImage } from '../../../../services/payments/payments';
import { PaymentProvider, PROVIDER_TYPE } from '../../../../services/payments/types';
import { toBase64 } from '../../../../services/util';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    active?: boolean;
    isActiveRow?: boolean;
    onClick: () => void;
    provider: PaymentProvider;
    rowNumber: number;
    type: PROVIDER_TYPE;
}

export default function PaymentProvidersSliderProvider({ active, isActiveRow, onClick, provider, rowNumber }: Props) {
    const [{ isPhone }] = useStore(media);
    const { aux, image, isShowingPaymentMethods, slug } = provider;
    const isShowingLogo = provider.aux?.isPaymentMethodProvider;
    function setFallbackProviderImage(event) {
        // fm=pjpg is a hack to fix broken image in Rackspace/Imgix.
        const fallBackImage = getCmsImageUrl({ fileName: getPaymentProviderImage(image), postfix: '&fm=pjpg' });

        if (event.target.src !== fallBackImage) {
            event.target.src = fallBackImage;
        }
    }
    const imageSize = isPhone
        ? {
              height: 45,
              minWidth: 100,
              maxWidth: 140,
          }
        : {
              height: 75,
              minWidth: 80,
              maxWidth: 100,
          };
    const elementRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (active && isPhone) {
            elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        }
    }, [active]);

    return (
        <Wrapper
            $active={active}
            $isShowingLogo={isShowingLogo}
            {...filterStyleProps({ isActiveRow, rowNumber, isShowingPaymentMethods })}
            onClick={onClick}
            ref={elementRef as any}
        >
            <div className="method-container">
                <div className="method-icon" data-test={`${toBase64(slug)}`}>
                    {isShowingPaymentMethods && aux?.bankImage ? (
                        <img className="bank-icon" src={aux.bankImage} alt="" onError={setFallbackProviderImage} />
                    ) : (
                        <img
                            id={image}
                            src={getCmsImageUrl({
                                fileName: provider.image,
                                postfix: `&fit=fill&ar=1&max-h=${imageSize.height}&min-w=${imageSize.minWidth}&max-w=${imageSize.maxWidth}`,
                            })}
                            alt=""
                            onError={setFallbackProviderImage}
                        />
                    )}
                </div>
                {aux?.number && <div className="title">{aux.number}</div>}
                {isShowingPaymentMethods && provider.aux?.isPaymentMethodProvider && (
                    <img className="logo" src={`/assets/images/payments/${provider.aux.additionalLogo}`} alt="" />
                )}
            </div>
        </Wrapper>
    );
}
