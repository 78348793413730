import React, { PropsWithChildren } from 'react';
import Wrapper from './styles';
import Svg from '../../../../svg/Svg';
import { filterStyleProps } from '../../../../../styles/utils';
import { SportCategoryNavigation } from '../../../../../microservices/sbgate';

interface Props extends PropsWithChildren, Omit<SportCategoryNavigation, 'id' | 'label'> {}

export default function SportCategoryListV2Button({ icon, ...rest }: Props) {
    return <Wrapper type="card" size="tiny" icon={icon && <Svg icon={icon} size={1} />} {...filterStyleProps(rest)} />;
}
