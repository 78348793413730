import React from 'react';
import Svg from '../../../../svg/Svg';
import { translate } from '../../../../../services/translate';
import { DATE_TIME_FORMAT, getFormattedDate } from '../../../../../services/date';
import { maintenance } from '../../../../../stores/maintenance';
import { useStore } from '../../../../../hooks/useStore';
import UiDottedArrow from '../../../../ui/dotted-arrow/UiDottedArrow';
import { stores } from '../../../../../stores';
import isEmpty from 'lodash/isEmpty';
import Wrapper from '../styles';

export default function SportBetslipUiClosed() {
    const [{ endDate }] = useStore(maintenance.sportsbookMaintenanceState);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    return (
        <Wrapper>
            <div className="pick-bets">
                <div className="animated bounceInDown">
                    <Svg icon="block-user" size={2.6} />
                </div>
                <div className="pick-bets-text animated fadeIn">
                    {endDate
                        ? translate(
                              [
                                  'betting-closed-until',
                                  'Betting is closed until: {{endDate}}, You can still prepare your betslip',
                              ],
                              'ui.sportsbook',
                              {
                                  endDate: getFormattedDate({
                                      format: DATE_TIME_FORMAT,
                                      date: endDate,
                                  }),
                              },
                          )
                        : translate(
                              ['betting-closed', 'Betting closed, You can still prepare your betslip'],
                              'ui.sportsbook',
                          )}
                </div>
                {isEmpty(betSlipMarketIdToOutcomeId) && (
                    <div className="pick-bets-arrows">
                        <UiDottedArrow size={8} rotate={180} />
                        <UiDottedArrow size={8} rotate={180} />
                        <UiDottedArrow size={8} rotate={180} />
                    </div>
                )}
            </div>
        </Wrapper>
    );
}
