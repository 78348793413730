import React from 'react';
import Wrapper from './styles';
import ComboCardsBetslipHeader from './header/ComboCardsBetslipHeader';
import { stores } from '../../../stores';
import ComboCardsBetslipSelection from './selection/ComboCardsBetslipSelection';
import ComboCardsBetslipFooter from './footer/ComboCardsBetslipFooter';
import { removeSelectionFromBetslip, resetComboCardBetSlip } from '../../../services/sports/combo-cards';
import ComboCardsBetslipReceipt from './receipt/ComboCardsBetslipReceipt';
import UiButton from '../../ui/button/UiButton';
import { translate } from '../../../services/translate';
import { initialBetSlipPlacingState } from '../../../services/sports/constants';
import { isMobile } from '../../../services/browser';
import { useStore } from '../../../hooks/useStore';
import SportBetslipShareButton from '../../sport/betslip/share-button/SportBetslipShareButton';
import { isFeatureAvailable } from '../../../services/feature';
import { FEATURE } from '../../../services/types';
import { getStoreValue } from '../../../stores/store/utils';

export default function ComboCardsBetslip() {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [{ receiptById }] = useStore(stores.sports.betSlipPlacingState);
    const cardIdsWithReceipt = Object.keys(receiptById);
    const betSlipComboCardMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipComboCardMarketIdToOutcomeId);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isBetslipOrReceiptHasItem = Boolean(cardIdsWithReceipt.length) || Boolean(cardsInBetslip.length);
    const isBetslipSharingAvailable =
        isFeatureAvailable(FEATURE.BETSLIP_SHARE) && isBetslipOrReceiptHasItem && isAuthenticated;

    function clearSelections(cardId?: number) {
        if (!cardId) {
            resetComboCardBetSlip();
            return;
        }

        removeSelectionFromBetslip(cardId);
    }

    function removeReceipt() {
        resetComboCardBetSlip();
        stores.sports.betSlipPlacingState.set(initialBetSlipPlacingState);
        if (isMobile()) {
            stores.isBetslipOpen.set(false);
        }
    }

    return (
        <Wrapper>
            {Boolean(cardIdsWithReceipt.length) ? (
                <>
                    <UiButton block onClick={removeReceipt} className="remove-receipt">
                        {translate('Close', 'ui.common')}
                    </UiButton>
                    {cardIdsWithReceipt.map((cardId) => (
                        <ComboCardsBetslipReceipt key={cardId} cardId={cardId} removeReceipt={removeReceipt} />
                    ))}
                </>
            ) : (
                <>
                    <ComboCardsBetslipHeader numberOfCards={cardsInBetslip.length} clearSelections={clearSelections} />
                    {cardsInBetslip.map((card) => (
                        <ComboCardsBetslipSelection key={card.id} card={card} clearSelections={clearSelections} />
                    ))}
                    <ComboCardsBetslipFooter clearSelections={clearSelections} />
                </>
            )}

            {isBetslipSharingAvailable && (
                <SportBetslipShareButton
                    outcomeIds={Object.values(betSlipComboCardMarketIdToOutcomeId)}
                    comboCardId={cardsInBetslip[0].id}
                    betType="comboCard"
                />
            )}
        </Wrapper>
    );
}
