import React, { useEffect, useRef, useState } from 'react';
import UiButton from '../../../../ui/button/UiButton';
import { translate } from '../../../../../services/translate';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import { stores } from '../../../../../stores';
import { logger } from '../../../../../services/logger';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import { depositWithProvider } from '../../../../../services/payments/payments';
import { getStoreValue } from '../../../../../stores/store/utils';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    provider: string;
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderBanklink({ provider, amount, deviceHash, onClose }: Props) {
    const [{ isPhone }] = useStore(media);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [banklink, setBanklink] = useState<any>();
    const [bankForm, setBankForm] = useState<any>();
    const formReference = useRef<any>();

    useEffect(() => {
        initBanklink();
    }, []);

    async function initBanklink() {
        try {
            const banklink = await depositWithProvider({
                amount,
                deviceHash,
                provider,
                providerParams: { language: getStoreValue(stores.language) },
            });

            const bankForm = Object.keys(banklink.formParams).map((key) => {
                const value = banklink.formParams[key];
                return <UiFormInput key={key} name={key} value={value} className="hidden" />;
            });

            setBanklink(banklink);
            setBankForm(bankForm);
            setTimeout(() => formReference.current?.submit(), 1000);
        } catch (error) {
            logger.error('PaymentDepositProviderBanklink', 'initBanklink', error);
            setIsError(true);
        }

        setIsLoading(false);
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper>
            <form ref={formReference} action={banklink.bankUrl} method="POST">
                <h2>{translate(banklink.bankName, 'ui.account')}</h2>
                <p>{translate('Please wait, click "Confirm" if You are not redirected', 'ui.account')}</p>
                {bankForm}

                <UiButton type="button" color="primary" block={isPhone} isFormSubmitButton>
                    {translate('Confirm Banklink', 'ui.account')}
                </UiButton>
            </form>
        </Wrapper>
    );
}
