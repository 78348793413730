import { useEffect, useState } from 'react';
import { validateUserCountry } from '../microservices/sbgate';
import { stores } from '../stores';
import { useStore } from './useStore';
export const useSportsbookCountryBlock = () => {
    const [ipCountry] = useStore(stores.ipCountry);
    const [isCountryBlocked, setIsCountryBlocked] = useState(false);
    useEffect(() => {
        validateUserCountry(ipCountry).then(setIsCountryBlocked);
    }, [ipCountry]);
    return isCountryBlocked;
};
