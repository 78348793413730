import React, { useEffect, useState } from 'react';
import { isProduction } from '../../../../../../services/util';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';

interface Props {
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentDepositProviderWorldpayScripts({ onError, onScriptsLoaded }: Props) {
    const [isWorldpayScriptLoaded, setIsWorldpayScriptLoaded] = useState(false);

    useEffect(() => {
        if (isWorldpayScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isWorldpayScriptLoaded]);

    return (
        <>
            <ScriptLoader
                url={
                    isProduction()
                        ? 'https://request.eprotect.vantiv.com/eProtect/js/eProtect-iframe-client.min.js'
                        : 'https://request.eprotect.vantivprelive.com/eProtect/js/eProtect-iframe-client.min.js'
                }
                onError={onError}
                onLoad={() => setIsWorldpayScriptLoaded(true)}
            />
        </>
    );
}
