import React, { ComponentType, Suspense } from 'react';
import { ErrorBoundary } from '../../higher-order-components/error-boundary';
import Loader from '../loader/Loader';

export default function AsyncComponent(
    loader: () => Promise<{ default: ComponentType<any> }>,
    options?: { isSilent: boolean },
) {
    const Component = React.lazy(loader);
    return (props) => (
        <ErrorBoundary>
            <Suspense fallback={options?.isSilent ? null : <Loader />}>
                <Component {...props} />
            </Suspense>
        </ErrorBoundary>
    );
}
