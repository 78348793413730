import styled from 'styled-components';

export default styled.div`
    margin-bottom: var(--spacing-32);

    .menu-label {
        margin-top: 0 !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 0.6rem;
        text-transform: uppercase;
        color: var(--font-color-secondary);
        font-weight: var(--font-weight-bold);
        letter-spacing: 1px;
        user-select: none;
        ${({ theme }) => theme.optimizeFontRendering};

        margin-right: var(--spacing-16);
    }

    .campaigns-list {
        display: flex;
        flex-direction: column;

        > * {
            padding: var(--spacing-8);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
        }
    }
`;
