import React, { useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import Ui2FormCheckbox from '../../../../ui-2/form/checkbox/Ui2FormCheckbox';
import Ui2FormTextInput from '../../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2Form from '../../../../ui-2/form/Ui2Form';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiGroup from '../../../../ui/group/UiGroup';
import Wrapper from './styles';

interface Props {
    hasMethods: boolean;
    isEnrollment: boolean;
    linkAccount: () => Promise<void>;
    onClose: () => void;
    onNewAccountSubmit: () => Promise<void>;
    setIsAddingAccount: React.Dispatch<React.SetStateAction<boolean>>;
    useFormMethods: UseFormReturn<FieldValues, any>;
}

export default function PaymentProvidersCustomGlobalpayNewAccount({
    hasMethods,
    isEnrollment,
    linkAccount,
    onClose,
    onNewAccountSubmit,
    setIsAddingAccount,
    useFormMethods,
}: Props) {
    const [isManualInsert, setIsManualInsert] = useState(false);

    function backButton() {
        if (isEnrollment || !hasMethods) {
            onClose();
        } else {
            setIsAddingAccount(false);
        }
    }

    return (
        <Wrapper>
            <Ui2Form useFormMethods={useFormMethods} onSubmit={onNewAccountSubmit}>
                <UiButton color="default" block onClick={linkAccount}>
                    {translate('Link account', 'ui.account')}
                </UiButton>
                <div className="link aligned-text" onClick={() => setIsManualInsert(!isManualInsert)}>
                    {translate('click here to enter manually', 'ui.account')}{' '}
                </div>
                <UiFormGroup>
                    {isManualInsert && (
                        <>
                            <Ui2FormTextInput
                                label={translate('Routing Number', 'ui.payments')}
                                maxLength={20}
                                name="routingNumber"
                                required
                                type="number"
                            />
                            <Ui2FormTextInput
                                label={translate('Account Number', 'ui.payments')}
                                maxLength={20}
                                name="accountNumber"
                                required
                                type="number"
                            />
                        </>
                    )}

                    {isEnrollment && (
                        <Ui2FormCheckbox
                            className="terms-conditions-checkbox"
                            name="isTermAccepted"
                            label={translate('I agree to Globalpay Terms & Conditions', 'ui.payments')}
                        />
                    )}
                    <UiGroup>
                        <UiButton color="default" block onClick={backButton}>
                            {translate('Back', 'ui.account')}
                        </UiButton>
                        <UiButton type="button" isFormSubmitButton color="primary" block>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                </UiFormGroup>
            </Ui2Form>
        </Wrapper>
    );
}
