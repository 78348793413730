import orderBy from 'lodash/orderBy';
import chunk from 'lodash/chunk';
import React, { useEffect, useState } from 'react';
import SportOdds from '../../../odds/SportOdds';
import { MATCH_STATUS } from '../../../../../services/sports/constants';
import { SportMatchSidebetMarketProps as Props } from '../../../../../services/sports/types';
import Svg from '../../../../svg/Svg';
import Wrapper from '../styles';

export default function SportMatchMarketsHalfTimeFullTime({
    marketType: { market_type_id, view_type, translatedName, markets },
    match,
    collapsed,
    isBetbuilderMarkets = false,
}: Props) {
    const [isCollapsed, setIsCollapsed] = useState(Boolean(collapsed));
    const outcomeOrder = [
        '[Home]/[Home]',
        'Draw/Draw',
        '[Away]/[Away]',
        '[Home]/Draw',
        'Draw/[Home]',
        '[Away]/Draw',
        '[Home]/[Away]',
        'Draw/[Away]',
        '[Away]/[Home]',
    ];
    const market = markets[0];
    const groupedOutcomes = chunk(
        orderBy(market.outcomes, (outcome) => outcomeOrder.indexOf(outcome.result_key)),
        3,
    );

    useEffect(() => {
        setIsCollapsed(Boolean(collapsed));
    }, [collapsed]);

    return (
        <Wrapper className="market-container" $collapsed={isCollapsed}>
            <div
                className="sidebet"
                key={market_type_id}
                style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <div className="sidebet-name" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <div className="name">{translatedName}</div>
                    <div className="toggle-collapse">
                        <Svg icon={isCollapsed ? 'plus-sign' : 'minus-round'} size={0.75} />️
                    </div>
                </div>
                {!isCollapsed &&
                    groupedOutcomes.map((outcomeGroup, index) => (
                        <div className="sidebet-outcomes" key={index}>
                            {outcomeGroup.map(({ id, name }) => (
                                <div className="sidebet-outcome" key={id}>
                                    <div className="sidebet-outcome-name">{name.replace(/\//g, ' / ')}</div>
                                    <SportOdds
                                        light
                                        outcomeId={id}
                                        isBetbuilderOdds={isBetbuilderMarkets}
                                        market={{
                                            ...market,
                                            view_type,
                                            in_play: match.status === MATCH_STATUS.LIVE,
                                            betting_end: match.betting_end,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
            </div>
        </Wrapper>
    );
}
