import groupBy from 'lodash/groupBy';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import React, { useEffect, useMemo, useState } from 'react';
import { useOddsByOutcomeIds } from '../../../../../services/sports/hooks';
import { isOddsOpen } from '../../../../../services/sports/odds';
import SportOdds from '../../../odds/SportOdds';
import { MATCH_STATUS } from '../../../../../services/sports/constants';
import { translate } from '../../../../../services/translate';
import { SportMatchSidebetMarketProps as Props } from '../../../../../services/sports/types';
import Svg from '../../../../svg/Svg';
import Wrapper from '../styles';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';

export default function SportMatchMarketsExact({
    marketType: { markets, view_type: viewType, market_type_id: marketTypeId, translatedName: marketTypeName },
    match: {
        sport_category: sportCategoryId,
        home_team_name: homeTeamName,
        away_team_name: awayTeamName,
        status: matchStatus,
        betting_end: matchBettingEnd,
    },
    collapsed,
    isBetbuilderMarkets = false,
}: Props) {
    const ROWS_LIMIT = 3;
    const market = markets[0];
    const outcomeIds = (market?.outcomes || []).map((outcome) => outcome.id);
    const [oddsByOutcomeId] = useOddsByOutcomeIds(outcomeIds);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId });

    const [isAllDisplayed, setIsAllDisplayed] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(Boolean(collapsed));

    const outcomeNames = !isAmericanLayout
        ? [homeTeamName, translate('ui.sportsbook.draw'), awayTeamName]
        : [awayTeamName, translate('ui.sportsbook.draw'), homeTeamName];

    useEffect(() => {
        setIsCollapsed(Boolean(collapsed));
    }, [collapsed]);

    const groupedOutcomes = useMemo(
        () => market?.outcomes && getSortedExactScoreRows(market),
        [market, isAmericanLayout],
    );

    if (!market?.outcomes) {
        return null;
    }

    function groupScoresByWhichCompetitorWins(outcome) {
        const split = outcome.result_key.split(/[_/]/);

        let number = split[0] - split[1];
        if (isAmericanLayout) {
            number *= -1;
        }

        if (isNumber(number) && !isNaN(number)) {
            const outcomeIndex = Math.sign(number) + 1;
            return outcomeNames[2 - outcomeIndex];
        }
    }

    function getSortedExactScoreRows(market) {
        const openOutcomes = market.outcomes.filter((outcome) => isOddsOpen(outcome, oddsByOutcomeId));
        return groupBy(openOutcomes, groupScoresByWhichCompetitorWins);
    }

    function getRowComponent(outcome, index) {
        return (
            <div className="sidebet-outcome" key={outcome ? outcome.id : index}>
                {outcome && (
                    <>
                        <div className="sidebet-outcome-name">{outcome.name}</div>
                        <SportOdds
                            light={true}
                            outcomeId={outcome.id as number}
                            market={{
                                ...market,
                                view_type: viewType,
                                in_play: matchStatus === MATCH_STATUS.LIVE,
                                betting_end: matchBettingEnd,
                            }}
                            isBetbuilderOdds={isBetbuilderMarkets}
                        />
                    </>
                )}
            </div>
        );
    }

    function getColumnComponent(outcomeName, index) {
        const outcomeGroup = groupedOutcomes[outcomeName as string];
        return (
            <div className="sidebet-outcomes" key={index}>
                {outcomeGroup.slice(0, ROWS_LIMIT).map(getRowComponent)}
                {isAllDisplayed && outcomeGroup.slice(ROWS_LIMIT).map(getRowComponent)}
            </div>
        );
    }

    const filteredNames = outcomeNames.filter((outcomeName) => groupedOutcomes[outcomeName as string]);
    const hasHiddenRows = !!filteredNames
        .map((outcomeName) => groupedOutcomes[outcomeName as string].length > ROWS_LIMIT)
        .filter(Boolean).length;
    const hiddenRowsCount = Math.max(
        ...filteredNames.map((outcomeName) => groupedOutcomes[outcomeName as string].length - ROWS_LIMIT),
    );

    return (
        <Wrapper className="market-container" $collapsed={isCollapsed}>
            <div className="sidebet" key={marketTypeId}>
                <div className="sidebet-name" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <div className="name">{marketTypeName}</div>
                    <div className="toggle-collapse">
                        <Svg icon={isCollapsed ? 'plus-sign' : 'minus-round'} size={0.75} />️
                    </div>
                </div>
                {!isCollapsed && (
                    <>
                        <div className="sidebet-outcomes">
                            {filteredNames.map((outcomeName) => (
                                <div className="sidebet-outcome" key={outcomeName}>
                                    <div className="sidebet-outcome-name">{outcomeName}</div>
                                </div>
                            ))}
                        </div>

                        <div className="sidebet-exact">{filteredNames.map(getColumnComponent)}</div>
                        {hasHiddenRows && (
                            <div className="display-all">
                                <div
                                    className="display-all-line-markets"
                                    onClick={() => setIsAllDisplayed(!isAllDisplayed)}
                                >
                                    {isAllDisplayed
                                        ? translate('Display less', 'ui.sportsbook')
                                        : `${translate('Display all', 'ui.sportsbook')} ${
                                              hiddenRowsCount > 0 && ` (${hiddenRowsCount})`
                                          }`}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Wrapper>
    );
}
