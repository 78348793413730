import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    .sport-category-list {
        margin-top: var(--spacing-8);
    }
    .livebet-match {
        position: relative;
        display: flex;
        align-items: center;
        background-color: var(--surface-level-1-bg);
        border: var(--surface-level-1-border);
        border-radius: var(--ui-radius);
        padding: 0.5rem 0.8rem;
        margin-bottom: 0.5rem;
        color: var(--font-color-primary);
        &.active {
            background-color: var(--button-active-bg);
            color: var(--button-active-text-color);
            border: var(--button-active-border);
            .match-name .team-name:first-of-type {
                border-color: var(--color-highlight);
            }
        }
        .match-name {
            flex: 1;
            align-items: flex-start;
            color: var(--font-color-primary);
            font-size: 0.875rem;
            text-align: left;
            @media ${mediaQuery.isPhone} {
                font-size: 1rem;
            }
            .team-name {
                padding: var(--spacing-8) 0;
                &:first-of-type {
                    border-bottom: 1px dotted #3b3b46;
                }
            }
        }
        .match-score {
            display: flex;
            flex-direction: column;
            width: auto;
            margin: 0;
            .score-divider {
                visibility: hidden;
            }
        }
    }

    .match-time {
        padding: 0 var(--spacing-8);
        max-width: 65px;
        .live-period {
            white-space: pre-wrap;
        }
    }

    .sport-category-tab {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--surface-level-1-bg);
        border: var(--surface-level-1-border);
        border-radius: var(--ui-radius);

        min-height: 40px;
        width: 100%;

        color: var(--font-color-primary);
        font-size: 1rem;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        outline: none;
        cursor: pointer;

        padding: 1rem;
        margin-bottom: 0.4rem;

        .sport-name {
            flex: 1;
            margin: 0 0.5rem;
            text-align: left;
        }
        svg {
            width: 1rem;
        }
    }

    .sub-category {
        color: var(--font-color-subtle);
        font-size: 0.8rem;
        margin: var(--spacing-12);
    }
`;
