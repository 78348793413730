import styled from 'styled-components';

export default styled.div`
    .accounts {
        max-height: 25rem;
        overflow: auto;
    }
    .accounts label {
        border-bottom: var(--surface-level-2-border);
        padding-bottom: 0.4rem;
    }
    .accounts .pseudo-label {
        color: var(--font-color-primary);
        font-size: 0.8rem;
        text-transform: none;
    }
    .form-actions {
        margin-top: 0.6rem;
        display: flex;
        gap: 0.4rem;
    }
    .form-input {
        max-height: 45vh;
        overflow: auto;
    }
    .exchange-rate {
        font-weight: bold;
    }
    .value {
        font-style: italic;
        margin: 0.6rem 0 0.8rem 0;
    }
`;
