import { useState } from 'react';
import useScroll from './useScroll';

export function useScrollPosition() {
    const [scroll, setScroll] = useState(0);

    useScroll(setScroll);

    return scroll;
}
