import React, { useMemo } from 'react';
import { isOfficeIp } from '../../../services/environment';
import { PaymentProvider, PROVIDER_TYPE, PROVIDERS } from '../../../services/payments/types';
import PaymentProvidersSliderRow from './row/PaymentProvidersSliderRow';
import Wrapper from './styles';

interface Props {
    onProviderSelected: (provider: PaymentProvider) => void;
    providers: PaymentProvider[];
    selectedProvider: PaymentProvider | undefined;
    type: PROVIDER_TYPE;
}

export default function PaymentProvidersSlider({ onProviderSelected, providers, selectedProvider, type }: Props) {
    const providerRows = useMemo(() => {
        const numberOfProvidersInRow = 3;
        const numberOfRows = Math.ceil(providers.length / numberOfProvidersInRow);
        const rows = Array.from({ length: numberOfRows }, () => [] as PaymentProvider[]);

        const providersToDisplay = isOfficeIp()
            ? providers
            : providers.filter(({ slug }) => {
                  if (
                      !window.ApplePaySession?.canMakePayments() &&
                      [PROVIDERS.NUVEI_APPLE_PAY, PROVIDERS.EVERY_PAY_APPLE_PAY].includes(slug)
                  ) {
                      return false;
                  }
                  return true;
              });

        providersToDisplay.forEach((provider, index) => {
            const rowIndex = Math.floor(index / numberOfProvidersInRow);
            rows[rowIndex].push(provider);
        });

        return rows;
    }, [providers]);

    return (
        <Wrapper>
            {providerRows.map((providerRow, index) => (
                <PaymentProvidersSliderRow
                    key={index}
                    onProviderSelected={onProviderSelected}
                    rowNumber={index + 1}
                    rowProviders={providerRow}
                    selectedProvider={selectedProvider}
                    type={type}
                />
            ))}
        </Wrapper>
    );
}
