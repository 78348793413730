import styled from 'styled-components';

export default styled.div`
    .promo-banner {
        margin-bottom: 0.4rem;
    }
    > * {
        margin: 0 0 0.8rem 0;
    }
    .betslip-box {
        position: relative;

        background: var(--betslip-bg);
        border: var(--betslip-border);
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        padding: var(--spacing-12);
        color: var(--font-color-primary);
        user-select: none;
        &:empty {
            display: none;
        }

        &-v2 {
            border-bottom: 1px solid #494958;

            &:empty {
                display: none;
            }
        }
    }
    .loyalty-guide {
        position: fixed;
        bottom: 7rem;
        right: 0;
        width: 300px;
        margin: 0 auto;
        z-index: 3;

        color: var(--font-secondary-color);
        svg {
            width: 150px;
        }
        button {
            margin: 0 auto;
        }
    }

    .menu-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 0.6rem;
        text-transform: uppercase;
        color: var(--sport-menu-label-color);
        font-weight: var(--font-weight-bold);
        letter-spacing: 1px;
        user-select: none;
        ${({ theme }) => theme.optimizeFontRendering};

        margin: 0.8rem;

        svg {
            width: 12px;
            opacity: 0.4;
        }
    }

    @keyframes jump {
        0% {
            transform: translate(0, 0) scale(1.15, 0.85);
        }
        50% {
            transform: translate(0, -100%) scale(1, 1);
        }
        100% {
            transform: translate(-0, 0) scale(1.15, 0.85);
        }
    }
    .tickets-history {
        padding: var(--spacing-8);
        background: var(--surface-level-1-bg);
        border: var(--surface-level-1-border);
        border-radius: var(--ui-radius);
    }
    .tickets-list {
        margin-top: var(--spacing-16);
        .show-all-button {
            margin-top: var(--spacing-4);
        }
    }
`;
