import React, { useEffect } from 'react';
import Wrapper from './styles';
import { stores } from '../../stores';
import MainLayout from '../main/MainLayout';
import { loadBlockedProductsByPlayerCountry } from '../../microservices/sbgate';
import UiAppLoader from '../../components/ui/app-loader/UiAppLoader';
import HorseRacingSidebar from '../../components/horse-racing/sidebar/HorseRacingSidebar';
import HorseRacingPhoneNavigation from '../../components/horse-racing/phone/navigation/HorseRacingPhoneNavigation';
import { media } from '../../stores/media/media';
import { useStore } from '../../hooks/useStore';

interface Props {
    children: any;
}

export default function HorseRacingLayout({ children }: Props) {
    const [{ isDesktop, isLaptop }] = useStore(media);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    useEffect(() => {
        if (!isAuthenticated) {
            loadBlockedProductsByPlayerCountry();
        }
    }, [isAuthenticated]);

    const [userSettings] = useStore(stores.sports.userSettings);
    const dependenciesLoaded = userSettings;

    function HorseRacingLayoutComponent() {
        return (
            <Wrapper>
                <div className="sport-content">
                    <div className="sport-menu" />

                    <div className="sport-matches">{dependenciesLoaded ? children : <UiAppLoader />}</div>

                    {isDesktop || isLaptop ? (
                        <div className="sport-sidebar">
                            <HorseRacingSidebar />
                        </div>
                    ) : (
                        <HorseRacingPhoneNavigation />
                    )}
                </div>
            </Wrapper>
        );
    }
    return <MainLayout>{HorseRacingLayoutComponent()}</MainLayout>;
}
