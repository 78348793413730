import React, { useEffect, useState } from 'react';
import PaymentDepositProviderEveryPayGooglePayScripts from './scripts/PaymentDepositProviderEveryPayGooglePayScripts';
import { logger } from '../../../../../../services/logger';
import { PROVIDERS } from '../../../../../../services/payments/types';
import { depositWithProvider } from '../../../../../../services/payments/payments';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import { isProduction } from '../../../../../../services/util';

interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderEveryPayGooglePay({ amount, deviceHash, onClose }: Props) {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);

    useEffect(() => {
        if (hasScriptsLoaded) {
            loadClient();
        }
    }, [hasScriptsLoaded]);

    function loadClient() {
        try {
            const googlePayClient = GooglePayClient.initialize({ testEnv: !isProduction() });
            googlePayClient.addGooglePayButton({
                buttonColor: 'white',
                buttonType: 'plain',
                buttonSizeMode: 'fill',
                onClick: tryDeposit,
            });
        } catch (error) {
            logger.error('PaymentDepositProviderEveryPayGooglePay', 'loadClient', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    async function tryDeposit() {
        try {
            const data = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.EVERY_PAY_GOOGLE_PAY,
                providerParams: {},
            });
            GooglePayClient.configure(data);
            GooglePayClient.createGooglePayPayment();
        } catch (error) {
            logger.error('PaymentDepositProviderEveryPayGooglePay', 'tryDeposit', error);
            setIsError(true);
        }
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <>
            <div id="google-pay-container" />
            <PaymentDepositProviderEveryPayGooglePayScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
            {(!hasScriptsLoaded || isLoading) && <UiDotsLoader />}
        </>
    );
}

declare let GooglePayClient: any;
