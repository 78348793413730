import React from 'react';
import {
    calculateTotalOdds,
    getStakeValueByMarketId,
    hasBetSlipChangedFromOdds,
} from '../../../../services/sports/betslip';
import { getFixedStake } from '../../../../services/sports/betslip-formatting';
import { getErrorCode } from '../../../../services/sports/betslip-errors';
import { COMBO_MARKET_ID, MA_ENABLED_ERROR, MULTI_SINGLE_MARKET_ID } from '../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../services/sports/manual-acceptance-helpers';
import { useOddsFormat } from '../../../../services/sports/user-settings';
import { translate } from '../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../stores';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { useStore } from '../../../../hooks/useStore';
import { BonusType } from '../../../../services/bonuses/types';
import { useMaxWin } from '../../../../hooks/betslip';
import SportBetslipV2WageToWin from '../wage-to-win/SportBetslipV2WageToWin';
import SportBetslipStakeInputExtraWin from '../../betslip/stake-input/extra-win/SportBetslipStakeInputExtraWin';

interface Props {
    marketId: string | typeof COMBO_MARKET_ID;
}

export default function SportBetslipV2StakeInput({ marketId }: Props) {
    const [userState, setUserState] = useStore(stores.sports.betSlipUserState);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [teaserPayouts] = useStore(stores.sports.teaserPayouts);
    const [genericErrors] = useStoreWithSelector(
        stores.sports.betSlipErrorByMarketId,
        (state) => state[String(COMBO_MARKET_ID)],
    );
    const [betSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [oddsIds] = useStoreWithSelector(stores.sports.oddsByOutcomeId, hasBetSlipChangedFromOdds);
    const [bonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const { stakeByMarketId, MAStakeByMarketId, disableInputs, betType } = userState;
    const stakeValue = getStakeValueByMarketId(marketId);
    const [oddsFormat] = useOddsFormat();

    const totalOdds = calculateTotalOdds(marketId);

    const { maxWin } = useMaxWin(marketId, [
        stakeValue,
        oddsIds,
        betSlipMarketIdToOutcomeId,
        oddsFormat,
        betType,
        teaserSelectedPoint,
        teaserPayouts,
    ]);

    function stakeChange(stakeValue: number) {
        if (marketId === MULTI_SINGLE_MARKET_ID) {
            applyStakeForAllSingles(stakeValue);
        } else {
            setUserState((state) => ({
                ...state,
                stakeByMarketId: {
                    ...stakeByMarketId,
                    [String(marketId)]: getFixedStake(stakeValue),
                    [MULTI_SINGLE_MARKET_ID]: 0,
                },
            }));
        }
    }

    function MAStakeChange(stakeValue) {
        setUserState((state) => ({
            ...state,
            MAStakeByMarketId: { ...MAStakeByMarketId, [String(marketId)]: getFixedStake(stakeValue) },
        }));
    }

    function applyStakeForAllSingles(stake: number) {
        setUserState((state) => ({
            ...state,
            stakeByMarketId: Object.keys(betSlipMarketIdToOutcomeId).reduce(
                (stakeByMarketId, marketId) => {
                    stakeByMarketId[marketId] = stake;
                    return stakeByMarketId;
                },
                { [MULTI_SINGLE_MARKET_ID]: getFixedStake(stake) },
            ),
        }));
    }

    const loading = betSlipPlacingState.isLoading || betSlipPlacingState.needsConfirm;
    const hasManualAcceptanceError = hasBetslipManualAcceptanceError();
    const isManualAcceptanceStakeAllowed = (genericErrors || []).map(getErrorCode).includes(MA_ENABLED_ERROR);
    const isMainInputDisabled = loading || disableInputs || hasManualAcceptanceError;
    const isFreeBetApplied = bonusBetsSelection?.free_bet || bonusBetsSelection?.free_bet_v2;

    return (
        <>
            <SportBetslipV2WageToWin
                name={`yourStake${marketId || 'Combo'}`}
                marketId={marketId}
                isDisabled={isMainInputDisabled}
                value={stakeValue}
                disableToWin={false}
                totalOdds={marketId === MULTI_SINGLE_MARKET_ID ? 1 : totalOdds}
                onChange={stakeChange}
                hideQuickStakes={hasManualAcceptanceError}
                disableQuickStakes={isFreeBetApplied}
            />

            {hasManualAcceptanceError && isManualAcceptanceStakeAllowed && (
                <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
                    <SportBetslipV2WageToWin
                        name={`extraStake${marketId || 'Combo'}`}
                        label={translate('Apply extra stake', 'ui.sportsbook')}
                        isDisabled={loading}
                        value={(MAStakeByMarketId[String(marketId)] as any) || 0}
                        onChange={MAStakeChange}
                        hideQuickStakes
                    />
                </UiAnimate>
            )}

            {bonusBetsSelection[BonusType.Multiplier] && (
                <SportBetslipStakeInputExtraWin maxWin={maxWin} stake={stakeValue} />
            )}
        </>
    );
}
