import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getUserCountry } from '../services/users/country';
import { FoMatch } from '@staycool/sports-types';
import { WithLanguageLayout } from '@staycool/sbgate-types';
import { setSingleCategoryMarketTypesToStore } from '../services/sports/sport-category';
import { Campaign, LeaderBoard, SportCategoryWithMatches } from '../services/sports/types';
import { Market as LocationMarket } from '@staycool/location';
import { getSportsLayout } from '../services/layout/utils';

const getUrl = (url) => getServiceUrl('sb-campaigns', url);

export function getCampaigns(params: { locale: string; market: LocationMarket }) {
    const url = getUrl('campaign');
    return httpGet<Campaign[]>(url, params);
}

export function postCampaignBet(campaignBetRequest) {
    return httpPost<string>(getUrl('bets'), campaignBetRequest);
}

export function getLeaderboard(campaignId, day) {
    const url = day ? `leaderboard/${campaignId}/total/${day}` : `leaderboard/${campaignId}/daily`;
    return httpGet<LeaderBoard[]>(getUrl(url));
}

export function getOddsCompetitionLeaderboard(campaignId) {
    const url = getUrl(`leaderboard/${campaignId}/oddsCompetition`);
    return httpGet<{ leaderBoard: LeaderBoard[]; campaign: Campaign[] }>(url);
}

export function getLeaderboardCounter(campaignId) {
    const url = getUrl(`leaderboard/${campaignId}/counter`);
    return httpGet<number>(url);
}
type GetCampaignMatchesExtraParams = Pick<FoMatch.FoMatchListFilteredCampaigns, 'product' | 'offset' | 'limit'>;
type CampaignsRequestMatchList = Pick<
    WithLanguageLayout<FoMatch.FoMatchListFilteredCampaigns>,
    'offset' | 'limit' | 'country' | 'locale' | 'layout' | 'language' | 'province'
>;

export async function getCampaignMatches(campaignId: number, params: GetCampaignMatchesExtraParams) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const query: CampaignsRequestMatchList = {
        ...params,
        language,
        locale: language,
        country,
        layout,
    };
    const categoryWithMatches = await httpGet<SportCategoryWithMatches>(
        getUrl(`campaign/matches/${campaignId}`),
        query,
    );
    setSingleCategoryMarketTypesToStore(categoryWithMatches);
    return categoryWithMatches?.matches;
}
