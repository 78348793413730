import React, { useEffect, useRef, useState } from 'react';
import {
    MarketTypeComponentByMarketType,
    MarketTypeGroupIdPopular,
    useMarketTypeSplitter,
    useSidebetSearch,
} from '../../../../services/sports/sidebets';
import { CategoryMatch, FoGroup, FoSidebetsMarketGroup } from '../../../../services/sports/types';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { storageGet, storageSet } from '../../../../services/storage';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import UiButton from '../../../ui/button/UiButton';
import UiAlert from '../../../ui/alert/UiAlert';
import UiColumns from '../../../ui/columns/UiColumns';
import SportMatchBetbuilder from '../betbuilder/SportMatchBetbuilder';
import SportMatchMarketsMarketGroupFilter from './market-group-filter/SportMatchMarketsMarketGroupFilter';
import SportMatchMarketsSmart from './smart/SportMatchMarketsSmart';
import Wrapper from './styles';
import SportMatchMarketsTableLike from './table-like/SportMatchMarketsTableLike';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import { isFeatureAvailable } from '../../../../services/feature';
import { MATCH_STATUS } from '../../../../services/sports/constants';
import { isB2B } from '../../../../services/environment';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import { getIsBetbuilderMatch, isBetBuilderFeatureAvailable } from '../../../../services/bet-builder';
interface Props {
    marketsByMarketType?: FoSidebetsMarketGroup[];
    match: CategoryMatch;
    allMarketTypeGroupFilters?: FoGroup[];
    currentMarketTypeGroup?: number;
    setCurrentMarketTypeGroup?;
    isBetbuilderShown?: boolean;
}

function SportMatchMarkets({
    marketsByMarketType = [],
    match,
    allMarketTypeGroupFilters,
    currentMarketTypeGroup = MarketTypeGroupIdPopular,
    setCurrentMarketTypeGroup = () => {},
    isBetbuilderShown = false,
}: Props) {
    const START_COLLAPSING_MARKETS_COUNT = 3;
    const MARKETS_COLLAPSE_SETTING = 'marketsCollapseSetting';
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: match.sport_category });
    const sidebetsContainer = useRef<HTMLDivElement>(null);
    const [{ deviceType, isPhone }] = useStore(media);
    const [isAllMarketsExpanded, setIsAllMarketsExpanded] = useState(Boolean(storageGet(MARKETS_COLLAPSE_SETTING)));
    const [hasUserClickedCollapseButton, setHasUserClickedCollapseButton] = useState(false);
    const betBuilderInfoStatus = Boolean(storageGet('betBuilderInfoDismissed'));
    const [isBetBuilderInfoDismissed, setIsBetBuilderInfoDismissed] = useState(Boolean(betBuilderInfoStatus));
    const isBetbuilderMatch = getIsBetbuilderMatch(match);
    const isLiveNow = match.status === MATCH_STATUS.LIVE;
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);

    const searchedSidebetMarkets = useSidebetSearch(
        marketsByMarketType,
        currentMarketTypeGroup,
        setCurrentMarketTypeGroup,
    );

    const { smallColumnAmount, smallColumnMarkets, bigColumnAmount, bigMarketTypesSplit } = useMarketTypeSplitter(
        searchedSidebetMarkets || marketsByMarketType,
        deviceType,
        match.match_type,
    );
    const isBetBuilderVisible = isBetBuilderFeatureAvailable() && !isLiveNow && isBetbuilderMatch;

    useEffect(() => {
        if (!marketsByMarketType.length) {
            setCurrentMarketTypeGroup(allMarketTypeGroupFilters?.[0]?.id || MarketTypeGroupIdPopular);
        }
    }, [marketsByMarketType, smallColumnMarkets]);

    function dismissBetBuilderInfo() {
        setIsBetBuilderInfoDismissed(true);
        storageSet('betBuilderInfoDismissed', true);
    }

    function hasMultipleMarkets() {
        return smallColumnMarkets?.length > 1;
    }

    function toggleMarketsCollapse() {
        storageSet(MARKETS_COLLAPSE_SETTING, !isAllMarketsExpanded);
        setIsAllMarketsExpanded(!isAllMarketsExpanded);
        setHasUserClickedCollapseButton(true);
    }

    const showSearch =
        (match?.markets_total_count ?? marketsByMarketType.length) > (isPhone ? 3 : 6) ||
        (allMarketTypeGroupFilters?.length || 0) > 4;

    return (
        <Wrapper $isAmericanLayout={isAmericanLayout} ref={sidebetsContainer} $isB2B={isB2B()}>
            {!isBetBuilderInfoDismissed && isBetBuilderVisible && (
                <div className="info-box">
                    <UiCloseButton className="close-button" onClick={dismissBetBuilderInfo} />
                    <div className="flex gap-16">
                        <Svg icon="betbuilder-full" width={isLayoutB ? 6 : 4} />
                        <div>
                            <div className="info-box-title">
                                <b>{translate('ui.betbuilder.title')}</b>
                            </div>
                            <div>{translate('ui.betbuilder.description')}</div>
                        </div>
                    </div>
                </div>
            )}
            <SportMatchMarketsMarketGroupFilter
                allMarketTypeGroupFilters={allMarketTypeGroupFilters}
                currentMarketTypeGroup={currentMarketTypeGroup}
                onSetCurrentMarketTypeGroup={setCurrentMarketTypeGroup}
                showSearch={showSearch}
            />
            {!isBetbuilderShown && hasMultipleMarkets() && (
                <UiButton
                    block
                    size="small"
                    color="outline"
                    onClick={toggleMarketsCollapse}
                    icon={<Svg icon={isAllMarketsExpanded ? 'minus-round' : 'plus-sign'} size={0.75} />}
                >
                    {isAllMarketsExpanded
                        ? translate('Collapse all markets', 'ui.sportsbook')
                        : translate('Expand all markets', 'ui.sportsbook')}
                </UiButton>
            )}
            {searchedSidebetMarkets?.length === 0 && <UiAlert info>{translate('No markets', 'ui.sportsbook')}</UiAlert>}
            {!isBetbuilderShown && (
                <div className="sidebets-layout">
                    <UiColumns columnsAmount={smallColumnAmount} layoutInRows>
                        {smallColumnMarkets.map((marketType, index) => {
                            const collapsed = isAllMarketsExpanded
                                ? false
                                : hasUserClickedCollapseButton
                                ? true
                                : index >= START_COLLAPSING_MARKETS_COUNT;

                            const ComponentByMarketTypeElement = MarketTypeComponentByMarketType[marketType.view_type];
                            if (ComponentByMarketTypeElement) {
                                return (
                                    <ComponentByMarketTypeElement
                                        marketType={marketType}
                                        match={match}
                                        key={marketType.market_type_id}
                                        collapsed={collapsed}
                                    />
                                );
                            }

                            const isTableLike =
                                ['team_outright', 'player_outright'].includes(marketType.view_type) ||
                                marketType.markets?.some((market) => market.outcomes?.length > 3);
                            if (isTableLike) {
                                return (
                                    <SportMatchMarketsTableLike
                                        marketType={marketType}
                                        match={match}
                                        key={marketType.market_type_id}
                                        collapsed={collapsed}
                                    />
                                );
                            }
                            return (
                                <SportMatchMarketsSmart
                                    key={marketType.markets[0]?.id}
                                    marketType={marketType}
                                    match={match}
                                    collapsed={collapsed}
                                />
                            );
                        })}
                    </UiColumns>
                </div>
            )}
            {isBetbuilderShown && <SportMatchBetbuilder match={match} />}
            {!isBetbuilderShown && bigMarketTypesSplit && (
                <div className="sidebets-layout">
                    <UiColumns columnsAmount={bigColumnAmount} layoutInRows>
                        {bigMarketTypesSplit.map((marketType) => (
                            <SportMatchMarketsTableLike
                                key={marketType.markets?.[0]?.id}
                                marketType={marketType}
                                match={match}
                                collapsed={
                                    isAllMarketsExpanded
                                        ? false
                                        : smallColumnMarkets.length > START_COLLAPSING_MARKETS_COUNT
                                }
                            />
                        ))}
                    </UiColumns>
                </div>
            )}
        </Wrapper>
    );
}

export default React.memo(SportMatchMarkets, (prev, next) => {
    return (
        prev.match.id === next.match.id &&
        prev.marketsByMarketType === next.marketsByMarketType &&
        prev.isBetbuilderShown === next.isBetbuilderShown
    );
});
