import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { stringify } from 'query-string';
import { getCasinoGames } from '../../microservices/casino';
import { stores } from '../../stores';
import { casino } from '../../stores/casino';
import { environment } from '../../stores/environment/environment';
import { getStoreValue } from '../../stores/store/utils';
import { requestLogin } from '../auth';
import { isB2B } from '../environment';
import { isFeatureAvailable } from '../feature';
import { logger } from '../logger';
import { isMobileApp, NativeMessageEvent, NativeMessageEventType, sendNativeEvent } from '../mobile-app';
import { AppType } from '../mobile-app/types';
import { getRoute } from '../router';
import { FEATURE } from '../types';
import { isTestUser } from '../user';
import { getDefaultQueryParams } from './casino';
import {
    CasinoCategory,
    CasinoGame,
    CasinoGameLauncher,
    CasinoMaintenance,
    CasinoProviders,
    CasinoSubProvider,
} from './types';

export const LAST_WINNINGS_MAX_SIZE = 7;
export const LAST_WINNINGS_CRASH_MAX_SIZE = 5;

export const CRAZY_TIME_FEATURE = 'crazytime';

export const INFINITE_SEATS_FEATURE = 'infinite seats';

export const CasinoInternalBaccaratResults = {
    PLAYER: 'P',
    DEALER: 'B',
    TIE: 'T',
    HOME: 'H',
    AWAY: 'A',
    DRAW: 'D',
    LEFT: 'L',
    RIGHT: 'R',
    SUITED_TIE: 'S',
    DRAGON: 'Dn',
    TIGER: 'Tr',
} as const;

export const CrazyResults = {
    NUM_1: '1',
    NUM_2: '2',
    NUM_5: '5',
    NUM_10: '10',
    CA: 'ca',
    PA: 'pa',
    FL: 'fl',
    FL_R: 'fl_r',
    FL_B: 'fl_b',
    BO: 'bo',
} as const;

export interface OnAirEntertainmentMessage {
    Name: string;
    Body: {
        gameId: string;
    };
    Type: string;
    Origin: string;
    SenderId: string;
    Id: string;
    Bridge: string;
}

export enum CasinoGameProperties {
    LINES_SPECIAL = 1,
    RTP_SPECIAL = 2,
    X_WIN_SPECIAL = 53,
}

export function mapCasinoGames(): void {
    const games = getStoreValue<CasinoGame[]>(casino.filteredGames);
    const gamesMap = getStoreValue<{ [key: number]: CasinoGame }>(casino.gamesById);
    const allGames = getStoreValue(casino.allGames);

    casino.gamesBySlug.set(keyBy(games, (game) => getCasinoGameSlug(game.name, game.id)));
    casino.gamesBySlugOld.set(keyBy(games, (game) => getCasinoGameSlugOld(game.name)));
    casino.gamesByServerId.set(keyBy(games, 'serverGameId'));
    casino.gamesByTableId.set(keyBy(games, 'tableId'));
    casino.gamesByExternalId.set(keyBy(games, 'externalId'));

    const gamesByProviderAndServerId = {};
    const gamesByProviderAndTableId = {};
    const allGamesByProviderAndServerId = {};
    const allGamesByExternalId = {};

    for (const game of games) {
        if (!gamesByProviderAndServerId[game.providerId]) {
            gamesByProviderAndServerId[game.providerId] = {};
        }
        gamesByProviderAndServerId[game.providerId][game.serverGameId] = game;

        if (game.tableId) {
            if (!gamesByProviderAndTableId[game.providerId]) {
                gamesByProviderAndTableId[game.providerId] = {};
            }
            gamesByProviderAndTableId[game.providerId][game.tableId] = game;
        }
    }

    for (const game of allGames) {
        if (!allGamesByProviderAndServerId[game.providerId]) {
            allGamesByProviderAndServerId[game.providerId] = {};
        }
        allGamesByProviderAndServerId[game.providerId][game.serverGameId] = game;

        if (game.externalId) {
            allGamesByExternalId[game.externalId] = game;
        }
    }

    casino.gamesByProviderAndServerId.set(gamesByProviderAndServerId);
    casino.gamesByProviderAndTableId.set(gamesByProviderAndTableId);
    casino.allGamesByProviderAndServerId.set(allGamesByProviderAndServerId);
    casino.allGamesByExternalId.set(allGamesByExternalId);

    const gamesByCategoryId = {} as { [key: number]: CasinoGame[] };

    getStoreValue<CasinoCategory[]>(casino.categories).forEach((category) => {
        gamesByCategoryId[category.id] = category.games.reduce((categoryGames, gameId) => {
            if (gamesMap[gameId] && (gamesMap[gameId].isPublic || isTestUser())) {
                categoryGames.push(gamesMap[gameId]);
            }
            return categoryGames;
        }, [] as CasinoGame[]);
    });

    casino.gamesByCategoryId.set(gamesByCategoryId);
}

export function setCasinoGameMode(isPlayForReal: boolean): void {
    if (isPlayForReal && !getStoreValue(stores.isAuthenticated)) {
        requestLogin();
        return;
    }

    casino.playForReal.set(isPlayForReal);
}

export function getCasinoGameSlug(name: string, id: number): string {
    return name
        .toLowerCase()
        .replace(/#|_|:|\.|;|,|!|^|\*|'|`|\?|’|%/g, '')
        .replace(/\/|\\/g, '-')
        .split(' ')
        .join('-')
        .concat(`-${id}`);
}

/**
 * @deprecated try not to use. Exists for old emails and promotions that contain game urls.
 */
function getCasinoGameSlugOld(name: string): string {
    return name
        .toLowerCase()
        .replace(/#|_|:|\.|;|,|!|^|\*|'|`|’/g, '')
        .split(' ')
        .join('-');
}

export function searchCasinoGames(searchWord: string): CasinoGame[] {
    if (!searchWord) {
        return [];
    }

    const filterRegex = /[^a-zA-Z0-9 ]/g;
    const searchWordModified = searchWord.replace(filterRegex, '').toLowerCase();

    const games: CasinoGame[] = getStoreValue(casino.allGames).filter((game) => game.isPublic || isTestUser());
    return uniqBy(
        [
            ...games.filter((game) => game.name.replace(filterRegex, '').toLowerCase().startsWith(searchWordModified)),
            ...games.filter((game) => game.name.replace(filterRegex, '').toLowerCase().includes(searchWordModified)),
        ],
        'id',
    );
}

export function getGamesByThemeId(themeId: number | undefined): CasinoGame[] {
    if (!themeId) {
        return [];
    }
    return getStoreValue(casino.allGames).filter(
        (game) => (game.isPublic || isTestUser()) && game.themes.includes(themeId),
    );
}

export function getGameImageUrl(imageName: string | null, imageWidthInPx = 300): string {
    return getGameImageUrlForImage(imageName, {
        dpr: Math.min(window.devicePixelRatio, 2),
        w: imageWidthInPx,
        auto: 'format',
    });
}

export function getSubproviderLogoUrl(subproviderId: number, imageWidthInPx = 300): string {
    return getSubproviderLogoUrlForLogo(subproviderId, {
        dpr: Math.min(window.devicePixelRatio, 2),
        w: imageWidthInPx,
        auto: 'format',
    });
}

export function getGameImageUrlForImage(imageName: string | null, params): string {
    if (imageName === null) {
        return '';
    }

    let url = `${getStoreValue(environment).CASINO_GAMES_PICS}`;
    if (imageName === null) {
        return '';
    }
    if (imageName.startsWith('http')) {
        url = '';
    } else if (isB2B()) {
        url = `${getStoreValue(environment).IMAGES_HOST}${getStoreValue(environment).CLIENT_NAME}/casino/games/`;
    }

    return `${url}${imageName}?${stringify(params)}`;
}

function getSubproviderLogoUrlForLogo(subproviderId: number, params = {}): string {
    const url = isB2B()
        ? `${getStoreValue(environment).IMAGES_HOST}/${getStoreValue(environment).CLIENT_NAME}/casino/providers`
        : `${getStoreValue(environment).CASINO_GAMES_PICS}providers`;
    return `${url}/${subproviderId}.png?${stringify(params)}`;
}

export function getCasinoGameBackgroundUrl(backgroundImageName: string | null, params = {}): string {
    if (backgroundImageName === null) {
        return '';
    }

    let url = `${getStoreValue(environment).CASINO_GAMES_PICS}`;

    if (backgroundImageName.startsWith('http')) {
        url = '';
    } else if (isB2B()) {
        url = `${getStoreValue(environment).IMAGES_HOST}${getStoreValue(environment).CLIENT_NAME}/casino/games/`;
    }

    return `${url}${backgroundImageName}?${stringify({
        fm: 'pjpg',
        dpr: Math.min(window.devicePixelRatio, 2),
        auto: 'format',
        ...params,
    })}`;
}

export function isCasinoGameBlockedByBonus(disabledWithBonus: boolean): boolean {
    return Boolean(getStoreValue(casino.hasActiveBonus) && disabledWithBonus);
}

export function isCasinoGameUnderMaintenance(providerId: CasinoProviders, subProviderId?: CasinoSubProvider): boolean {
    const maintenance: CasinoMaintenance = getStoreValue(casino.maintenance)[providerId];

    if (!maintenance) {
        return false;
    }

    return !subProviderId || isEmpty(maintenance.subproviderIds) || maintenance.subproviderIds.includes(subProviderId);
}

export function isCasinoGameDisabled(gameLauncher: CasinoGameLauncher): boolean {
    return (
        (gameLauncher.disabledWithBonus && isCasinoGameBlockedByBonus(gameLauncher.disabledWithBonus)) ||
        isCasinoGameUnderMaintenance(gameLauncher.providerId, gameLauncher.subProviderId)
    );
}

export function getCasinoGameRoute(game: { id: number; name: string }): string {
    return `${getRoute('casino-game')}/${getCasinoGameSlug(game.name, game.id)}`;
}

export function openCasinoGame(navigationHandler, game: CasinoGame): void {
    const isAuthenticated = getStoreValue(stores.isAuthenticated);
    if (!game || isCasinoGameDisabled(game)) {
        return;
    }

    if (!hasCasinoGamePlayForFun(game) && !isAuthenticated) {
        requestLogin();
        return;
    }

    navigateToCasinoGamePage(navigationHandler, game);
}

export function navigateToCasinoGamePage(navigationHandler, game: CasinoGame) {
    if (isMobileApp() && !getStoreValue(stores.isAuthenticated) && !hasCasinoGamePlayForFun(game)) {
        requestLogin();
        return;
    }

    const gameRoute = getCasinoGameRoute(game);
    navigationHandler(gameRoute);
}

export function sendGameUrlNativeMessageOrElseReplaceGameUrl(game: CasinoGame) {
    if (isMobileApp() && !getStoreValue(stores.isAuthenticated) && !hasCasinoGamePlayForFun(game)) {
        requestLogin();
        return;
    }

    const gameRoute = getCasinoGameRoute(game);
    window.history.pushState({}, '', gameRoute);
}

export function sendMobileAppGameLaunchNativeMessage(game: CasinoGame) {
    const gameLink = `${window.location.origin}${getCasinoGameRoute(game)}${window.location.search}`;
    const message: NativeMessageEvent = {
        type: NativeMessageEventType.GAME_LAUNCH,
        url: gameLink,
    };
    sendNativeEvent(message);
}

export function hasCasinoGamePlayForFun(gameLauncher: CasinoGameLauncher): boolean {
    if (gameLauncher.subProviderId === CasinoSubProvider.EVOLUTION) {
        return false;
    }

    if (!isFeatureAvailable(FEATURE.CASINO_PLAY_FOR_FUN_ENABLED)) {
        return false;
    }

    if (gameLauncher?.id) {
        // Pragmatic Play hack (https://coolbet.atlassian.net/browse/CASINOFEAT-1985)
        // 4051 = stage
        // 4375 = prod
        if (gameLauncher?.name?.toLowerCase() === 'spaceman' && [4051, 4375].includes(gameLauncher.id)) {
            return false;
        }
        // Pragmatic Play hack (https://gan-tech.atlassian.net/browse/CCASPLAT-518)
        // not on stage
        // 7993 = prod
        if (gameLauncher?.name?.toLowerCase() === 'big bass crash' && gameLauncher.id === 7993) {
            return false;
        }
    }

    return gameLauncher.gameType !== 'live';
}

export async function loadCasinoGames(appType: AppType): Promise<void> {
    try {
        const defaultParams = getDefaultQueryParams(appType);
        const params = isTestUser() ? { ...defaultParams, isTestUser: true } : defaultParams;

        const games = await getCasinoGames(params);

        casino.allGames.set(games);
        loadFilteredCasinoGames();
    } catch (error) {
        logger.error('CasinoGamesService', 'loadCasinoGames', error);
    }
}

export function loadFilteredCasinoGames(): void {
    const closedGames = getStoreValue(casino.closedGames);
    const notFilteredGames = getStoreValue(casino.allGames);

    if (!isTestUser()) {
        casino.filteredGames.set(notFilteredGames.filter((game) => !Boolean(closedGames[game.id])));
    } else {
        casino.filteredGames.set(notFilteredGames);
    }
    casino.gamesById.set(keyBy(getStoreValue(casino.filteredGames), 'id'));
    casino.gamesByGroupId.set(keyBy(getStoreValue(casino.filteredGames), 'groupId'));
}
