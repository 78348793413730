import React from 'react';
import { Tooltip } from 'react-tooltip';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import classNames from 'classnames';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';
import { SportsLayout } from '../../../../../services/layout/types';

interface Props {
    key: number;
}

export default function SportSettingsMenuOptionAmericanLayout({ key }: Props) {
    const [{ isPhone }] = useStore(media);
    const { layout, toggleLayout } = useSportsUserSettings();

    return (
        <div className="setting-item item-row" key={key}>
            <div className="item-label">
                <div className="label-text">{translate('American layout', 'sport.settings')}</div>
                <Tooltip id="layout-tooltip" variant="light" place={isPhone ? 'bottom' : 'top'} className="tooltip">
                    <span>
                        {translate(
                            'Enable American layout for: American Football, Ice Hockey, Basketball and Baseball',
                            'sport.settings',
                        )}
                    </span>
                </Tooltip>
                <Svg icon="info" className="help-bubble" data-tooltip-id="layout-tooltip" size={isPhone ? 1.3 : 1} />
            </div>
            <div>
                <UiFormGroup className="toggle">
                    <UiFormInput
                        className={classNames({
                            'toggle-enabled': layout === SportsLayout.AMERICAN,
                        })}
                        toggle
                        value={layout === SportsLayout.AMERICAN}
                        name="american-view"
                        onChange={toggleLayout}
                        nowrap
                    />
                </UiFormGroup>
            </div>
        </div>
    );
}
