import React, { useEffect } from 'react';
import { useStore } from '../../../hooks/useStore';
import { stores } from '../../../stores';
import { FoHotEvent } from '@staycool/sbgate-types';
import { SportCategoryNavigation, loadHotEvents } from '../../../microservices/sbgate';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../services/analytics';
import { getRoute } from '../../../services/router';
import { translate } from '../../../services/translate';
import SportMenuV2 from '../menu-v-2/SportMenuV2';
import SportCategoryListV2 from '../category/list-v-2/SportCategoryListV2';
import SportMenuV2NavLink from '../menu-v-2/nav-link/SportMenuV2NavLink';

interface Props {
    isVertical?: boolean;
}

export default function SportHotEventsV2({ isVertical }: Props) {
    const [hotEvents] = useStore(stores.sports.hotEvents);
    const hotEventsOrPlaceholders: SportCategoryNavigation[] = !hotEvents
        ? (Array(5).fill({}) as SportCategoryNavigation[])
        : [
              ...(!isVertical
                  ? [
                        {
                            id: 'recommendations',
                            url: getRoute('sport.recommendations'),
                            icon: 'all',
                            label: translate('All', 'ui.common'),
                            onClick: handleHotEventClick,
                        },
                    ]
                  : []),
              ...hotEvents.map((hotEvent) => ({
                  id: hotEvent.category_id,
                  url: getUrl(hotEvent),
                  icon: getIcon(hotEvent),
                  label: hotEvent.display_name,
                  onClick: handleHotEventClick,
              })),
          ];

    useEffect(() => {
        loadHotEvents();
    }, []);

    function handleHotEventClick() {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_HOT_EVENT_CLICKED);
        stores.sports.isSportSideMenuOpen.set(false);
    }

    function getUrl(hotEvent: FoHotEvent) {
        if (hotEvent.match_id) {
            return `${getRoute('sport')}/match/${hotEvent.match_id}`;
        }

        return `${getRoute('sport')}/${hotEvent.slug}`;
    }

    function getIcon(hotEvent: FoHotEvent) {
        return `${hotEvent.sport_icon ?? hotEvent.league_icon}`;
    }

    if (!hotEvents?.length) {
        return null;
    }

    if (isVertical) {
        return (
            <SportMenuV2 label={translate('Hot Events', 'ui.sportsbook')}>
                {hotEventsOrPlaceholders.map(({ id, url, icon, label, onClick }) => (
                    <SportMenuV2NavLink key={id} to={url} onClick={onClick} icon={icon} label={label} />
                ))}
            </SportMenuV2>
        );
    }

    return <SportCategoryListV2 list={hotEventsOrPlaceholders} />;
}
