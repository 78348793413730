export const ClientName = {
    COOLBET: 'coolbet',
    DEMO: 'demo',
    LOCKER01: 'l1',
    IVC: 'ivc',
    WYNNBET: 'wynnbet',
    WYNNBET_NEVADA: 'wynnbet-nv',
    STATION: 'station',
    CLUB8: 'club8',
    FONTAINEBLEAU: 'fontainebleau-nv',
    SOARING_EAGLE: 'soaring-eagle',
    OAKLAWN: 'oaklawn',
    MAVERICK: 'maverick',
} as const;
