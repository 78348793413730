import { getServiceUrl, httpGet } from '../services/api';
import { logger } from '../services/logger';
import { stores } from '../stores';

const getUrl = (url) => getServiceUrl('sports/statistics', url);

export async function getLiveMatchesCount() {
    try {
        const response = await httpGet<{ count: number }>(`${getUrl('live-status-count')}`);
        stores.sports.liveMatchesCount.set(response.count);
        return response;
    } catch (e) {
        logger.error('SportsStatisticsMicroservice', 'getLiveMatchesCount', e);
        return { count: 0 };
    }
}
