import styled from 'styled-components';

export default styled.section`
    .selections {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-8);
        margin: var(--spacing-8) 0;
    }
`;
