import React, { useMemo } from 'react';
import Wrapper from './styles';
import PaymentProvidersSliderProvider from '../provider/PaymentProvidersSliderProvider';
import { PROVIDER_TYPE, PaymentProvider } from '../../../../services/payments/types';

interface Props {
    rowNumber: number;
    rowProviders: PaymentProvider[];
    selectedProvider: PaymentProvider | undefined;
    onProviderSelected: (provider: PaymentProvider) => void;
    type: PROVIDER_TYPE;
}

export default function PaymentProvidersSliderRow({
    rowNumber,
    rowProviders,
    selectedProvider,
    onProviderSelected,
    type,
}: Props) {
    function getIsActiveProvider(provider) {
        return selectedProvider === provider;
    }
    const isActiveRow = useMemo(() => {
        return rowProviders.includes(selectedProvider as PaymentProvider);
    }, [selectedProvider]);

    return (
        <Wrapper>
            {rowProviders.map((provider) => (
                <PaymentProvidersSliderProvider
                    key={provider.id}
                    active={getIsActiveProvider(provider)}
                    isActiveRow={isActiveRow}
                    onClick={() => onProviderSelected(provider)}
                    provider={provider}
                    rowNumber={rowNumber}
                    type={type}
                />
            ))}
        </Wrapper>
    );
}
