import React, { Dispatch, SetStateAction, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import UiButton from '../../../../../../ui/button/UiButton';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../../../../services/analytics';
import { CategoryMatchMarket } from '../../../../../../../services/sports/types';
import Svg from '../../../../../../svg/Svg';
import { MarketsWithOutcomesMinimal } from '../../../../../../../microservices/sbgate';

interface Props {
    markets: (CategoryMatchMarket | MarketsWithOutcomesMinimal)[] | undefined;
    selectedMarket: CategoryMatchMarket | MarketsWithOutcomesMinimal;
    onLineClick: Dispatch<SetStateAction<CategoryMatchMarket | MarketsWithOutcomesMinimal | undefined>>;
    linesRef: any;
    isAmericanLayout: boolean;
    onClose: any;
    bestMarketLineId: number;
}

export default function SportMatchMarketsTopMarketsMarketLineSelector({
    markets,
    onLineClick,
    selectedMarket,
    linesRef,
    isAmericanLayout,
    bestMarketLineId,
}: Props) {
    useEffect(() => {
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_LINE_MARKET_SELECT_OPENED);
    }, []);

    if (!markets) {
        return null;
    }

    function handleMarketLineChange(marketLine: CategoryMatchMarket | MarketsWithOutcomesMinimal) {
        onLineClick(marketLine);
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.SPORT_LINE_MARKET_LINE_CHANGED);
    }

    return (
        <div className="market-lines-list" ref={linesRef}>
            <div className="market-lines-list-odds">
                {orderBy(markets, ['raw_line']).map((marketLine) => (
                    <UiButton
                        type="button"
                        icon={marketLine.id === bestMarketLineId && <Svg icon="star" size={0.5} />}
                        iconPosition="left"
                        key={marketLine.id}
                        selected={selectedMarket.raw_line === marketLine.raw_line}
                        onClick={() => handleMarketLineChange(marketLine)}
                        size="small"
                    >
                        {isAmericanLayout ? marketLine.raw_line : marketLine.line}
                    </UiButton>
                ))}
            </div>
        </div>
    );
}
