import React from 'react';
import Wrapper from './styles';
import SportLobbyCategoriesV2 from '../../sport/lobby-categories-v-2/SportLobbyCategoriesV2';
import { FEATURE } from '../../../services/types';
import { isFeatureAvailable } from '../../../services/feature';
import { useScrollPosition } from '../../../hooks/scroll/useScrollPosition';

export default function TournamentSidebar() {
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    const scroll = useScrollPosition();

    return <Wrapper $scroll={scroll}>{isMainNavigationV2 && <SportLobbyCategoriesV2 />}</Wrapper>;
}
