import React from 'react';
import ScriptLoader from '../../../script-loader/ScriptLoader';
import { isFeatureAvailable } from '../../../../services/feature';
import { logger } from '../../../../services/logger';
import { isMobileApp } from '../../../../services/mobile-app';
import { FEATURE } from '../../../../services/types';

interface Props {
    onScriptLoaded: () => void;
}
export default function PaymentDepositDeviceFingerprint({ onScriptLoaded }: Props) {
    if (!isFeatureAvailable(FEATURE.DEVICE_FINGERPRINT_SEON) && !isMobileApp()) {
        return null;
    }

    function onError(message: string | Event) {
        logger.error('PaymentDepositDeviceFingerprint', 'onError', message);
    }
    return <ScriptLoader url="https://cdn.seondf.com/js/v5/agent.js" onError={onError} onLoad={onScriptLoaded} />;
}
