import { getStoreValue } from '../../stores/store/utils';
import { stores } from '../../stores';
import { isProd } from '../environment';
import { BetType } from '@staycool/lvdc-types/dist/features/bets/types';

export function isHorseRacingDevMode() {
    const isHorseRacingDev = getStoreValue(stores.horseRacingDevMode.isEnabled);
    return Boolean(!isProd() && isHorseRacingDev);
}

export const getCurrentDateString = () => {
    // returns array of date parts:
    // [
    //     {type: 'month', value: '04'},
    //     {type: 'literal', value: '/'},
    //     {type: 'day', value: '18'},
    //     {type: 'literal', value: '/'},
    //     {type: 'year', value: '2023'}
    // ]
    const dateParts = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'America/Los_Angeles',
    }).formatToParts();

    const mockedDate = getStoreValue(stores.horseRacingDevMode.mockedDate);
    return isHorseRacingDevMode()
        ? mockedDate.toString()
        : `${dateParts[4].value}-${dateParts[0].value}-${dateParts[2].value}`;
};

export const settingsByBetType: Partial<Record<BetType, BetTypeSettings>> = {
    WIN: { selectionsCount: 1 },
    PLC: { selectionsCount: 1 },
    SHW: { selectionsCount: 1 },
    WP: { selectionsCount: 1, bet_pools_count: 2 },
    WPS: { selectionsCount: 1, bet_pools_count: 3 },
    WS: { selectionsCount: 1, bet_pools_count: 2 },
    PS: { selectionsCount: 1, bet_pools_count: 2 },
    EXA: { selectionsCount: 2, swappable: true },
    EBX: { selectionsCount: 1 },
    QNL: { selectionsCount: 2 },
    QBX: { selectionsCount: 1 },
    TRI: { selectionsCount: 3, swappable: true },
    TBX: { selectionsCount: 1 },
    DBL: { selectionsCount: 2, isMultiRace: true },
    PK3: { selectionsCount: 3, isMultiRace: true },
    PK4: { selectionsCount: 4, isMultiRace: true },
    PK5: { selectionsCount: 5, isMultiRace: true },
    PK6: { selectionsCount: 6, isMultiRace: true },
    PK7: { selectionsCount: 7, isMultiRace: true },
    PK8: { selectionsCount: 8, isMultiRace: true },
    PK9: { selectionsCount: 9, isMultiRace: true },
    P10: { selectionsCount: 10, isMultiRace: true },
    SFC: { selectionsCount: 4, swappable: true },
    SFX: { selectionsCount: 1 },
    TRK: { selectionsCount: 2, swappable: true, isKeyType: true },
    SFK: { selectionsCount: 2, swappable: true, isKeyType: true },
    E5K: { selectionsCount: 2, isKeyType: true },
    GSL: { selectionsCount: 4, isMultiRace: true },
    E05: { selectionsCount: 5, swappable: true },
    E5X: { selectionsCount: 1 },
};

interface BetTypeSettings {
    selectionsCount: number;
    isMultiRace?: boolean;
    swappable?: boolean;
    bet_pools_count?: number;
    isKeyType?: boolean;
}
