import React from 'react';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';
import PaymentFormModal from '../../../../form-modal/PaymentFormModal';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
    provider:
        | PROVIDERS.DEVCODE_INTERAC_COMBINED
        | PROVIDERS.DEVCODE_INTERAC_E_TRANSFER
        | PROVIDERS.DEVCODE_INTERAC_ONLINE;
}

export default function PaymentDepositProviderDevcodeInterac({
    amount,
    deviceHash,
    disclaimer,
    onClose,
    provider,
}: Props) {
    const INTERAC_SERVICE = {
        [PROVIDERS.DEVCODE_INTERAC_COMBINED]: 'COMBINED',
        [PROVIDERS.DEVCODE_INTERAC_E_TRANSFER]: 'E_TRANSFER',
        [PROVIDERS.DEVCODE_INTERAC_ONLINE]: 'ONLINE',
    };

    const extraFields = [
        {
            defaultValue: INTERAC_SERVICE[provider],
            name: 'service',
            label: 'Service',
            isHidden: true,
        },
    ];

    return (
        <PaymentFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            extraFields={extraFields}
            isRedirectProvider
            onClose={onClose}
            provider={provider}
            providerType={PROVIDER_TYPE.DEPOSIT}
        />
    );
}
