import React, { useEffect, useState } from 'react';
import UiButton from '../../../ui/button/UiButton';
import { stores } from '../../../../stores';
import { translate } from '../../../../services/translate';
import { isWarning, getErrorMessage, isInfo, isBetslipButtonDisabled } from '../../../../services/sports/betslip';
import Wrapper from '../../../sport/betslip/button-and-errors/styles';
import UiLinearProgress from '../../../ui/linear-progress/UiLinearProgress';
import SportBetslipButtonAndErrorsConfirm from '../../../sport/betslip/button-and-errors/confirm/SportBetslipButtonAndErrorsConfirm';
import UiAlert from '../../../ui/alert/UiAlert';
import { COMBO_MARKET_ID, MA_DISABLED_ERROR } from '../../../../services/sports/constants';
import { useStore } from '../../../../hooks/useStore';
import SportBetslipUiClosed from '../../../sport/betslip/ui/closed/SportBetslipUiClosed';
import { useCampaignOrNormalBetSlipFunctions } from '../../../../hooks/useCampaignOrNormalBetslipFunctions';
import { getErrorCode } from '../../../../services/sports/betslip-errors';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import UiFormInput from '../../../ui/form/input/UiFormInput';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';

interface Props {
    clearSelections: (id?: number) => void;
}
export default function ComboCardsBetslipFooter({ clearSelections }: Props) {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const { matches } = cardsInBetslip[0];
    const markets = matches.flatMap(({ markets }) => markets);

    const [{ acceptAnyOddsChanges }, setUserState] = useStore(stores.sports.betSlipUserState);
    const [stakeByCardId] = useStore(stores.sports.comboCard.stakeByCardId);
    const doAllCardsHaveStake = cardsInBetslip.every((card) => stakeByCardId[card.id] > 0);
    const { placeBet } = useCampaignOrNormalBetSlipFunctions();
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [ineligibleCardIds] = useStore(stores.sports.comboCard.ineligibleCardIds);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const [maintenance] = useStore(stores.maintenance.sportsbookMaintenanceState);
    const [oddsByOutcomeId] = useOddsByOutcomeIds(markets.map(({ outcome_id }) => outcome_id));
    const { isLoading, needsConfirm, needsConfirmDuplicate } = betSlipPlacingState;
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
    const [firstMarketError] = Object.entries(betSlipErrorByMarketId)
        .map(([key, value]) => (key !== 'null' ? value : undefined))
        .filter(Boolean);

    const [isOddsChanged, setIsOddsChanged] = useState(false);

    const isBetPlacementButtonDisabled =
        isLoading ||
        !doAllCardsHaveStake ||
        genericErrors.length ||
        firstMarketError ||
        ineligibleCardIds.length ||
        isOddsChanged;

    const { placeBetTextPrefix } = useCampaignOrNormalBetSlipFunctions();
    const manualAcceptanceWithoutMaAllowed = genericErrors.some((error) => getErrorCode(error) === MA_DISABLED_ERROR);
    const [totalStake, setTotalStake] = useState(0);
    const keepValidBecauseMA = manualAcceptanceWithoutMaAllowed && totalStake > 0 && genericErrors.length === 1;

    const setAcceptAnyOddsChanges = (acceptAnyOddsChanges: boolean) => {
        setUserState((state) => ({ ...state, acceptAnyOddsChanges }));
        if (acceptAnyOddsChanges) {
            setIsOddsChanged(false);
        }
    };

    useEffect(() => {
        setTotalStake(calculateTotalStake());
    }, [stakeByCardId]);

    useEffect(() => {
        if (acceptAnyOddsChanges || !totalStake) {
            return;
        }
        setIsOddsChanged(true);
    }, [oddsByOutcomeId]);

    function calculateTotalStake() {
        return cardsInBetslip.reduce((total, card) => total + Number(stakeByCardId[card.id] || 0), 0);
    }

    if (needsConfirm || needsConfirmDuplicate) {
        return (
            <Wrapper>
                {needsConfirmDuplicate && isLoading && (
                    <UiLinearProgress
                        className="progress"
                        fakeDuration={8500}
                        showAfterPercentage={20}
                        showPercentage
                    />
                )}
                <SportBetslipButtonAndErrorsConfirm
                    onPlaceBet={placeBet}
                    isForceDuplicate={needsConfirmDuplicate}
                    clearSelection={clearSelections}
                    betSlipPlacingState={betSlipPlacingState}
                    setBetSlipPlacingState={setBetSlipPlacingState}
                />
            </Wrapper>
        );
    }

    return (
        <>
            {firstMarketError && (
                <UiAlert
                    className="market-error"
                    info={isInfo(firstMarketError)}
                    warning={isWarning(firstMarketError)}
                    failure={!isWarning(firstMarketError) && !isInfo(firstMarketError)}
                >
                    {getErrorMessage(firstMarketError)}
                </UiAlert>
            )}
            {maintenance.betslip ? (
                <SportBetslipUiClosed />
            ) : (
                <>
                    {manualAcceptanceWithoutMaAllowed && totalStake > 0 ? (
                        <UiButton
                            color="primary"
                            size="large"
                            block
                            isLoading={isLoading}
                            onClick={() => placeBet(false, manualAcceptanceWithoutMaAllowed)}
                            disabled={isLoading || (isBetslipButtonDisabled() && !keepValidBecauseMA)}
                            data-test="button-place-bet"
                        >
                            <div>{translate(`Place ${placeBetTextPrefix}bet`, 'ui.sportsbook')}</div>
                            {totalStake > 0 && (
                                <div className="extra-text">
                                    {translate(
                                        'Place the bet of %1 and disregard the remaining amount.',
                                        'ui.sportsbook',
                                        [`${formattedAmountWithCurrency(totalStake)}`],
                                    )}
                                </div>
                            )}
                        </UiButton>
                    ) : (
                        <Wrapper>
                            <UiButton
                                color="primary"
                                size="large"
                                block
                                onClick={() => placeBet()}
                                disabled={isBetPlacementButtonDisabled}
                                data-test="button-place-bet"
                                isLoading={isLoading}
                            >
                                {translate(`Place bet${cardsInBetslip.length > 1 ? 's' : ''}`, 'ui.sportsbook')}
                            </UiButton>
                        </Wrapper>
                    )}
                    <UiFormInput
                        name="acceptOddsChanges"
                        checkbox
                        disabled={isLoading}
                        label={translate('Accept any odds changes', 'ui.sportsbook')}
                        onValueChange={setAcceptAnyOddsChanges}
                        value={acceptAnyOddsChanges}
                        className="accept-odds-change"
                    />
                </>
            )}
        </>
    );
}
