import React from 'react';
import Wrapper from './styles';
import {
    Card,
    formatCardNumber,
    formatExpirationDate,
    getPaymentMethodImage,
} from '../../../services/payments/payments';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    isActive: boolean;
    card: Card;
    onClick?: () => void;
}

export default function PaymentCard({ card, isActive, ...rest }: Props) {
    // Payments2 provides expirationDate, holder & number. Other fields are for backwards compatibility.
    const paymentCard = {
        number: formatCardNumber(card.number || card.card_number || card.masked_pan || card.description),
        expirationDate: formatExpirationDate(
            card.expirationDate ||
                card.expiration_date ||
                card.expiry ||
                card.expiry_date ||
                `${card.expiration_month}/${card.expiration_year}`,
        ),
    };

    return (
        <Wrapper $isActive={isActive} {...filterStyleProps({ ...rest })}>
            <div className="method-icon">
                <img src={getPaymentMethodImage(paymentCard.number)} alt="" />
            </div>
            <div className="method-info">
                <div className="number">{paymentCard.number}</div>
                <div className="expiration-date">{paymentCard.expirationDate}</div>
            </div>
        </Wrapper>
    );
}
