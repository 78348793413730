import React from 'react';
import Wrapper from './styles';
import UiButton from '../../../../ui/button/UiButton';
import { translate } from '../../../../../services/translate';
import UiBox from '../../../../ui/box/UiBox';

interface Props {
    onConfirm: () => void;
}

export default function SportContestsBetslipResubmitModal({ onConfirm }: Props) {
    return (
        <Wrapper>
            <UiBox>
                <div className="modal-text">
                    <p>Resubmitting this selection will eliminate previous predictions.</p>
                    <p>Are you sure you want to proceed?</p>
                </div>
                <div>
                    <UiButton size="large" color="primary" block onClick={onConfirm}>
                        {translate('Confirm', 'ui.sportsbook')}
                    </UiButton>
                </div>
            </UiBox>
        </Wrapper>
    );
}
