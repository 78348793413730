import React, { useEffect, useRef, useState } from 'react';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import UiFormInput from '../../ui/form/input/UiFormInput';
import { getActiveCurrency } from '../../../services/currency';
import { getCurrencyRates } from '../../../microservices/wallet';
import UiGroup from '../../ui/group/UiGroup';
import { logger } from '../../../services/logger';
import UiButton from '../../ui/button/UiButton';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { Currency } from '../../../services/wallet/types';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    className?: string;
    onAmountChanged: (amount: number, baseAmount: number) => void;
    selectedAmount: number;
}

export default function PaymentAmountSelector({
    className = '',
    onAmountChanged = () => {},
    selectedAmount = 0,
}: Props) {
    const [{ isPhone }] = useStore(media);

    const [currencyRates, setCurrencyRates] = useState({});
    const currency = getActiveCurrency();
    const [isInputFocused, setIsInputFocused] = useState(false);

    const predefinedAmounts = {
        [Currency.CAD]: [25, 50, 100],
        [Currency.CLP]: [10000, 20000, 30000],
        [Currency.DKK]: [250, 500, 1000],
        [Currency.EUR]: [25, 50, 100],
        [Currency.MXN]: [250, 500, 1000],
        [Currency.NOK]: [250, 500, 1000],
        [Currency.PEN]: [100, 250, 500],
        [Currency.PYG]: [50000, 100000, 500000],
        [Currency.SEK]: [250, 500, 1000],
        [Currency.USD]: [25, 50, 100],
        [Currency.USDT]: [25, 50, 100],
    };

    const roundingPrecision = currency === Currency.CLP ? 0 : 2;

    const quickDepositAmounts = predefinedAmounts[currency];

    useEffect(() => {
        loadCurrencyRates();
    }, []);

    const elementRef = useRef();

    useEffect(() => {
        if (isInputFocused && isPhone) {
            const stakeElement = elementRef.current;
            if (stakeElement) {
                (stakeElement as any).scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [isInputFocused]);

    async function loadCurrencyRates() {
        try {
            setCurrencyRates(await getCurrencyRates());
        } catch (error) {
            setCurrencyRates({});
            logger.error('PaymentAmountSelector', 'loadCurrencyRates', error);
        }
    }

    function calculateBaseAmount(amount) {
        if (!currencyRates[currency]) {
            return 0;
        }
        return amount * currencyRates[currency];
    }

    function selectAmount(amount) {
        onAmountChanged(Number(amount), calculateBaseAmount(amount));
    }

    return (
        <Wrapper>
            <div className="amount-selector">
                <div className="custom-amount" ref={elementRef as any}>
                    <UiFormGroup row>
                        <UiFormInput
                            className={className}
                            name="depositAmount"
                            data-test="deposit-amount"
                            alert={translate('Success message', 'ui.alert')}
                            onValueChange={selectAmount}
                            value={!selectedAmount && isInputFocused ? '' : selectedAmount}
                            typeNumeric
                            round={roundingPrecision}
                            type={currency === Currency.CLP ? 'text' : 'number'}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                            hideMobileTabOnFocus
                        />
                    </UiFormGroup>
                </div>
                <UiGroup expand layoutGutterInRem={'0.5rem'}>
                    {quickDepositAmounts.map((amount) => (
                        <UiButton key={amount} onClick={() => selectAmount(amount)}>
                            <span className="amount-container">
                                <span className="amount-font">{formattedAmountWithCurrency(amount)} </span>
                            </span>
                        </UiButton>
                    ))}
                </UiGroup>
            </div>
        </Wrapper>
    );
}
