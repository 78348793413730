import React, { useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { loadGeocomplyLicenseStatus, softResetGeocomplyStore } from '../../../services/geocomply/geocomply';
import { stores } from '../../../stores';
import { geocomply } from '../../../stores/geocomply';
import UiModal from '../../ui/modal/UiModal';
import GeocomplyFeedbackErrors from './errors/GeocomplyFeedbackErrors';
import GeocomplyFeedbackFull from './full/GeocomplyFeedbackFull';
import GeocomplyFeedbackMinimized from './minimized/GeocomplyFeedbackMinimized';

interface Props {
    isDev?: boolean;
}

export default function GeocomplyFeedback({ isDev = false }: Props) {
    const [geocomplyClient] = useStore(geocomply.client);
    const [serviceError] = useStore(geocomply.serviceError);
    const [unexpectedError] = useStore(geocomply.unexpectedError);
    const [isDevModeVisibility] = useStore(geocomply.isDevModeVisibility);

    const [isVisible, setIsVisible] = useState(false);
    const [isSilentMode] = useStore(geocomply.isSilentMode);
    const [, setIsGeoComplyModalOpen] = useStore(stores.modals.isGeoComplyModalOpen);

    function dismiss() {
        softResetGeocomplyStore();
        setIsVisible(false);
        setIsGeoComplyModalOpen(false);
    }

    const isProcessing = geocomplyClient.isAttemptingConnection || geocomplyClient.isAttemptingGeolocation;
    const hasErrors = geocomplyClient.clientError || geocomplyClient.hint || serviceError || unexpectedError;
    const isDone = !isProcessing && !hasErrors;
    // 608 code is LICENSE_EXPIRED_SERVER error
    const isLicenseExpireError = Boolean(hasErrors && geocomplyClient.clientError?.code === 608);

    useEffect(() => {
        if (isLicenseExpireError) {
            void loadGeocomplyLicenseStatus(true);
        }
    }, [isLicenseExpireError]);

    useEffect(() => {
        const isProcessing = geocomplyClient.isAttemptingConnection || geocomplyClient.isAttemptingGeolocation;
        const hasErrors = geocomplyClient.clientError || geocomplyClient.hint || serviceError || unexpectedError;

        if (!isVisible && isProcessing && ((isDev && isDevModeVisibility) || (!isDev && !isDevModeVisibility))) {
            setIsGeoComplyModalOpen(true);
            setIsVisible(true);
            return;
        }

        let timeoutRef;

        if (isVisible && !isProcessing && !hasErrors) {
            timeoutRef = setTimeout(() => dismiss(), 3000);
        }

        return () => {
            if (timeoutRef) {
                clearTimeout(timeoutRef);
            }
        };
    }, [isVisible, geocomplyClient, serviceError, unexpectedError, isSilentMode]);

    if (!isVisible) {
        return null;
    }

    if (isLicenseExpireError) {
        return null;
    }

    return (
        <>
            {(isProcessing || isDone) && isSilentMode && <GeocomplyFeedbackMinimized />}

            {(isProcessing || isDone) && !isSilentMode && isDevModeVisibility && <GeocomplyFeedbackFull />}

            {(isProcessing || isDone) && !isSilentMode && !isDevModeVisibility && (
                <UiModal open maxWidth="90vw" onClose={() => {}}>
                    <GeocomplyFeedbackFull />
                </UiModal>
            )}

            {hasErrors && !isProcessing && isDevModeVisibility && <GeocomplyFeedbackErrors onDismiss={dismiss} />}

            {hasErrors && !isProcessing && !isDevModeVisibility && (
                <UiModal open maxWidth="90vw" onClose={() => {}}>
                    <GeocomplyFeedbackErrors onDismiss={dismiss} />
                </UiModal>
            )}
        </>
    );
}
