import React from 'react';
import Wrapper from './styles';
import { stores } from '../../../../stores';
import Svg from '../../../svg/Svg';
import UiModal from '../../../ui/modal/UiModal';
import SportBetslipWrapper from '../../betslip-wrapper/SportBetslipWrapper';
import isEmpty from 'lodash/isEmpty';
// import ComboCardsBetslip from '../../../combo-cards/betslip/ComboCardsBetslip';
// import { getMarketIdsFromComboCardMatches } from '../../../../services/sports/combo-cards';
import { useStore } from '../../../../hooks/useStore';
import classNames from 'classnames';
import { calculateTotalOdds } from '../../../../services/sports/betslip';
import { convertOdds } from '../../../../services/odds-format';
import { translate } from '../../../../services/translate';
import { BET_TYPE } from '../../../../services/sports/types';
import { SwipeableDrawer } from '@mui/material';
import SportPhoneSelectSportSidebar from '../select-sport-sidebar/SportPhoneSelectSportSidebar';

export default function SportPhoneNavigationV2() {
    const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const [betSlipUserState] = useStore(stores.sports.betSlipUserState);
    // FIXME: bet type is not updated
    const { betType } = betSlipUserState;
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    // const [cardsInBetslip] = useStore(stores.sports.comboCard.cardsInBetslip);
    const numberOfBets = cardsInBetslip.length || Object.values(betSlipMarketIdToOutcomeId).length;
    // const numberOfComboCardBets = cardsInBetslip.flatMap(({ matches }) =>
    //     getMarketIdsFromComboCardMatches(matches),
    // ).length;
    const [isBetslipOpen, setIsBetslipOpen] = useStore(stores.isBetslipOpen);
    const isBetslipNotEmpty = !isEmpty(betSlipMarketIdToOutcomeId);
    // const isComboCardBetslipEmpty = isEmpty(cardsInBetslip);
    // const [isComboCardBetslipOpen, setIsComboCardBetslipOpen] = useStore(
    //     stores.sports.comboCard.isComboCardBetslipOpen,
    // );
    const [isSportSideMenuOpen, setIsSportSideMenuOpen] = useStore(stores.sports.isSportSideMenuOpen);

    // TODO: bet type is currently set inside betslip, must be moved outside
    // TODO: handle other types
    function getLabel(totalOdds: string) {
        if (betType === BET_TYPE.SYSTEM) {
            return translate('System bet - {{total}}', 'ui.betslip', { total: totalOdds });
        } else if (betType === BET_TYPE.COMBO) {
            return translate('Combo bet - {{total}}', 'ui.betslip', { total: totalOdds });
        } else if (betType === BET_TYPE.COMBO_CARD) {
            return translate('Combo Card - {{total}}', 'ui.betslip', { total: totalOdds });
        } else if (betType === BET_TYPE.SINGLE) {
            return translate('Single bet', 'ui.betslip');
        } else {
            return translate('No bets selected', 'ui.betslip');
        }
    }

    return (
        <Wrapper $iconWidth={1.6}>
            <div
                onClick={() => setIsBetslipOpen(true)}
                className={classNames('betslip-toggle', { 'active-betslip': isBetslipNotEmpty })}
            >
                {/* TODO: when combo card bet is removed from betslip then number of bets and bet type are wrong */}
                <div className="betslip-title">
                    {numberOfBets ? (
                        <>
                            <div className="count">
                                <Svg size={1.6} icon="filled-betslip" />
                                <span>{numberOfBets}</span>
                            </div>
                            {/* TODO: when combo card total odds calculates wrong */}
                            {getLabel(convertOdds(calculateTotalOdds(null)))}
                        </>
                    ) : (
                        <>
                            <Svg size={1.6} icon="empty-betslip" />
                            {translate('No bets selected', 'ui.betslip')}
                        </>
                    )}
                </div>
                <aside>
                    <Svg icon="drawer" size={0.9} rotate={90} />
                </aside>
            </div>

            <SwipeableDrawer
                disableEnforceFocus
                open={isSportSideMenuOpen}
                onOpen={() => setIsSportSideMenuOpen(true)}
                onClose={() => setIsSportSideMenuOpen(false)}
                anchor="right"
                disableDiscovery={true}
                swipeAreaWidth={0}
            >
                <SportPhoneSelectSportSidebar />
            </SwipeableDrawer>

            <UiModal
                open={isBetslipOpen}
                onClose={() => setIsBetslipOpen(false)}
                onOpen={() => setIsBetslipOpen(true)}
                mode="drawer"
            >
                <div className="betslip-box">
                    <SportBetslipWrapper />
                </div>
            </UiModal>

            {/* TODO: handle combo card in betslip */}
            {/*<UiModal*/}
            {/*    open={isComboCardBetslipOpen}*/}
            {/*    onClose={() => setIsComboCardBetslipOpen(false)}*/}
            {/*    onOpen={() => setIsComboCardBetslipOpen(true)}*/}
            {/*    mode="drawer"*/}
            {/*>*/}
            {/*    <div className="betslip-box">*/}
            {/*        <ComboCardsBetslip />*/}
            {/*    </div>*/}
            {/*</UiModal>*/}
        </Wrapper>
    );
}
