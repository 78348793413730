import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components';
import { hideScrollBar, mediaQuery } from '../styles/utils';

export const GlobalStyles = createGlobalStyle`
   :root {
        // #####################################################################
        // ## generalUi ########################################################
        // #####################################################################

       --spacing-4: 0.25rem;
       --spacing-8: 0.5rem;
       --spacing-12: 0.75rem;
       --spacing-16: 1rem;
       --spacing-20: 1.25rem;
       --spacing-24: 1.5rem;
       --spacing-32: 2rem;

        --ui-radius: ${({ theme }) => theme.generalUi.radius};
        --ui-shadow: ${({ theme }) => theme.generalUi.shadow};
        --ui-scroll-bg: ${({ theme }) => theme.generalUi.scrollBackground};
        --ui-scroll-track-bg: ${({ theme }) => theme.generalUi.scrollTrackBackground};

        // #####################################################################
        // ## colors ###########################################################
        // #####################################################################

        --color-body-bg: ${({ theme }) => theme.colors.bodyBackground};
        --color-header-bg: ${({ theme }) => theme.colors.headerBackground};

        --color-primary: ${({ theme }) => theme.colors.primary};
        --color-secondary: ${({ theme }) => theme.colors.secondary};
        --color-tertiary: ${({ theme }) => theme.colors.trietary};

        --color-odds: ${({ theme }) => theme.colors.odds};
        --color-motd: ${({ theme }) => theme.colors.motd};
        --color-turnover: ${({ theme }) => theme.colors.turnover};

        --color-highlight: ${({ theme }) => theme.colors.highlight};
        --color-on-highlight: ${({ theme }) => theme.colors.onHighlight};

        --color-success: ${({ theme }) => theme.colors.success};
        --color-info: ${({ theme }) => theme.colors.info};
        --color-warning: ${({ theme }) => theme.colors.warning};
        --color-lost: ${({ theme }) => theme.colors.lost};
        --color-error: ${({ theme }) => theme.colors.error};
        --color-alert: ${({ theme }) => theme.colors.alert};

        // #####################################################################
        // ## typography #######################################################
        // #####################################################################

        --font-family: ${({ theme }) => theme.typography.fontFamily};

        --font-base-size: ${({ theme }) => theme.typography.fontBaseSize};

       --font-8:  0.5rem;
       --font-10: 0.625rem;
       --font-12: 0.750rem;
       --font-14: 0.875rem;
       --font-16: 1rem;
       --font-20: 1.25rem;
       --font-24: 1.5rem;

        --font-color-primary: ${({ theme }) => theme.typography.fontColorPrimary};
        --font-color-secondary: ${({ theme }) => theme.typography.fontColorSecondary};
        --font-color-subtle: ${({ theme }) => theme.typography.fontColorSubtle};
        --font-color-modal-desc: ${({ theme }) => theme.typography.fontColorModalDesc};
        --font-color-faded: ${({ theme }) => theme.typography.fontColorFaded};

        --font-link-color: ${({ theme }) => theme.typography.fontLinkColor};

        --font-weight-regular: ${({ theme }) => theme.typography.fontWeightRegular};
        --font-weight-medium: ${({ theme }) => theme.typography.fontWeightMedium};
        --font-weight-bold: ${({ theme }) => theme.typography.fontWeightBold};
        --font-weight-heavy: ${({ theme }) => theme.typography.fontWeightHeavy};

        // #####################################################################
        // ## surfacesLevel1 ###################################################
        // #####################################################################

        --surface-level-1-bg: ${({ theme }) => theme.surfacesLevel1.background};
        --surface-level-1-border:${({ theme }) => theme.surfacesLevel1.border};
        --surface-level-1-line-color: ${({ theme }) => theme.surfacesLevel1.lineColor};

        // #####################################################################
        // ## surfacesLevel2 ###################################################
        // #####################################################################

        --surface-level-2-bg: ${({ theme }) => theme.surfacesLevel2.background};
        --surface-level-2-border: ${({ theme }) => theme.surfacesLevel2.border};
        --surface-level-2-line-color: ${({ theme }) => theme.surfacesLevel2.lineColor};

        // #####################################################################
        // ## surfacesLevel3 ###################################################
        // #####################################################################

        --surface-level-3-bg: ${({ theme }) => theme.surfacesLevel3.background};
        --surface-level-3-border: ${({ theme }) => theme.surfacesLevel3.border};
        --surface-level-3-line-color: ${({ theme }) => theme.surfacesLevel3.lineColor};

       // #####################################################################
       // ## alerts ##########################################################
       // #####################################################################

       --alert-success-bg: rgba(112, 198, 42, 0.2);
       --alert-info-bg: rgba(76, 169, 255, 0.2);
       --alert-warning-bg: rgba(255, 166, 4, 0.2);
       --alert-error-bg: rgba(255, 65, 54, 0.2);

        // #####################################################################
        // ## buttons ##########################################################
        // #####################################################################

        --button-font-size: 0.8rem;
        --button-font-size-tiny: 0.5rem;
        --button-font-size-small: 0.625rem;
        --button-font-size-large: 0.9rem;

        --button-font-weight: ${({ theme }) => theme.buttons.fontWeight};
        --button-font-weight-mobile: ${({ theme }) => theme.buttons.fontWeightMobile};

        --button-size-default: 0.75rem;
        --button-size-tiny: 0.25rem 0.3125rem;
        --button-size-small: 0.5rem 0.625rem;
        --button-size-large: 1.2rem 1.6rem;

        --button-bg:  ${({ theme }) => theme.buttons.background};
        --button-bg-hover: ${({ theme }) => theme.buttons.backgroundHover};
        --button-border: ${({ theme }) => theme.buttons.border};
        --button-text-color: ${({ theme }) => theme.buttons.textColor};
        --button-text-color-hover: ${({ theme }) => theme.buttons.textColorHover};
        --button-shadow: ${({ theme }) => theme.buttons.shadow};

        --button-primary-bg: ${({ theme }) => theme.buttons.primaryBackground};
        --button-primary-bg-hover: ${({ theme }) => theme.buttons.primaryBackgroundHover};
        --button-primary-border: ${({ theme }) => theme.buttons.primaryBorder};
        --button-primary-text-color: ${({ theme }) => theme.buttons.primaryTextColor};
        --button-primary-text-color-hover: ${({ theme }) => theme.buttons.primaryTextColorHover};
        --button-primary-shadow: ${({ theme }) => theme.buttons.primaryShadow};

       --button-outline-border: ${({ theme }) => theme.buttons.outlineBorder};
       --button-outline-border-hover: ${({ theme }) => theme.buttons.outlineBorderHover};
       --button-outline-text-color: ${({ theme }) => theme.buttons.outlineTextColor};
       --button-outline-text-color-hover: ${({ theme }) => theme.buttons.outlineTextColorHover};

        --button-ghost-text-color: ${({ theme }) => theme.buttons.ghostTextColor};
        --button-ghost-bg-hover: ${({ theme }) => theme.buttons.ghostBackgroundHover};

        --button-active-bg: ${({ theme }) => theme.buttons.activeBackground};
        --button-active-bg-hover: ${({ theme }) => theme.buttons.activeBackgroundHover};
        --button-active-border: ${({ theme }) => theme.buttons.activeBorder};
        --button-active-text-color: ${({ theme }) => theme.buttons.activeTextColor};
        --button-active-text-color-hover: ${({ theme }) => theme.buttons.activeTextColorHover};
        --button-active-shadow: ${({ theme }) => theme.buttons.activeShadow};
       
        --button-cashout-bg: ${({ theme }) => theme.buttons.cashoutBackground};
        --button-cashout-bg-hover: ${({ theme }) => theme.buttons.cashoutBackgroundHover};
        --button-cashout-border: ${({ theme }) => theme.buttons.cashoutBorder};
        --button-cashout-text-color: ${({ theme }) => theme.buttons.cashoutTextColor};

        --close-button-size: 24px;

        // #####################################################################
        // ## cardButton #######################################################
        // #####################################################################

        --card-button-bg: ${({ theme }) => theme.cardButton.background};
        --card-button-bg-hover: ${({ theme }) => theme.cardButton.backgroundHover};
        --card-button-on-surface-bg: ${({ theme }) => theme.cardButton.onSurfaceBackground};

        --card-button-border: ${({ theme }) => theme.cardButton.border};
        --card-button-on-surface-border: ${({ theme }) => theme.cardButton.onSurfaceBorder};
        --card-button-on-surface-text-color: ${({ theme }) => theme.cardButton.onSurfaceTextColor};

        --card-button-text-color: ${({ theme }) => theme.cardButton.textColor};
        --card-button-text-color-hover: ${({ theme }) => theme.cardButton.textColorHover};
        --card-button-icon-color: ${({ theme }) => theme.cardButton.iconColor};
        --card-button-shadow: ${({ theme }) => theme.cardButton.shadow};

        --card-button-on-surface-active-bg: ${({ theme }) => theme.cardButton.onSurfaceActiveBackground};
        --card-button-active-border: ${({ theme }) => theme.cardButton.activeBorder};
        --card-button-active-bg: ${({ theme }) => theme.cardButton.activeBackground};
        --card-button-active-text-color: ${({ theme }) => theme.cardButton.activeTextColor};
        --card-button-on-surface-active-text-color: ${({ theme }) => theme.cardButton.onSurfaceActiveTextColor};
        --card-button-active-icon-color: ${({ theme }) => theme.cardButton.activeIconColor};
        --card-button-active-shadow: ${({ theme }) => theme.cardButton.activeShadow};

        // #####################################################################
        // ## tabs #############################################################
        // #####################################################################

        --tabs-text-color: ${({ theme }) => theme.tabs.textColor};
        --tabs-line: ${({ theme }) => theme.tabs.line};
        --tabs-text-color-active: ${({ theme }) => theme.tabs.textColorActive};
        --tabs-text-weight: ${({ theme }) => theme.tabs.textWeight};
        --tabs-text-color-inverse: ${({ theme }) => theme.tabs.textColorInverse};

        // #####################################################################
        // ## chips ############################################################
        // #####################################################################

        --chips-bg: ${({ theme }) => theme.chips.background};
        --chips-bg-hover: ${({ theme }) => theme.chips.backgroundHover};
        --chips-bg-selected: ${({ theme }) => theme.chips.backgroundSelected};

        --chips-border: ${({ theme }) => theme.chips.border};
        --chips-border-selected: ${({ theme }) => theme.chips.borderSelected};

        --chips-text-color: ${({ theme }) => theme.chips.textColor};
        --chips-text-color-hover: ${({ theme }) => theme.chips.textColorHover};
        --chips-text-color-selected: ${({ theme }) => theme.chips.textColorSelected};

        --chips-radius: 100px;

        // #####################################################################
        // ## forms ############################################################
        // #####################################################################

        --form-input-bg: ${({ theme }) => theme.forms.inputBackground};
        --form-input-border: ${({ theme }) => theme.forms.inputBorder};

        --form-input-text: ${({ theme }) => theme.forms.inputText};
        --form-input-label-text: ${({ theme }) => theme.forms.inputLabelText};

        --form-input-toggle-bg: ${({ theme }) => theme.forms.inputToggleBackground};
        --form-input-toggle-border: ${({ theme }) => theme.forms.inputToggleBorder};
        --form-input-toggle-knob-bg: ${({ theme }) => theme.forms.inputToggleKnobBackground};

        --form-input-toggle-bg-active: ${({ theme }) => theme.forms.inputToggleBackgroundActive};
        --form-input-toggle-border-active: ${({ theme }) => theme.forms.inputToggleBorderActive};
        --form-input-toggle-knob-bg-active: ${({ theme }) => theme.forms.inputToggleKnobBackgroundActive};
        
        // #####################################################################
        // ## Tables ###########################################################
        // #####################################################################
        
        --table-zebra-light-color: #353640;
        --table-zebra-dark-color: #2D2E37;
        --table-zebra-border-color: #23232A;
        
        // #####################################################################
        // ## mobileNavigation #################################################
        // #####################################################################

        --mobile-nav-height: ${({ theme }) => theme.mobileNavigation.height};
        --mobile-nav-bg: ${({ theme }) => theme.mobileNavigation.background};
        --mobile-nav-border: ${({ theme }) => theme.mobileNavigation.border};

        --mobile-nav-tab-text-color: ${({ theme }) => theme.mobileNavigation.tabTextColor};
        --mobile-nav-tab-text-color-active: ${({ theme }) => theme.mobileNavigation.tabTextColorActive};

        --mobile-nav-tab-bg-active: ${({ theme }) => theme.mobileNavigation.tabBackgroundActive};
        --mobile-nav-tab-border-active: ${({ theme }) => theme.mobileNavigation.tabBorderActive};

        --mobile-nav-tab-badge-bg: ${({ theme }) => theme.mobileNavigation.tabBadgeBackground};
        --mobile-nav-tab-badge-border: ${({ theme }) => theme.mobileNavigation.tabBadgeBorder};
        --mobile-nav-tab-badge-text-color: ${({ theme }) => theme.mobileNavigation.tabBadgeTextColor};

        // #####################################################################
        // ## sportMenu ########################################################
        // #####################################################################

        --sport-menu-select-bg: ${({ theme }) => theme.sportMenu.selectBackground};
        --sport-menu-select-border: ${({ theme }) => theme.sportMenu.selectBorder};
        --sport-menu-select-font-color: ${({ theme }) => theme.sportMenu.selectFontColor};
        --sport-menu-select-font-size: ${({ theme }) => theme.sportMenu.selectFontSize};
        --sport-menu-select-font-weight: ${({ theme }) => theme.sportMenu.selectFontWeight};
        --sport-menu-select-font-style: ${({ theme }) => theme.sportMenu.selectFontStyle};

        --sport-menu-bg: ${({ theme }) => theme.sportMenu.background};
        --sport-menu-border: ${({ theme }) => theme.sportMenu.border};

        --sport-menu-expanded-bg: ${({ theme }) => theme.sportMenu.expandedBackground};

        --sport-menu-item-bg: ${({ theme }) => theme.sportMenu.itemBackground};
        --sport-menu-item-bg-hover: ${({ theme }) => theme.sportMenu.itemBackgroundHover};
        --sport-menu-item-bg-active: ${({ theme }) => theme.sportMenu.itemBackgroundActive};
        --sport-menu-item-bg-expanded: ${({ theme }) => theme.sportMenu.itemBackgroundExpanded};
        --sport-menu-item-bg-expanded-hover: ${({ theme }) => theme.sportMenu.itemBackgroundExpandedHover};

        --sport-menu-item-border: ${({ theme }) => theme.sportMenu.itemBorder};
        --sport-menu-item-border-active: ${({ theme }) => theme.sportMenu.itemBorderActive};

        --sport-menu-label-color: ${({ theme }) => theme.sportMenu.labelColor};

        --sport-menu-text-color: ${({ theme }) => theme.sportMenu.textColor};
        --sport-menu-text-active: ${({ theme }) => theme.sportMenu.textActive};

        --sport-menu-icon-color: ${({ theme }) => theme.sportMenu.iconColor};
        --sport-menu-icon-color-hover: ${({ theme }) => theme.sportMenu.iconColorHover};
        --sport-menu-icon-color-active: ${({ theme }) => theme.sportMenu.iconColorActive};

        // #####################################################################
        // ## sportContent #####################################################
        // #####################################################################

        --sport-content-bg: ${({ theme }) => theme.sportContent.background};
        --sport-content-border: ${({ theme }) => theme.sportContent.border};

        // #####################################################################
        // ## sportSearch ######################################################
        // #####################################################################

        --sport-search-height: 3rem;

        --sport-search-bg: ${({ theme }) => theme.sportSearch.background};
        --sport-search-border: ${({ theme }) => theme.sportSearch.border};

        --sport-search-icon-color: ${({ theme }) => theme.sportSearch.iconColor};
        --sport-search-text-color: ${({ theme }) => theme.sportSearch.textColor};
        --sport-search-placeholder-color:  ${({ theme }) => theme.sportSearch.placeholderColor};

        --sport-search-result-bg: ${({ theme }) => theme.sportSearch.resultBackground};
        --sport-search-result-line-separator: ${({ theme }) => theme.sportSearch.resultLineSeparator};

        // #####################################################################
        // ## sportCategory ####################################################
        // #####################################################################

        --sport-category-bg: ${({ theme }) => theme.sportCategory.background};
        --sport-category-bg-mobile: ${({ theme }) => theme.sportCategory.backgroundMobile};
        --sport-category-border: ${({ theme }) => theme.sportCategory.border};
       
        --sport-category-border-width: ${({ theme }) => theme.sportCategory.borderWidth};
        --sport-category-border-color: ${({ theme }) => theme.sportCategory.borderColor};
        --sport-category-border-radius: ${({ theme }) => theme.sportCategory.borderRadius};

        --sport-category-title-color: ${({ theme }) => theme.sportCategory.titleColor};
        --sport-category-markets-color: ${({ theme }) => theme.sportCategory.marketsColor};

        // #####################################################################
        // ## match ############################################################
        // #####################################################################

        --match-name-size: 0.95rem;
        --match-name-size-laptop: 0.95rem;
        --match-name-size-mobile: 1rem;
        --match-name-size-mobile-compact: 0.875rem;

        --match-name-width-desktop: ${({ theme }) => theme.match.nameWidthDesktop};
        --match-name-width-desktop-small: 270px;
        --match-name-width-laptop: ${({ theme }) => theme.match.nameWidthLaptop};
        --match-name-width-laptop-small: 180px;
        --match-name-width-tablet: 350px;

        --match-name-weight:${({ theme }) => theme.match.nameWeight};

        --match-primary-color: ${({ theme }) => theme.match.primaryColor};
        --match-secondary-color: ${({ theme }) => theme.match.secondaryColor};

        --match-background: ${({ theme }) => theme.match.background};
        --match-background-hover: ${({ theme }) => theme.match.backgroundHover};

        --match-open-background: ${({ theme }) => theme.match.openBackground};
        --match-open-border-color: ${({ theme }) => theme.match.openBorderColor};

        --match-border-width: ${({ theme }) => theme.match.borderWidth};
        --match-border-style: ${({ theme }) => theme.match.borderStyle};
        --match-border-color: ${({ theme }) => theme.match.borderColor};
        --match-border-color-hover: ${({ theme }) => theme.match.borderColorHover};
        --match-border-radius: ${({ theme }) => theme.match.borderRadius};

        --match-margin: ${({ theme }) => theme.match.margin};

        --match-line-select-bg: ${({ theme }) => theme.match.lineSelectBackground};
        --match-line-select-bg-hover: ${({ theme }) => theme.match.lineSelectBackgroundHover};

        --match-line-select-border: ${({ theme }) => theme.match.lineSelectBorder};
        --match-line-select-border-hover: ${({ theme }) => theme.match.lineSelectBorderHover};

        --match-line-list-bg: ${({ theme }) => theme.match.lineListBackground};
        --match-line-list-border: ${({ theme }) => theme.match.lineListBorder};

        --match-market-color: ${({ theme }) => theme.match.marketColor};

        --match-sidebet-border: ${({ theme }) => theme.match.sidebetBorder};
        --match-sidebet-background: ${({ theme }) => theme.match.sidebetBackground};
        --match-sidebet-name-color: ${({ theme }) => theme.match.sidebetNameColor};
        --match-sidebet-outcome-color: ${({ theme }) => theme.match.sidebetOutcomeColor};

        --match-score-bg:  ${({ theme }) => theme.match.scoreBackground};
        --match-score-border: ${({ theme }) => theme.match.scoreBorder};
        --match-score-radius: ${({ theme }) => theme.match.scoreRadius};
        --match-score-text-color: ${({ theme }) => theme.match.scoreTextColor};

        --match-live-text-color:  ${({ theme }) => theme.match.liveTextColor};

       // #####################################################################
       // ## info box #############################################################
       // #####################################################################
       
        --info-box-text-color:  ${({ theme }) => theme.infoBox.textColor};
        --info-box-bg:  ${({ theme }) => theme.infoBox.background};
        --info-box-border:  ${({ theme }) => theme.infoBox.border};

        // #####################################################################
        // ## odds #############################################################
        // #####################################################################

        --odds-height: 40px;
        --odds-height-mobile: 45px;
        --odds-american-height-mobile: 55px;

        --odds-american-height: 85px;

        --odds-radius: ${({ theme }) => theme.odds.radius};

        --odds-text-weight: ${({ theme }) => theme.odds.textWeight};
        --odds-line-text-weight: ${({ theme }) => theme.odds.lineTextWeight};

        --odds-bg: ${({ theme }) => theme.odds.background};
        --odds-border: ${({ theme }) => theme.odds.border};

        --odds-bg-hover: ${({ theme }) => theme.odds.backgroundHover};
        --odds-border-hover: ${({ theme }) => theme.odds.borderHover};

        --odds-bg-active: ${({ theme }) => theme.odds.backgroundActive};
        --odds-border-active: ${({ theme }) => theme.odds.borderActive};

        --odds-font-size-mobile: ${({ theme }) => theme.odds.fontSizeMobile};
       
        --odds-text-color: ${({ theme }) => theme.odds.textColor};
        --odds-text-hover: ${({ theme }) => theme.odds.textHover};
        --odds-text-active: ${({ theme }) => theme.odds.textActive};

        --odds-line-text: ${({ theme }) => theme.odds.lineText};
        --odds-line-text-hover: ${({ theme }) => theme.odds.lineTextHover};
        --odds-line-text-active: ${({ theme }) => theme.odds.lineTextActive};

        --odds-outcome-text: ${({ theme }) => theme.odds.outcomeText};

        --odds-increase: #70C62A;
        --odds-decrease: #FF4A30;

        // #####################################################################
        // ## betslip ##########################################################
        // #####################################################################

        --betslip-bg: ${({ theme }) => theme.betslip.background};
        --betslip-border: ${({ theme }) => theme.betslip.border};

        --betslip-stake-bg: ${({ theme }) => theme.betslip.stakeBackground};
        --betslip-stake-bg-focused: ${({ theme }) => theme.betslip.stakeBackgroundFocused};
        --betslip-stake-border: ${({ theme }) => theme.betslip.stakeBorder};
        --betslip-stake-label-color: ${({ theme }) => theme.betslip.stakeLabelColor};

        --betslip-stake-towin-bg: ${({ theme }) => theme.betslip.stakeToWinBackground};
        --betslip-stake-towin-border: ${({ theme }) => theme.betslip.stakeToWinBorder};
        --betslip-stake-towin-label-color: ${({ theme }) => theme.betslip.stakeToWinLabelColor};

        --betslip-return-label-color: ${({ theme }) => theme.betslip.stakeReturnLabelColor};

        --betslip-label-general-color:  ${({ theme }) => theme.betslip.labelGeneralColor};
        --betslip-label-odds-color: ${({ theme }) => theme.betslip.labelOddsColor};
        --betslip-label-return-color: ${({ theme }) => theme.betslip.labelReturnColor};

        --betslip-quick-hightlight: ${({ theme }) => theme.betslip.quickHightlight};
        --betslip-toggle-height: ${({ theme }) => theme.betslip.betslipToggleHeight};


        // #####################################################################
        // ## tickets ##########################################################
        // #####################################################################

        --ticket-bg: ${({ theme }) => theme.tickets.background};
        --ticket-border:  ${({ theme }) => theme.tickets.border};

        --ticket-totals-bg: ${({ theme }) => theme.tickets.totalsBackground};

        --ticket-text-color-primary: ${({ theme }) => theme.tickets.textColorPrimary};
        --ticket-text-color-secondary: ${({ theme }) => theme.tickets.textColorSecondary};

        --ticket-selection-separator: ${({ theme }) => theme.tickets.selectionSeparator};

        --ticket-total-odds-text-color: ${({ theme }) => theme.tickets.totalOddsTextColor};
        --ticket-total-odds-bg: ${({ theme }) => theme.tickets.totalOddsBackground};
        --ticket-total-odds-border: ${({ theme }) => theme.tickets.totalOddsBorder};

        --ticket-stake-bg: ${({ theme }) => theme.tickets.stakeBackground};
        --ticket-stake-text: ${({ theme }) => theme.tickets.stakeText};
        --ticket-stake-border: ${({ theme }) => theme.tickets.stakeBorder};

        --ticket-return-bg: ${({ theme }) => theme.tickets.returnBackground};
        --ticket-return-text: ${({ theme }) => theme.tickets.returnText};
        --ticket-return-border: ${({ theme }) => theme.tickets.returnBorder};

        --ticket-ticketid-color:  ${({ theme }) => theme.tickets.ticketIdColor};

        --ticket-badge-lost-bg:  ${({ theme }) => theme.tickets.badgeLostBackground};
        --ticket-badge-lost-border: ${({ theme }) => theme.tickets.badgeLostBorder};
        --ticket-badge-lost-text-color: ${({ theme }) => theme.tickets.badgeLostTextColor};

        --ticket-badge-won-bg:  ${({ theme }) => theme.tickets.badgeWonBackground};
        --ticket-badge-won-border: ${({ theme }) => theme.tickets.badgeWonBorder};
        --ticket-badge-won-text-color: ${({ theme }) => theme.tickets.badgeWonTextColor};

        --ticket-badge-pending-bg:  ${({ theme }) => theme.tickets.badgePendingBackground};
        --ticket-badge-pending-border: ${({ theme }) => theme.tickets.badgePendingBorder};
        --ticket-badge-pending-text-color: ${({ theme }) => theme.tickets.badgePendingTextColor};

        --ticket-badge-cashout-bg: ${({ theme }) => theme.tickets.badgeCashoutBackground};
        --ticket-badge-cashout-border: ${({ theme }) => theme.tickets.badgeCashoutBorder};
        --ticket-badge-cashout-text-color:  ${({ theme }) => theme.tickets.badgeCashoutTextColor};

        --ticket-badge-warning-bg:  ${({ theme }) => theme.tickets.badgeWarningBackground};
        --ticket-badge-warning-border: ${({ theme }) => theme.tickets.badgeWarningBorder};
        --ticket-badge-warning-text-color: ${({ theme }) => theme.tickets.badgeWarningTextColor};

        --ticket-badge-livebet-bg: ${({ theme }) => theme.tickets.badgeLivebetBackground};
        --ticket-badge-livebet-border: ${({ theme }) => theme.tickets.badgeLivebetBorder};
        --ticket-badge-livebet-text-color: ${({ theme }) => theme.tickets.badgeLivebetTextColor};

        --ticket-badge-live-bg: ${({ theme }) => theme.tickets.badgeLiveBackground};
        --ticket-badge-live-border: ${({ theme }) => theme.tickets.badgeLiveBorder};
        --ticket-badge-live-text-color: ${({ theme }) => theme.tickets.badgeLiveTextColor};

        --ticket-badge-system-bg: ${({ theme }) => theme.tickets.badgeSystemBackground};
        --ticket-badge-system-border: ${({ theme }) => theme.tickets.badgeSystemBorder};
        --ticket-badge-system-text-color: ${({ theme }) => theme.tickets.badgeSystemTextColor};

        --ticket-badge-combo-bg: ${({ theme }) => theme.tickets.badgeComboBackground};
        --ticket-badge-combo-border: ${({ theme }) => theme.tickets.badgeComboBorder};
        --ticket-badge-combo-text-color: ${({ theme }) => theme.tickets.badgeComboTextColor};

        --ticket-badge-single-bg: ${({ theme }) => theme.tickets.badgeSingleBackground};
        --ticket-badge-single-border: ${({ theme }) => theme.tickets.badgeSingleBorder};
        --ticket-badge-single-text-color: ${({ theme }) => theme.tickets.badgeSingleTextColor};
       
       // Toastify overrides

       --toastify-color-dark:  ${({ theme }) => theme.surfacesLevel3.background};
       --toastify-color-info: var(--color-info);
       --toastify-color-success: var(--color-success);
       --toastify-color-warning:  var(--color-warning);
       --toastify-color-error: var(--color-error);
       --toastify-icon-color-info: var(--toastify-color-info);
       --toastify-icon-color-success: var(--toastify-color-success);
       --toastify-icon-color-warning: var(--toastify-color-warning);
       --toastify-icon-color-error: var(--toastify-color-error);
       --toastify-font-family: var(--font-family);
       --toastify-text-color-dark: var(--font-color-primary);

       // header
       --header-logo-width: ${({ theme }) => theme.header.logoWidth};
       --header-logo-mobile-width: ${({ theme }) => theme.header.logoWidthMobile};
       --header-color: ${({ theme }) => theme.header.color};
       --header-color-active: ${({ theme }) => theme.header.activeColor};
       --header-color-highlight: ${({ theme }) => theme.header.highlightColor};
     
        // Footer
        --footer-color: ${({ theme }) => theme.footer.color};
        --footer-bg: ${({ theme }) => theme.footer.background};
    }

    html {
        box-sizing: border-box;
        min-height: 100%;
        font-family: var(--font-family);
        color: var(--font-color-primary);
        scrollbar-color: ${({ theme }) => theme.scrollBar.color};
        scrollbar-width: ${({ theme }) => theme.scrollBar.width};

        @media ${mediaQuery.isSmallPhone} {
            font-size:15px
        }
    }

    body {
        overflow-y:scroll;
        min-height: 100vh;
        min-height: 100dvh;
        background: var(--color-body-bg);

        touch-action: pan-y;
        margin: 0;

        ${hideScrollBar};
    }

    html, body {
      overscroll-behavior-x: none;
    }

    iframe {
        border: unset;
    }

   mark {
       background: transparent;
       color: var(--color-primary);
   }

    * {-webkit-tap-highlight-color:transparent;}

    @supports (scrollbar-width: auto) {
        * {
            scrollbar-color: var(--ui-scroll-bg) var(--ui-scroll-track-bg);
            scrollbar-width: thin;

            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                scrollbar-width: none;
            }
        }
    }
    
    @supports selector(::-webkit-scrollbar) {
        &::-webkit-scrollbar-thumb {
            background: var(--ui-scroll-bg);
            border-radius: ${({ theme }) => theme.scrollBar.thumbRadius};
        }
            
        &::-webkit-scrollbar-track {
            background: var(--ui-scroll-track-bg);
        }
        
        &::-webkit-scrollbar {
            border-radius: ${({ theme }) => theme.scrollBar.scrollbarRadius};
            height: ${({ theme }) => theme.scrollBar.scrollbarWidth};
            width:${({ theme }) => theme.scrollBar.scrollbarHeight};
            
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                display:none;
            }
        }
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

   a,
   .link {
       color: var(--font-link-color);
       text-decoration: none;
       cursor: pointer;
   }

   .MuiInputBase-root {
       color: var(--font-color-primary);
       .MuiOutlinedInput-notchedOutline {
           border: var(--form-input-border);
       }
       &:hover, &.Mui-focused {
           .MuiOutlinedInput-notchedOutline{
               border-color: var(--form-toggle-border-active)
           }
       }
   }
   
   .MuiInputBase-root,
   .MuiPaper-root,
   .MuiButtonBase-root .MuiSvgIcon-root {
       background: transparent;
       color: var(--font-color-primary);
       background-image: none !important;
   }

   .MuiAutocomplete-popper .MuiAutocomplete-paper,
   .MuiAutocomplete-popper .MuiAutocomplete-option,
   .MuiModal-root .MuiPaper-root,
   .MuiDrawer-paper {
       color: var(--font-color-primary);
       background-color: var(--surface-level-1-bg);
   }

   .MuiAutocomplete-popper {
       .MuiAutocomplete-noOptions {
           color: rgba(255,255,255,0.7)
       }

       .MuiAutocomplete-option.Mui-focused {
           background-color: rgba(255, 255, 255, 0.08);
           &[aria-selected="true"] {
               background-color: var(--button-active-bg)
           }
       }
   }

   .cms-betslip {
       background: var(--surface-level-1-bg);
   }

   .flex {
       display: flex;
       align-items: center;
       
       &.justify-center {
           justify-content: center;
       }
       
       &.justify-space-between {
           justify-content: space-between;
       }

       .align-center {
           margin: auto;
       }

       .align-left {
           margin-right: auto;
       }

       .align-right {
           margin-left: auto;
       }
       
       &.gap-8 {
           gap: var(--spacing-8);
       }
       
       &.gap-16 {
           gap: var(--spacing-16);
       }
       
       &.column {
        flex-direction:column;
       }
   }
   
   .new-modal.esport-widget{
       max-width: 860px;
       padding: 0;
   }
`;

export interface ClientTheme {
    name?: string;
    generalUi: {
        radius: string;
        shadow: string;
        scrollBackground: string;
        scrollTrackBackground: string;
    };
    colors: {
        bodyBackground: string;
        headerBackground: string;
        primary: string;
        secondary: string;
        odds: string;
        turnover: string;
        success: string;
        info: string;
        warning: string;
        lost: string;
        error: string;
        alert: string;
        highlight: string;
        onHighlight: string;
        // TODO: refactor
        apple?: string;
        facebook: string;
        google: string;
        gold: string;
        motd: string;
        red: string;
        yellow: string;
        green: string;
        black: string;
        white: string;
    };
    typography: {
        fontFamily: string;
        fontBaseSize: string;
        fontColorPrimary: string;
        fontColorSecondary: string;
        fontColorSubtle: string;
        fontColorFaded: string;
        fontColorModalDesc: string;
        fontLinkColor: string;
        fontWeightRegular: number;
        fontWeightMedium: number;
        fontWeightBold: number;
        fontWeightHeavy: number;
    };
    surfacesLevel1: {
        background: string;
        border: string;
        lineColor: string;
    };
    surfacesLevel2: {
        background: string;
        border: string;
        lineColor: string;
    };
    surfacesLevel3: {
        background: string;
        border: string;
        lineColor: string;
    };
    buttons: {
        fontWeight: number | null;
        fontWeightMobile: number | null;
        background: string;
        backgroundHover: string;
        border: string;
        textColor: string | null;
        textColorHover: string | null;
        shadow: string | null;
        primaryBackground: string;
        primaryBackgroundHover: string;
        primaryBorder: string;
        primaryTextColor: string;
        primaryTextColorHover: string;
        primaryShadow: string | null;
        outlineBorder: string;
        outlineBorderHover: string;
        outlineTextColor: string;
        outlineTextColorHover: string;
        ghostTextColor: string;
        ghostBackgroundHover: string;
        activeBackground: string;
        activeBackgroundHover: string;
        activeBorder: string | null;
        activeTextColor: string | null;
        activeTextColorHover: string | null;
        activeShadow: string;
        cashoutBackground: string;
        cashoutBackgroundHover: string;
        cashoutBorder: string;
        cashoutTextColor: string;
    };
    cardButton: {
        background: string | null;
        onSurfaceBackground: string;
        onSurfaceActiveBackground: string;
        backgroundHover: string | null;
        border: string | null;
        onSurfaceBorder: string;
        textColor: string | null;
        onSurfaceActiveTextColor: string;
        textColorHover: string | null;
        onSurfaceTextColor: string;
        iconColor: string;
        shadow: string | null;
        activeBackground: string | null;
        activeBorder: string | null;
        activeTextColor: string | null;
        activeIconColor: string;
        activeShadow: string;
    };
    tabs: {
        line: string | null;
        textColor: string | null;
        textColorActive: string | null;
        textColorInverse: string | null;
        textWeight: number | null;
    };
    chips: {
        background: string | null;
        backgroundHover: string | null;
        backgroundSelected: string | null;

        border: string | null;
        borderSelected: string | null;

        textColor: string | null;
        textColorHover: string | null;
        textColorSelected: string | null;
    };
    forms: {
        inputBackground: string | null;
        inputBorder: string | null;

        inputText: string | null;
        inputLabelText: string;

        inputToggleBackground: string;
        inputToggleBorder: string;
        inputToggleKnobBackground: string;

        inputToggleBackgroundActive: string;
        inputToggleBorderActive: string;
        inputToggleKnobBackgroundActive: string;
    };
    mobileNavigation: {
        height: string;

        background: string;
        border: string;

        tabTextColor: string | null;
        tabTextColorActive: string | null;

        tabBackgroundActive: string;
        tabBorderActive: string;

        tabBadgeBackground: string | null;
        tabBadgeBorder: string;
        tabBadgeTextColor: string;
    };
    sportMenu: {
        background: string;
        border: string;

        selectBackground: string;
        selectBorder: string;
        selectFontColor: string;
        selectFontSize: string;
        selectFontWeight: string;
        selectFontStyle: string;

        expandedBackground: string | null;

        itemBackground: string;
        itemBackgroundHover: string;
        itemBackgroundActive: string;
        itemBackgroundExpanded: string | null;
        itemBackgroundExpandedHover: string | null;

        itemBorder: string;
        itemBorderActive: string | null;

        labelColor: string | null;

        textColor: string | null;
        textActive: string | null;

        iconColor: string;
        iconColorHover: string | null;
        iconColorActive: string | null;
    };
    sportContent: {
        background: string | null;
        border: string | null;
    };
    sportSearch: {
        background: string;
        border: string;
        iconColor: string | null;
        textColor: string | null;
        placeholderColor: string | null;
        resultBackground: string | null;
        resultLineSeparator: string;
    };
    sportCategory: {
        background: string;
        backgroundMobile: string;

        border?: string;

        borderWidth: string;
        borderColor: string;
        borderRadius: string;

        titleColor: string | null;
        marketsColor: string | null;
    };
    match: {
        nameWeight: number | null;
        primaryColor: string | null;
        secondaryColor: string | null;
        nameWidthDesktop: string;
        nameWidthLaptop: string;
        openBackground: string;
        openBorderColor: string;
        background: string;
        backgroundHover: string;
        borderWidth: string;
        borderStyle: string;
        borderColor: string | null;
        borderColorHover: string | null;
        borderRadius: string;
        margin: string;
        lineSelectBackground: string;
        lineSelectBackgroundHover: string;
        lineSelectBorder: string;
        lineSelectBorderHover: string;
        lineListBackground: string | null;
        lineListBorder: string | null;
        marketColor: string | null;
        sidebetBorder: string;
        sidebetBackground: string;
        sidebetNameColor: string | null;
        sidebetOutcomeColor: string | null;
        scoreBackground: string;
        scoreBorder: string;
        scoreRadius: string;
        scoreTextColor: string | null;
        liveTextColor: string | null;
        marketLineSelectWidthMobile: string;
        matchWidthDesktopAmericanLite: string;
        matchWidthDesktopLite: string;
        matchWidthLaptopLite: string;
        marketLineSelectHeightAmerican: string;
        marketLineSelectHeight: string;
        marketLineSelectWidth: string;
        marketLineSelectHeightAmericanMobile: string;
        marketLineSelectHeightMobile: string;
        marketLineSelectAmericanRadius: string;
        marketLineSelectRadius: string;
        marketLineSelectHeightAmericanTablet: string;
    };
    infoBox: {
        textColor: string | null;
        background: string | null;
        border: string | null;
    };
    odds: {
        radius: string;
        height: string;
        textWeight: number | null;
        lineTextWeight: number | null;
        background: string;
        border: string;
        backgroundHover: string;
        borderHover: string | null;
        backgroundActive: string | null;
        borderActive: string | null;
        fontSizeMobile: string;
        textColor: string | null;
        textHover: string | null;
        textActive: string;
        lineText: string | null;
        lineTextHover: string;
        lineTextActive: string | null;
        outcomeText: string | null;
        outcomeHeight: string;
    };
    betslip: {
        background: string | null;
        border: string | null;

        stakeBackground: string | null;
        stakeBackgroundFocused: string | null;
        stakeBorder: string | null;
        stakeLabelColor: string | null;

        stakeToWinBackground: string | null;
        stakeToWinBorder: string | null;
        stakeToWinLabelColor: string | null;

        stakeReturnLabelColor: string | null;

        labelGeneralColor: string | null;
        labelOddsColor: string | null;
        labelReturnColor: string;

        quickHightlight: string;
        betslipToggleHeight?: string;
    };
    tickets: {
        background: string | null;
        border: string | null;
        totalsBackground: string;
        textColorPrimary: string | null;
        textColorSecondary: string | null;
        selectionSeparator: string | null;
        totalOddsTextColor: string | null;
        totalOddsBackground: string | null;
        totalOddsBorder: string | null;
        stakeBackground: string | null;
        stakeText: string | null;
        stakeBorder: string;
        returnBackground: string;
        returnText: string | null;
        returnBorder: string;
        ticketIdColor: string | null;
        badgeLostBackground: string | null;
        badgeLostBorder: string | null;
        badgeLostTextColor: string;
        badgeWonBackground: string | null;
        badgeWonBorder: string | null;
        badgeWonTextColor: string;
        badgePendingBackground: string;
        badgePendingBorder: string;
        badgePendingTextColor: string | null;
        badgeCashoutBackground: string;
        badgeCashoutBorder: string;
        badgeCashoutTextColor: string;
        badgeWarningBackground: string | null;
        badgeWarningBorder: string | null;
        badgeWarningTextColor: string;
        badgeLivebetBackground: string;
        badgeLivebetBorder: string | null;
        badgeLivebetTextColor: string | null;
        badgeLiveBackground: string;
        badgeLiveBorder: string | null;
        badgeLiveTextColor: string | null;
        badgeSystemBackground: string;
        badgeSystemBorder: string | null;
        badgeSystemTextColor: string | null;
        badgeComboBackground: string;
        badgeComboBorder: string;
        badgeComboTextColor: string;
        badgeSingleBackground: string;
        badgeSingleBorder: string | null;
        badgeSingleTextColor: string | null;
        totalsFontSize: string;
        totalsFontSizeMobile: string;
    };
    layout: {
        gutter: string;
        gutterHalf: string;
        gutterDouble: string;
        gutterMobile: string;
        maxWidth: string;
        rgPanelHeight: string;
    };
    alerts: {
        successColor: string;
        warningColor: string;
        failureColor: string;
    };
    scrollBar: {
        color: string;
        width: string;
        thumbBackground: string;
        thumbRadius: string;
        trackBackground: string;
        scrollbarRadius: string;
        scrollbarWidth: string;
        scrollbarHeight: string;
    };
    header: {
        logoWidth: string;
        logoWidthMobile?: string;
        height: string;
        color: string;
        activeColor: string;
        highlightColor: string;
    };
    footer: {
        color: string;
        background: string;
    };
    optimizeFontRendering: any;
    contentBoxStyle: string;
    box: {
        background: string;
    };
    mobileTabs: {
        height: string;
    };
    casinoRace?: {
        primaryBackground: string;
        secondaryBackground: string;
        scoreColor: string;
        placeColor: string;
        comboBackground: string;
        comboUnfilledColor: string;
        mainInfoContainerColor: string;
        marginBottom: string;
        racePrizeComponentColor: string;
        racePrizeComponentBorder: string;
        racePrizeBackground: string;
        racePrizeText: string;
        marginTop: string;
        successfulRegistration: string;
        leaderboardHeaderColor: string;
        raceRuleButtomLineColor: string;
        raceFinishedTickColor: string;
        spinBarColor: string;
        spinBarUnfilledColor: string;
        strikeBarFilled: string;
        showAllGameButtonColor: string;
        ruleIconBackgroundColor: string;
        spinIconRuleColor: string;
        casinoCardClickedColor: string;
        casinoCardBackground: string;
        casinoCardBorderColor: string;
    };
    pokerLeaderboard?: {
        primaryBackground: string;
        secondaryBackground: string;
        placeColor: string;
    };
    poker?: {
        backgroundColor: string;
        cardBackgroundColor: string;
        cardBorderColor: string;
    };
    payback?: {
        primaryColor: string;
    };
    paybackBooster?: {
        primaryColor: string;
    };
    providerLobby?: {
        primaryColor: string;
    };
    mediaQuery: {
        isPhone: string;
        isTablet: string;
        isLaptop: string;
        isDesktop: string;
        isDesktopMedium: string;
        isLaptopMin: string;
        isTabletMax: string;
        isTabletMediumLarge: string;
        isTabletMediumSmall: string;
        isTabletMin: string;
        isPhoneMedium: string;
        isPhoneMin: string;
        isDesktopSmall: string;
        isSmallerThanLaptop: string;
        is4kDesktop: string;
        is8kDesktop: string;
    };
}
