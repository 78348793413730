import React, { createContext, useContext, useRef, useState } from 'react';

const BetslipV2KeyboardContext = createContext<{
    inputRef: any;
    isOpen: boolean;
    openKeyboard: (ref: any, onChange: (value: any) => void) => void;
    closeKeyboard: () => void;
    state: any;
}>({
    inputRef: null,
    isOpen: false,
    openKeyboard: () => {},
    closeKeyboard: () => {},
    state: {},
});

export function BetslipV2KeyboardContextProvider({ children }: React.PropsWithChildren) {
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState({});
    const inputRef = useRef(null);
    function openKeyboard(ref, onChange) {
        setIsOpen(true);
        inputRef.current = ref.current;
        setState({ ...state, onChange });
    }

    function closeKeyboard() {
        setIsOpen(false);
        inputRef.current = null;
        setState({});
    }

    return (
        <BetslipV2KeyboardContext.Provider value={{ inputRef, isOpen, openKeyboard, closeKeyboard, state }}>
            {children}
        </BetslipV2KeyboardContext.Provider>
    );
}

export default function useBetslipV2KeyboardContext() {
    return useContext(BetslipV2KeyboardContext);
}
