import styled from 'styled-components';
import UiButton from '../../../../ui/button/UiButton';
import { mediaQuery } from '../../../../../styles/utils';

export default styled(UiButton)`
    background-color: var(--surface-level-3-bg);
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-4);
    padding: var(--spacing-8);
    min-width: unset;
    max-width: unset;
    height: 2rem;
    font-size: var(--font-12);
    line-height: 1;

    @media ${mediaQuery.isPhone} {
        padding: var(--spacing-12);
        height: 2.5rem;
    }

    & .button-icon {
        margin: 0;

        min-height: unset;

        & svg {
            height: unset;
        }
    }

    & .button-text {
        display: inline;
        max-width: 114px;
        min-height: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
