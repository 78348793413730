import { number as checkCreditCard } from 'card-validator';
import React, { useEffect, useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { getProviderPaymentMethods, requestProviderPayout } from '../../../microservices/payments';
import { logger } from '../../../services/logger';
import {
    cleanCardNumber,
    cleanExpirationDate,
    depositWithProvider,
    expirationDateTo6Digits,
    getDepositReturnRoute,
    getIsMaxCardsReached,
    validateExpirationDate,
} from '../../../services/payments/payments';
import { DepositStatus, ExistingPaymentMethod, PROVIDERS, PROVIDER_TYPE } from '../../../services/payments/types';
import { REGEX_NUMERIC } from '../../../services/regex';
import { useRouter } from '../../../services/router';
import { translate } from '../../../services/translate';
import { getFullName } from '../../../services/user';
import { stores } from '../../../stores';
import Snippet from '../../snippet/Snippet';
import Ui2Form from '../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../ui-2/form/text-input/Ui2FormTextInput';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import UiGroup from '../../ui/group/UiGroup';
import PaymentCardFormModalCards from './cards/PaymentCardFormModalCards';
import Wrapper from './styles';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';

interface Props {
    amount: number;
    disclaimer?: string;
    errorCodeMessages?: Record<number, string>;
    isAllowedToAddCard?: boolean;
    isAllowedToGoOverMaxCardsLimit?: boolean;
    isCvcRequiredForNewCard?: boolean;
    isCvcRequiredForSavedCard?: boolean;
    isHolderEditAllowed?: boolean;
    isIframe?: boolean;
    onClose: (event?: 'success') => void;
    onEncryption?: (arg0: string) => void;
    provider: PROVIDERS;
}
interface DepositProps extends Props {
    onDeposit?: (arg0: any) => void;
    deviceHash: string;
    providerType: PROVIDER_TYPE.DEPOSIT;
}

interface WithdrawalProps extends Props {
    providerType: PROVIDER_TYPE.WITHDRAWAL;
}

export default function PaymentCardFormModal({
    amount,
    disclaimer,
    errorCodeMessages = {},
    isAllowedToAddCard = true,
    isAllowedToGoOverMaxCardsLimit = false,
    isCvcRequiredForNewCard = true,
    isCvcRequiredForSavedCard = true,
    isIframe,
    onClose,
    onEncryption,
    provider,
    providerType,
    isHolderEditAllowed = false,
    ...providerTypeProps
}: DepositProps | WithdrawalProps) {
    const useFormMethods = useForm();
    const { errors } = useFormMethods.formState;
    const [cards, setCards] = useState<{ id: string; number: string; expiration_date?: string; description: string }[]>(
        [],
    );
    const [errorMessage, setErrorMessage] = useState('');
    const [isAddingNewCard, setIsAddingNewCard] = useState<boolean>(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isMaxCardsReached, setIsMaxCardsReached] = useState(false);
    const [{ isPhone }] = useStore(media);
    const { navigateTo } = useRouter();
    const [user] = useStore(stores.user);

    useEffect(() => {
        loadCards();
    }, []);

    async function loadCards() {
        try {
            const cards = await getProviderPaymentMethods(provider, providerType);
            setIsMaxCardsReached(await getIsMaxCardsReached({ raiseLimitBy: +isAllowedToGoOverMaxCardsLimit }));
            useFormMethods.setValue('holder', getFullName(), { shouldValidate: true });

            if (cards.length) {
                setCards(cards);
                useFormMethods.setValue('methodId', cards[0].id, { shouldValidate: true });
            } else {
                if (isIframe && !disclaimer) {
                    await payment({});
                    return;
                }
                setIsAddingNewCard(true);
            }
        } catch (error) {
            logger.error('PaymentCardFormModal', 'loadCards', error);
        }

        setIsLoading(false);
    }

    async function onSubmit(formValues: FieldValues) {
        if (!isValidForm(formValues)) {
            return;
        }

        setIsInProgress(true);

        if (onEncryption) {
            handleOnEncryption(formValues, onEncryption);
        }

        await doPayment(formValues);
        setIsInProgress(false);
    }

    async function doPayment(formValues: FieldValues) {
        try {
            await payment(formValues);
        } catch (error: any) {
            handlePaymentErrors(error);
        }
    }

    function handlePaymentErrors(error: any) {
        const { code, field, message } = error;

        if (field) {
            const translatedMessage = translate(message, 'ui.payments');

            if (field === 'number') {
                useFormMethods.setError(field, { message: translatedMessage });
            } else if (field === 'cvc') {
                useFormMethods.setError(field, { message: translatedMessage });
            } else if (field === 'expirationDate') {
                useFormMethods.setError(field, { message: translatedMessage });
            }
        } else if (code && errorCodeMessages[code]) {
            setErrorMessage(errorCodeMessages[code]);
        } else {
            setErrorMessage(translate('Technical Error', 'ui.common'));
            logger.error('PaymentCardFormModal', 'handlePaymentErrors', error);
        }
    }

    function isValidForm(formValues: FieldValues) {
        let isFormValid = true;
        let isCvcRequired = false;

        if (!isAddingNewCard) {
            delete formValues.number;
            isCvcRequired = providerType === PROVIDER_TYPE.DEPOSIT && isCvcRequiredForSavedCard;
        } else {
            formValues.number = cleanCardNumber(formValues.number);
            const { isValid: isCreditCardValid } = checkCreditCard(formValues.number);

            if (!formValues.number || !isCreditCardValid) {
                useFormMethods.setError('number', { message: translate('Invalid card number', 'ui.payments') });
                isFormValid = false;
            }

            if (!formValues.holder && isHolderEditAllowed) {
                useFormMethods.setError('holder', { message: translate('Invalid holder name', 'ui.payments') });
                isFormValid = false;
            }

            formValues.expirationDate = expirationDateTo6Digits(formValues.expirationDate);
            const [isExpirationDateValid, error] = validateExpirationDate(formValues.expirationDate);

            if (!isExpirationDateValid) {
                useFormMethods.setError('expirationDate', { message: translate(error, 'ui.payments') });
                isFormValid = false;
            }

            isCvcRequired = providerType === PROVIDER_TYPE.DEPOSIT && isCvcRequiredForNewCard;
        }

        if (isCvcRequired && (!formValues.cvc || formValues.cvc.length < 3)) {
            useFormMethods.setError('cvc', { message: translate('Invalid CVC', 'ui.payments') });
            isFormValid = false;
        }

        return isFormValid;
    }

    function handleOnEncryption(formValues: FieldValues, onEncryption) {
        formValues.encryptedCvc = onEncryption(formValues.cvc);
        if (isAddingNewCard) {
            formValues.encryptedNumber = onEncryption(formValues.number);
            // When external encryption mechanism is being used and private key is not known, we can't decrypt
            // card number in the backend. So we have to pass unencrypted sanitized number instead.
            formValues.number = blurNumber(formValues.number);
        }
        delete formValues.cvc;
    }

    async function payment(formValues: FieldValues) {
        const requestData = {
            amount,
            methodId: formValues?.methodId,
            provider,
            providerParams: formValues,
        };

        if (providerType === PROVIDER_TYPE.DEPOSIT) {
            await deposit(requestData);
        } else if (providerType === PROVIDER_TYPE.WITHDRAWAL) {
            await payout(requestData);
        }
    }

    async function deposit(params: { amount: number; methodId?: string; provider: PROVIDERS; providerParams?: any }) {
        const { onDeposit, deviceHash } = providerTypeProps as DepositProps;
        const depositResponse = await depositWithProvider({ ...params, deviceHash });

        if (onDeposit) {
            onDeposit(depositResponse);
        } else if (depositResponse.url) {
            window.location.href = depositResponse.url;
        } else {
            navigateTo(getDepositReturnRoute(DepositStatus.COMPLETED));
        }
    }

    async function payout(params: { amount: number; methodId?: string; provider: string; providerParams?: any }) {
        await requestProviderPayout(params);
        onClose('success');
    }

    function setSelectedCard(card: ExistingPaymentMethod) {
        useFormMethods.setValue('methodId', card.id);
        useFormMethods.clearErrors();
    }

    async function addCard() {
        useFormMethods.clearErrors();
        if (isMaxCardsReached) {
            setErrorMessage(translate('MAX_CARDS_REGISTERED_REACHED', 'ui.account'));
            return;
        }

        useFormMethods.setValue('methodId', undefined);
        isIframe ? await doIframePayment() : setIsAddingNewCard(true);
    }

    async function doIframePayment() {
        setIsInProgress(true);

        try {
            await payment({});
        } catch (error) {
            setErrorMessage(translate('Technical Error', 'ui.common'));
            logger.error('PaymentCardFormModal', 'doIframePayment', error);
            setIsInProgress(false);
        }
    }

    function onExpirationDateChange(expirationDate: string) {
        useFormMethods.setValue(
            'expirationDate',
            expirationDate.match(/^\d{2}$/) ? `${expirationDate}/` : cleanExpirationDate(expirationDate),
        );
    }

    function handleOnChange({ target }) {
        if (target.name === 'expirationDate') {
            onExpirationDateChange(target.value);
        }
    }

    function onBack() {
        if (cards.length) {
            useFormMethods.clearErrors();
            useFormMethods.setValue('methodId', cards[0].id);
            setIsAddingNewCard(false);
        } else {
            onClose();
        }
    }

    const blurNumber = (number) => `${number.substring(0, 6)}******${number.substring(12)}`;

    if (!user) {
        return null;
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    if (isIframe && disclaimer && isAddingNewCard) {
        return (
            <>
                {disclaimer && <Snippet snippetKey={disclaimer} />}
                <UiButton
                    block={isPhone}
                    color="primary"
                    onClick={doIframePayment}
                    isLoading={isInProgress}
                    disabled={isInProgress}
                >
                    {translate('Continue', 'ui.account')}
                </UiButton>
            </>
        );
    }

    return (
        <Wrapper>
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {errorMessage && <UiAlert failure>{errorMessage}</UiAlert>}
            <Ui2Form
                onSubmit={() => onSubmit(useFormMethods.getValues())}
                useFormMethods={useFormMethods}
                onChange={handleOnChange}
            >
                {isAddingNewCard && (
                    <>
                        <UiFormGroup>
                            <Ui2FormTextInput
                                className="number-input"
                                error={errors?.['number'] as FieldError}
                                label={translate('Card Number', 'ui.payments')}
                                maxLength={19}
                                name="number"
                                pattern={REGEX_NUMERIC}
                            />
                        </UiFormGroup>

                        <UiFormGroup>
                            <Ui2FormTextInput
                                readOnly={!isHolderEditAllowed}
                                error={errors?.['holder'] as FieldError}
                                name="holder"
                                label={translate('Holder Name', 'ui.payments')}
                                type="text"
                            />
                        </UiFormGroup>
                        <UiGroup className="card-expiration-date-and-cvc-container">
                            <Ui2FormTextInput
                                className="expiration-date-and-cvc-inputs"
                                error={errors?.['expirationDate'] as FieldError}
                                label={translate('Exp. Date', 'ui.payments')}
                                maxLength={5}
                                name="expirationDate"
                                placeholder={translate('MM/YY', 'ui.payments')}
                            />
                            {providerType === PROVIDER_TYPE.DEPOSIT && isCvcRequiredForNewCard && (
                                <Ui2FormTextInput
                                    className="expiration-date-and-cvc-inputs"
                                    error={errors?.['cvc'] as FieldError}
                                    label={translate('CVC', 'ui.payments')}
                                    maxLength={3}
                                    name="cvc"
                                    pattern={REGEX_NUMERIC}
                                />
                            )}
                        </UiGroup>

                        <UiGroup className="buttons">
                            <UiButton block onClick={onBack}>
                                {translate('Back', 'ui.common')}
                            </UiButton>
                            <UiButton
                                type="button"
                                block
                                color="primary"
                                isFormSubmitButton
                                isLoading={isInProgress}
                                disabled={isInProgress}
                            >
                                {translate('Continue', 'ui.account')}
                            </UiButton>
                        </UiGroup>
                    </>
                )}

                {!isAddingNewCard && (
                    <>
                        <UiGroup vertical layoutGutterInRem="1rem">
                            <PaymentCardFormModalCards
                                cards={cards}
                                shouldShowCvcInput={providerType === PROVIDER_TYPE.DEPOSIT && isCvcRequiredForSavedCard}
                                onSelectCard={setSelectedCard}
                                cvcError={errors?.['cvc'] as FieldError}
                            />
                            {isAllowedToAddCard && (
                                <UiButton block disabled={isInProgress} color="ghost" onClick={addCard}>
                                    {translate('Add card', 'ui.account')}
                                </UiButton>
                            )}
                        </UiGroup>
                        <UiGroup className="buttons">
                            <UiButton block onClick={() => onClose()}>
                                {translate('Back', 'ui.common')}
                            </UiButton>

                            <UiButton
                                type="button"
                                block
                                color="primary"
                                isFormSubmitButton
                                isLoading={isInProgress}
                                disabled={isInProgress}
                            >
                                {translate('Continue', 'ui.account')}
                            </UiButton>
                        </UiGroup>
                    </>
                )}
            </Ui2Form>
        </Wrapper>
    );
}
