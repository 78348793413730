import { getEvolutionLobbyUrl } from '../microservices/casino-integrations';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { sendNativeEvent, NativeMessageEventType } from './mobile-app';
import { requestLogin } from './auth';
import { CasinoProviders } from './casino/types';
import { AppType } from './mobile-app/types';

export async function openCasinoLobby(appType: AppType) {
    if (getStoreValue(stores.isAuthenticated)) {
        const launchUrl = await getEvolutionLobbyUrl(appType);
        sendNativeEvent({
            type: NativeMessageEventType.GAME_LAUNCH,
            provider: CasinoProviders[CasinoProviders.EVOLUTION]?.toLowerCase(),
            params: launchUrl.launchParams,
        });
    } else {
        if (!getStoreValue(stores.modals.isLoginModalOpen)) {
            requestLogin();
        }
    }
}
