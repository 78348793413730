import React from 'react';
import Wrapper from './styles';
import classNames from 'classnames';
import UiButton from '../../../../ui/button/UiButton';
import Svg from '../../../../svg/Svg';
import UiBadge from '../../../../ui/badge/UiBadge';

interface Props {
    icon: string;
    label: string;
    isSelected: boolean;
    liveMatchesCount?: number;
    url?: string;
    onClick?: () => void;
}

export default function SportLobbyCategoriesV2MobileButton({
    isSelected,
    icon,
    label,
    liveMatchesCount,
    ...rest
}: Props) {
    return (
        <Wrapper
            className={classNames({
                ['sport-menu-selected']: isSelected,
            })}
        >
            <UiButton
                type="card"
                icon={<Svg icon={icon} size={1.4} />}
                size="tiny"
                selected={isSelected}
                {...rest}
                className={classNames('sport-menu-button', {
                    ['sport-menu-button-selected']: isSelected,
                })}
            />
            <span>{label}</span>
            {liveMatchesCount && <UiBadge className="sport-menu-counter" badge={liveMatchesCount} />}
        </Wrapper>
    );
}
