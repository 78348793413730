import React, { useEffect, useState } from 'react';
import ScriptLoader from '../../../../../../script-loader/ScriptLoader';

interface Props {
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentDepositProviderEveryPayGooglePayScripts({ onError, onScriptsLoaded }: Props) {
    const SDK_URL = 'https://pay.every-pay.eu/google_pay/google-pay-client-1.4.js';
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
        if (isScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isScriptLoaded]);

    return <ScriptLoader url={SDK_URL} onError={onError} onLoad={() => setIsScriptLoaded(true)} />;
}
