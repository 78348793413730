import React, { useEffect, useState } from 'react';
import SportMatchMarkets from '../SportMatchMarkets';
import {
    MarketTypeGroupIdAll,
    MarketTypeGroupIdBetbuilder,
    MarketTypeGroupIdPopular,
    useFoGroupFilters,
    useMatchSidebets,
} from '../../../../../services/sports/sidebets';
import { CategoryMatch } from '../../../../../services/sports/types';
import { isBetBuilderFeatureAvailable } from '../../../../../services/bet-builder';

interface Props {
    matchId: number;
    match: CategoryMatch;
}

export default function SportMatchMarketsLoad({ matchId, match }: Props) {
    const [isBetbuilderShown, setIsBetbuilderShown] = useState(false);
    const [currentMarketTypeGroup, setCurrentMarketTypeGroup] = useState(
        match?.match_type ? MarketTypeGroupIdAll : MarketTypeGroupIdPopular,
    );
    const { sidebetResponse } = useMatchSidebets(matchId, currentMarketTypeGroup, match.status);
    const { markets, foGroups } = sidebetResponse || {};
    const allMarketTypeGroupFilters = useFoGroupFilters(
        setCurrentMarketTypeGroup,
        foGroups,
        currentMarketTypeGroup,
        match.id,
        isBetBuilderFeatureAvailable() && Boolean(match.betbuilder_id),
    );

    useEffect(() => {
        setIsBetbuilderShown(currentMarketTypeGroup === MarketTypeGroupIdBetbuilder);
    }, [currentMarketTypeGroup]);

    return (
        <SportMatchMarkets
            marketsByMarketType={markets}
            allMarketTypeGroupFilters={allMarketTypeGroupFilters}
            match={match}
            setCurrentMarketTypeGroup={setCurrentMarketTypeGroup}
            currentMarketTypeGroup={currentMarketTypeGroup}
            isBetbuilderShown={isBetbuilderShown}
        />
    );
}
