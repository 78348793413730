import React, { useEffect, useRef, useState } from 'react';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import ceil from 'lodash/ceil';
import round from 'lodash/round';
import {
    ADJUSTED_TOWIN_CALC,
    BETSLIP_MAX_LENGTH,
    COMBO_MARKET_ID,
    MULTI_SINGLE_MARKET_ID,
} from '../../../../services/sports/constants';
import { addBetslipError, removeBetslipError } from '../../../../services/sports/betslip-errors';
import Svg from '../../../svg/Svg';
import { TAG_MANAGER_EVENT, trackGoogleTagManagerEvent } from '../../../../services/analytics';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import UiGroup from '../../../ui/group/UiGroup';
import classNames from 'classnames';
import { CURRENCY_SYMBOL, getActiveCurrency } from '../../../../services/currency';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import { BET_TYPE } from '../../../../services/sports/types';
import Wrapper from './styles';
import useBetslipV2KeyboardContext from '../../../../contexts/betslip-v-2/keyboard/BetslipV2KeyboardContext';
import { useTotalStake } from '../../../../hooks/betslip';
import { useTotalMaxWin } from '../../../../hooks/betslip-v2';
import { payoutRound } from '../../../../services/sports/betslip-formatting';

interface Props {
    onChange: (value) => void;
    isDisabled: boolean;
    hideQuickStakes?: boolean;
    disableQuickStakes?: boolean;
    value: number;
    name: string;
    totalOdds?: number;
    disableToWin?: boolean;
    marketId?: string | null;
    label?: string;
}

export default function SportBetslipV2WageToWin({
    onChange,
    isDisabled,
    value,
    name,
    totalOdds,
    disableToWin = true,
    label,
    marketId,
}: Props) {
    const { isAmericanBetslipInputFormat } = useSportsUserSettings();
    const [betSlipUserState] = useStore(stores.sports.betSlipUserState);
    const { betType } = betSlipUserState;
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const [toWinValue, setToWinValue] = useState<string>();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isToWinFocused, setIsToWinFocused] = useState(false);
    const [{ isPhone }] = useStore(media);
    const inputRef = useRef<HTMLInputElement>(null);
    const marketIdsToTake =
        betType !== BET_TYPE.SINGLE
            ? [COMBO_MARKET_ID]
            : marketId
            ? [marketId]
            : Object.keys(betSlipMarketIdToOutcomeId);

    const elementRef = useRef<HTMLDivElement>(null);
    const betStakeInputId = `betStakeInputId-${name}`;

    const { openKeyboard } = useBetslipV2KeyboardContext();
    const { totalStake } = useTotalStake();
    const { totalMaxWin } = useTotalMaxWin();

    useEffect(() => {
        if (marketId === MULTI_SINGLE_MARKET_ID) {
            setToWinValue(round(totalMaxWin - totalStake, 2).toString());
        }
    }, [totalStake, totalOdds]);

    useEffect(() => {
        if (marketId !== MULTI_SINGLE_MARKET_ID) {
            if (!disableToWin && totalOdds && isAmericanBetslipInputFormat && !isToWinFocused) {
                const newToWinValue = payoutRound(totalOdds * value - value);
                setToWinValue(newToWinValue ? newToWinValue.toString() : '');
            }
        }
    }, [value, isAmericanBetslipInputFormat, totalOdds]);

    useEffect(() => {
        Object.keys(betSlipErrorByMarketId).forEach((marketId) => {
            removeBetslipError(marketId, ADJUSTED_TOWIN_CALC);
        });
    }, [isAmericanBetslipInputFormat, betType]);

    useEffect(() => {
        if (isInputFocused) {
            clearStake();

            if (isPhone) {
                const stakeElement = elementRef.current;
                if (stakeElement) {
                    stakeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }
        }
    }, [isInputFocused]);

    function handleInputFocus() {
        setIsInputFocused(true);
        setIsToWinFocused(false);
        stores.sports.isBottomNavigationVisible.set(false);
        openKeyboard(inputRef, onChange);
    }

    function handleToWinFocus() {
        setIsToWinFocused(true);
        setIsInputFocused(false);
        stores.sports.isBottomNavigationVisible.set(false);
    }

    function handleInputBlur() {
        setIsInputFocused(false);
        stores.sports.isBottomNavigationVisible.set(true);
    }

    function handleToWinBlur() {
        setIsToWinFocused(false);
        stores.sports.isBottomNavigationVisible.set(true);

        if (totalOdds) {
            const newToWinValue = payoutRound(totalOdds * value - value).toString();

            if (newToWinValue !== toWinValue) {
                marketIdsToTake.forEach((marketId) => {
                    addBetslipError(marketId, ADJUSTED_TOWIN_CALC);
                });

                setToWinValue(newToWinValue);
            }
        }
    }

    function handleToWinChange(newToWinValue) {
        setToWinValue(newToWinValue);
        if (totalOdds && newToWinValue) {
            const roundValue = round(newToWinValue / (totalOdds - 1), 2);
            const ceilValue = ceil(newToWinValue / (totalOdds - 1), 2);
            const newValue =
                round(totalOdds * roundValue - roundValue, 2).toString() === newToWinValue ? roundValue : ceilValue;
            onChange(newValue);
        }

        marketIdsToTake.forEach((marketId) => {
            removeBetslipError(marketId, ADJUSTED_TOWIN_CALC);
        });
    }

    function handleStakeInput(value) {
        onChange(value);
    }

    const inputProps = {
        name,
        type: 'text',
        min: 0,
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
        onChange: (e) => handleStakeInput(e.target.value),
        autoComplete: 'off',
        autoCorrect: 'off',
        autoCapitalize: 'off',
        spellCheck: 'false',
        'data-gramm_editor': 'false',
        'data-gramm': 'false',
    } as const;

    function clearStake() {
        onChange('');
        trackGoogleTagManagerEvent(TAG_MANAGER_EVENT.BETSLIP_QUICK_STAKE_REMOVE_CLICKED);
    }

    return (
        <Wrapper>
            <UiGroup horizontallyCentered>
                <div className="stake-input stake-input-v2" ref={elementRef}>
                    <label
                        htmlFor={betStakeInputId}
                        className={classNames('stake-input-label', {
                            'single-label': betType === BET_TYPE.SINGLE || betType === BET_TYPE.BETBUILDER,
                        })}
                    >
                        {translate(label || (!isAmericanBetslipInputFormat ? 'Your stake' : 'Wager'), 'ui.sportsbook')}
                    </label>

                    <div className="stake-input-wrapper" data-test="retailStakeInputWrapper">
                        <div className="stake-currency">{CURRENCY_SYMBOL[getActiveCurrency()]}</div>
                        <input
                            id={betStakeInputId}
                            value={value}
                            ref={inputRef}
                            maxLength={BETSLIP_MAX_LENGTH}
                            readOnly={isPhone}
                            disabled={isDisabled}
                            placeholder="Stake"
                            {...inputProps}
                        />
                    </div>

                    {value > 0 && !isDisabled && (
                        <div
                            className={classNames('remove-stake-button', {
                                'remove-button-small': isAmericanBetslipInputFormat,
                            })}
                            onClick={clearStake}
                        >
                            <Svg icon="backspace" />
                        </div>
                    )}
                </div>
                {isAmericanBetslipInputFormat && !disableToWin && Number(totalOdds) > 0 && (
                    <>
                        <div className="stake-input input-to-win stake-input-v2">
                            <label
                                htmlFor="betStakeInput"
                                className={classNames('stake-input-label label-to-win', {
                                    'single-label': betType === BET_TYPE.SINGLE || betType === BET_TYPE.BETBUILDER,
                                })}
                            >
                                {translate('To win', 'ui.sportsbook')}
                            </label>
                            <div className="stake-input-wrapper input-to-win">
                                <div className="stake-currency">{CURRENCY_SYMBOL[getActiveCurrency()]}</div>
                                <input
                                    id="betStake"
                                    value={toWinValue}
                                    {...inputProps}
                                    onChange={(e) => handleToWinChange(e.target.value)}
                                    onBlur={handleToWinBlur}
                                    onFocus={handleToWinFocus}
                                    disabled={isDisabled || marketId === MULTI_SINGLE_MARKET_ID}
                                    readOnly={isPhone}
                                    placeholder="Stake"
                                />
                            </div>
                        </div>
                    </>
                )}
            </UiGroup>
        </Wrapper>
    );
}
