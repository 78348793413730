import styled from 'styled-components';

export default styled.div`
    flex-shrink: 0;
    height: 14.4rem;
    width: 17.75rem;
    border: var(--surface-level-2-border);
    box-shadow: var(--ui-shadow);
    border-radius: var(--ui-radius);
    overflow: hidden;
    color: var(--font-color-primary);
    user-select: none;

    &.motd {
        border-color: var(--color-primary);
        display: flex;
        flex-direction: column;
    }
`;
