import React, { useEffect, useState } from 'react';
import { depositWithProvider } from '../../../../../services/payments/payments';
import { PaymentProvider, PROVIDERS } from '../../../../../services/payments/types';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';

interface Props {
    amount: number;
    deviceHash: string;
    selectedDepositProvider: PaymentProvider;
    isQuickDeposit?: boolean;
}

export default function PaymentDepositProviderTrustly({
    amount,
    deviceHash,
    selectedDepositProvider,
    isQuickDeposit,
}: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isProviderNotAvailable, setIsProviderNotAvailable] = useState(false);
    const [depositUrl, setDepositUrl] = useState('');

    useEffect(() => {
        if (selectedDepositProvider.aux?.methodId) {
            depositWithSavedMethod();
        } else {
            deposit();
        }
    }, []);

    async function deposit() {
        setIsLoading(true);
        const requestData = {
            amount,
            deviceHash,
            methodId: selectedDepositProvider.aux.methodId,
            provider: PROVIDERS.TRUSTLY,
            providerParams: {
                isQuickDeposit,
            },
        };
        try {
            const { url } = await depositWithProvider(requestData);
            setDepositUrl(url);
            setIsLoading(false);
        } catch (error) {
            setIsProviderNotAvailable(true);
            setIsLoading(false);
        }
    }

    async function depositWithSavedMethod() {
        setIsLoading(true);
        const requestData = {
            amount,
            deviceHash,
            methodId: selectedDepositProvider.aux.methodId,
            provider: PROVIDERS.TRUSTLY,
            providerParams: {
                isQuickDeposit,
            },
        };
        try {
            const { url } = await depositWithProvider(requestData);
            setDepositUrl(url);
            setIsLoading(false);
        } catch (error) {
            setIsProviderNotAvailable(true);
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    if (isProviderNotAvailable) {
        return <PaymentProviderNotAvailable />;
    }

    return (
        <Wrapper>
            <iframe src={depositUrl} className="trustly-iframe" title="trustly-iframe" />
        </Wrapper>
    );
}
