import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

type StyledProps = {
    $isCompact?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;

    text-align: center;
    font-size: 0.7rem;
    line-height: 1.125rem;
    height: 1.125rem;

    ${({ $isCompact }) =>
        $isCompact &&
        css`
            flex-direction: column;
            justify-content: center;
            margin-right: 0;
            > *:not(:last-of-type) {
                margin-bottom: 0.2rem;
            }
        `}

    > *:not(:last-child) {
        margin-right: ${({ $isCompact }) => ($isCompact ? '0' : '0.4rem')};
    }
    .live-time {
        color: var(--match-live-text-color);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        font-size: 0.875rem;
    }
    .live-period {
        white-space: nowrap;
        color: var(--match-secondary-color);
        @media ${mediaQuery.isPhone} {
            white-space: ${({ $isCompact }) => ($isCompact ? 'normal' : '')};
        }
    }
`;
