import styled from 'styled-components';

interface StyledProps {
    $scroll: number;
}

export default styled.div<StyledProps>`
    position: sticky;
    top: 0;
    height: 100vw;
    max-height: calc(100vh - ${(props) => (props.$scroll < 80 ? 80 - props.$scroll : 0)}px);

    background: var(--sport-menu-bg);
    border: var(--sport-menu-border);
    border-radius: var(--ui-radius);

    padding: var(--spacing-12);
    overflow: auto;
    scrollbar-width: thin;
`;
