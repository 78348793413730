import styled from 'styled-components';

export default styled.div`
    margin-left: calc(-1 * var(--spacing-24));
    margin-right: calc(-1 * var(--spacing-24));
    margin-bottom: calc(-1 * var(--spacing-24));

    .trustly-iframe {
        width: 100%;
        height: 500px;
    }
`;
