import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    margin: var(--spacing-8) 0;
    .iframe {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 60vh;
        min-height: 410px;
        @media ${mediaQuery.isPhone} {
            min-height: 320px;
        }
        z-index: 100;

        font-size: 30px;

        background-color: 2px solid var(--color-body-bg);

        border: 2px solid var(--surface-level-2-bg);
        border-radius: var(--ui-radius);
    }

    .alert {
        text-align: center;
    }
`;
