import { DatabaseComboCardType } from '@staycool/sports-types';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useCountdown } from '../../../../hooks/useCountdown';
import { getRemainingBettingEndTime } from '../../../../services/sports/combo-cards';
import { translate } from '../../../../services/translate';
import Svg from '../../../svg/Svg';
import Wrapper from './styles';

interface Props {
    icon?: ReactNode;
    type?: DatabaseComboCardType;
    numberOfOutcomes?: number;
    bettingEnd: string;
    title: ReactNode;
}

export default function SportCardHeader({ icon, type, bettingEnd, numberOfOutcomes, title }: Props) {
    const [days, hours, minutes, seconds] = useCountdown(bettingEnd.toString());
    const cardType = type ? type.name : translate('mobile.app.feature.motd.title');

    return (
        <Wrapper $accentColor={type?.bg_color}>
            <div className={classNames('header', { motd: !type })}>
                <div className="card-type">
                    {cardType}
                    {numberOfOutcomes && (
                        <div className="outcomes">
                            <span>{numberOfOutcomes}</span>
                        </div>
                    )}
                </div>
                <div className="betting-end">
                    <Svg icon="clock" size={0.625} />
                    {getRemainingBettingEndTime(days, hours, minutes, seconds)}
                </div>
            </div>
            <div className="card-name">
                {icon}
                {title}
            </div>
        </Wrapper>
    );
}
