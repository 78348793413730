import styled from 'styled-components';

export default styled.div`
    position: relative;
    border: var(--surface-level-2-border);
    border-radius: var(--ui-radius);
    background: var(--surface-level-2-bg);
    box-shadow: var(--ui-shadow);
    padding: var(--spacing-16) var(--spacing-12);
    margin-bottom: var(--spacing-8);

    .selection {
        display: flex;
        align-items: center;
        gap: var(--spacing-16);

        &-remove {
            top: var(--spacing-8);
            right: var(--spacing-8);
        }

        &-number {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 2rem;
            min-height: 2rem;
            border-radius: 50%;
            border: var(--odds-border);
            box-shadow: var(--ui-shadow);
            font-weight: var(--font-weight-heavy);
            border: none;
            background: var(--color-primary);
            color: white;
        }

        &-description {
            font-size: var(--font-16);
            font-weight: var(--font-weight-heavy);

            span {
                display: block;
                font-size: var(--font-12);
                color: var(--font-color-secondary);
                font-weight: var(--font-weight-normal);
            }
        }
    }
`;
