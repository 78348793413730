import React from 'react';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    color?: string;
    amount?: number;
    size?: number;
    horizontal?: boolean;
    rotate?: number;
}

export default function UiDottedArrow({ amount, size, horizontal, rotate, ...rest }: Props) {
    const arrows = Array.from(Array(amount).keys());
    return (
        <Wrapper $size={size} $horizontal={horizontal} $rotate={rotate} {...filterStyleProps({ ...rest })}>
            {arrows.map((index) => (
                <Svg key={index} icon="dotted-arrow" />
            ))}
        </Wrapper>
    );
}
