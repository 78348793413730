import React from 'react';
import { useStore } from '../../../../hooks/useStore';
import { isDesktopPlatform, isMobilePlatform, verifyGeoLocation } from '../../../../services/geocomply/geocomply';
import { GEOCOMPLY_REASON, GeoComplyDesktopLibrary, GeoComplyMobileClient } from '../../../../services/geocomply/types';
import { logger } from '../../../../services/logger';
import { getRoute, useRouter } from '../../../../services/router';
import { translate } from '../../../../services/translate';
import { geocomply } from '../../../../stores/geocomply';
import { getStoreValue } from '../../../../stores/store/utils';
import UiBox from '../../../ui/box/UiBox';
import UiButton from '../../../ui/button/UiButton';
import UiGroup from '../../../ui/group/UiGroup';
import GeocomplyMobileHint from '../../mobile/hint/GeocomplyMobileHint';
import GeocomplyFeedbackErrorsClientError from './client-error/GeocomplyFeedbackErrorsClientError';
import GeocomplyFeedbackErrorsServiceError from './service-error/GeocomplyFeedbackErrorsServiceError';
import GeocomplyFeedbackErrorsUnexpectedError from './unexpected-error/GeocomplyFeedbackErrorsUnexpectedError';

interface Props {
    onDismiss: () => void;
}

export default function GeocomplyFeedbackErrors({ onDismiss }: Props) {
    const [geocomplyClient] = useStore(geocomply.client);
    const [serviceError] = useStore(geocomply.serviceError);
    const [unexpectedError] = useStore(geocomply.unexpectedError);

    const isProcessing = geocomplyClient.isAttemptingConnection || geocomplyClient.isAttemptingGeolocation;
    const hasErrors = geocomplyClient.clientError || geocomplyClient.hint || serviceError || unexpectedError;

    const { navigateTo } = useRouter();

    function shouldDisplayRetryButton() {
        const hasRetryableTroubleshootingErrors =
            serviceError?.troubleshooter && serviceError.troubleshooter.filter((message) => message.retry).length > 0;

        if (isMobilePlatform()) {
            const GeoComplyMobileClient = (window as any).CoolbetGeoComplyMobileClient as GeoComplyMobileClient;

            const retryableErrorCodes = [
                GeoComplyMobileClient.ErrorCodes.ERROR_INTERNAL as number,
                GeoComplyMobileClient.ErrorCodes.UNEXPECTED as number,
                GeoComplyMobileClient.ErrorCodes.GEOLOCATION_TIMEOUT as number,
                GeoComplyMobileClient.ErrorCodes.ERROR_UNEXPECTED as number,
            ];

            return (
                hasRetryableTroubleshootingErrors ||
                (geocomplyClient.clientError && retryableErrorCodes.includes(geocomplyClient.clientError.code))
            );
        }

        if (isDesktopPlatform()) {
            const GeoComplyDesktopLibrary = (window as any).GeoComply as GeoComplyDesktopLibrary;

            const retryableErrorCodes = [
                GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE as number,
                GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION as number,
                GeoComplyDesktopLibrary.Client.CLNT_ERROR_UNEXPECTED as number,
            ];

            return (
                hasRetryableTroubleshootingErrors ||
                (geocomplyClient.clientError && retryableErrorCodes.includes(geocomplyClient.clientError.code))
            );
        }

        return hasRetryableTroubleshootingErrors;
    }

    async function retry() {
        try {
            const lastReason = getStoreValue(geocomply.lastReason) as GEOCOMPLY_REASON;
            await verifyGeoLocation(lastReason);
        } catch (error) {
            logger.error('GeocomplyFeedbackErrors', 'retry', error);
        }
    }

    if (isProcessing || !hasErrors) {
        return null;
    }

    function dismiss() {
        onDismiss();
        navigateTo(getRoute('sport.recommendations'));
    }

    return (
        <UiBox>
            <UiGroup vertical>
                {geocomplyClient.clientError && !geocomplyClient.hint && (
                    <GeocomplyFeedbackErrorsClientError clientError={geocomplyClient.clientError} />
                )}

                {geocomplyClient.hint && (
                    <GeocomplyMobileHint hint={geocomplyClient.hint} onDismiss={() => onDismiss()} />
                )}

                {serviceError && <GeocomplyFeedbackErrorsServiceError serviceError={serviceError} />}

                {unexpectedError && <GeocomplyFeedbackErrorsUnexpectedError unexpectedError={unexpectedError} />}

                {hasErrors && (
                    <UiGroup>
                        {shouldDisplayRetryButton() && (
                            <UiButton size="small" onClick={retry}>
                                {translate('Retry', 'ui.common')}
                            </UiButton>
                        )}

                        <UiButton size="small" onClick={() => navigateTo(getRoute('support'))}>
                            {translate('Contact us', 'ui.footer')}
                        </UiButton>

                        <UiButton size="small" onClick={dismiss}>
                            {translate('Dismiss', 'ui.common')}
                        </UiButton>
                    </UiGroup>
                )}
            </UiGroup>
        </UiBox>
    );
}
