import styled from 'styled-components';
import SnippetWithCmsBlocks from '../../snippet-with-cms-blocks/SnippetWithCmsBlocks';

export default styled(SnippetWithCmsBlocks)`
    max-width: 1920px;
    margin: 0 auto;
    padding: var(--spacing-16);

    h1,
    h2 {
        font-size: var(--font-16);
        color: #f7f7f8;
        line-height: 20px;
        margin: var(--spacing-16) 0;
    }

    .line {
        position: relative;
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: var(--spacing-16);
            display: block;
            width: 3px;
            background: var(--color-primary);
            height: 50px;
        }
    }

    .description {
        padding: 0 var(--spacing-24);
        transition: max-height 500ms ease-in-out;
        max-height: none;
        overflow: hidden;

        p {
            font-size: var(--font-14);
            line-height: 18px;
            color: #cccccc;
            margin: var(--spacing-8) 0;
            padding: 0;
        }
    }

    a#read-more {
        font-size: var(--font-14);
        color: #cccccc;
        text-decoration: underline;
    }

    .hidden {
        max-height: 0;
    }
`;
