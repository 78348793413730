import React from 'react';
import Wrapper from './styles';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';

interface Props {
    inputRef: any;
    value: number;
    onChange: (value) => void;
    onClose: () => void;
}

export default function SportBetslipStakeKeyboard({ inputRef, value, onChange, onClose }: Props) {
    const stakes = Array.from({ length: 9 }, (_, k) => k + 1);

    function onBackspace() {
        const currentValue = inputRef.current.value.toString();
        if (value) {
            onChange(Number(currentValue.slice(0, -1)));
        }
    }

    function onStakeChange(inputValue) {
        const currentValue = inputRef.current.value.toString();
        if (currentValue === '0' && inputValue?.toString().match(/[0-9]/)) {
            onChange(inputValue);
        } else {
            onChange(`${currentValue}${inputValue}`);
        }
    }

    function stakeButton(stake) {
        return (
            <UiButton className="normal-btn" key={stake} size="small" onClick={() => onStakeChange(stake)}>
                {stake}
            </UiButton>
        );
    }

    return (
        <Wrapper>
            {stakes.map(stakeButton)}
            <UiButton className="normal-btn backspace-btn" size="small" onClick={onBackspace}>
                <Svg icon="backspace" size={1.1} />
            </UiButton>
            <UiButton className="normal-btn ok-btn" size="small" onClick={onClose}>
                OK
            </UiButton>
            <UiButton className="normal-btn zero-button" size="small" onClick={() => onStakeChange(0)}>
                0
            </UiButton>
            <UiButton className="normal-btn" size="small" onClick={() => onStakeChange('.')}>
                .
            </UiButton>
        </Wrapper>
    );
}
