import QRCode from 'qrcode.react';
import React, { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { getProviderPaymentMethods, pollDeposit } from '../../../../../microservices/payments';
import { getActiveCurrency } from '../../../../../services/currency';
import { useForm } from '../../../../../services/form';
import { useInterval } from '../../../../../services/hooks';
import { logger } from '../../../../../services/logger';
import { cryptoCurrencyByProvider, depositWithProvider, fiatToCrypto } from '../../../../../services/payments/payments';
import { PaymentProvider, PROVIDER_TYPE, PROVIDERS, TransactionState } from '../../../../../services/payments/types';
import { getLastActiveProductRoute, getRoute, useRouter } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import { getFullName } from '../../../../../services/user';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';
import Snippet from '../../../../snippet/Snippet';
import Svg from '../../../../svg/Svg';
import UiButton from '../../../../ui/button/UiButton';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import UiFormInput from '../../../../ui/form/input/UiFormInput';
import UiGroup from '../../../../ui/group/UiGroup';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';

interface Props {
    provider: PROVIDERS;
    deviceHash: string;
    routedProvider?: PaymentProvider;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderPayhound({ deviceHash, onClose, provider, routedProvider }: Props) {
    const { navigateTo } = useRouter();
    const currency = getActiveCurrency();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [conversionRate, setConversionRate] = useState(0);

    const [amount, setAmount] = useState(routedProvider?.minAmount[currency]);

    const [convertedAmount, setConvertedAmount] = useState(0);
    const cryptoCurrency = cryptoCurrencyByProvider[provider];

    const defaultFormValues = {
        holder: getFullName(),
        walletAddress: '',
    };
    const pollStartDate = useMemo(() => {
        return new Date().toISOString();
    }, []);

    const form = useForm(defaultFormValues);

    useEffect(() => {
        init();
    }, []);

    useInterval(poll, 10 * 1000);

    async function init() {
        try {
            const accounts = await getProviderPaymentMethods(provider, PROVIDER_TYPE.DEPOSIT);
            const { walletAddress, exchangeRate } = await depositWithProvider({
                amount: routedProvider?.minAmount[currency],
                deviceHash,
                methodId: accounts[0]?.id,
                provider,
                providerParams: {
                    holder: defaultFormValues.holder,
                },
            });
            if (exchangeRate) {
                setConversionRate(Number(exchangeRate));
                setConvertedAmount(fiatToCrypto(routedProvider?.minAmount[currency], Number(exchangeRate), provider));
            }
            form.setInputValue({ walletAddress: walletAddress });
        } catch (error) {
            logger.error('PaymentDepositProviderPayhound', 'init', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    async function poll() {
        const { walletAddress } = form.getValues();
        if (!walletAddress) {
            return;
        }
        try {
            const state = await pollDeposit(provider, pollStartDate);
            if (state === TransactionState.COMPLETED) {
                navigateTo(
                    `${getLastActiveProductRoute()}?success=${translate('Your deposit was successful.', 'ui.account')}`,
                );
            } else if (state === TransactionState.FAILED) {
                navigateTo(
                    `${getRoute('deposit')}?error=${translate('Your deposit did not go through.', 'ui.account')}`,
                );
            }
        } catch (error: any) {
            if (error.name === 'PollingTooLongError') {
                navigateTo(`${getRoute('deposit')}?error=${translate('Your deposit was cancelled.', 'ui.account')}`);
            }
        }
    }

    function onAmountValueChange(value: number) {
        setAmount(value);
        if (value) {
            const cryptoAmount = fiatToCrypto(value, conversionRate, provider);
            setConvertedAmount(cryptoAmount);
        }
    }

    function getQRCodeValue() {
        return form.walletAddress.value;
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper vertical layoutGutterInRem="1rem">
            <UiGroup layoutGutterInRem="1rem">
                <QRCode size={140} value={getQRCodeValue()} bgColor="#ffffff" />
                <UiGroup vertical verticallyCentered layoutGutterInRem="1rem">
                    <DangerousHtml content={translate('Scan QR Code to', 'ui.payments')} elementType="p" />
                    {!!conversionRate && (
                        <div className="exchange-rate text-bold">
                            {translate(`Current exchange rate`, 'ui.payments', { currency: cryptoCurrency })}
                            <mark>{conversionRate}</mark>
                        </div>
                    )}
                </UiGroup>
            </UiGroup>
            <div>
                <UiFormInput
                    label={translate('Wallet address', 'ui.payments', { currency: cryptoCurrency })}
                    value={form.walletAddress.value}
                    endAdornment={
                        <CopyToClipboard
                            text={form.walletAddress.value}
                            onCopy={() => toast.success(translate('Wallet Address Copied', 'ui.payments'))}
                        >
                            <UiButton color="ghost">
                                <Svg icon="copy" size={1.125} />
                            </UiButton>
                        </CopyToClipboard>
                    }
                />
                <p className="hint-message">
                    {translate('deposit-address-hint', 'ui.payments', { currency: cryptoCurrency })}
                </p>
            </div>
            {!!conversionRate && (
                <UiGroup className="amount-field-wrapper" horizontallyCentered layoutGutterInRem="1rem">
                    <UiFormInput
                        value={amount}
                        label={translate('Enter amount', 'ui.payments')}
                        typeNumeric
                        numberTo={routedProvider?.maxAmount[currency]}
                        onValueChange={onAmountValueChange}
                        endAdornment={<>{currency}</>}
                    />
                    <p className="amount-hint">
                        {translate(`Approximate`, 'ui.payments')}
                        <br />
                        <span className="text-bold">
                            {`${cryptoCurrency}: `}
                            <mark>{convertedAmount}</mark>
                        </span>
                    </p>
                </UiGroup>
            )}
            {provider === PROVIDERS.PAYHOUND_USDT ? (
                <Snippet className="snippet" snippetKey={'ui.payments.moneybite-deposit-exchange-rate'} />
            ) : (
                <Snippet className="snippet" snippetKey={'ui.payments.moneybite-btc-deposit-exchange-rate'} />
            )}
            <p className="progress-message text-bold">{translate('Waiting for deposit', 'ui.payments')}</p>
        </Wrapper>
    );
}
