import React, { useEffect, useState } from 'react';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import { getProviderMethodAmounts, getProviderMethods } from '../../../../../../microservices/payments';
import { PROVIDER_TYPE, PROVIDERS } from '../../../../../../services/payments/types';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import PaymentFormModal from '../../../../form-modal/PaymentFormModal';
import isEqual from 'lodash/isEqual';
import { logger } from '../../../../../../services/logger';

interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
    setAmount: (value: number) => void;
}

export default function PaymentDepositProviderLuqapayCommunityBank({ amount, deviceHash, onClose, setAmount }: Props) {
    const [amounts, setAmounts] = useState<{ activeAmountId: number; amount: number; currency: string }[]>([]);
    const [availableAmountsBySwift, setAvailableAmountsBySwift] = useState<
        Record<string, { activeAmountId: number; amount: number; currency: string }[]>
    >({});
    const [hasPreviouslyAcceptedTerms, setHasPreviouslyAcceptedTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [providerMethods, setProviderMethods] = useState<{ bankName: string; swiftCode: string }[]>([]);

    useEffect(() => {
        getAvailableBanks();
    }, []);

    async function getAvailableBanks() {
        try {
            const { methods, isPaymentProviderTermsAccepted } = await getProviderMethods(
                PROVIDERS.LUQAPAY_COMMUNITY_BANK,
                amount,
                PROVIDER_TYPE.DEPOSIT,
            );
            if (isPaymentProviderTermsAccepted) {
                setHasPreviouslyAcceptedTerms(true);
            }
            if (methods.length > 0) {
                setProviderMethods(methods);
            } else {
                setIsError(true);
            }
        } catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    }

    async function getBankAvailableAmounts(swift) {
        if (!swift) {
            return;
        }
        if (availableAmountsBySwift[swift]) {
            if (!isEqual(availableAmountsBySwift[swift], amounts)) {
                setAmounts(availableAmountsBySwift[swift]);
            }
            return;
        }
        try {
            const { amounts } = (await getProviderMethodAmounts(
                PROVIDERS.LUQAPAY_COMMUNITY_BANK,
                amount,
                swift,
            )) as any;
            if (amounts.length > 0) {
                setAvailableAmountsBySwift({ ...availableAmountsBySwift, [swift]: amounts });
                setAmounts(amounts);
            } else {
                setIsError(true);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderLuqapayCommunityBank', 'getBankAvailableAmounts', error);
        }
    }

    const fields = [
        {
            name: 'swiftCode',
            label: 'Bank Name',
            options: providerMethods.map((option) => {
                return { label: option.bankName, value: option.swiftCode };
            }),
            page: 1,
        },
        {
            name: 'activeAmountId',
            label: 'Amount',
            options: amounts.map((option) => {
                return { label: String(option.amount), value: Number(option.activeAmountId) };
            }),
            page: 2,
        },
    ];

    const extraFields = [
        {
            defaultValue: hasPreviouslyAcceptedTerms,
            name: 'isPaymentProviderTermsAccepted',
            label: 'Accept with sharing bank details.',
            isHidden: hasPreviouslyAcceptedTerms,
        },
    ];

    function onSwiftChange(swift: string) {
        if (!swift) {
            return;
        }
        const providerMethod = providerMethods.find((method) => method.swiftCode === swift);
        getBankAvailableAmounts(providerMethod?.swiftCode);
    }

    function onActiveAmountIdChange(id: number) {
        if (!id) {
            return;
        }
        const option = amounts.find((option) => option.activeAmountId === Number(id));
        if (option) {
            setAmount(Number(option.amount));
        }
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <PaymentFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer="payments.deposit.luqapay-community-bank-deposit-info-text"
            extraFields={extraFields}
            fields={fields}
            isAnonymousHolder
            onClose={onClose}
            onFormChange={{ activeAmountId: onActiveAmountIdChange, swiftCode: onSwiftChange }}
            provider={PROVIDERS.LUQAPAY_COMMUNITY_BANK}
            providerType={PROVIDER_TYPE.DEPOSIT}
        />
    );
}
