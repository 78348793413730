import styled from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

export default styled.div`
    z-index: 1;
    .stake-input {
        position: relative;

        width: 100%;
        margin-top: var(--spacing-16);

        &.input-to-win input {
            background-color: transparent;
            border: var(--betslip-stake-towin-border);
        }

        input {
            margin: 0;
            padding: 0.2rem;
            height: 3.5rem;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            outline: none;

            background-color: transparent;

            border: var(--betslip-stake-border);
            border-radius: var(--ui-radius);

            color: var(--font-color-primary);
            font-size: 1.4rem;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: center;

            @media ${mediaQuery.isPhone} {
                height: 56px;
            }

            &:focus {
                background-color: var(--betslip-stake-bg-focused);
            }
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            &[disabled] {
                opacity: 0.4;
                pointer-events: none;
            }
        }
        &.input-to-win input {
            background-color: transparent;
            border: var(--betslip-stake-towin-border);
        }

        .stake-input-label {
            position: absolute;
            top: -7px;
            left: 50%;
            transform: translate(-50%, 0);
            z-index: 1;
            padding: 0.2rem 0.4rem;
            background: var(--surface-level-2-bg);
            color: var(--betslip-stake-label-color);
            font-size: 0.625rem;
            white-space: nowrap;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            &.label-to-win {
                color: var(--betslip-stake-towin-label-color);
            }
        }
    }

    .stake-input-label {
        line-height: 1;
        color: var(--font-color-secondary);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
    }
    .stake-currency {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: var(--spacing-16);
        font-size: var(--font-16);
    }
    .remove-stake-button {
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;
        padding: var(--spacing-12);

        display: flex;
        align-items: center;
        justify-content: center;

        color: var(--font-color-subtle);
        cursor: pointer;
        svg {
            width: 1.25rem;
        }
    }

    .apply-to-all {
        display: block;
        padding: 0.4rem 0;

        text-transform: uppercase;
        font-size: 0.5rem;
        letter-spacing: 1px;
        text-align: center;
        cursor: pointer;

        span {
            border-bottom: 1px dotted;
        }
    }

    .quick-stake-buttons {
        display: flex;
        align-items: center;
        width: 100%;

        margin: 0.4rem 0;

        > * {
            flex: 1;
            animation: zoomIn 200ms ease-in;
            animation-fill-mode: both;
        }

        > *:not(:last-child) {
            margin-right: 0.1rem;
        }
    }

    .potential-return,
    .total-odds {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.4rem;
        margin: 0;

        font-size: 0.55rem;
        color: var(--betslip-label-general-color);
        text-align: right;
        text-transform: uppercase;
        white-space: nowrap;

        @media ${mediaQuery.isPhone} {
            font-size: 0.55rem;
        }
        .stake-input-value {
            font-size: 1.2rem;
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: right;
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            color: var(--betslip-label-odds-color);
        }
    }
    .stake-input-label {
        line-height: 1;
        color: var(--font-color-secondary);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
    }
    .potential-return .stake-input-value {
        color: var(--betslip-return-label-color);
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering};
        &.animated-value {
            animation: 'flipInX' 500ms linear;
        }
    }
`;
