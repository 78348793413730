import { ObjectValues } from '../../services/ts-utils';

export type UserCrmPreferences = {
    user_id: string;
    subscribeDirectMail: boolean;
    subscribePhoneCalls: boolean;
    subscribeSms: boolean;
    subscribeMailList: boolean;
    created_at?: Date;
    updated_at?: Date;
};

export const ONSITE_MESSAGE_DISPLAY_TYPE = {
    INBOX_MESSAGE: 'INBOX_MESSAGE',
    NOTIFICATION: 'NOTIFICATION',
    MODAL: 'MODAL',
    POP_UP: 'POP_UP',
    // only on FE side
    NEW_MESSAGES: 'NEW_MESSAGES',
} as const;

export type OnsiteMessageDisplayType = ObjectValues<typeof ONSITE_MESSAGE_DISPLAY_TYPE>;

export type OnsiteMessage = {
    id: number;
    userId: string;
    createdAt: string;
    expiresAt: string;
    deletedAt?: string;
    deleteAfterSeen: boolean;
    seenAt?: string | Date;
    type: string;
    mascotMessage: string | null;
    mascotAvatar: string | null;
    title: string;
    image: string;
    description: string;
    ctaTitle: string;
    ctaLink: string;
    designTag?: string;
    status: string;
    displayType: OnsiteMessageDisplayType | null;
    freeSpinBonus?: {
        [gameId: number]: {
            bonusId: string;
            coinValues: {
                [currency: string]: number;
            };
            gameId: number;
            gameImage: string;
            gameName: string;
            spinCount: number;
            bonusCode: string;
        };
    };
    header?: string;
    body?: string;
    footer?: string;
    onsiteMessageTheme?: 'daily-login-reward' | 'level-up' | 'default';
};

export type OnsiteMessageWithCampaign = OnsiteMessage & {
    campaignName?: string;
    campaignId?: number;
};

export const ONSITE_MESSAGE_TAB = {
    ALL: 'ALL',
    NOTIFICATIONS: 'NOTIFICATIONS',
    MESSAGES: 'MESSAGES',
} as const;

export type OnsiteMessageTab = ObjectValues<typeof ONSITE_MESSAGE_TAB>;

export type OnsiteMessagesByTab = Record<
    OnsiteMessageTab,
    { messages: OnsiteMessageWithCampaign[]; unreadMessagesTotal: number }
>;
