import React from 'react';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../services/payments/types';
import { getFieldValidators } from '../../../../../services/validators';
import PaymentFormModal from '../../../form-modal/PaymentFormModal';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderNeteller({ amount, deviceHash, disclaimer, onClose }: Props) {
    const fields = [
        {
            name: 'number',
            label: 'Neteller email address',
            validator: getFieldValidators(['email']),
            type: 'email' as const,
        },
    ];

    return (
        <PaymentFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            fields={fields}
            provider={PROVIDERS.NETELLER}
            providerType={PROVIDER_TYPE.DEPOSIT}
            onClose={onClose}
        />
    );
}
