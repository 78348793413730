import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { ExistingPaymentMethod } from '../../../../services/payments/types';
import PaymentCardFormModalCard from '../card/PaymentCardFormModalCard';

interface Props {
    cards: ExistingPaymentMethod[];
    shouldShowCvcInput: boolean;
    cvcError?: FieldError;
    onSelectCard: (value) => void;
}

export default function PaymentCardFormModalCards({
    cards,
    shouldShowCvcInput,
    cvcError,
    onSelectCard = () => {},
}: Props) {
    const [selectedCard, setSelectedCard] = useState<ExistingPaymentMethod>(cards[0]);

    function selectCard(card: ExistingPaymentMethod) {
        setSelectedCard(card);
        onSelectCard(card);
    }

    return (
        <>
            {cards &&
                cards.map((card) => (
                    <PaymentCardFormModalCard
                        key={card.number}
                        isActive={card.id === selectedCard.id}
                        onClick={() => selectCard(card)}
                        card={card}
                        cvcError={cvcError}
                        shouldShowCvcInput={shouldShowCvcInput}
                    />
                ))}
        </>
    );
}
