import React from 'react';
import Wrapper from './styles';
import { environment } from '../../stores/environment/environment';

import { useStore } from '../../hooks/useStore';

interface Props {
    teamName?: string;
    width?: number;
}

export default function TeamLogo({ teamName, width = 28 }: Props) {
    const [{ IMAGE_BASE_URL }] = useStore(environment);
    const imagePath = `${IMAGE_BASE_URL}sportsbook/team-logos/${teamName}.png?auto=format`;

    if (!teamName) {
        return null;
    }

    return (
        <Wrapper
            $width={width}
            srcSet={`${imagePath}&w=${width}&dpr=1 1x,
          ${imagePath}&w=${width}&dpr=2 2x,
          ${imagePath}&w=${width}&dpr=3 3x,
          ${imagePath}&w=${width}&dpr=4 4x`}
            src={`${imagePath}&w=${width}`}
            width={width || width}
            alt=""
            onError={(event) => (event.currentTarget.style.display = 'none')}
        />
    );
}
