import React, { useEffect, useRef, useState } from 'react';
import { logger } from '../../../../../../services/logger';
import { depositWithProvider } from '../../../../../../services/payments/payments';
import { PROVIDERS } from '../../../../../../services/payments/types';
import Snippet from '../../../../../snippet/Snippet';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderDevcodeEcoPayz({ amount, deviceHash, disclaimer, onClose }: Props) {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [url, setUrl] = useState('');
    const [params, setParams] = useState({});
    const ref = useRef<HTMLFormElement>();

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            ref?.current?.submit();
        }
    }, [isLoading]);

    async function load() {
        try {
            const { url, parameters } = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.DEVCODE_ECOPAYZ,
                providerParams: {},
            });
            setUrl(url);
            setParams(parameters);
            setIsLoading(false);
        } catch (error) {
            logger.error('PaymentDepositProviderDevcodeEcoPayz', 'load', error);
            setIsError(true);
        }
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <>
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {isLoading && <UiDotsLoader />}
            <form
                id="providerRedirectForm"
                ref={ref as any}
                action={url}
                method="post"
                name="provider-redirect-form-window"
            >
                {Object.keys(params).map((param, index) => (
                    <input key={index} type="hidden" name={param} value={params[param]} />
                ))}
            </form>
        </>
    );
}
