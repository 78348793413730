import React, { useEffect, useState } from 'react';
import { getProviderMethods } from '../../../../../../microservices/payments';
import { logger } from '../../../../../../services/logger';
import { Directa24Method } from '../../../../../../services/payments/directa24-methods';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';
import { translate } from '../../../../../../services/translate';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import PaymentFormModal from '../../../../form-modal/PaymentFormModal';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderDirecta24Vouchers({ amount, deviceHash, disclaimer, onClose }: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [voucherProviders, setVoucherProviders] = useState<Directa24Method[]>([]);

    useEffect(() => {
        getAvailableMethods();
    }, []);

    async function getAvailableMethods() {
        try {
            const methods = (await getProviderMethods(
                PROVIDERS.DIRECTA24_VOUCHERS,
                amount,
                PROVIDER_TYPE.DEPOSIT,
            )) as Directa24Method[];
            if (methods.length > 0) {
                setVoucherProviders(methods);
            } else {
                setIsError(true);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderDirecta24Vouchers', 'getAvailableMethods', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    const fields = [
        {
            name: 'service',
            label: translate('Voucher name', 'ui.payments'),
            options: voucherProviders.map((provider) => ({ label: provider.name, value: provider.code })),
        },
    ];

    return (
        <PaymentFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            fields={fields}
            isAnonymousHolder
            onClose={onClose}
            provider={PROVIDERS.DIRECTA24_VOUCHERS}
            providerType={PROVIDER_TYPE.DEPOSIT}
        />
    );
}
