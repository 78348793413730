import React from 'react';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import { translate } from '../../../../../services/translate';
import UiMultiToggle from '../../../../ui/multi-toggle/UiMultiToggle';

import { SportsOddsFormat } from '../../../../../services/types';

interface Props {
    key: number;
}

export default function SportSettingsMenuOptionOddsType({ key }: Props) {
    const ODDS_FORMAT_OPTIONS = [
        {
            text: translate(['oddsformat.decimal', 'Decimal odds'], 'ui.sportsbook'),
            value: SportsOddsFormat.DECIMAL,
        },
        {
            text: translate(['oddsformat.american', 'American odds'], 'ui.sportsbook'),
            value: SportsOddsFormat.AMERICAN,
        },
    ];
    const { oddsFormat, updateUserSettings } = useSportsUserSettings();

    function toggleOddsFormat(value: SportsOddsFormat) {
        updateUserSettings({ odds_format: value });
    }

    return (
        <div className="setting-item item-row" key={key}>
            <UiMultiToggle options={ODDS_FORMAT_OPTIONS} selected={oddsFormat} onChange={toggleOddsFormat} />
        </div>
    );
}
