import React, { useEffect } from 'react';
import { getRoute } from '../../../../services/router';
import { getSlug, loadCampaigns } from '../../../../services/sports/campaigns';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import UiButton from '../../../ui/button/UiButton';
import Svg from '../../../svg/Svg';
import { useStore } from '../../../../hooks/useStore';

export default function SportCampaignsList() {
    const [campaigns] = useStore(stores.sports.campaigns);
    const [language] = useStore(stores.language);
    const [isAuthenticated] = useStore(stores.isAuthenticated);

    useEffect(() => {
        loadCampaigns();
    }, [language, isAuthenticated]);

    return (
        <>
            {campaigns?.list?.map((campaign) => (
                <UiButton
                    type="menu"
                    url={`${getRoute('sport.campaign')}/${getSlug(campaign)}`}
                    key={campaign.id}
                    onClick={() => stores.sports.isSportSideMenuOpen.set(false)}
                    icon={<Svg icon="promotions" />}
                >
                    <span>{translate(`${campaign.id}`, 'ui.campaign.name')}</span>
                </UiButton>
            ))}
        </>
    );
}
