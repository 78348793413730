import styled from 'styled-components';
import { buttonSizes } from '../../../styles/utils';

type StyledProps = {
    $size?: 'small' | 'large' | 'default';
};

export default styled.div<StyledProps>`
    background: var(--button-bg);
    color: var(--button-text-color);
    border: var(--button-border);
    border-radius: 100px;
    display: flex;
    width: 100%;
    > * {
        flex: 1;
    }

    .toggle-option {
        padding: 0 var(--spacing-4);
        display: flex;
        justify-content: center;
        align-items: center;

        height: 2rem;
        border-radius: 100px;

        font-size: 0.75rem;
        white-space: nowrap;
        font-weight: var(--font-weight-medium);
        text-transform: capitalize;
        cursor: pointer;
        outline: none;
        user-select: none;
        border: 2px solid transparent;

        ${({ $size }) => $size && buttonSizes[$size].css};

        &.selected {
            border: var(--button-active-border);
            color: var(--button-active-text-color);
            background: var(--button-active-bg);
        }
    }
`;
