import React, { useEffect, useState } from 'react';
import ScriptLoader from '../../../../../script-loader/ScriptLoader';
import UiButton from '../../../../../ui/button/UiButton';
import UiDotsLoader from '../../../../../ui/dots-loader/UiDotsLoader';
import Snippet from '../../../../../snippet/Snippet';
import UiGroup from '../../../../../ui/group/UiGroup';
import { depositWithProvider, redirectDepositTo } from '../../../../../../services/payments/payments';
import { DepositStatus, PROVIDERS } from '../../../../../../services/payments/types';
import { logger } from '../../../../../../services/logger';
import PaymentProviderNotAvailable from '../../../../provider-not-available/PaymentProviderNotAvailable';
import { translate } from '../../../../../../services/translate';
import { useRouter } from '../../../../../../services/router';
import { media } from '../../../../../../stores/media/media';
import { useStore } from '../../../../../../hooks/useStore';
import Wrapper from './styles';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderNuveiApplePay({ amount, deviceHash, disclaimer, onClose }: Props) {
    const { navigateTo } = useRouter();
    const [{ isPhone }] = useStore(media);
    const [depositInitParams, setDepositInitParams] = useState<Record<string, any>>({});
    const [depositPaymentParams, setDepositPaymentParams] = useState<Record<string, any>>({});
    const [error, setError] = useState('');
    const [isDepositing, setIsDepositing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [sdk, setSdk] = useState<{ createApplePayPayment: (params, callback: (response) => void) => void }>();

    useEffect(() => {
        startDeposit();
    }, []);

    useEffect(() => {
        if (isScriptLoaded && Object.keys(depositInitParams).length) {
            setSdk(SafeCharge(depositInitParams));
        }
    }, [depositInitParams, isScriptLoaded]);

    function deposit() {
        setIsDepositing(true);
        try {
            sdk?.createApplePayPayment(depositPaymentParams, function (res) {
                if (['ERROR', 'DECLINED'].includes(res.result)) {
                    setIsError(true);
                    setError(translate('Your deposit did not go through.', 'ui.account'));
                }
                if (res.result === 'PENDING') {
                    navigateTo(redirectDepositTo(DepositStatus.PENDING));
                }
                if (res.result === 'APPROVED') {
                    navigateTo(redirectDepositTo(DepositStatus.COMPLETED));
                }
            });
        } catch (error) {
            logger.error('PaymentDepositProviderNuveiApplePay', 'deposit', error);
        }
        setIsDepositing(false);
    }

    async function startDeposit() {
        if (!window.ApplePaySession?.canMakePayments()) {
            setError(translate('Apple Pay is not supported on your device', 'ui.payments'));
            setIsError(true);
            return;
        }
        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.NUVEI_APPLE_PAY,
                providerParams: {},
            });
            setDepositPaymentParams(response.payment);
            setDepositInitParams(response.init);
        } catch (error: any) {
            logger.error('PaymentDepositProviderNuveiApplePay', 'startDeposit', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    if (isError) {
        return <PaymentProviderNotAvailable message={error} onBack={onClose} />;
    }

    return (
        <Wrapper>
            <ScriptLoader
                url="https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js"
                onLoad={() => setIsScriptLoaded(true)}
            />
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {(!isScriptLoaded || isLoading) && <UiDotsLoader />}
            {!isLoading && (
                <UiGroup expand>
                    <UiButton
                        className="apple-pay-button apple-pay-button-white"
                        block={isPhone}
                        color="primary"
                        disabled={isDepositing}
                        isLoading={isDepositing}
                        onClick={deposit}
                    />
                </UiGroup>
            )}
        </Wrapper>
    );
}

declare let SafeCharge: any;
