import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../../styles/utils';

type StyledProps = {
    $active?: boolean;
    $isShowingLogo?: boolean;
};

const active = (props: StyledProps) =>
    props.$active &&
    css`
        opacity: 1;
        border-color: var(--color-highlight);
    `;

export default styled.div<StyledProps>`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 0;
    overflow: hidden;

    min-width: ${(props) => (props.$active ? '132px' : '120px')};

    border: 2px solid transparent;

    opacity: 0.8;
    transition: 200ms opacity ease-out;

    cursor: pointer;
    outline: none;
    @media ${mediaQuery.isPhone} {
        min-width: ${(props) => (props.$active ? '92px' : '80px')};
    }

    &:hover {
        opacity: 1;
    }
    .method-container {
        padding-top: var(--spacing-8);
        padding-right: var(--spacing-8);
        padding-left: var(--spacing-8);
        padding-bottom: ${(props) => !props.$isShowingLogo && 'var(--spacing-8)'};
        border: 2px solid transparent;
        border-radius: var(--ui-radius);
        box-shadow: var(--ui-shadow);
        text-align: center;
        ${active}

        @media ${mediaQuery.isPhone} {
            padding-top: var(--spacing-4);
            padding-right: var(--spacing-4);
            padding-left: var(--spacing-4);
            padding-bottom: ${(props) => !props.$isShowingLogo && 'var(--spacing-4)'};
        }

        .title {
            font-weight: 500;
            text-align: center;
            padding-top: 0.5rem;
            font-size: 0.7rem;

            @media ${mediaQuery.isPhone} {
                font-size: 0.6rem;
            }
        }

        .logo {
            max-width: 50%;
        }
    }
    .method-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: var(--surface-level-1-border);
        border-radius: var(--ui-radius);
        background: #fff;
        padding: var(--spacing-8);
        min-height: 80px;
        max-height: 80px;
        min-width: 120px;
        max-width: 120px;
        @media ${mediaQuery.isPhone} {
            min-height: 50px;
            max-height: 50px;
            min-width: 80px;
            max-width: 80px;
        }
    }
    .bank-icon {
        max-width: 80%;
        padding: var(--spacing-8);
    }
`;
