import styled from 'styled-components';

export default styled.div`
    .buttons {
        padding-bottom: var(--spacing-16);
    }

    .icon-alignment {
        margin-left: auto;
    }

    .methods label {
        padding-bottom: var(--spacing-12);
    }
`;
