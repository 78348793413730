import styled, { css } from 'styled-components';

type StyledProps = {
    $type?: string;
};

export default styled.div<StyledProps>`
    ${({ $type }) =>
        $type === 'menu' &&
        css`
            .hot-events-list {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.8rem;
                > * {
                    padding: var(--spacing-8) var(--spacing-12);
                }

                > .placeholder {
                    visibility: hidden;
                }
            }
        `};

    .tabs-list {
        display: flex;
        align-items: center;
        overflow: auto;
        padding-bottom: var(--spacing-4);
        margin-bottom: var(--spacing-8);

        &.wrap {
            flex-wrap: wrap;
            > * {
                margin-bottom: 0.4rem;
            }
        }
        > * {
            margin-right: 0.4rem;
        }
    }
`;
