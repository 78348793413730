import React, { Dispatch, SetStateAction, useState } from 'react';
import Wrapper from './styles';
import Slider from 'react-slick/lib';
import PaymentCard from '../card/PaymentCard';
import { Card } from '../../../services/payments/payments';

interface Props {
    cards: Card[];
    onSelectCard: Dispatch<SetStateAction<Card | undefined>>;
}

export default function PaymentCardsSlider({ cards, onSelectCard = () => {} }: Props) {
    const [selectedCard, setSelectedCard] = useState(cards[0]);

    const sliderSettings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 2,
        variableWidth: true,
    };

    function selectCard(card) {
        setSelectedCard(card);
        onSelectCard(card);
    }

    // Payments2 provides number. Other fields are for backwards compatibility.
    return (
        <Wrapper>
            <Slider {...sliderSettings}>
                {cards &&
                    cards.map((card) => (
                        <PaymentCard
                            key={card.number || card.card_number || card.masked_pan}
                            isActive={card === selectedCard}
                            onClick={() => selectCard(card)}
                            card={card}
                        />
                    ))}
            </Slider>
        </Wrapper>
    );
}
