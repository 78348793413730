import React from 'react';
import { useRouter } from '../../../../../../services/router';
import { getDepositReturnRoute } from '../../../../../../services/payments/payments';
import { DepositStatus, PROVIDERS, PROVIDER_TYPE } from '../../../../../../services/payments/types';
import PaymentCardFormModal from '../../../../card-form-modal/PaymentCardFormModal';

interface Props {
    amount: number;
    disclaimer?: string;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderAuthipayAibmsCard({ amount, deviceHash, disclaimer, onClose }: Props) {
    const { navigateTo } = useRouter();

    function onDeposit({ parameters, url }) {
        if (!url) {
            navigateTo(getDepositReturnRoute(DepositStatus.COMPLETED));
            return;
        }
        const form = document.createElement('form');
        form.method = 'post';
        form.action = url;

        for (const key in parameters) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = parameters[key];
            form.appendChild(hiddenField);
        }

        document.body.appendChild(form);
        form.submit();
    }

    return (
        <PaymentCardFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            isAllowedToGoOverMaxCardsLimit={true}
            onDeposit={onDeposit}
            onClose={onClose}
            isIframe={true}
            provider={PROVIDERS.AUTHIPAY_AIBMS_CARD}
            providerType={PROVIDER_TYPE.DEPOSIT}
            isCvcRequiredForSavedCard={false}
        />
    );
}
