import { EventEmitter } from 'events';
interface GeocomplyGeopacketTroubleshooterMessage {
    help: string;
    message: string;
    optin: string;
    retry: boolean;
    rule: string;
}

export type GeocomplyGeopacketErrors = Array<GeocomplyGeopacketTroubleshooterMessage> | null;

export type GeocomplyServiceError = {
    code: number;
    name: string;
    troubleshooter: GeocomplyGeopacketErrors;
} | null;

export interface GeocomplyClientError {
    code: number;
    message: string;
}

export interface GeocomplyLicense {
    license: string;
    expiresAt: string;
}

export interface GeocomplyGeoverificationStatus {
    deviceUuid: string;
    isGeoVerified: boolean;
    expiresAt: string | null;
    retryAt: string | null;
    ipAddress: string | null;
}

export interface GeocomplyGeoverificationStatusStore {
    deviceUuid: string | null;
    isGeoVerified: boolean | null;
    expiresAt: string | null;
    retryAt: string | null;
    ipAddress: string | null;
}

export type GeocomplyGeoverification = GeocomplyGeoverificationStatusStore | GeocomplyGeoverificationStatus;

export type GEOCOMPLY_ENVIRONMENT = 'production' | 'staging';
export type GEOCOMPLY_REASON = 'TEST' | 'BET' | 'CASINO' | 'DEPOSIT' | 'WITHDRAW' | 'RENEW' | 'IP_CHANGE' | 'LOGIN';

export enum GEOCOMPLY_SERVICE_ERROR {
    GEOPACKET_IP_ADDRESS_MISMATCH_ERROR = 3006,
}

export type LOG_LEVEL = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR' | 'DEBUG';

export interface GeocomplyLogMessage {
    time: string;
    message: string;
    level: LOG_LEVEL;
}

export type GeoComplyMobileAppNotOpenedHint = GeoComplyMobileClient['HINTS']['APP_NOT_OPENED'];
export type GeoComplyMobileTrueLocationBrowserHint = GeoComplyMobileClient['HINTS']['TRUE_LOCATION_BROWSER'];
export type GeoComplyMobileAppRequiredHint = GeoComplyMobileClient['HINTS']['APP_REQUIRED'];
export type GeoComplyMobileAppRequiredLocationPermissionHint =
    GeoComplyMobileClient['HINTS']['APP_REQUIRED_LOCATION_PERMISSION'];
export type GeoComplyMobileAppRequiredNotificationPermissionHint =
    GeoComplyMobileClient['HINTS']['APP_REQUIRED_NOTIFICATION_PERMISSION'];

export type GeoComplyMobileHint =
    | GeoComplyMobileAppNotOpenedHint
    | GeoComplyMobileTrueLocationBrowserHint
    | GeoComplyMobileAppRequiredHint
    | GeoComplyMobileAppRequiredLocationPermissionHint
    | GeoComplyMobileAppRequiredNotificationPermissionHint;

export interface GeoComplyMobileClient {
    configure: (options?: GeocomplyMobileSettings) => void; // Not specified in OobeeJS documentation pages 33 and 34
    request: (options?: RequestOptions) => void;
    getVersion: () => string;
    connect: (options: ConnectOptions, successCallback: () => void, errorCallback: () => void) => void;
    getLastRegisterDate: () => string;
    getLastGeolocateDate: () => string;
    hasRegisteredRecently: () => boolean;
    hasGeolocatedRecently: () => boolean;
    ping: (successCallback: () => void, errorCallback: () => void) => void;
    isTrueLocationBrowser: () => boolean;
    stopUpdating: () => void; // To stop the indoor geolocation flow immediately. The event geolocation.stop_updating will be triggered right after that.
    enablePlayStorePopUp: boolean;
    setServiceUrl: (serviceUrl: GeocomplyMobileSettings['serviceUrl']) => void;
    setOobeeUrl: (oobeeUrl: GeocomplyMobileSettings['oobeeUrl']) => void;
    setEnablePlayStorePopUp: (enablePlayStorePopUp: GeocomplyMobileSettings['enablePlayStorePopUp']) => void;
    setGeoTimeout: (geoTimeout: GeocomplyMobileSettings['geoTimeout']) => void;
    setEnableAndroidSimplified: (enableAndroidSimplified: GeocomplyMobileSettings['enableAndroidSimplified']) => void;
    setLicense: (license: GeocomplyMobileSettings['license']) => void;
    setUserId: (userId: GeocomplyMobileSettings['userId']) => void;
    setReason: (reason: GeocomplyMobileSettings['reason']) => void;
    setSession: (session: GeocomplyMobileSettings['session']) => void;
    setCustomFields: (customFields: GeocomplyMobileSettings['customFields']) => void;
    setCustomURL: (customUrl: GeocomplyMobileSettings['customUrl']) => void;
    setAndroidAppName: (androidAppName: GeocomplyMobileSettings['androidAppName']) => void;
    setAndroidAppBundleId: (androidAppBundleId: GeocomplyMobileSettings['androidAppBundleId']) => void;
    setAndroidHttpServerPorts: (androidHttpServerPorts: GeocomplyMobileSettings['androidHttpServerPorts']) => void;
    setIOSAppName: (iOSAppName: GeocomplyMobileSettings['iOSAppName']) => void;
    setIOSAppBundleId: (iOSAppBundleId: GeocomplyMobileSettings['iOSAppBundleId']) => void;
    setIOSHttpServerPorts: (iOSHttpServerPorts: GeocomplyMobileSettings['iOSHttpServerPorts']) => void;
    setAppName: (appName: GeocomplyMobileSettings['appName']) => void;
    setAppBundleId: (appBundleId: GeocomplyMobileSettings['appBundleId']) => void;
    setHttpServerPorts: (httpServerPorts: GeocomplyMobileSettings['httpServerPorts']) => void;
    setBranchIOCallBackURL(branchIOCallBackURL: GeocomplyMobileSettings['branchIOCallBackURL'], callback: () => void);
    setUseTrueLocationBrowser: (useTrueLocationBrowser: GeocomplyMobileSettings['useTrueLocationBrowser']) => void;
    setTrueLocationBrowserUseGIF: (
        trueLocationBrowserUseGIF: GeocomplyMobileSettings['trueLocationBrowserUseGIF'],
    ) => void;
    setTrueLocationBrowserReturn611: (
        trueLocationBrowserReturn611: GeocomplyMobileSettings['trueLocationBrowserReturn611'],
    ) => void;
    setSdkVersion: (sdkVersion: GeocomplyMobileSettings['sdkVersion']) => void;
    ErrorCodes: {
        AJAX: 1001;
        APN_MESSAGE_ERROR: 57;
        APN_SERVER_ERROR: 56;
        APP_NOT_INSTALLED: 103;
        APP_REQUIRED: 61;
        CANNOT_CONNECT_ANDROID_TLB_SERVICE: 700;
        CANNOT_FIND_CALLBACK_URL: 312;
        CLIENT_AKEY_OCCUPIED: 36;
        CLIENT_LOCKED: 38;
        CLIENT_NOT_EXISTS: 35;
        CLIENT_SUSPENDED: 102;
        CLIENT_SUSPENDED_SERVER: 604;
        CLIENT_UNAUTHORIZED: 101;
        CLIENT_USER_DUPLICATED: 239;
        DEVICE_ACCESS_DENIED: 9;
        DEVICE_ALREADY_CANCELED: 132;
        DEVICE_ALREADY_REGISTERED: 212;
        DEVICE_ALREADY_UNPAIRED: 17;
        DEVICE_CALLBACK_NOT_FOUND: 617;
        DEVICE_DUPLICATED: 133;
        DEVICE_NOT_EXISTS: 14;
        DEVICE_NOT_INITIALIZED: 131;
        DEVICE_NOT_PAIRED: 13;
        DEVICE_PROXIMITY_NOT_REQUIRED: 16;
        DEVICE_PROXIMITY_REQUIRED: 15;
        DEVICE_UID_NOT_EXIST: 214;
        DISABLED_SOLUTION: 605;
        DUPLICATE_APN: 305;
        ERROR_INTERNAL: 99;
        ERROR_NETWORK_CONNECTION: 602;
        ERROR_UNEXPECTED: 600;
        ES_INVALID_REQUEST_DATA: 291;
        GEOLOCATION_IN_PROGRESS: 614;
        GEOLOCATION_TIMEOUT: 301;
        GOOGLE_PLAY_SERVICE_NOT_FOUND: 616;
        INVALID_ALGORITHM: 125;
        INVALID_ARGUMENT_ERROR: 303;
        INVALID_BRANCH_IO_CALLBACK_URL: 641;
        INVALID_CALLBACK: 123;
        INVALID_CUSTOM_FIELDS: 609;
        INVALID_DEVICE_UID: 120;
        INVALID_HMAC: 640;
        INVALID_LICENSE: 306;
        INVALID_PHONE_NUMBER: 121;
        INVALID_REQUEST_DATA: 91;
        INVALID_REQUEST_FORMAT: 90;
        INVALID_REQUEST_SOURCE: 92;
        INVALID_RESPONSE_DATA: 172;
        INVALID_RETURN_FROM_SERVER: 311;
        INVALID_SDK_VERSION: 93;
        INVALID_TRANSACTION: 124;
        INVALID_USERNAME: 122;
        INVALID_USER_INPUT: 635;
        LICENSE_EXPIRED: 106;
        LICENSE_EXPIRED_SERVER: 608;
        LICENSE_FORMAT: 107;
        LICENSE_FORMAT_SERVER: 606;
        LICENSE_REQUIRED: 108;
        LICENSE_UNAUTHORIZED: 105;
        LICENSE_UNAUTHORIZED_SERVER: 607;
        MERCHANT_ID_NOT_FOUND: 308;
        NOTIFICATION_NOT_ALLOWED: 322;
        NOTIFICATION_SERVICE_DISABLED: 60;
        NO_NETWORK: 173;
        NULL_ARGUMENT_ERROR: 302;
        OK: 0;
        PAYLOAD_APN: 143;
        PAYLOAD_GCM: 142;
        PAYLOAD_INVALID_ID: 21;
        PAYLOAD_PROXIMITY_EXPIRED: 140;
        PAYLOAD_RESPONSE: 141;
        PERMISSION_NOT_GRANTED: 615;
        PRECISE_LOCATION_OFF: 637;
        PROXIMITY_IS_NOT_REQUIRED: 309;
        PROXIMITY_NOT_REQUIRED: 216;
        PROXIMITY_TIMEOUT: 310;
        REGISTER_SMS: 150;
        REQUEST_CANCELED: 611;
        SERVER_COMMUNICATION: 603;
        SERVICE_LOCKED: 112;
        SERVICE_NOT_CONFIGURED: 110;
        SERVICE_NO_RESPONSE: 113;
        SERVICE_UNAUTHORIZED: 111;
        SOCKET: 1002;
        SOCKET_URL_ERROR: 307;
        TOKEN_EXPIRED: 219;
        TOKEN_INVALID: 220;
        TOKEN_VERIFICATION_FAILED: 218;
        TRUE_LOCATION_BROWSER_REQUIRED: 134;
        UNEXPECTED: 100;
        USERID_REQUIRED: 126;
        WRONG_APN_FORMAT: 304;
        WRONG_REQUEST_URL: 171;
    };
    events: EventEmitter;
    EVENTS: {
        '**': '**';
        BEFORE: 'before';
        LOG: 'log';
        HINT: 'hint';
        // ABORT: 'abort'; // Not found in actual object although specified in documentation
        REVISE_FAILED: 'revise.failed';
        INIT_FAILED: 'init.failed';
        INIT_SUCCESS: 'init.success';
        REGISTER_BEFORE: 'register.before';
        REGISTER_FAILED: 'register.failed';
        REGISTER_SUCCESS: 'register.success';
        GEOLOCATION_BEFORE: 'geolocation.before';
        GEOLOCATION_PENDING: 'geolocation.pending';
        GEOLOCATION_FAILED: 'geolocation.failed';
        GEOLOCATION_SUCCESS: 'geolocation.success';
        GIF_STOP_UPDATING: 'geolocation.stop_updating';
        GIF_BLUETOOTH_OFF: 'geolocation.bluetooth_off';
        USERDEVICE_BEFORE: 'userDevice.before';
        USERDEVICE_FAILED: 'userDevice.failed';
        USERDEVICE_REGISTERED: 'userDevice.registered';
        USERDEVICE_UNREGISTERED: 'userDevice.unregistered';
        DEREGISTER_BEFORE: 'deregister.before';
        DEREGISTER_FAILED: 'deregister.failed';
        DEREGISTER_SUCCESS: 'deregister.success';
        CONNECT_BEFORE: 'connect.before';
        CONNECT_FAILED: 'connect.failed';
        CONNECT_SUCCESS: 'connect.success';
    };
    HINTS: {
        // If the JS SDK can not connect to the application and it requires users to install or to open the app. Or if the operator web app tries to call connect() without the user action and userAction is set by “false”.
        // If user does not allow the app to access Location Precise Permission, the hint event below will be returned along with the error 637
        // If user does not allow the app to access Notification Permission, the hint event below will be returned along with the error 322
        APP_NOT_OPENED: {
            reason: 1;
            message: string;
            retry: boolean;
            iosWakeupUrl: string;
            androidWakeupUrl?: string;
            androidWakeupIntent?: string;
        };
        // If “useTrueLocationBrowser” is “true” and users are using another browser instead of TrueLocation Browser.
        TRUE_LOCATION_BROWSER: { reason: 2; message: string; link: string };
        // If the app was installed but never opened. Android App is required to be opened at least
        // once to initialize before geolocating.
        APP_REQUIRED: {
            reason: 3;
            message: string;
            retry: boolean;
            wakeupAppIntentURI: string;
        };
        // Hint user to open the Android App and grant location permission to the app when JS SDK receives the error 615 from the app.
        APP_REQUIRED_LOCATION_PERMISSION: { reason: 4; message: string; wakeupUrl: string };
        // Hint user to open the Notification Settings and turn on the settings for the Android App when JS SDK receives the error 60 from the Android App.
        APP_REQUIRED_NOTIFICATION_PERMISSION: {
            reason: 6;
            message: string;
            androidNotificationSettingUrl: string;
            androidNotificationSettingsIntentUri: string;
        };
    };
}

interface RequestOptions {
    connect?: ConnectOptions;
    geoTimeout?: GeocomplyMobileSettings['geoTimeout'];
}

interface ConnectOptions {
    timeout: number;
    userAction: true; // Don't know in which cases it should be false. Forcing true everywhere.
}

export interface GeoComplyMobileLibrary {
    createClient: (options?: GeocomplyMobileSettings) => GeoComplyMobileClient;
    utils: {
        browser: {
            is: {
                android: boolean;
                ios: boolean;
            };
        };
    };
}

export interface GeoComplyDesktopLibrary {
    createClient: () => GeoComplyDesktopLibrary['Client']; // The use of this mehtod is deprecated
    Client: {
        CLNT_OK: 0; // No errors
        CLNT_ERROR_UNEXPECTED: 600; // Unexpected error
        CLNT_ERROR_NOT_CERTIFIED_BINARIES: 601; // Core libraries are not certified
        CLNT_ERROR_NETWORK_CONNECTION: 602; // Network connection error
        CLNT_ERROR_SERVER_COMMUNICATION: 603; //  Server communication error
        CLNT_ERROR_CLIENT_SUSPENDED: 604; // Client is suspended
        CLNT_ERROR_DISABLED_SOLUTION: 605; // Solution is disabled
        CLNT_ERROR_INVALID_LICENSE_FORMAT: 606; // Invalid license format
        CLNT_ERROR_CLIENT_LICENSE_UNAUTHORIZED: 607; // Client's license is unauthorized
        CLNT_ERROR_LICENSE_EXPIRED: 608; // Client's license is expired
        CLNT_ERROR_INVALID_CUSTOM_FIELDS: 609; // Invalid custom fields
        CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE: 612; // Local service is unavailable
        CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION: 613; // Local service communication error
        CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS: 614; // Geolocation request in progress
        CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER: 615; // Installed version of PLC is not supported
        CLNT_ERROR_TRANSACTION_TIMEOUT: 620; // The create transaction procedure took more time than it was configured
        CLNT_ERROR_WRONG_OR_MISSING_PARAMETER: 699; //  Wrong or missing required Client.connect() parameter

        connect: (installerID: string, environment: GEOCOMPLY_ENVIRONMENT) => void;
        customFields: {
            set: (fieldName: string, fieldValue: string | number) => unknown;
            get: () => unknown;
            remove: () => unknown;
            clear: () => unknown;
        };
        detectInstall: () => unknown;
        disconnect: () => void;
        getConnectionMethod: () => string;
        getFallbackVersion: () => string;
        getGeolocationReason: () => string;
        getJSLibraryVersion: () => string;
        getLastErrorCode: () => string;
        getLicense: () => string;
        getUserId: () => string;
        getUserPhoneNumber: () => string;
        getVersion: () => string;
        isConnected: () => boolean;
        off: (eventName: string) => void;
        on: (eventName: string, eventHandler: (...args: any[]) => void) => void;
        requestGeolocation: () => void;
        setGeolocationReason: (reason: string) => GeoComplyDesktopLibrary['Client'];
        setLicense: (license: string) => GeoComplyDesktopLibrary['Client'];
        setUserId: (userId: string) => GeoComplyDesktopLibrary['Client'];
        setUserPhoneNumber: (phoneNumber: string) => GeoComplyDesktopLibrary['Client'];
    };
    Events: {
        add: (a, b, c) => void;
        ready: (a, b) => void;
        remove: (a, b, c) => void;
        trigger: (a, b) => void;
    };
    events: {
        add: (a, b, c) => void;
        ready: (a, b) => void;
        remove: (a, b, c) => void;
        trigger: (a, b) => void;
    };
}

export interface GeocomplyDesktopSettings {
    license: string | null;
    userId: string | null;
    reason: GEOCOMPLY_REASON;
    customFields: {
        sessionKey: string | null;
    };
    envId: GEOCOMPLY_ENVIRONMENT;
    installerID: string;
}

export interface GeocomplyMobileSettings {
    serviceUrl: string;
    oobeeUrl: string;
    enablePlayStorePopUp?: boolean; // If true and app not installed in addition to CLNT_ERROR_APP_NOT_INSTALLED event it will also open PlayStore page for the app
    geoTimeout?: number;
    enableAndroidSimplified?: boolean;
    license: string;
    userId: string;
    reason: GEOCOMPLY_REASON;
    session?: string;
    customFields: Record<string, string | number>; // Optional
    customUrl?: string;
    androidAppName?: string;
    androidAppBundleId?: string;
    androidHttpServerPorts?: Array<number>;
    iOSAppName?: string;
    iOSAppBundleId?: string;
    iOSHttpServerPorts?: Array<number>;
    appName?: string;
    appBundleId?: string;
    httpServerPorts?: Array<number>;
    branchIOCallBackURL?: string;
    useTrueLocationBrowser?: boolean;
    trueLocationBrowserUseGIF?: boolean;
    trueLocationBrowserReturn611?: boolean;
    sdkVersion?: string;
}
