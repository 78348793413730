import React from 'react';
import Wrapper from './styles';
import SportMenuV2Drawer from '../drawer/SportMenuV2Drawer';

interface Props extends React.PropsWithChildren {
    isMenuVisible: boolean;
    onDrawerClick: (value: boolean) => void;
}

export default function SportMenuV2Label({ children, isMenuVisible, onDrawerClick }: Props) {
    return (
        <Wrapper>
            {children}
            <SportMenuV2Drawer isOpen={isMenuVisible} onDrawerClick={onDrawerClick} />
        </Wrapper>
    );
}
