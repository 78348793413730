import { stringify } from 'querystring';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { Currency } from './wallet/types';

const SUPPORTED_CURRENCIES = [Currency.CAD, Currency.EUR, Currency.USD];

export function quickSignup(trackingCode?: string) {
    const user = getStoreValue(stores.user);
    const wallet = getStoreValue(stores.wallet);
    if (!user || !wallet) {
        return;
    }
    const currency = SUPPORTED_CURRENCIES.includes(wallet.currency) ? wallet?.currency : Currency.EUR;
    const url = `https://a.api.muchbetter.com/merchant/user?${stringify({
        trackingCode,
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phonenumber: user.phonePrefix + user.phoneNumber,
        zipcode: user.zip,
        city: user.city,
        address1: user.address,
        country: user.country,
        dobYYYYMMDD: user.birthDate.replace(/-/g, ''),
        currency,
    })}`;
    window.open(url, '_blank');
}
