import React from 'react';
import Wrapper from './styles';
import SportCategoryIcon from '../../../../sport/category-icon/SportCategoryIcon';
import { FoComboCardMatch } from '@staycool/sports-types/fo-combo-card';
import { DatabaseComboCardType } from '@staycool/sports-types';
import DangerousHtml from '../../../../dangerous-html/DangerousHtml';

interface Props {
    matches: FoComboCardMatch[];
    type: DatabaseComboCardType;
}

export default function ComboCardsItemDetailsMultiSport({ matches, type }: Props) {
    return (
        <Wrapper>
            {matches.map(({ markets, name, sport_icon }) => (
                <div key={name}>
                    <div className="match-name">
                        <SportCategoryIcon color={type.bg_color} icon={sport_icon} size={0.75} />
                        <span>{name}</span>
                    </div>

                    <div className="dot-indicator">
                        {markets.map(({ id, outcome_name }) => (
                            <DangerousHtml key={id} content={outcome_name} />
                        ))}
                    </div>
                </div>
            ))}
        </Wrapper>
    );
}
