import React from 'react';
import { translate } from '../../../../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import { placeBet } from '../../../../../services/parlay-card/betslip';
import UiLinearProgress from '../../../../ui/linear-progress/UiLinearProgress';
import { stores } from '../../../../../stores';
import SportBetslipButtonAndErrorsConfirm from '../../../betslip/button-and-errors/confirm/SportBetslipButtonAndErrorsConfirm';
import { getActiveCurrency } from '../../../../../services/currency';
import {
    isMAErrorForParlayCard,
    isMAStakeAllowedForParlayCard,
} from '../../../../../services/sports/manual-acceptance-helpers';
import Wrapper from './styles';
import { isBYOD } from '../../../../../services/environment';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    isDisabled: boolean;
    clearSelection: () => void;
}

function SportParlayCardBetslipButtons({ isDisabled, clearSelection }: Props) {
    const [stake] = useStore(stores.sports.parlayCard.stake);
    const [manualAcceptanceStake] = useStore(stores.sports.parlayCard.manualAcceptanceStake);
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.parlayCard.betSlipPlacingState);
    const { isLoading, needsConfirm, needsConfirmDuplicate } = betSlipPlacingState;
    const totalStake = stake - manualAcceptanceStake;
    const normalStakeAllowed = totalStake > 0 && isMAErrorForParlayCard();
    const shouldDisplayNormalPlaceBetButton = Boolean(!isMAErrorForParlayCard() || normalStakeAllowed);

    if (isBYOD()) {
        return null;
    }

    if (needsConfirm) {
        return (
            <>
                {needsConfirmDuplicate && isLoading && (
                    <UiLinearProgress
                        className="progress"
                        fakeDuration={8500}
                        showAfterPercentage={20}
                        showPercentage
                    />
                )}
                <SportBetslipButtonAndErrorsConfirm
                    onPlaceBet={(isManualAcceptance, allowErrorForMarket, isForceDuplicate) =>
                        placeBet({ isManualAcceptance, allowErrorForMarket, isForceDuplicate })
                    }
                    isForceDuplicate={needsConfirmDuplicate}
                    clearSelection={clearSelection}
                    betSlipPlacingState={betSlipPlacingState}
                    setBetSlipPlacingState={setBetSlipPlacingState}
                />
            </>
        );
    }
    return (
        <Wrapper>
            {isMAStakeAllowedForParlayCard() && (
                <UiButton
                    color="primary"
                    block
                    size="large"
                    isLoading={isLoading}
                    disabled={isDisabled || manualAcceptanceStake <= 0}
                    onClick={() => placeBet({ isManualAcceptance: true })}
                >
                    <div>{translate('Manual Acceptance', 'ui.betslip')}</div>
                    <small>
                        {normalStakeAllowed
                            ? translate('Place the bet of %1 and apply for the remaining %2.', 'ui.sportsbook', [
                                  `${totalStake} ${getActiveCurrency()}`,
                                  `${manualAcceptanceStake} ${getActiveCurrency()}`,
                              ])
                            : translate('Request to place a stake of %1.', 'ui.sportsbook', [
                                  `${manualAcceptanceStake} ${getActiveCurrency()}`,
                              ])}
                    </small>
                </UiButton>
            )}

            {normalStakeAllowed && <div className="or">{translate('OR', 'ui.betslip')}</div>}

            {shouldDisplayNormalPlaceBetButton && (
                <UiButton
                    color="primary"
                    size="large"
                    block
                    isLoading={isLoading}
                    disabled={isDisabled}
                    onClick={() =>
                        placeBet({
                            isManualAcceptance: false,
                            allowErrorForMarket: normalStakeAllowed && isMAStakeAllowedForParlayCard(),
                        })
                    }
                >
                    {translate(`Place bet`, 'ui.sportsbook')}
                    {normalStakeAllowed && (
                        <span>
                            <br />
                            <small>
                                {translate('Place the bet of %1 and disregard the remaining amount.', 'ui.sportsbook', [
                                    `${totalStake} ${getActiveCurrency()}`,
                                ])}
                            </small>
                        </span>
                    )}
                </UiButton>
            )}
        </Wrapper>
    );
}

export default React.memo(SportParlayCardBetslipButtons);
