import { CategoryMatch } from '../../../../../services/sports/types';
import { useMatchStatusUpdate } from '../../../../../services/sports/hooks';
import { MATCH_STATUS } from '../../../../../services/sports/constants';
import React from 'react';

interface Props {
    pitcher: string | null;
    match: CategoryMatch;
}

export default function SportMatchNamePitcher({ match, pitcher }: Props) {
    const matchStatus = useMatchStatusUpdate(match);
    const isLiveNow = matchStatus === MATCH_STATUS.LIVE;

    if (isLiveNow || !pitcher) {
        return null;
    }
    return <span className="pitcher-name">{pitcher}</span>;
}
