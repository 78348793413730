import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .selection-btn {
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        background: var(--surface-level-3-bg);
        border-width: 1px;
        color: #f1f1f1;
        height: 2rem;
        &:hover {
            border-color: #72e7e9;
            background: var(--odds-bg-hover);
        }
        &.active {
            background-color: var(--odds-bg-active);
            .boosted-odds,
            .original-odds {
                color: var(--odds-text-active);
            }
            .original-odds {
                opacity: 0.6;
            }
        }
    }

    .boosted-odds {
        color: #72e7e9;
    }
    .original-odds {
        color: var(--font-color-secondary);
        text-decoration: line-through;
    }
`;
