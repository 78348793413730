import React, { useEffect, useState } from 'react';
import { PROVIDERS } from '../../../../../services/payments/types';
import Snippet from '../../../../snippet/Snippet';
import Wrapper from './styles';
import UiButton from '../../../../ui/button/UiButton';
import { logger } from '../../../../../services/logger';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import UiGroup from '../../../../ui/group/UiGroup';
import { translate } from '../../../../../services/translate';
import { depositWithProvider } from '../../../../../services/payments/payments';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderBlixtpay({ amount, deviceHash, disclaimer, onClose }: Props) {
    const [{ isPhone }] = useStore(media);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [qrCode, setQrCode] = useState('');
    const [webUrl, setWebUrl] = useState('');
    const [appUrl, setAppUrl] = useState('');

    useEffect(() => {
        deposit();
    }, []);

    async function deposit() {
        try {
            const response = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.BLIXTPAY,
                providerParams: {},
            });
            if (typeof response === 'string') {
                setQrCode(response);
            } else {
                setWebUrl(response.url);
                setAppUrl(response.mobile_app_url);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderBlixtpay', 'deposit', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    function pay(type: 'app' | 'web') {
        const url = type === 'app' ? appUrl : webUrl;
        window.location.href = url;
    }

    if (isLoading) {
        return <UiDotsLoader />;
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <Wrapper>
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {qrCode && (
                <UiGroup vertical horizontallyCentered>
                    <div dangerouslySetInnerHTML={{ __html: qrCode }} />
                    <p>{translate('Please scan the QR code to start the payment', 'ui.payments')}</p>
                </UiGroup>
            )}
            {!qrCode && (
                <UiGroup vertical>
                    <UiButton color="primary" onClick={() => pay('app')} block={isPhone}>
                        Pay in Blixtpay app
                    </UiButton>
                    <UiButton color="primary" onClick={() => pay('web')} block={isPhone}>
                        Pay on Blixtpay website
                    </UiButton>
                    <UiButton onClick={onClose} block={isPhone}>
                        {translate('Back', 'ui.common')}
                    </UiButton>
                </UiGroup>
            )}
        </Wrapper>
    );
}
