import classNames from 'classnames';
import clamp from 'lodash/clamp';
import React, { HTMLAttributes, ReactNode } from 'react';
import { useFakeProgress } from '../../../hooks/useFakeProgress';
import Wrapper from './styles';

interface Props extends HTMLAttributes<HTMLDivElement> {
    rounded?: boolean;
    animated?: boolean;
    fakeDuration?: number;
    showPercentage?: boolean;
    showAfterPercentage?: number;
    thickness?: number;
    label?: ReactNode;
    value?: number;
    color?: string;
}

export default function UiLinearProgress({
    thickness = 20,
    fakeDuration,
    className,
    showPercentage,
    showAfterPercentage,
    animated = true,
    color = 'var(--color-success)',
    value = 0,
    label,
    rounded = true,
}: Props) {
    const fakeValue = useFakeProgress({ duration: fakeDuration, steps: 100 });
    const preparedValue = fakeDuration ? fakeValue : clamp(value, 0, 100);
    if (showAfterPercentage && showAfterPercentage > preparedValue) {
        return null;
    }

    return (
        <Wrapper className={classNames(className, { rounded, animated })} $height={thickness} $color={color}>
            <div className="progress-bar" style={{ maxWidth: `${preparedValue}%` }} />
            <div className="progress-label">
                {[label, showPercentage && `${preparedValue}%`].filter(Boolean).join(' ')}
            </div>
        </Wrapper>
    );
}
