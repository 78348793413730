import React from 'react';
import Wrapper from './styles';
import UiBox from '../../../ui/box/UiBox';
import UiButton from '../../../ui/button/UiButton';
import { translate } from '../../../../services/translate';

interface Props {
    onAccept: () => void;
    onDismiss: () => void;
}
export default function SportBetslipV2ClearAllModal({ onDismiss, onAccept }: Props) {
    return (
        <Wrapper>
            <UiBox className="clear-all-modal">
                <h1>{translate('Clear your betslip?', 'ui.sportsbook')}</h1>
                <p>{translate('Do you really want to clear betslip?', 'ui.sportsbook')}</p>
                <div className="clear-all-modal-buttons">
                    <UiButton onClick={onDismiss}>{translate('Cancel', 'ui.sportsbook')}</UiButton>
                    <UiButton onClick={onAccept} color="primary">
                        {translate('Yes, clear', 'ui.sportsbook')}
                    </UiButton>
                </div>
            </UiBox>
        </Wrapper>
    );
}
