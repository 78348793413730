import React, { useEffect, useMemo, useState } from 'react';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import SportOdds from '../../../odds/SportOdds';
import { MATCH_STATUS } from '../../../../../services/sports/constants';
import { withErrorBoundary } from '../../../../../higher-order-components/error-boundary';
import {
    getOddsValue,
    getOutcomesSortFn,
    getSortString,
    useGlobalSearchMarketHighlightScroll,
    useLocalSearchMarketHighLight,
    useSmartMarketUniqueNameByMarketId,
} from '../../../../../services/sports/sidebets';
import { useOddsByOutcomeIds } from '../../../../../services/sports/hooks';
import orderBy from 'lodash/orderBy';
import fromPairs from 'lodash/fromPairs';
import some from 'lodash/some';
import { SportMatchSidebetMarketProps as Props } from '../../../../../services/sports/types';
import SportOddsSpacer from '../../../odds/spacer/SportOddsSpacer';
import classNames from 'classnames';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import Wrapper from '../styles';
import { useSportsUserSettings } from '../../../../../services/sports/user-settings';
import isEmpty from 'lodash/isEmpty';

function SportMatchMarketsTableLike({
    marketType,
    match: { sport_category: sportCategoryId, status: matchStatus, betting_end: matchBettingEnd },
    collapsed,
    isBetbuilderMarkets = false,
}: Props) {
    const ROWS_LIMIT = 10;
    const {
        markets,
        market_type_id: marketTypeId,
        uniqueSequences,
        view_type: marketTypeViewType,
        sequence,
        translatedName: marketTypeName,
        isChunked,
    } = marketType;
    const filteredMarkets = useMemo(() => markets?.filter(({ outcomes }) => outcomes?.length), [markets]);
    const [sortByMarket, setSortByMarket] = useState(filteredMarkets[0]);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId });
    const [isCollapsed, setIsCollapsed] = useState(Boolean(collapsed));
    const [isAllLinesVisible, setIsAllLinesVisible] = useState(false);

    useEffect(() => {
        if (!filteredMarkets.includes(sortByMarket)) {
            setSortByMarket(filteredMarkets[0]);
        }
    }, [filteredMarkets]);

    useEffect(() => {
        setIsCollapsed(Boolean(collapsed));
    }, [collapsed]);

    const [isSortByReverse, setIsSortByReverse] = useState(false);
    const outcomeIds = useMemo(
        () => filteredMarkets.flatMap(({ outcomes }) => outcomes.map(({ id }) => id)),
        [filteredMarkets],
    );
    const [odds] = useOddsByOutcomeIds(outcomeIds, [outcomeIds]);
    const outcomeNames = useMemo(
        () => uniq(filteredMarkets.flatMap(({ outcomes }) => outcomes.map(({ name }) => name))),
        [filteredMarkets],
    );
    const outcomesByName = useMemo(
        () =>
            keyBy(
                filteredMarkets.flatMap((market) => market.outcomes.map((outcome) => ({ ...outcome, market }))),
                ({ name: outcomeName, market: { id: marketId } }) => `${outcomeName}_${marketId}`,
            ),
        [filteredMarkets],
    );

    const oddsByOutcomeName = useMemo(
        () =>
            fromPairs(
                outcomeNames.map((outcomeName) => [
                    outcomeName,
                    filteredMarkets.map(({ id: marketId }) =>
                        getOddsValue(outcomesByName[`${outcomeName}_${marketId}`], odds),
                    ),
                ]),
            ),
        [outcomeNames, filteredMarkets, odds, outcomesByName],
    );

    const sortedOutcomes = useMemo(() => {
        const sortFn = getOutcomesSortFn(odds, sortByMarket);
        return orderBy(
            outcomeNames.filter((outcomeName) => some(oddsByOutcomeName[outcomeName], (oddsValue) => oddsValue > 1)),
            (outcomeName) => sortFn(outcomesByName[`${outcomeName}_${sortByMarket.id}`]),
            [getSortString(isSortByReverse)],
        );
    }, [outcomeNames, outcomesByName, isSortByReverse, sortByMarket, oddsByOutcomeName]);

    const isLineMarket = Boolean(markets[0]?.line);
    const showUniqueNamePart = isLineMarket || Boolean(uniqueSequences.length);
    const uniqNamePartByMarketId = useSmartMarketUniqueNameByMarketId(
        showUniqueNamePart,
        markets,
        uniqueSequences,
        isLineMarket,
    );

    const { ref, highLightMatch: highLightMatchGlobal } = useGlobalSearchMarketHighlightScroll(marketTypeId, markets);
    const highLightMatchLocal = useLocalSearchMarketHighLight(marketType);

    const FILTERED_MARK = '$FILTERED_EXPAND_COLLAPSE$';
    const sortedFilteredOutcomes = useMemo(() => {
        if (sortedOutcomes.length < 8) {
            return sortedOutcomes;
        }
        const mIdx = sortedOutcomes.indexOf(highLightMatchLocal || '');
        if (mIdx <= 6) {
            return sortedOutcomes;
        }
        return sortedOutcomes
            .slice(0, 3)
            .concat(
                FILTERED_MARK,
                sortedOutcomes.slice(mIdx - 1, mIdx + 4),
                mIdx + 4 < sortedOutcomes.length ? FILTERED_MARK : [],
            );
    }, [sortedOutcomes, highLightMatchLocal]);

    const highLightMatch = highLightMatchLocal || highLightMatchGlobal;

    if (!filteredMarkets?.length && !sortedOutcomes?.length) {
        return null;
    }

    const setSort = (market) => () =>
        market === sortByMarket ? setIsSortByReverse(!isSortByReverse) : setSortByMarket(market);

    const isMultiMarketTable = filteredMarkets.length > 1;

    function getMarketTypeNameWithCommonSequence() {
        if (isEmpty(sequence)) {
            return marketTypeName;
        }
        let marketTypeNameWithCommonSequence = marketTypeName;
        Object.keys(sequence).forEach((key) => {
            if (marketTypeName.includes(`[${key}]`)) {
                marketTypeNameWithCommonSequence = marketTypeName.replace(`[${key}]`, sequence[key]);
            }
        });
        return marketTypeNameWithCommonSequence;
    }

    function getDefaultMarketsTitle() {
        return markets.map((market) => market.translatedName).join(' ');
    }

    function getMarketsTitle() {
        if (!showUniqueNamePart || markets.length < 2) {
            return getDefaultMarketsTitle();
        }

        if (!isChunked) {
            return getMarketTypeNameWithCommonSequence();
        }

        const range = isLineMarket ? getRangeFromLines() : getRangeFromSequences();
        if (!range) {
            return getDefaultMarketsTitle();
        }

        return `${getMarketTypeNameWithCommonSequence()} ${range}`;
    }

    function getRangeFromSequences() {
        const uniqueSequenceKey = uniqueSequences[0]?.key;
        if (uniqueSequences.length !== 1 || isNaN(Number(markets[0].sequence[uniqueSequenceKey]))) {
            return;
        }
        const minValue = Math.min(...markets.map((mr) => Number(mr.sequence[uniqueSequenceKey])));
        const maxValue = Math.max(...markets.map((mr) => Number(mr.sequence[uniqueSequenceKey])));
        if (minValue && maxValue) {
            return `${minValue} - ${maxValue}`;
        }
    }

    function getRangeFromLines() {
        const minValue = Math.min(...markets.map((mr) => Number(mr.line)));
        const maxValue = Math.max(...markets.map((mr) => Number(mr.line)));
        if (minValue && maxValue) {
            return `${minValue} - ${maxValue}`;
        }
    }

    return (
        <Wrapper key={marketTypeId} className="market-container" $collapsed={isCollapsed}>
            <div
                className={classNames('sidebet table-like', { highlight: highLightMatch })}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
                <div className="sidebet-name full-width" onClick={() => setIsCollapsed(!isCollapsed)}>
                    <div className="name">{getMarketsTitle()}</div>
                    <div className="toggle-collapse">
                        <Svg icon={isCollapsed ? 'plus-sign' : 'minus-round'} size={0.75} />️
                    </div>
                </div>

                {!isCollapsed && (
                    <>
                        <div className="sidebet-outcomes restricted-width">
                            <div className="overflow-auto">
                                {!isMultiMarketTable && <div className="sidebet-outcome list" />}
                                {sortedFilteredOutcomes
                                    .filter((_, index) => isAllLinesVisible || index < ROWS_LIMIT)
                                    .map((resultKey) => (
                                        <div
                                            className={classNames('sidebet-outcome list table-outcome-name', {
                                                highlight: resultKey === highLightMatch,
                                            })}
                                            key={resultKey}
                                            ref={resultKey === highLightMatch ? ref : null}
                                        >
                                            {resultKey === FILTERED_MARK ? '...' : <div>{resultKey}</div>}
                                        </div>
                                    ))}
                            </div>
                            {filteredMarkets.map((market) => (
                                <div key={market.id} className="overflow-auto">
                                    {isMultiMarketTable && (
                                        <div className="sidebet-outcome">
                                            <div className="sidebet-name" onClick={setSort(market)}>
                                                {sortByMarket === market && (
                                                    <i className="sort-arrow">{isSortByReverse ? '↑' : '↓'}</i>
                                                )}
                                                <span>
                                                    {showUniqueNamePart
                                                        ? uniqNamePartByMarketId[market.id]
                                                        : market.translatedName}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        {sortedFilteredOutcomes
                                            .filter((_, index) => isAllLinesVisible || index < ROWS_LIMIT)
                                            .map((resultKey) => ({
                                                outcome: outcomesByName[`${resultKey}_${market.id}`],
                                                resultKey,
                                            }))
                                            .map(({ outcome, resultKey }) => (
                                                <div className="sidebet-outcome" key={resultKey}>
                                                    {outcome ? (
                                                        <SportOdds
                                                            light
                                                            outcomeId={outcome.id}
                                                            market={{
                                                                view_type: marketTypeViewType,
                                                                in_play: matchStatus === MATCH_STATUS.LIVE,
                                                                betting_end: matchBettingEnd,
                                                                ...market,
                                                            }}
                                                            isAmericanLayout={isAmericanLayout}
                                                            isBetbuilderOdds={isBetbuilderMarkets}
                                                        />
                                                    ) : (
                                                        <SportOddsSpacer />
                                                    )}
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {sortedFilteredOutcomes.length > ROWS_LIMIT && (
                            <div
                                className="display-all-line-markets"
                                onClick={() => setIsAllLinesVisible(!isAllLinesVisible)}
                            >
                                {isAllLinesVisible
                                    ? translate('Display less', 'ui.sportsbook')
                                    : `${translate('Display all', 'ui.sportsbook')} ${
                                          sortedFilteredOutcomes.length - ROWS_LIMIT > 0 &&
                                          ` (${sortedFilteredOutcomes.length - ROWS_LIMIT})`
                                      }`}
                            </div>
                        )}
                    </>
                )}
            </div>
        </Wrapper>
    );
}

export default withErrorBoundary(
    React.memo(
        SportMatchMarketsTableLike,
        (prev, next) => prev.marketType === next.marketType && prev.collapsed === next.collapsed,
    ),
);
