import React from 'react';
import UiModal from '../../../ui/modal/UiModal';
import UiBox from '../../../ui/box/UiBox';
import SportMatchTurnover from '../../match/turnover/SportMatchTurnover';

import { MatchOfTheDay } from '../../../../microservices/match-of-the-day/types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    market: MatchOfTheDay['market'] & { in_play: boolean; betting_end: Date };
    matchId: number;
}

export default function SportMatchOfTheDayCardTurnoverModal({ isOpen = false, onClose, market, matchId }: Props) {
    return (
        <UiModal maxHeight={740} maxWidth={875} open={isOpen} onClose={onClose}>
            <UiBox>
                <SportMatchTurnover mainMarket={market} matchId={matchId} />
            </UiBox>
        </UiModal>
    );
}
