import React from 'react';
import Wrapper from './styles';

interface Props {
    className?: any;
    children?: any;
}
export default function UiTabs({ className, children }: Props) {
    return (
        <Wrapper className={className}>
            <div className="tabs">{children}</div>
        </Wrapper>
    );
}
