import React from 'react';
import Wrapper from './styles';
import Svg from '../../../svg/Svg';
import { translate } from '../../../../services/translate';
import { useStore } from '../../../../hooks/useStore';
import { stores } from '../../../../stores';

interface Props {
    isPhone: boolean;
}

export default function SportLobbyCategoriesMenu({ isPhone }: Props) {
    const [isSportSideMenuOpen, setIsSportSideMenuOpen] = useStore(stores.sports.isSportSideMenuOpen);

    return (
        <Wrapper
            type="card"
            icon={<Svg icon="sport-menu" size={1.4} />}
            size={isPhone ? 'small' : 'default'}
            selected={isSportSideMenuOpen}
            onClick={() => setIsSportSideMenuOpen(!isSportSideMenuOpen)}
        >
            {translate('Sport menu')}
        </Wrapper>
    );
}
