import React from 'react';
import Svg from '../../../../../../svg/Svg';
import { translate } from '../../../../../../../services/translate';
import { useHiddenMarketsCountWithDevice } from '../../../../../../../services/sports/top-markets';
import { CategoryMatch } from '../../../../../../../services/sports/types';
import { media } from '../../../../../../../stores/media/media';
import { useStore } from '../../../../../../../hooks/useStore';

interface Props {
    match: CategoryMatch;
}

function SportMatchMarketsTopMarketsMarketNoOdds({ match }: Props) {
    const { hiddenMarketsCount } = useHiddenMarketsCountWithDevice(match);
    const [{ isPhone }] = useStore(media);

    return (
        <div className="no-odds-container">
            <div className="no-odds-available animated fadeIn">
                {hiddenMarketsCount > 0 ? (
                    isPhone ? (
                        <Svg icon="menu-dots-horizontal" size={0.75} />
                    ) : (
                        <span>{translate('Check more odds', 'sport.market')} →</span>
                    )
                ) : isPhone ? (
                    <Svg icon="deny-sign" size={1} />
                ) : (
                    <span>{translate('No odds for this market', 'sport.market')}</span>
                )}
            </div>
        </div>
    );
}

export default React.memo(SportMatchMarketsTopMarketsMarketNoOdds);
