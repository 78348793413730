import React from 'react';
import Wrapper from './styles';

interface Props {
    selectedOutcomesCount: number;
    minSelections: number;
}
export default function SportContestsBetslipCounter({ selectedOutcomesCount, minSelections }: Props) {
    return (
        <Wrapper>
            {selectedOutcomesCount} / {minSelections}
        </Wrapper>
    );
}
