import styled from 'styled-components';
import { mediaQuery } from '../../../../../styles/utils';

export default styled.div`
    .paymentMethods {
        flex-flow: row wrap;

        button {
            height: auto;
            margin-bottom: 1rem;
            opacity: 0.5;
            padding: 0;

            span {
                .image {
                    align-items: center;
                    background-color: white;
                    border-radius: 0.5rem;
                    display: flex;
                    justify-content: center;
                    max-width: 8rem;
                    min-height: 4rem;

                    img {
                        border-radius: 0.5rem;
                        max-height: 4rem;
                    }
                }

                .channel {
                    display: flex;
                    justify-content: center;
                    line-height: 1.5;
                    padding: 0.5rem;
                    vertical-align: middle;
                    white-space: initial;
                    width: 8rem;
                }
            }

            &.selected {
                opacity: 1;
            }
        }
    }
    #widget-container-id {
        display: flex;
        justify-content: center;
    }
    #paywithmybank-widget-root {
        @media ${mediaQuery.isDesktop} {
            width: 40rem;
        }
    }
`;
