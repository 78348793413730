import { useStore } from '../useStore';
import { stores } from '../../stores';
import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getStoreValue } from '../../stores/store/utils';
import { useGlobalModal } from '../useGlobalModal';
import SportBetslipV2ClearAllModal from '../../components/sport/betslip-v-2/clear-all-modal/SportBetslipV2ClearAllModal';
import { BET_TYPE } from '../../services/sports/types';
import { calculateMaxWinByMarketId } from '../../services/sports/betslip';
import { COMBO_MARKET_ID } from '../../services/sports/constants';
import { calculateSystemTotalPotentialReturn, getSystemCombinations } from '../../services/sports/system-bet-helpers';

export function useTotalMaxWin() {
    const [userState] = useStore(stores.sports.betSlipUserState);
    const { stakeByMarketId, betType, systemStakes } = userState;
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);
    const [totalMaxWin, setTotalMaxWin] = useState(0);

    function calculateTotalMaxWin() {
        switch (betType) {
            case BET_TYPE.SYSTEM:
                const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
                return calculateSystemTotalPotentialReturn(systemBets);
            case BET_TYPE.COMBO:
                return calculateMaxWinByMarketId(COMBO_MARKET_ID);
            case BET_TYPE.SINGLE:
            case BET_TYPE.TEASER:
            default: {
                return Object.keys(betSlipMarketIdToOutcomeId).reduce(
                    (acc, marketId) => acc + calculateMaxWinByMarketId(marketId),
                    0,
                );
            }
        }
    }

    useEffect(() => {
        setTotalMaxWin(calculateTotalMaxWin());
    }, [stakeByMarketId, betType, betSlipMarketIdToOutcomeId, systemStakes]);

    return {
        totalMaxWin,
    };
}
export function useAcceptAnyOddsChanges() {
    const [userState, setUserState] = useStore(stores.sports.betSlipUserState);
    const { acceptAnyOddsChanges } = userState;

    function setAcceptAnyOddsChanges(value: boolean) {
        setUserState((state) => ({ ...state, acceptAnyOddsChanges: value }));
    }

    return {
        acceptAnyOddsChanges,
        setAcceptAnyOddsChanges,
    };
}

export function useKeptMySelectionSettings() {
    const [keptBetslipSelections, setKeptBetslipSelections] = useStore(stores.sports.keptBetslipSelections);
    const [betSlipMarketIdToOutcomeId, setBetSlipMarketIdToOutcomeId] = useStore(
        stores.sports.betSlipMarketIdToOutcomeId,
    );

    useEffect(restoreSelections, []);

    useEffect(() => {
        if (isEmpty(betSlipMarketIdToOutcomeId) || isEmpty(keptBetslipSelections)) {
            return;
        }
        setKeptBetslipSelections(betSlipMarketIdToOutcomeId);
    }, [betSlipMarketIdToOutcomeId]);

    function updateKeptBetslipSelectionsValue(value: boolean) {
        setKeptBetslipSelections(value ? betSlipMarketIdToOutcomeId : null);
    }

    function restoreSelections() {
        const keptSelections = getStoreValue(stores.sports.keptBetslipSelections);
        if (!keptSelections) {
            return;
        }
        setBetSlipMarketIdToOutcomeId(keptSelections);
    }

    return {
        keptBetslipSelections,
        updateKeptBetslipSelectionsValue,
    };
}

export function useClearAllModal() {
    const { showModal, hideModal } = useGlobalModal();

    function show(onAccept: () => void) {
        showModal(SportBetslipV2ClearAllModal, {
            params: {
                onDismiss: hideModal,
                onAccept: () => {
                    onAccept();
                    hideModal();
                },
            },
            onClose: hideModal,
            mode: 'default',
        });
    }

    return {
        show,
    };
}

export function useStakePerBetSetting() {
    const [{ betType }] = useStore(stores.sports.betSlipUserState);
    const [stakePerBetSetting, setStakePerBetSetting] = useStore(stores.sports.stakePerBetSetting);

    const isStakePerBetAvailable = betType === BET_TYPE.SINGLE;

    return {
        isStakePerBetAvailable,
        stakePerBetSetting,
        setStakePerBetSetting,
    };
}
