import styled from 'styled-components';

export default styled.div`
    .modal-text {
        margin-bottom: var(--spacing-16);

        p {
            font-size: var(--font-16);
            line-height: 1.5;
        }
    }
`;
