import styled, { css } from 'styled-components';

type StyledProps = {
    selected?: boolean;
};

export default styled.div<StyledProps>`
    display: flex;
    align-items: center;

    font-size: 0.8rem;
    cursor: pointer;
    color: var(--chips-text-color);
    font-weight: var(--font-weight-bold);
    ${({ theme }) => theme.optimizeFontRendering}

    background-color: var(--chips-bg);
    border-radius: 100px;
    border: var(--chips-border);
    padding: var(--spacing-8) var(--spacing-12);
    max-width: 150px;
    &:hover {
        color: var(--chips-text-color-hover);
        text-decoration: none;
    }
    ${({ selected }) =>
        selected &&
        css`
            color: var(--chips-text-color-selected);
            border: var(--chips-border-selected);
            background-color: var(--chips-bg-selected);
            &:hover {
                color: var(--chips-text-color-selected);
            }
        `};

    .content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 130px;
    }
    .icon {
        display: flex;
        margin-right: 0.4rem;
        img {
            width: 1.125rem;
            min-width: 1.125rem;
            height: 1.125rem;
        }
    }
    .suffix-icon {
        margin-left: 0.8rem;
    }
`;
