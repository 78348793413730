import React from 'react';
import { GlobalpayMethod } from '../../../../../services/payments/globalpay';
import { translate } from '../../../../../services/translate';
import Svg from '../../../../svg/Svg';
import UiButton from '../../../../ui/button/UiButton';
import Ui2FormRadio from '../../../../ui-2/form/radio/Ui2FormRadio';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiGroup from '../../../../ui/group/UiGroup';

interface Props {
    onDelete: (providerMethod: GlobalpayMethod) => Promise<void>;
    providerMethod: GlobalpayMethod;
    setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PaymentProvidersCustomGlobalpayMethodDeletion({
    onDelete,
    providerMethod,
    setIsDeleting,
}: Props) {
    return (
        <UiFormGroup>
            <Ui2FormRadio
                className="methods"
                radioNowrap={true}
                radioRow={false}
                name="providerMethod"
                options={[
                    {
                        label: `${providerMethod.bankName} - ****${providerMethod.accountNumber.slice(-4)}`,
                        value: providerMethod.accountNumber,
                        defaultChecked: true,
                        editOptions: <Svg icon="trash" size={1} className="icon icon-alignment" />,
                    },
                ]}
            />
            <UiGroup className="buttons">
                <UiButton color="default" block onClick={() => setIsDeleting(false)}>
                    {translate('Cancel', 'ui.account')}
                </UiButton>
                <UiButton color="primary" block onClick={() => onDelete(providerMethod)}>
                    {translate('Confirm Delete', 'ui.account')}
                </UiButton>
            </UiGroup>
        </UiFormGroup>
    );
}
