import React, { useState } from 'react';
import { translate } from '../../../../services/translate';
import SportMenuV2 from '../../menu-v-2/SportMenuV2';
import UiButton from '../../../ui/button/UiButton';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';
import { PreMatchTreeChildren } from '../../../../services/sports/types';
import SportTreeMenuV2Item from './item/SportTreeMenuV2Item';
import { isActiveRouteBySlug } from '../../../../services/router';

export default function SportTreeMenuV2() {
    const [tree] = useStore(stores.sports.prematchTree);
    const [isDisplayingAll, setIsDisplayingAll] = useState(false);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const INITIAL_VISIBLE_CATEGORY_COUNT = isLayoutB ? 65 : 5;
    const fakeParentCategories = [{ id: -4 }, { id: -3 }, { id: -2 }, { id: -1 }] as PreMatchTreeChildren[];

    const activeParent = tree.children?.find((child) => isActiveRouteBySlug(child.slug, child));
    const activeInAlwaysVisible =
        activeParent &&
        tree.children &&
        tree.children.slice(0, INITIAL_VISIBLE_CATEGORY_COUNT).find((child) => isActiveRouteBySlug(child.slug));

    return (
        <SportMenuV2 label={translate('Top Sports', 'ui.sportsbook')}>
            <>
                {(tree.children ?? fakeParentCategories)
                    .slice(0, isDisplayingAll ? undefined : INITIAL_VISIBLE_CATEGORY_COUNT)
                    .filter((child) => isDisplayingAll || activeInAlwaysVisible || !isActiveRouteBySlug(child.slug))
                    .map((category) => (
                        <SportTreeMenuV2Item key={category.id} category={category} />
                    ))}
                {activeParent && !isDisplayingAll && !activeInAlwaysVisible && (
                    <SportTreeMenuV2Item category={activeParent} />
                )}
                {!isDisplayingAll && !isLayoutB && (
                    <UiButton
                        block
                        onClick={() => setIsDisplayingAll(true)}
                        className="tree--show-all-button"
                        size="small"
                    >
                        {translate('show all', 'ui.sportsbook')}
                    </UiButton>
                )}
            </>
        </SportMenuV2>
    );
}
