import maxBy from 'lodash/maxBy';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { getExchangeRate, getProviderPaymentMethods, requestProviderPayout } from '../../../microservices/payments';
import { validateName } from '../../../microservices/users';
import { logger } from '../../../services/logger';
import {
    cryptoCurrencyByProvider,
    PAYMENTS_ERROR_CODE,
    PROVIDER_UNAVAILABLE_MESSAGE,
    depositWithProvider,
    fiatToCrypto,
    getDepositReturnRoute,
} from '../../../services/payments/payments';
import { DepositStatus, ExistingPaymentMethod, PROVIDERS, PROVIDER_TYPE } from '../../../services/payments/types';
import { useRouter } from '../../../services/router';
import { translate } from '../../../services/translate';
import { getFullName } from '../../../services/user';
import Loader from '../../loader/Loader';
import Snippet from '../../snippet/Snippet';
import Ui2Form from '../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2FormCheckbox from '../../ui-2/form/checkbox/Ui2FormCheckbox';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import UiFormGroup from '../../ui/form/group/UiFormGroup';
import UiGroup from '../../ui/group/UiGroup';
import UiPrompt from '../../ui/prompt/UiPrompt';
import PaymentProviderNotAvailable from '../provider-not-available/PaymentProviderNotAvailable';
import Wrapper from './styles';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { stores } from '../../../stores';
import { Validator } from '../../../types/components/form/types';
import Ui2FormRadio from '../../ui-2/form/radio/Ui2FormRadio';

interface Props {
    amount: number;
    customErrorCodeMessages?: Record<number, any>;
    customHolder?: string;
    deviceHash?: string;
    disclaimer?: string;
    errorCodeMessages?: Record<number, string>;
    extraFields?: Field[];
    fields?: Field[];
    isAddingAccountAllowed?: boolean;
    isAnonymousHolder?: boolean;
    isCryptoProvider?: boolean;
    isOneAccountAllowed?: boolean;
    isRedirectProvider?: boolean;
    isShowingPaymentMethodDescription?: boolean;
    redirectProviderIfAccountsNotFound?: boolean;
    onClose: (event?: 'success') => void;
    onFormChange?: Record<string, (value, setValueFunction: (field: string, value: string | boolean) => void) => void>;
    provider: PROVIDERS;
    providerType: PROVIDER_TYPE;
}

export default function PaymentFormModal({
    amount,
    customErrorCodeMessages = {},
    customHolder,
    deviceHash,
    disclaimer,
    errorCodeMessages = {},
    extraFields,
    fields = [],
    onClose,
    onFormChange,
    provider,
    providerType,
    isAddingAccountAllowed = true,
    isAnonymousHolder = false,
    isCryptoProvider = false,
    isOneAccountAllowed = false,
    isRedirectProvider = false,
    isShowingPaymentMethodDescription = false,
    redirectProviderIfAccountsNotFound = false,
}: Props) {
    const useFormMethods = useForm();
    const { errors } = useFormMethods.formState;
    const [accounts, setAccounts] = useState<ExistingPaymentMethod[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [conversionRate, setConversionRate] = useState(0);
    const [convertedAmount, setConvertedAmount] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [fallbackProvider, setFallbackProvider] = useState('');
    const [isAddingAccount, setIsAddingAccount] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNameMismatchConfirmationRequired, setIsNameMismatchConfirmationRequired] = useState(false);
    const [isProviderUnavailable, setIsProviderUnavailable] = useState(false);
    const [previousProvider, setPreviousProvider] = useState('');
    const [providerUnavailableMessage, setProviderUnavailableMessage] = useState('');
    const [{ isPhone }] = useStore(media);
    const [wallet] = useStore(stores.wallet);
    const { navigateTo } = useRouter();
    const { methodId } = useFormMethods.getValues();
    let watchedValues: any[] = [];
    if (onFormChange) {
        watchedValues = Object.keys(onFormChange).map((name) => useFormMethods.watch(name));
    }
    const displayFields = useMemo(() => {
        return fields.filter((field) => field.isDisplayField).map((field) => field.name);
    }, [fields]);
    const extraFieldNames = useMemo(() => {
        return extraFields?.map((field) => field.name);
    }, [extraFields]);
    const addNewAccountLastPage = useMemo(() => {
        return maxBy(fields, 'page')?.page;
    }, [fields]);
    const cryptoCurrency = cryptoCurrencyByProvider[provider];

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (accounts.length && !isAddingAccount) {
            useFormMethods.setValue('methodId', accounts[0].id, { shouldValidate: true });
        }
    }, [accounts]);

    useEffect(() => {
        if (fallbackProvider) {
            onSubmit(useFormMethods.getValues());
        }
    }, [fallbackProvider]);

    useEffect(() => {
        if (!onFormChange || !watchedValues.length) {
            return;
        }
        watchedValues.forEach((value, index) => {
            const name = Object.keys(onFormChange)[index];
            onFormChange[name]?.(value, useFormMethods.setValue);
        });
    }, [watchedValues]);

    useEffect(() => {
        if (isOneAccountAllowed && methodId) {
            onSubmit(useFormMethods.getValues());
        }
    }, [methodId]);

    function load() {
        if (isRedirectProvider) {
            if (disclaimer) {
                return;
            }
            onSubmit(useFormMethods.getValues());
        } else if (isAnonymousHolder) {
            setIsAddingAccount(true);
        } else {
            const holderName = customHolder ? '' : getFullName();
            useFormMethods.setValue('holder', holderName, { shouldValidate: true });
            loadAccounts();
        }
        if (isCryptoProvider) {
            loadConversionRate();
        }
    }

    async function loadAccounts() {
        setIsLoading(true);

        try {
            const accounts = await getProviderPaymentMethods(provider, providerType);
            if (accounts.length) {
                setAccounts(accounts);
            } else if (redirectProviderIfAccountsNotFound) {
                if (disclaimer) {
                    return;
                }
                onSubmit(useFormMethods.getValues());
            } else if (isAddingAccountAllowed) {
                setIsAddingAccount(true);
            } else {
                setIsProviderUnavailable(true);
            }
        } catch (error) {
            logger.error('PaymentFormModal', loadAccounts.name, error);
            setIsAddingAccount(true);
        }

        setIsLoading(false);
    }

    async function loadConversionRate() {
        try {
            const currency = wallet?.currency as string;
            const exchangeRate = await getExchangeRate(provider, currency);
            setConversionRate(Number(exchangeRate));
            setConvertedAmount(fiatToCrypto(amount, Number(exchangeRate), provider));
        } catch (error: any) {
            logger.error('PaymentFormModal', loadConversionRate.name, error);
        }
    }

    async function onSubmit(formValues: FieldValues) {
        const { methodId } = formValues;
        setIsLoading(true);

        const providerParams: Record<string, any> = {};
        Object.keys(formValues)
            .filter((key) => !displayFields.includes(key))
            .forEach((key) => {
                providerParams[key] = formValues[key];
            });
        extraFieldNames?.forEach((key) => {
            if (!providerParams[key]) {
                providerParams[key] = extraFields?.find((field) => field.name === key)?.defaultValue;
            }
        });
        if (customHolder && isAddingAccount && !isNameMismatchConfirmationRequired) {
            const { areNamesSimilar } = await validateName({ externalFirstOrFullName: formValues.holder });
            if (!areNamesSimilar) {
                setIsNameMismatchConfirmationRequired(true);
                return;
            }
        }
        try {
            await doPayment({
                amount,
                provider: fallbackProvider || provider,
                methodId,
                providerParams,
            });
        } catch (error: any) {
            const { code, field, message, translationVariables } = error;
            if (field) {
                const errorMessage = translate(message, 'ui.payments', translationVariables);
                useFormMethods.setError(field, { message: errorMessage });
            } else if (code && errorCodeMessages[code]) {
                setErrorMessage(errorCodeMessages[code]);
            } else if (code && customErrorCodeMessages[code]) {
                setErrorMessage(customErrorCodeMessages[code](previousProvider || provider));
            } else {
                setErrorMessage(translate('Technical Error', 'ui.common'));
                logger.error('PaymentFormModal', onSubmit.name, error);
            }
        }
        setIsLoading(false);
    }

    async function doPayment(requestData) {
        try {
            if (providerType === PROVIDER_TYPE.DEPOSIT) {
                await doDeposit(requestData);
            } else {
                await doPayout(requestData);
            }
        } catch (error: any) {
            if (error.name === 'ProviderDownError') {
                setProviderUnavailableMessage(PROVIDER_UNAVAILABLE_MESSAGE.PROVIDER_DOWN);
                setIsProviderUnavailable(true);
            } else if (error.name === 'TooManyAttemptsError') {
                setProviderUnavailableMessage(PROVIDER_UNAVAILABLE_MESSAGE.TOO_MANY_ATTEMPTS);
                setIsProviderUnavailable(true);
            } else if (error.code === PAYMENTS_ERROR_CODE.RETRY_WITH_FALLBACK_PROVIDER) {
                setPreviousProvider(provider);
                setFallbackProvider(error.provider);
                return;
            } else if (isRedirectProvider) {
                setIsProviderUnavailable(true);
                return;
            }
            throw error;
        }
    }

    async function doDeposit(requestData) {
        const { url } = await depositWithProvider({ ...requestData, deviceHash });
        if (url) {
            window.location.href = url;
        } else {
            navigateTo(getDepositReturnRoute(DepositStatus.COMPLETED));
        }
    }

    async function doPayout(requestData) {
        await requestProviderPayout(requestData);
        onClose('success');
    }

    function onAddAccount() {
        setFallbackProvider('');
        useFormMethods.setValue('methodId', undefined);
        setIsAddingAccount(true);
    }

    function onBack() {
        if (addNewAccountLastPage && currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        } else if (accounts.length) {
            useFormMethods.setValue('methodId', accounts[0].id);
            setIsAddingAccount(false);
        } else {
            onClose();
        }
    }

    function getAccountName(account: ExistingPaymentMethod) {
        if (isShowingPaymentMethodDescription) {
            return account.description;
        }
        return account.number;
    }

    function getRadioOptions(field: Field) {
        const { isTouched, isDirty } = useFormMethods.getFieldState(field.name);
        return field.options
            ?.filter((option) => !option.isHidden)
            .map((option, index) => ({
                ...option,
                defaultChecked: option?.defaultChecked ?? (index === 0 && !isTouched && !isDirty),
            }));
    }

    if (isProviderUnavailable) {
        return <PaymentProviderNotAvailable message={providerUnavailableMessage} onBack={onClose} />;
    }

    if ((isRedirectProvider || redirectProviderIfAccountsNotFound) && disclaimer) {
        return (
            <>
                <Snippet snippetKey={disclaimer} />
                <UiGroup expand horizontallyCentered>
                    <UiButton block={isPhone} onClick={onBack}>
                        {translate('Back', 'ui.common')}
                    </UiButton>
                    <UiButton
                        onClick={() => onSubmit(useFormMethods.getValues())}
                        color="primary"
                        block={isPhone}
                        isLoading={isLoading}
                    >
                        {translate('Continue', 'ui.account')}
                    </UiButton>
                </UiGroup>
            </>
        );
    }

    if (isRedirectProvider || redirectProviderIfAccountsNotFound) {
        return <Loader />;
    }

    return (
        <Wrapper>
            {isCryptoProvider && (
                <>
                    <div className="exchange-rate">
                        {`${translate('Exchange rate', 'ui.payments')}: 1${cryptoCurrency} = ${conversionRate} ${
                            wallet?.currency
                        }`}
                    </div>
                    <div className="value">
                        {`${translate(
                            'The approximate value in',
                            'ui.payments',
                        )} ${cryptoCurrency}: ${convertedAmount}`}
                    </div>
                </>
            )}
            {errorMessage && <UiAlert failure>{errorMessage}</UiAlert>}
            <Ui2Form onSubmit={onSubmit} useFormMethods={useFormMethods}>
                {isAddingAccount && (
                    <>
                        {!isAnonymousHolder && (!addNewAccountLastPage || currentPage === addNewAccountLastPage) && (
                            <UiFormGroup>
                                <Ui2FormTextInput
                                    name="holder"
                                    disabled={!customHolder}
                                    required
                                    label={translate('Holder Name', 'ui.payments')}
                                />
                                {customHolder && <UiAlert info>{translate(customHolder, 'ui.payments')}</UiAlert>}
                            </UiFormGroup>
                        )}
                        {fields
                            .filter((field) => !field.isHidden && (!field.page || field.page === currentPage))
                            .map((field) =>
                                field.options ? (
                                    <Ui2FormRadio
                                        className="form-input"
                                        defaultValue={field.defaultValue}
                                        disabled={field.isDisplayField}
                                        error={errors?.[field.name] as FieldError}
                                        key={field.name}
                                        label={translate(field.label, 'ui.payments')}
                                        maxLength={field.maxLength}
                                        minLength={field.minLength}
                                        name={field.name}
                                        placeholder={translate(field.label, 'ui.payments')}
                                        options={getRadioOptions(field) || []}
                                        radioRow={false}
                                        required={!field.isDisplayField}
                                        type={field.type}
                                    />
                                ) : (
                                    <Ui2FormTextInput
                                        className="form-input"
                                        defaultValue={field.defaultValue}
                                        disabled={field.isDisplayField}
                                        error={errors?.[field.name] as FieldError}
                                        key={field.name}
                                        label={translate(field.label, 'ui.payments')}
                                        maxLength={field.maxLength}
                                        minLength={field.minLength}
                                        name={field.name}
                                        pattern={field.pattern}
                                        placeholder={translate(field.label, 'ui.payments')}
                                        required={!field.isDisplayField}
                                        type={field.type}
                                        validator={field.validator}
                                    />
                                ),
                            )}
                    </>
                )}

                {!isAddingAccount && accounts.length > 0 && (
                    <Ui2FormRadio
                        className="accounts"
                        error={errors?.methodId as FieldError}
                        name="methodId"
                        options={accounts.map((account) => ({
                            value: account.id,
                            label: getAccountName(account),
                        }))}
                        radioRow={false}
                        required
                    />
                )}

                {disclaimer && <Snippet snippetKey={disclaimer} />}

                {extraFields
                    ?.filter((field) => !field.isHidden)
                    .map((field) => (
                        <Ui2FormCheckbox
                            className="form-input"
                            defaultValue={field.defaultValue}
                            error={errors?.[field.name] as FieldError}
                            key={field.name}
                            label={translate(field.label, 'ui.payments')}
                            name={field.name}
                            required={!field.isDisplayField}
                        />
                    ))}
                <div className="form-actions">
                    {isAddingAccount ? (
                        <UiButton onClick={onBack} block>
                            {translate('Back', 'ui.account')}
                        </UiButton>
                    ) : (
                        isAddingAccountAllowed && (
                            <UiButton onClick={onAddAccount} block>
                                {translate('Add account', 'ui.account')}
                            </UiButton>
                        )
                    )}
                    {isAddingAccount && addNewAccountLastPage && currentPage !== addNewAccountLastPage && (
                        <UiButton color="primary" onClick={() => setCurrentPage(currentPage + 1)} block>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    )}
                    {(!isAddingAccount || !addNewAccountLastPage || currentPage === addNewAccountLastPage) && (
                        <UiButton type="button" color="primary" isLoading={isLoading} isFormSubmitButton block>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    )}
                </div>
            </Ui2Form>
            <UiPrompt
                open={isNameMismatchConfirmationRequired}
                message={translate(`${providerType.toLowerCase()}-name-mismatch-warning-text`, 'ui.payments')}
                onAccept={() => onSubmit(useFormMethods.getValues())}
                onDismiss={() => {
                    setIsNameMismatchConfirmationRequired(false);
                    setIsLoading(false);
                }}
            />
        </Wrapper>
    );
}

type Field = {
    defaultValue?: any;
    isDisplayField?: boolean;
    isHidden?: boolean;
    label: string;
    maxLength?: number;
    minLength?: number;
    name: string;
    options?: { label: string; value: any; isHidden?: boolean; defaultChecked?: boolean }[];
    page?: number;
    pattern?: RegExp;
    type?: 'email' | 'number' | 'password' | 'text';
    validator?: {
        validate: Validator;
    };
};
