import React, { useEffect, useState } from 'react';
import { providerAction, getProviderMethods } from '../../../../../microservices/payments';
import { logger } from '../../../../../services/logger';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../services/payments/types';
import { translate } from '../../../../../services/translate';
import { useForm } from 'react-hook-form';
import Loader from '../../../../loader/Loader';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import UiAlert from '../../../../ui/alert/UiAlert';
import PaymentProvidersCustomGlobalpayScripts from '../../../providers-custom/globalpay/scripts/PaymentProvidersCustomGlobalpayScripts';
import PaymentProvidersCustomGlobalpayNewAccount from '../../../providers-custom/globalpay/new-account/PaymentProvidersCustomGlobalpayNewAccount';
import PaymentProvidersCustomGlobalpayExistingMethods from '../../../providers-custom/globalpay/existing-methods/PaymentProvidersCustomGlobalpayExistingMethods';
import PaymentProvidersCustomGlobalpayChangeLimit from '../../../providers-custom/globalpay/change-limit/PaymentProvidersCustomGlobalpayChangeLimit';
import Wrapper from './styles';
import { GlobalpayActions, GlobalpayMethod } from '../../../../../services/payments/globalpay';
import { depositWithProvider } from '../../../../../services/payments/payments';
import Snippet from '../../../../snippet/Snippet';
import { NativeMessageEventType, isMobileApp, sendNativeEvent } from '../../../../../services/mobile-app';
import { stores } from '../../../../../stores';
import isFinite from 'lodash/isFinite';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
    disclaimer?: string;
    onClose: () => void;
}

export default function PaymentDepositProviderGlobalpay({ amount, deviceHash, disclaimer, onClose }: Props) {
    const [appType] = useStore(stores.appType);
    const [availableLimit, setAvailableLimit] = useState(0);
    const [error, setError] = useState('');
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);
    const [isAddingAccount, setIsAddingAccount] = useState(false);
    const [isChangingLimit, setIsChangingLimit] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [preferredAccount, setPreferredAccount] = useState('');
    const [providerMethods, setProviderMethods] = useState<GlobalpayMethod[]>([]);
    const isEnrollment = Boolean(isAddingAccount && !preferredAccount);
    const useFormMethods = useForm();

    useEffect(() => {
        getAvailableMethods();
    }, []);

    useEffect(() => {
        setError('');
    }, [isAddingAccount]);

    async function getAvailableMethods() {
        setIsLoading(true);
        try {
            const response = await getProviderMethods(PROVIDERS.GLOBALPAY, amount, PROVIDER_TYPE.DEPOSIT);
            if (response.errorCode) {
                setError(translate(response.errorCode, 'ui.payments'));
                return;
            }
            setAvailableLimit(response?.availableLimit);
            setPreferredAccount(response?.preferredAccount);
            if (response?.methods) {
                setProviderMethods(response.methods);
                setIsAddingAccount(false);
                setIsChangingLimit(false);
            } else {
                setIsAddingAccount(true);
            }
        } catch (error: any) {
            logger.error('PaymentDepositProviderGlobalpay', 'getAvailableMethods', error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }

    async function onDepositSubmit(values: any) {
        setIsLoading(true);
        const { providerMethod: accountNumber } = values;
        const selectedMethod = providerMethods.find((method) => method.accountNumber === accountNumber);
        const { accountNumber: number, bankName, routingNumber, methodId } = { ...selectedMethod };
        const providerParams = {
            bankName,
            methodId,
            number,
            preferredAccount,
            routingNumber,
        };
        const requestData = {
            amount,
            deviceHash,
            provider: PROVIDERS.GLOBALPAY,
            providerParams,
        };
        try {
            const response = await depositWithProvider(requestData);
            if (response.url) {
                window.location.href = response.url;
            }
            if (response.errorCode) {
                setError(translate(response.errorCode, 'ui.payments'));
                setIsLoading(false);
            }
        } catch (error: any) {
            logger.error('PaymentDepositProviderGlobalpay', 'onDepositSubmit', error);
            setIsLoading(false);
        }
    }

    async function onNewAccountSubmit() {
        const { accountNumber: number, isTermAccepted, routingNumber } = useFormMethods.getValues();
        if (!preferredAccount && !isTermAccepted) {
            setError('Agree to Terms of Service to continue');
            return;
        }
        if (!(number || routingNumber)) {
            setError('No bank account selected');
            return;
        }

        const providerParams = {
            number,
            preferredAccount,
            routingNumber,
            action: preferredAccount ? GlobalpayActions.ADD_BANK : GlobalpayActions.ENROLLMENT,
            isTermAccepted,
        };
        await doAction(providerParams);
    }

    async function onDelete(providerMethod: GlobalpayMethod) {
        const { accountNumber: number, routingNumber, methodId } = providerMethod;
        const providerParams = {
            methodId,
            number,
            preferredAccount,
            routingNumber,
            action: GlobalpayActions.DELETE_BANK,
        };
        await doAction(providerParams);
    }

    async function onLimitChangeSubmit() {
        const { newLimit } = useFormMethods.getValues();
        const providerParams = { newLimit, preferredAccount, action: GlobalpayActions.LIMIT_CHANGE };
        await doAction(providerParams);
    }

    async function doAction(providerParams: {
        action: GlobalpayActions;
        methodId?: string;
        newLimit?: string;
        number?: string;
        preferredAccount: string;
        routingNumber?: string;
    }) {
        setError('');
        setIsLoading(true);
        const requestData = {
            provider: PROVIDERS.GLOBALPAY,
            providerParams,
        };
        try {
            const response = await providerAction(requestData);
            if (response.errorCode) {
                setError(translate(response.errorCode, 'ui.payments'));
                setIsLoading(false);
                return;
            }
            await getAvailableMethods();
        } catch (error: any) {
            logger.error('PaymentDepositProviderGlobalpay', 'doAction', error);
            setIsLoading(false);
        }
    }

    async function linkAccount() {
        if (!hasScriptsLoaded) {
            return;
        }
        const { isTermAccepted } = useFormMethods.getValues();
        if (!preferredAccount && !isTermAccepted) {
            setError('Agree to Terms of Service to continue');
            return;
        }
        setIsLoading(true);
        setError('');

        const requestData = {
            provider: PROVIDERS.GLOBALPAY,
            appType,
            providerParams: { action: GlobalpayActions.GET_LINK_TOKEN },
        };

        try {
            const response = await providerAction(requestData);
            if (response.errorCode) {
                setError(translate(response.errorCode, 'ui.payments'));
                setIsLoading(false);
                return;
            }
            if (!isMobileApp()) {
                const handler = createPlaidHandler(response.message);
                handler.open();
            } else {
                window.plaid = {
                    onSuccess: onPlaidSuccess,
                    onExit: onPlaidExit,
                };
            }
            sendNativeEvent({
                type: NativeMessageEventType.PAYMENT_GLOBALPAY,
                token: response.message,
                paymentMethod: PROVIDERS.GLOBALPAY,
            });
        } catch (error: any) {
            logger.error('PaymentDepositProviderGlobalpay', 'linkAccount', error);
            setIsError(true);
            setIsLoading(false);
        }
    }

    function createPlaidHandler(token: string) {
        return Plaid.create({
            onSuccess: onPlaidSuccess,
            onExit: onPlaidExit,
            token,
        });
    }

    async function onPlaidSuccess(public_token, metadata) {
        const action = isEnrollment ? GlobalpayActions.ENROLLMENT : GlobalpayActions.ADD_BANK;
        const providerParams = {
            isTermAccepted: true,
            plaidAccountId: metadata.account_id,
            publicToken: public_token,
            preferredAccount,
            action,
        };
        await doAction(providerParams);
    }

    function onPlaidExit() {
        setIsLoading(false);
    }

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <Wrapper>
            {disclaimer && <Snippet snippetKey={disclaimer} />}
            {!isAddingAccount && isFinite(availableLimit) && (
                <div className="subtitle">Limit Available: {availableLimit}</div>
            )}
            {error && <UiAlert failure>{error}</UiAlert>}
            <PaymentProvidersCustomGlobalpayScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
            {!isAddingAccount && (
                <>
                    {isChangingLimit ? (
                        <PaymentProvidersCustomGlobalpayChangeLimit
                            availableLimit={availableLimit}
                            onLimitChangeSubmit={onLimitChangeSubmit}
                            setIsChangingLimit={setIsChangingLimit}
                            useFormMethods={useFormMethods}
                        />
                    ) : (
                        <PaymentProvidersCustomGlobalpayExistingMethods
                            onDelete={onDelete}
                            onSubmit={onDepositSubmit}
                            providerMethods={providerMethods}
                            setIsAddingAccount={setIsAddingAccount}
                            // changing limits removed by payment provider's request until further notice
                            // setIsChangingLimit={setIsChangingLimit}
                            onClose={onClose}
                            useFormMethods={useFormMethods}
                        />
                    )}
                </>
            )}
            {isAddingAccount && (
                <PaymentProvidersCustomGlobalpayNewAccount
                    hasMethods={Boolean(providerMethods.length)}
                    isEnrollment={isEnrollment}
                    linkAccount={linkAccount}
                    onClose={onClose}
                    onNewAccountSubmit={onNewAccountSubmit}
                    setIsAddingAccount={setIsAddingAccount}
                    useFormMethods={useFormMethods}
                />
            )}
        </Wrapper>
    );
}

declare let Plaid: any;
