import map from 'lodash/map';
import React, { useEffect, useRef } from 'react';

import { hasBetSlipChangedFromOdds } from '../../../../services/sports/betslip';
import { getFixedStake } from '../../../../services/sports/betslip-formatting';
import { getErrorCode } from '../../../../services/sports/betslip-errors';
import { COMBO_MARKET_ID, MA_ENABLED_ERROR, systemBetTypeBySystemKey } from '../../../../services/sports/constants';
import { hasBetslipManualAcceptanceError } from '../../../../services/sports/manual-acceptance-helpers';
import {
    calculateSystemTotalPotentialReturn,
    calculateSystemTotalStake,
    getSystemCombinations,
} from '../../../../services/sports/system-bet-helpers';
import { translate } from '../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../stores';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import { Tooltip } from 'react-tooltip';
import Svg from '../../../svg/Svg';
import SportBetslipWageToWin from '../wage-to-win/SportBetslipWageToWin';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { formattedAmountWithCurrency } from '../../../../services/currency';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import { isFeatureAvailable } from '../../../../services/feature';
import { FEATURE } from '../../../../services/types';
import { isTestUser } from '../../../../services/user';

interface Props {
    isPositionChanged: boolean;
}
export default function SportBetslipSystemStakeInput({ isPositionChanged = false }: Props) {
    const [userState, setUserState] = useStore(stores.sports.betSlipUserState);
    const [placingState] = useStore(stores.sports.betSlipPlacingState);
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const [genericErrors] = useStoreWithSelector(
        stores.sports.betSlipErrorByMarketId,
        (state) => state[String(COMBO_MARKET_ID)],
    );
    const [{ isPhone }] = useStore(media);
    const [isByodOrReplay] = useStore(stores.sports.isByodOrReplay);
    const previousValues = useRef({ isByodOrReplay });

    useStoreWithSelector(stores.sports.oddsByOutcomeId, hasBetSlipChangedFromOdds);
    const { systemStakes, disableSystemIndividualUpdate, MASystemStakes, parentSystemStakes, disableSystemSingles } =
        userState;
    const { isLoading, needsConfirm } = placingState;
    const change = (systemType) => (value) => {
        setUserState((state) => ({
            ...state,
            systemStakes: { ...systemStakes, [systemType]: getFixedStake(value) },
        }));
    };
    const MAStakeChange = (systemType) => (value) => {
        setUserState((state) => ({
            ...state,
            MASystemStakes: { ...MASystemStakes, [systemType]: getFixedStake(value) },
        }));
    };

    const combCountToSystemType = {
        3: { withSingles: 'PATENT' },
        4: { noSingles: 'YANKEE', withSingles: 'LUCKY15' },
        5: { noSingles: 'CANADIAN', withSingles: 'LUCKY31' },
        6: { noSingles: 'HEINZ', withSingles: 'LUCKY63' },
        7: { noSingles: 'SUPER_HEINZ' },
        8: { noSingles: 'GOLIATH' },
    };

    useEffect(() => {
        if (isByodOrReplay || previousValues.current.isByodOrReplay !== isByodOrReplay || isPositionChanged) {
            previousValues.current.isByodOrReplay = isByodOrReplay;
            return;
        }
        setUserState((state) => ({
            ...state,
            systemStakes: {},
            parentSystemStakes: { withSingles: '', noSingles: '' },
            MASystemStakes: {},
            disableSystemIndividualUpdate: false,
            disableSystemSingles: false,
        }));
    }, [betSlipMarketIdToOutcomeId, isByodOrReplay]);
    const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
    const countOfSystems = Object.keys(systemBets).length;
    const onBatchStakeSet = (withSinglesOrNotKey) => (value) => {
        const withSingles = withSinglesOrNotKey === 'withSingles';
        const disableSystemSingles = withSingles && Boolean(value);
        const stake = getFixedStake(value);
        setUserState((state) => {
            const { systemStakes, parentSystemStakes } = state as any;
            Object.keys(systemBets).forEach((systemKey) => {
                if (!withSingles && systemKey === '1') {
                    systemStakes[systemBetTypeBySystemKey[systemKey]] = '';
                } else {
                    systemStakes[systemBetTypeBySystemKey[systemKey]] = stake;
                }
            });
            if (withSingles) {
                parentSystemStakes.withSingles = stake;
                parentSystemStakes.noSingles = '';
            } else {
                parentSystemStakes.withSingles = '';
                parentSystemStakes.noSingles = stake;
            }
            state.disableSystemSingles = disableSystemSingles;
            state.disableSystemIndividualUpdate = Boolean(value);
        });
    };
    const disable = isLoading || needsConfirm;
    const hasManualAcceptanceError = hasBetslipManualAcceptanceError();
    const isManualAcceptanceStakeAllowed = (genericErrors || []).map(getErrorCode).includes(MA_ENABLED_ERROR);
    const potentialReturn = calculateSystemTotalPotentialReturn(systemBets);

    return (
        <>
            <UiFormGroup>
                {map(systemBets, (betsOfType, systemKey) => {
                    const systemType = systemBetTypeBySystemKey[systemKey];
                    const isDisabled =
                        (systemType === 'SINGLE' ? disableSystemSingles : disableSystemIndividualUpdate) ||
                        Boolean(MASystemStakes[systemType]) ||
                        disable;
                    return (
                        <div key={systemType}>
                            <div className="system-stake">
                                <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
                                    <div className="system-stake-label">
                                        <Svg
                                            icon="info"
                                            className="help-bubble"
                                            data-tooltip-id={systemType}
                                            size={isPhone ? 1.5 : 1.1}
                                        />
                                        <label className="system-stake-input-label">
                                            {betsOfType.length} X {translate(systemType, 'ui.betslip')}
                                        </label>
                                    </div>
                                    <SportBetslipWageToWin
                                        name={`systemBetInput${systemType}`}
                                        isDisabled={isDisabled}
                                        value={(systemStakes[systemType] as any) || 0}
                                        key={systemType}
                                        onChange={change(systemType)}
                                        disableQuickStakes={isDisabled}
                                    />
                                </UiAnimate>

                                <Tooltip
                                    id={systemType}
                                    variant="light"
                                    place={isPhone ? 'right' : 'bottom'}
                                    className="tooltip"
                                >
                                    <span>{translate(`ui.betlip.system.helpbubble.${systemType}`)}</span>
                                </Tooltip>
                            </div>
                            {hasManualAcceptanceError && isManualAcceptanceStakeAllowed && (
                                <div className="system-stake">
                                    <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
                                        <div className="system-stake-label">
                                            <label className="system-stake-input-label">
                                                {betsOfType.length} X {translate(systemType, 'ui.betslip')}{' '}
                                                {translate(' extra stake', 'ui.sportsbook')} →
                                            </label>
                                        </div>
                                        <SportBetslipWageToWin
                                            name={`systemBetInputManualAcceptance${systemType}`}
                                            isDisabled={disable}
                                            value={(MASystemStakes[systemType] as any) || 0}
                                            onChange={MAStakeChange(systemType)}
                                        />
                                    </UiAnimate>
                                </div>
                            )}
                        </div>
                    );
                })}
                {combCountToSystemType[countOfSystems]
                    ? Object.keys(combCountToSystemType[countOfSystems]).map((withSinglesOrNotKey) => (
                          <div className="system-stake" key={withSinglesOrNotKey}>
                              <UiAnimate animationIn="bounceIn" animationOut="bounceOut" isVisible={true}>
                                  <div className="system-stake-label">
                                      <Svg
                                          icon="info"
                                          className="help-bubble"
                                          data-tooltip-id={withSinglesOrNotKey}
                                          size={isPhone ? 1.5 : 1.1}
                                      />
                                      <label className="system-stake-input-label">
                                          {translate(
                                              combCountToSystemType[countOfSystems][withSinglesOrNotKey],
                                              'ui.sportsbook',
                                          )}
                                      </label>
                                  </div>
                                  <SportBetslipWageToWin
                                      name={`systemBetSystemInput${withSinglesOrNotKey}`}
                                      isDisabled={Boolean(Object.keys(MASystemStakes).length) || disable}
                                      value={parentSystemStakes[withSinglesOrNotKey] || 0}
                                      onChange={onBatchStakeSet(withSinglesOrNotKey)}
                                  />
                              </UiAnimate>
                              <Tooltip
                                  id={withSinglesOrNotKey}
                                  variant="light"
                                  place={isPhone ? 'right' : 'bottom'}
                                  className="tooltip"
                              >
                                  <span>
                                      {translate(
                                          `ui.betlip.system.helpbubble.${combCountToSystemType[countOfSystems][withSinglesOrNotKey]}`,
                                      )}
                                  </span>
                              </Tooltip>
                          </div>
                      ))
                    : null}
            </UiFormGroup>
            {!(isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser()) && (
                <>
                    <div className="total-stake">
                        <span className="stake-input-label">{translate('Total stake', 'ui.betslip')} →</span>
                        <span className="stake-input-value">{calculateSystemTotalStake(systemBets)}</span>
                    </div>
                    <div className="potential-return">
                        <span className="stake-input-label">{translate('Potential return', 'ui.betslip')} →</span>
                        <span className="stake-input-value">
                            {potentialReturn > 0 ? formattedAmountWithCurrency(potentialReturn) : ''}
                        </span>
                    </div>
                </>
            )}
        </>
    );
}
