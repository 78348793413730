import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { translate } from '../../../../../..../../services/translate';
import UiButton from '../../../../ui/button/UiButton';
import Ui2FormTextInput from '../../../../ui-2/form/text-input/Ui2FormTextInput';
import Ui2Form from '../../../../ui-2/form/Ui2Form';
import UiFormGroup from '../../../../ui/form/group/UiFormGroup';
import UiGroup from '../../../../ui/group/UiGroup';
import Wrapper from './styles';

interface Props {
    availableLimit: number;
    onLimitChangeSubmit: () => void;
    setIsChangingLimit: React.Dispatch<React.SetStateAction<boolean>>;
    useFormMethods: UseFormReturn<FieldValues, any>;
}

export default function PaymentProvidersCustomGlobalpayChangeLimit({
    availableLimit,
    onLimitChangeSubmit,
    setIsChangingLimit,
    useFormMethods,
}: Props) {
    return (
        <Wrapper>
            <Ui2Form useFormMethods={useFormMethods} onSubmit={onLimitChangeSubmit}>
                <UiFormGroup>
                    <Ui2FormTextInput
                        label={translate('Current Limit', 'ui.payments')}
                        maxLength={20}
                        name="availableLimit"
                        type="number"
                        defaultValue={availableLimit}
                        disabled
                        required={false}
                    />
                    <Ui2FormTextInput
                        label={translate('New Limit', 'ui.payments')}
                        maxLength={20}
                        name="newLimit"
                        required
                        type="number"
                    />
                    <UiGroup>
                        <UiButton color="default" block onClick={() => setIsChangingLimit(false)}>
                            {translate('Back', 'ui.account')}
                        </UiButton>
                        <UiButton type="button" isFormSubmitButton color="primary" block>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                </UiFormGroup>
            </Ui2Form>
        </Wrapper>
    );
}
