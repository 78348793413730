import React from 'react';
import { getRoute, isActiveRoute } from '../../../services/router';
import { isFeatureAvailable } from '../../../services/feature';
import { stores } from '../../../stores';
import Wrapper from './styles';
import Svg from '../../svg/Svg';
import UiButton from '../../ui/button/UiButton';
import { media } from '../../../stores/media/media';
import { useStore } from '../../../hooks/useStore';
import { FEATURE } from '../../../services/types';
import { isTestUser } from '../../../services/user';
import SportLobbyCategoriesMenu from './menu/SportLobbyCategoriesMenu';
import { useSportLobbyCategoriesLinks } from '../../../hooks/main-navigation-v2';

export default function SportLobbyCategories() {
    const [liveMatchesCount] = useStore(stores.sports.liveMatchesCount);
    const [{ isPhone, isTablet }] = useStore(media);

    const links = useSportLobbyCategoriesLinks();

    return (
        <Wrapper>
            {isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser() && (isPhone || isTablet) && (
                <SportLobbyCategoriesMenu isPhone={isPhone} />
            )}
            {links.map(({ route, icon, label }) => {
                const isLiveCard = route.includes(`${getRoute('sport.live')}`);
                return (
                    <UiButton
                        url={route}
                        key={route}
                        type="card"
                        icon={<Svg icon={icon} size={1.4} />}
                        badge={isLiveCard ? liveMatchesCount : 0}
                        size={isPhone ? 'small' : 'default'}
                        selected={isActiveRoute(route, false)}
                    >
                        {label}
                    </UiButton>
                );
            })}
        </Wrapper>
    );
}
