import React, { useEffect, useState } from 'react';
import ScriptLoader from '../../../../script-loader/ScriptLoader';

interface Props {
    onError: () => void;
    onScriptsLoaded: () => void;
}

export default function PaymentProvidersCustomGlobalpayScripts({ onError, onScriptsLoaded }: Props) {
    const SDK_URL = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';
    const [isGlobalpayScriptLoaded, setIsGlobalpayScriptLoaded] = useState(false);
    useEffect(() => {
        if (isGlobalpayScriptLoaded) {
            onScriptsLoaded();
        }
    }, [isGlobalpayScriptLoaded]);
    return <ScriptLoader url={SDK_URL} onError={onError} onLoad={() => setIsGlobalpayScriptLoaded(true)} />;
}
