import { BonusType } from '../services/bonuses/types';
import {
    PlaceBetHandle,
    createComboCardsDataAndPlaceBets,
    createDataAndPlaceBet,
    getBetslipErrorMessage,
    getErrorMessage,
    placeBetWrap,
} from '../services/sports/betslip';
import {
    getActiveEligibleBonus,
    placeFreeBet,
    getBonusBetErrorMessage,
    placeFreeBetV2,
    placeRiskFreeBet,
    placeMultiplierBet,
} from '../services/sports/bonus-bets';
import { isCampaignEligibleForBetSlip, placeCampaignBet } from '../services/sports/campaigns';
import { BET_TYPE } from '../services/sports/types';
import { stores } from '../stores';
import { useStore } from './useStore';

export function useCampaignOrNormalBetSlipFunctions() {
    const [campaigns] = useStore(stores.sports.campaigns);
    const [bonusBetsSelection] = useStore(stores.sports.bonusBetsSelection);
    const [{ betType }] = useStore(stores.sports.betSlipUserState);
    let placeBet: PlaceBetHandle = placeBetWrap(createDataAndPlaceBet);
    let placeBetTextPrefix = '';
    let getErrorMessageByError = (error) => getBetslipErrorMessage(error);
    const activeBonusBet = getActiveEligibleBonus();

    if (betType === BET_TYPE.COMBO_CARD) {
        placeBet = placeBetWrap(createComboCardsDataAndPlaceBets);
    } else if (bonusBetsSelection[BonusType.FreeBet] && activeBonusBet) {
        placeBet = placeFreeBet;
        getErrorMessageByError = getBonusBetErrorMessage;
        placeBetTextPrefix = 'free ';
    } else if (bonusBetsSelection[BonusType.FreeBetV2] && activeBonusBet) {
        placeBet = placeFreeBetV2;
        getErrorMessageByError = getBonusBetErrorMessage;
        placeBetTextPrefix = 'free ';
    } else if (bonusBetsSelection[BonusType.RiskFreeBet] && activeBonusBet) {
        placeBet = placeRiskFreeBet;
        getErrorMessageByError = getBonusBetErrorMessage;
        placeBetTextPrefix = 'risk free ';
    } else if (bonusBetsSelection[BonusType.Multiplier] && activeBonusBet) {
        placeBet = placeMultiplierBet;
        getErrorMessageByError = getBonusBetErrorMessage;
        placeBetTextPrefix = 'multiplier ';
    } else if (isCampaignEligibleForBetSlip(campaigns.selected)) {
        placeBet = placeCampaignBet;
        placeBetTextPrefix = 'campaign ';
        getErrorMessageByError = getErrorMessage;
    }
    return { placeBet, getErrorMessageByError, placeBetTextPrefix };
}
