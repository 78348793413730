import { useEffect } from 'react';

export default function useScroll(callback: (offset: number) => void) {
    useEffect(() => {
        function scrollHandler() {
            callback && callback(window.scrollY);
        }

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
}
