import { Country } from '@staycool/location';
import React, { useState } from 'react';
import { stores } from '../../../../stores';
import AuthPayAndPlay from '../AuthPayAndPlay';
import { translate } from '../../../../services/translate';
import UiModal from '../../../ui/modal/UiModal';
import { media } from '../../../../stores/media/media';
import { useStore } from '../../../../hooks/useStore';
import Wrapper from './styles';
import { PROVIDERS } from '../../../../services/payments/types';

export default function AuthPayAndPlayModal() {
    const [payAndPlaySettings] = useStore(stores.payAndPlaySettings);
    const [isPayAndPlayModalOpen, setIsPayAndPlayModalOpen] = useStore(stores.modals.isPayAndPlayModalOpen);
    const [{ isPhone }] = useStore(media);
    const [shouldDisableBackdropClick, setShouldDisableBackdropClick] = useState(false);
    const maxWidthByProvider = {
        [PROVIDERS.TRUELAYER]: '350px',
    };

    function onClose() {
        setIsPayAndPlayModalOpen(false);
        setShouldDisableBackdropClick(false);
    }

    return (
        <UiModal
            onClose={onClose}
            mode={isPhone ? 'drawer' : undefined}
            open={isPayAndPlayModalOpen}
            className="new-modal"
            maxWidth={payAndPlaySettings?.provider && maxWidthByProvider[payAndPlaySettings.provider]}
            disableBackdropClick={shouldDisableBackdropClick}
        >
            <Wrapper>
                <h3>{translate(`Deposit your amount in 3 easy steps`, 'ui.pay-and-play')}</h3>
                <ol className="steps">
                    <li>{translate('Enter deposit amount', 'ui.pay-and-play')}</li>
                    <li>
                        {translate('Choose your bank', 'ui.pay-and-play')}
                        {payAndPlaySettings?.country === Country.ESTONIA && (
                            <div className="bank-example">{translate('SEB, Swedbank or LHV', 'ui.pay-and-play')}</div>
                        )}
                    </li>
                    <li>{translate('Start playing', 'ui.pay-and-play')}</li>
                </ol>
                <AuthPayAndPlay onClose={onClose} setShouldDisableBackdropClick={setShouldDisableBackdropClick} />
            </Wrapper>
        </UiModal>
    );
}
