import React from 'react';
import Wrapper from './styles';
import { translate } from '../../../../services/translate';
import UiAlert from '../../../ui/alert/UiAlert';
import HorseRacingBetslipRunnerMaps from '../runner-maps/HorseRacingBetslipRunnerMaps';
import HorseRacingUiTag from '../../ui/tag/HorseRacingUiTag';
import UiCloseButton from '../../../ui/close-button/UiCloseButton';
import { clearReceipt } from '../../../../services/lvdc/lvdc';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { TicketReceipt } from '@staycool/lvdc-types/bets';
import { DATE_YEAR_TIME_FORMAT, getFormattedDate } from '../../../../services/date';

interface Props {
    receipt: TicketReceipt;
    isTicketList?: boolean;
}

export default function HorseRacingBetslipReceipt({ receipt, isTicketList = false }: Props) {
    const { display_id, bet_type, date, race_id, races, runner_maps, ticket_cost, track_name, breed, bet_cost } =
        receipt;

    if (isEmpty(receipt)) {
        return null;
    }

    return (
        <Wrapper>
            {!isTicketList && <UiCloseButton className="close-button" onClick={clearReceipt} />}

            <div className="race-info">
                <div className="bet-id">
                    Bet ID: {display_id} / {getFormattedDate({ date, format: DATE_YEAR_TIME_FORMAT, useDots: true })}
                </div>
                {!isTicketList && (
                    <UiAlert center success>
                        {translate('Your bet is placed. Good luck!', 'ui.betslip')}
                    </UiAlert>
                )}
                <div className="track-name">{track_name}</div>
                <HorseRacingUiTag>
                    {translate('race', 'ui.racebook')} {race_id}
                </HorseRacingUiTag>
            </div>

            <HorseRacingBetslipRunnerMaps betType={bet_type} runnerMaps={runner_maps} races={races} breed={breed} />
            <div className="amount-container">
                <div>{capitalize(translate('Cost per bet', 'ui.betslip'))} →</div>
                <div className="amount-value">$ {bet_cost}</div>
            </div>
            <div className="amount-container">
                <div>{capitalize(translate('Bet total', 'ui.betslip'))} →</div>
                <div className="amount-value">$ {ticket_cost}</div>
            </div>
        </Wrapper>
    );
}
