import React from 'react';
import { FieldError } from 'react-hook-form';
import { formatCardNumber, getPaymentMethodImage } from '../../../../services/payments/payments';
import { ExistingPaymentMethod } from '../../../../services/payments/types';
import { REGEX_NUMERIC } from '../../../../services/regex';
import { translate } from '../../../../services/translate';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import UiAlert from '../../../ui/alert/UiAlert';
import UiFormLabel from '../../../ui/form/label/UiFormLabel';
import UiGroup from '../../../ui/group/UiGroup';
import Wrapper from './styles';
import { filterStyleProps } from '../../../../styles/utils';

interface Props {
    isActive: boolean;
    card: ExistingPaymentMethod;
    shouldShowCvcInput: boolean;
    cvcError?: FieldError;
    onClick?: () => void;
}

export default function PaymentCardFormModalCard({ card, isActive, shouldShowCvcInput, cvcError, ...rest }: Props) {
    const number = formatCardNumber(card.number || card.description);

    return (
        <Wrapper {...filterStyleProps({ ...rest })} isActive={isActive}>
            <UiGroup verticallyCentered>
                <img className="method-icon" src={getPaymentMethodImage(number)} alt="Card option icon" />
                <div className="method-info">
                    <div>{`************${number.slice(-4)}`}</div>
                </div>
            </UiGroup>
            {isActive && shouldShowCvcInput && (
                <UiGroup className="method-cvc-field">
                    <div className="cvc-field">
                        <UiFormLabel>{translate('CVC', 'ui.payments')}</UiFormLabel>
                        <Ui2FormTextInput maxLength={3} name="cvc" pattern={REGEX_NUMERIC} />
                    </div>
                    {cvcError && (
                        <UiAlert className="cvc-field-error" failure={true}>
                            {cvcError?.message}
                        </UiAlert>
                    )}
                </UiGroup>
            )}
        </Wrapper>
    );
}
