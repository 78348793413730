import React from 'react';
import { isDesktopPlatform } from '../../../../../services/geocomply/geocomply';
import { GeoComplyDesktopLibrary, GeocomplyClientError } from '../../../../../services/geocomply/types';
import UiGroup from '../../../../ui/group/UiGroup';
import GeocomplyDesktopInstallers from '../../../desktop/installers/GeocomplyDesktopInstallers';
import Wrapper from './styles';

interface Props {
    clientError: GeocomplyClientError;
}

export default function GeocomplyFeedbackErrorsClientError({ clientError }: Props) {
    if (!clientError) {
        return null;
    }

    function shouldDisplayDesktopInstallers() {
        if (!isDesktopPlatform()) {
            return false;
        }

        const GeoComplyDesktopLibrary = (window as any).GeoComply as GeoComplyDesktopLibrary;
        return clientError.code === GeoComplyDesktopLibrary.Client.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE;
    }

    return (
        <Wrapper>
            <UiGroup vertical>
                {!shouldDisplayDesktopInstallers() && <div className="client-error">{clientError.message}</div>}
                {shouldDisplayDesktopInstallers() && <GeocomplyDesktopInstallers />}
            </UiGroup>
        </Wrapper>
    );
}
