import styled from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

export default styled.div`
    --match-width: 450px;

    position: relative;

    border: 2px solid var(--color-motd);
    border-radius: var(--ui-radius);
    background-color: var(--surface-level-1-bg);

    margin-bottom: var(--spacing-12);
    .match-of-day {
        display: flex;
        align-items: stretch;

        width: 100%;

        overflow: hidden;
        animation: fadeIn 200ms linear;
        @media ${mediaQuery.isPhone} {
            flex-direction: column;
        }
    }

    .motd-header {
        position: relative;

        display: flex;
        align-items: center;

        background-color: var(--color-motd);
        padding-left: 50px;

        z-index: 1;
        @media (max-width: 1300px) {
            padding-left: 30px;
        }
        @media ${mediaQuery.isPhone} {
            min-height: 50px;
            padding-left: var(--spacing-8);
        }
        &:after {
            content: '';
            position: absolute;
            width: 40px;
            top: 0;
            right: -20px;
            bottom: -1px;
            background-color: var(--surface-level-1-bg);
            transform: skewX(-15deg);
            z-index: -1;
            @media (max-width: 1300px) {
                right: -20px;
            }
            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }

        .mod-title {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            margin-left: 100%;
            transform: translate(-100%, 0);
            @media ${mediaQuery.isPhone} {
                flex-direction: row;
                align-items: center;
                transform: none;
                margin: 0;
            }
            .title {
                position: relative;
                z-index: 1;

                font-size: 0.875rem;
                color: var(--color-motd);
                text-transform: uppercase;
                font-style: italic !important;
                white-space: nowrap;
                font-weight: var(--font-weight-heavy);
                ${({ theme }) => theme.optimizeFontRendering};

                padding: 3px 10px;
                @media ${mediaQuery.isPhone} {
                    font-size: 0.75rem;
                }
                &.solid {
                    color: var(--color-motd);
                    font-size: 1.8rem;
                    @media ${mediaQuery.isLaptop} {
                        font-size: 1.4rem;
                    }
                    @media ${mediaQuery.isPhone} {
                        font-size: 1.2rem;
                    }
                }
                &:not(:last-of-type) {
                    margin-bottom: 3px;
                }
                @media ${mediaQuery.isPhone} {
                    margin-left: 5px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: var(--surface-level-1-bg);
                    transform: skewX(-15deg);
                    z-index: -1;
                }
            }
        }
    }
    .motd-rules {
        position: relative;

        color: var(--font-color-secondary);
        font-size: 1rem;
        line-height: 1.6;

        padding: var(--spacing-16);
        min-height: 300px;
        em {
            font-style: normal;
            color: var(--color-motd);
        }
    }

    .motd-info {
        position: relative;
        z-index: 1;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        width: 270px;
        margin-right: 1.6rem;

        cursor: pointer;
        @media ${mediaQuery.isDesktopSmall} {
            width: 180px;
        }
        @media ${mediaQuery.isLaptop} {
            width: 170px;
        }
        @media ${mediaQuery.isPhone} {
            flex-direction: row;
            align-items: center;
            width: 100%;
            order: 3;
            border-top: var(--surface-level-1-border);
            padding-top: var(--spacing-8);
        }
    }

    .desc-box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        @media ${mediaQuery.isPhone} {
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            padding: 0 0.8rem;
            margin-bottom: 0.4rem;
        }

        .info-title {
            display: flex;
            align-items: center;

            color: var(--color-motd);
            font-size: 0.75rem;
            text-transform: uppercase;
            text-align: left;

            padding: var(--spacing-4) var(--spacing-8);
            @media ${mediaQuery.isPhone} {
                padding: 0 0.8rem 0 0;
                font-size: 0.625rem;
            }
            .offer-icon {
                font-size: 1.5rem;
                margin-right: var(--spacing-8);
                @media ${mediaQuery.isPhone} {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .rules-link {
        position: relative;

        display: flex;
        align-items: center;
        text-align: left;
        justify-content: center;

        font-size: 0.6rem;
        color: var(--color-motd);
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;

        margin-left: auto;
        z-index: 1;
        @media ${mediaQuery.isPhone} {
            color: var(--surface-level-1-bg);
        }
        &:after {
            content: '';
            position: absolute;
            width: 70px;
            top: 0;
            right: -25px;
            bottom: -1px;
            background-color: var(--color-motd);
            transform: skewX(-15deg);
            z-index: -1;
            @media ${mediaQuery.isPhone} {
                display: none;
            }
        }
        .rules-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            padding: 5px;
            margin-right: var(--spacing-8);
            border-radius: 100px;
            background-color: var(--surface-level-1-bg);

            @media ${mediaQuery.isPhone} {
                padding: 0;
                background: none;
            }
        }
    }

    .motd-match {
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 2;

        padding: 0 0 0.4rem 0;
        @media ${mediaQuery.isLaptop} {
            padding: 0 0 0.4rem 1.6rem;
        }
        @media ${mediaQuery.isPhone} {
            order: 1;
        }
        .match-info {
            position: relative;

            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: flex-start;

            padding: 0 0.8rem;

            max-width: var(--match-width);
            margin: 0 auto;
            @media ${mediaQuery.isLaptop} {
                width: 100%;
            }
            @media ${mediaQuery.isPhone} {
                max-width: initial;
                width: 100%;
            }

            .match-name {
                flex: 1;
                margin-top: 1rem;
                margin-right: 1.6rem;
                min-width: 200px;
                max-width: 200px;

                @media ${mediaQuery.isLaptop} {
                    max-width: 150px;
                    min-width: 150px;
                }

                @media ${mediaQuery.isPhone} {
                    margin-top: 1.6rem;
                    margin-right: 0.8rem;
                    max-width: 110px;
                    min-width: 110px;
                }
                .team-away,
                .team-home {
                    height: auto;
                }
            }
        }

        .match-category {
            display: flex;
            align-items: center;

            color: var(--match-secondary-color);
            font-size: 0.7rem;
            padding: 0.4rem 0.8rem;

            max-width: var(--match-width);
            width: var(--match-width);
            margin: 0 auto;
            > *:not(:last-child) {
                margin-right: 0.8rem;
            }
        }

        .turnover-icon {
            color: var(--match-secondary-color);
            &.selected {
                color: var(--color-highlight);
            }
        }

        .motd-match-extra {
            display: flex;
            align-items: center;
            justify-content: center;

            color: var(--match-secondary-color);
            cursor: pointer;

            margin-left: auto;
            > *:not(:last-child) {
                margin-right: 0.8rem;
            }
        }
        .motd-odds {
            position: relative;

            display: flex;
            align-content: center;
            align-items: flex-end;

            width: 350px;
            max-width: 100%;
            min-width: 0;
            > * {
                min-width: 0;
                &:not(:last-of-type) {
                    margin-right: 0.2rem;
                }
            }
            @media ${mediaQuery.isLaptop} {
                width: 270px;
            }
            @media ${mediaQuery.isTablet} {
                width: 250px;
            }
            @media ${mediaQuery.isPhone} {
                width: 100%;
            }
        }

        .motd-sidebets-count {
            font-size: 0.8rem;
            color: var(--match-secondary-color);
            cursor: pointer;
        }
    }

    .motd-sidebets-container {
        position: relative;
        padding: var(--spacing-12);
        @media ${mediaQuery.isPhone} {
            margin: 0;
        }
        .column {
            overflow: hidden;
        }
    }
`;
