import styled from 'styled-components';
import UiButton from '../../../ui/button/UiButton';

export default styled(UiButton)`
    position: relative;
    margin-right: 15px;
    &:after {
        content: '';
        height: 50%;
        width: 2px;
        background-color: var(--surface-level-1-line-color);
        position: absolute;
        right: -13px;
    }
`;
