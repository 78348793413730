import React, { useMemo } from 'react';
import Wrapper from './styles';
import { getRoute, isActiveRoute } from '../../../../../services/router';
import { translate } from '../../../../../services/translate';
import SportCampaignsList from '../../../campaigns/list/SportCampaignsList';
import { stores } from '../../../../../stores';
import UiButton from '../../../../ui/button/UiButton';
import Svg from '../../../../svg/Svg';
import { isFeatureAvailable } from '../../../../../services/feature';
import { useStore } from '../../../../../hooks/useStore';
import { FEATURE } from '../../../../../services/types';
import { isBetBuilderFeatureAvailable } from '../../../../../services/bet-builder';

type FeaturedLink = { url: string; icon: string; text: string; selected?: boolean };

export default function SportSidebarSoonCampaigns() {
    const [bonusBetsStore] = useStore(stores.sports.bonusBets);

    const campaignListItems = useMemo(() => {
        const result: JSX.Element[] = [];

        if (isFeatureAvailable(FEATURE.CAMPAIGN_FEATURED_LINKS)) {
            const featuredLinks: (FeaturedLink | boolean)[] = [
                isFeatureAvailable(FEATURE.BOOSTED_ODDS) && {
                    url: getRoute('sport.boosted'),
                    icon: 'boosted',
                    text: translate('Cool events', 'ui.sportsbook'),
                },
                isBetBuilderFeatureAvailable() && {
                    url: getRoute('sport.betbuilder'),
                    icon: 'betbuilder',
                    text: translate('Betbuilder', 'ui.sportsbook'),
                    selected: isActiveRoute(getRoute('sport.betbuilder'), false),
                },
                isFeatureAvailable(FEATURE.HIGHEST_TURNOVER) && {
                    url: getRoute('sport.highest-turnover'),
                    icon: 'cash',
                    text: translate('Highest turnover', 'ui.sportsbook'),
                    selected: isActiveRoute(getRoute('sport.highest-turnover'), false),
                },
                isFeatureAvailable(FEATURE.SIMPLE_COMBO) && {
                    url: getRoute('sport.simple-combo'),
                    icon: 'combo-generator',
                    text: translate('ui.sportsbook.combo-generator.combo-generator'),
                    selected: isActiveRoute(getRoute('sport.simple-combo'), false),
                },
            ];
            result.push(
                ...(featuredLinks.filter(Boolean) as FeaturedLink[]).map((featuredLink) => (
                    <UiButton
                        key={featuredLink.url}
                        url={featuredLink.url}
                        onClick={() => stores.sports.isSportSideMenuOpen.set(false)}
                        icon={<Svg icon={featuredLink.icon} />}
                        type="menu"
                        selected={featuredLink.selected}
                    >
                        {featuredLink.text}
                    </UiButton>
                )),
            );
        }

        if (isFeatureAvailable(FEATURE.CAMPAIGNS)) {
            result.push(<SportCampaignsList key="sportCampaignsList" />);
        }

        return result;
    }, [bonusBetsStore]);

    if (!isFeatureAvailable(FEATURE.FEATURED_EVENTS)) {
        return null;
    }

    if (campaignListItems.length === 0) {
        return null;
    }

    return (
        <Wrapper>
            <div className="menu-label">{translate('Campaigns', 'ui.sportsbook')}</div>
            <div className="campaigns-list">{campaignListItems}</div>
        </Wrapper>
    );
}
