import styled from 'styled-components';

export default styled.div`
    &,
    & > ul {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    padding: 0.5rem;
    gap: 0.5rem;
    background: var(--sport-category-bg);
    ul {
        justify-content: space-around;
        margin: 0;
        list-style: none;
        padding-left: 0;
        font-size: 0.75rem;
        li {
            display: flex;
            align-items: center;
            min-height: 1.375rem;
            gap: 0.5rem;
            .list-item-icon {
                margin: 0.25rem;
                position: relative;
                color: var(--color-primary);
                z-index: 1;
            }
        }
    }

    .motd-odds {
        align-items: center;
        margin-bottom: -2px;
        &,
        .odds {
            display: flex;
            gap: 0.25rem;
        }
        .odds-button {
            background: #40404e;
            border-radius: 0;
            height: 2rem;
            border-color: transparent;
            &:hover {
                background: var(--odds-bg-hover);
            }
        }
        .odds {
            flex: 1;
            & :first-child .odds-button {
                border-radius: var(--ui-radius) 0 0 var(--ui-radius);
            }
            & :last-child .odds-button {
                border-radius: 0 var(--ui-radius) var(--ui-radius) 0;
            }
        }

        .outcome-value {
            font-size: 0.875rem;
        }
        .odds-outcome {
            font-size: 0.5rem;
            padding: 0;
            margin-bottom: 0.125rem;
            min-height: 0;
        }
    }

    .turnover-btn {
        padding: 0.25rem 1rem;
        border-radius: 0.375rem;
        color: var(--font-color-primary);
    }

    .terms-and-conditions {
        text-align: center;
        font-size: 0.75rem;
        text-decoration: underline;
        color: var(--match-secondary-color);
        cursor: pointer;
    }
`;
