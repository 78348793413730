import React from 'react';
import Wrapper from '../../styles-default';
import UiButton from '../../../../ui/button/UiButton';
import { translate } from '../../../../../services/translate';
import { isBonusEligibleAndSelected } from '../../../../../services/sports/bonus-bets';

import { BonusBet } from '../../../../../services/bonuses/types';

interface Props {
    bonus: BonusBet;
    onBonusToggle: () => void;
}

export default function SportBonusBetMultiplierDefaultLayout({ bonus, onBonusToggle }: Props) {
    return (
        <Wrapper>
            {!isBonusEligibleAndSelected(bonus) && bonus.multiplierSettings && bonus.multiplierSettings.multiplier > 0 && (
                <div className="campaign-bet animated fadeIn">
                    <div className="campaign-icon">{Math.round(bonus.multiplierSettings.multiplier * 100)}%</div>
                    <div className="campaing-description">{translate(`Multiplier`, 'ui.bonus')}</div>

                    <UiButton size="small" color="primary" onClick={onBonusToggle}>
                        {translate('Apply?', 'ui.bonus')}
                    </UiButton>
                </div>
            )}
            {isBonusEligibleAndSelected(bonus) && (
                <div className="campaign-bet selected animated fadeIn">
                    {bonus.multiplierSettings && bonus.multiplierSettings.multiplier > 0 && (
                        <div className="campaign-icon">{Math.round(bonus.multiplierSettings.multiplier * 100)}%</div>
                    )}
                    <div className="campaing-description">
                        {translate(`Multiplier`, 'ui.bonus')}
                        <br />
                        {translate('ui.bonus.is-applied')}
                    </div>
                    <UiButton size="small" onClick={onBonusToggle}>
                        {translate('Remove', 'ui.bonus')}
                    </UiButton>
                </div>
            )}
        </Wrapper>
    );
}
