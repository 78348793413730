import styled from 'styled-components';

export default styled.div`
    .subtitle {
        text-align: center;
        font-size: var(--font-12);
        color: var(--color-highlight);
        padding-bottom: var(--spacing-16);
    }
`;
