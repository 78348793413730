import styled from 'styled-components';

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: var(--spacing-8);
    color: var(--font-color-secondary);
    font-size: var(--font-10);

    &.sport-menu-selected {
        color: var(--card-button-active-text-color);
    }

    & .sport-menu-counter {
        position: absolute;
        top: var(--spacing-4);
        right: var(--spacing-4);
        background-color: var(--color-alert);
        color: var(--font-color-primary);
    }

    & .sport-menu-button {
        height: 3.5rem;
        border-radius: calc(var(--ui-radius) * 2);

        & .button-icon {
            margin: 0;
        }

        &.sport-menu-button-selected {
            border: unset;
            color: var(--button-active-bg);
            background-color: var(--card-button-active-text-color);
        }
    }
`;
