import { useEffect, useState } from 'react';
import { calculateMaxWinByMarketId, getBetslipStakeComboOrSingles } from '../../services/sports/betslip';
import { BET_BUILDER_NOT_ALLOWED_ERROR, COMBO_MARKET_ID, ODDS_CHANGED_ERROR } from '../../services/sports/constants';
import { useStore } from '../useStore';
import { stores } from '../../stores';
import { isBetBuilderFeatureAvailable } from '../../services/bet-builder';
import { BET_TYPE } from '../../services/sports/types';
import { addBetslipError, getErrorCode } from '../../services/sports/betslip-errors';
import { getStoreValue } from '../../stores/store/utils';
import { calculateSystemUserOnlyStake, getSystemCombinations } from '../../services/sports/system-bet-helpers';

export function useMaxWin(marketId: string | typeof COMBO_MARKET_ID, deps: unknown[]) {
    const [maxWin, setMaxWin] = useState(0);

    useEffect(() => {
        setMaxWin(calculateMaxWinByMarketId(marketId));
    }, deps);

    return {
        maxWin,
    };
}

export function useBetBuilderState() {
    const [userState] = useStore(stores.sports.betSlipUserState);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [isBetBuilderAllowed, setIsBetBuilderAllowed] = useState(true);

    useEffect(() => {
        if (userState) {
            if (userState.betType === BET_TYPE.BETBUILDER) {
                setIsBetBuilderAllowed(isBetBuilderFeatureAvailable());
                const betBuilderMarket = Object.values(marketInfoById).filter(
                    (market) => market.view_type === 'bet_builder',
                );
                if (betBuilderMarket.length && !isBetBuilderFeatureAvailable()) {
                    for (const { id } of betBuilderMarket) {
                        addBetslipError(id, BET_BUILDER_NOT_ALLOWED_ERROR);
                    }
                }
                return;
            }
            setIsBetBuilderAllowed(true);
        }
    }, [userState]);

    return {
        isBetBuilderAllowed,
    };
}

export function useOddsChangeConfirmRequired() {
    const [userState] = useStore(stores.sports.betSlipUserState);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const { acceptAnyOddsChanges } = userState;
    const [isOddsChangeConfirmRequired, setIsOddsChangeConfirmRequired] = useState(false);

    useEffect(() => {
        if (acceptAnyOddsChanges) {
            setIsOddsChangeConfirmRequired(false);
            return;
        }

        const hasOddsChanged = Object.values(betSlipErrorByMarketId).some((errors: any) => {
            return errors.some((errorObjectOrCode) => getErrorCode(errorObjectOrCode) === ODDS_CHANGED_ERROR);
        });
        setIsOddsChangeConfirmRequired(Boolean(hasOddsChanged));
    }, [betSlipErrorByMarketId, acceptAnyOddsChanges]);

    return {
        isOddsChangeConfirmRequired,
        setIsOddsChangeConfirmRequired,
    };
}

export function useUpdateBetSlipPlacingStateOnGeocomplyModalOpenState() {
    const [isGeoComplyModalOpen] = useStore(stores.modals.isGeoComplyModalOpen);
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);

    useEffect(() => {
        if (!isGeoComplyModalOpen) {
            setBetSlipPlacingState({
                ...betSlipPlacingState,
                isConfirmed: false,
                isLoading: false,
            });
        }
    }, [isGeoComplyModalOpen]);
}

export function useTotalStake() {
    const [userState] = useStore(stores.sports.betSlipUserState);
    const betSlipMarketIdToOutcomeId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);

    const { stakeByMarketId, betType, systemStakes } = userState;
    const [totalStake, setTotalStake] = useState(0);

    function calculateTotalStake() {
        if (betType === BET_TYPE.SYSTEM) {
            const systemBets = getSystemCombinations(betSlipMarketIdToOutcomeId);
            return calculateSystemUserOnlyStake(systemBets);
        } else {
            return getBetslipStakeComboOrSingles(betType, stakeByMarketId, betSlipMarketIdToOutcomeId);
        }
    }

    useEffect(() => {
        setTotalStake(calculateTotalStake() || 0);
    }, [betType, stakeByMarketId, systemStakes, betSlipMarketIdToOutcomeId]);

    return {
        totalStake,
    };
}
