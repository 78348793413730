import compact from 'lodash/compact';
import { media } from '../../stores/media/media';
import { useStore } from '../useStore';
import { translate } from '../../services/translate';
import { getRoute } from '../../services/router';
import { isFeatureAvailable } from '../../services/feature';
import { FEATURE } from '../../services/types';
import { stores } from '../../stores';

export function useSportLobbyCategoriesLinks() {
    const [{ isPhone, isTablet }] = useStore(media);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const isMainNavigationV2 = isFeatureAvailable(FEATURE.SPORT_MAIN_NAVIGATION_V2);

    return compact([
        {
            label:
                isPhone || isTablet
                    ? translate('sports.navigation.sports-lobby.short')
                    : translate('sports.navigation.sports-lobby'),
            route: getRoute('sport.recommendations'),
            icon: isMainNavigationV2 ? 'football' : 'sports',
        },
        {
            label:
                isPhone || isTablet
                    ? translate('sports.navigation.livebet-lobby.short')
                    : translate('sports.navigation.livebet-lobby'),
            route: getRoute('sport.live'),
            icon: isMainNavigationV2 ? 'poker-live-games' : 'live-stream',
        },
        isFeatureAvailable(FEATURE.CONTESTS) && {
            route: getRoute('sport.contests'),
            icon: 'casino-race',
            label: translate('Contests', 'ui.sportsbook'),
        },
        isAuthenticated &&
            isMainNavigationV2 && {
                route: getRoute('sport.bet-history'),
                icon: 'bethistory',
                label: translate('My bets', 'ui.sportsbook'),
            },
        isMainNavigationV2 && {
            route: getRoute('sport.favorites'),
            icon: 'star',
            label: translate('favorites', 'ui.sportsbook'),
        },
    ]);
}
