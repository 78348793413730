import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    .sport-content {
        display: grid;
        grid-template-columns: 280px minmax(0, 1fr) 300px;
        grid-column-gap: var(--spacing-8);
        width: 100%;
        margin-top: var(--spacing-8);

        @media (max-width: 1600px) {
            grid-template-columns: 230px minmax(0, 1fr) 280px;
        }
        @media (max-width: 1300px) {
            grid-template-columns: 200px minmax(0, 1fr) 280px;
        }
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: block;
        }

        .sport-matches {
            position: relative;
            ${({ theme }) => theme.contentBoxStyle};
            padding: var(--spacing-12);
            width: 100%;
            min-height: 100vh;
            @media ${mediaQuery.isPhone} {
                padding: var(--spacing-12) var(--spacing-8);
            }
        }

        .sport-sidebar {
            position: sticky;
            top: 10px;
            overflow-y: scroll;
            height: 100vh;
        }
    }

    .hot-event-card {
        padding: var(--spacing-12);
    }
`;
