import React from 'react';
import { PROVIDER_TYPE, PROVIDERS } from '../../../../../../services/payments/types';
import PaymentCardFormModal from '../../../../card-form-modal/PaymentCardFormModal';

interface Props {
    amount: number;
    disclaimer?: string;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderLuqapayVoucherCard({ amount, deviceHash, disclaimer, onClose }: Props) {
    return (
        <PaymentCardFormModal
            amount={amount}
            deviceHash={deviceHash}
            disclaimer={disclaimer}
            isAllowedToGoOverMaxCardsLimit={true}
            isCvcRequiredForNewCard={false}
            isCvcRequiredForSavedCard={false}
            provider={PROVIDERS.LUQAPAY_VOUCHER_CARD}
            providerType={PROVIDER_TYPE.DEPOSIT}
            onClose={onClose}
        />
    );
}
