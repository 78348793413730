import React, { useState, useEffect } from 'react';
import { stores } from '../../../stores';
import UiAlert from '../../ui/alert/UiAlert';
import { translate } from '../../../services/translate';
import { useStore } from '../../../hooks/useStore';
import { BannerMessageDisplayPage, BannerMessageSeverityLevel, CmsBannerMessage } from '../../../services/cms/types';

interface Props {
    paymentType: 'deposit' | 'payout';
}

export default function PaymentBanner({ paymentType }: Props) {
    const [bannerMessages] = useStore(stores.cms.bannerMessages);
    const [messages, setMessages] = useState<CmsBannerMessage[]>([]);

    useEffect(() => {
        setMessages(
            bannerMessages.filter(
                (message) =>
                    message.displayPage === BannerMessageDisplayPage[paymentType] &&
                    new Date(message.endDate) > new Date(),
            ),
        );
    }, [bannerMessages]);

    return (
        <>
            {messages.map((message) => (
                <UiAlert
                    key={message.id}
                    info={message.severityLevel === BannerMessageSeverityLevel.info}
                    failure={message.severityLevel === BannerMessageSeverityLevel.failure}
                    warning={message.severityLevel === BannerMessageSeverityLevel.warning}
                >
                    <div dangerouslySetInnerHTML={{ __html: translate(message.translationKey) }} />
                </UiAlert>
            ))}
        </>
    );
}
