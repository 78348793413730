import styled from 'styled-components';
import { mediaQuery } from '../../styles/utils';

export default styled.div`
    z-index: 1;
    display: grid;
    grid-template-columns: 280px minmax(0, 1fr);
    grid-column-gap: var(--spacing-8);
    width: 100%;

    @media (max-width: 1600px) {
        grid-template-columns: 230px minmax(0, 1fr);
    }

    @media (max-width: 1300px) {
        grid-template-columns: 200px minmax(0, 1fr) 280px;
    }

    @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
        display: block;
    }

    .league-menu {
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            margin-top: auto;
        }

        .menu-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: var(--font-10);
            color: var(--sport-menu-label-color);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            letter-spacing: 1px;
            text-transform: uppercase;
            user-select: none;

            margin: var(--spacing-24) var(--spacing-12);

            svg {
                width: 12px;
                opacity: 0.4;
            }
        }
    }
`;
