import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { translate } from '../../../../services/translate';
import { UiToggle } from '../../../ui/toggle/UiToggle';

interface Props extends React.HTMLProps<HTMLInputElement> {
    name: string;
    label?: string;
    justify?: 'spaced' | 'center' | 'left' | 'right';
    direction?: 'row' | 'column';
    reverse?: boolean;
    dataTest?: string;
}

export default function Ui2FormToggle({ name, required = false, ...args }: Props) {
    const { register } = useFormContext();

    const options: RegisterOptions = {
        required: { value: required, message: translate('Oops! You forgot this field', 'ui.registration') },
    };

    return <UiToggle registerReturn={register(name, options)} {...args} />;
}
