import styled from 'styled-components';
import UiNavLink from '../../../ui/nav-link/UiNavLink';

export default styled(UiNavLink)`
    display: flex;
    align-items: center;
    gap: var(--spacing-12);
    padding: var(--spacing-8) var(--spacing-12);
    color: var(--font-color-secondary);
    font-size: var(--font-12);
    line-height: 1.2;
    border-radius: var(--ui-radius);
    transition: background-color 0.2s, color 0.2s;

    &:hover,
    &.sport-nav-link-selected {
        color: var(--card-button-active-text-color);
        background-color: var(--surface-level-1-bg);
    }

    svg {
        margin: 0;
    }

    svg + .sport-nav-link-label,
    img + .sport-nav-link-label {
        margin-left: 0;
    }

    & .sport-nav-link-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: var(--font-14);
        margin-left: calc(1.5 * var(--spacing-20));
    }

    & .sport-nav-link-badge {
        background-color: var(--color-alert);
        color: var(--font-color-primary);
    }

    & .sport-nav-link-postfix {
        margin-left: auto;
    }
`;
