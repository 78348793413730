import styled, { css } from 'styled-components';
import { hideScrollBar, mediaQuery } from '../../styles/utils';

type StyledProps = {
    $isLayoutB?: boolean;
};

export default styled.div<StyledProps>`
    .sport-content {
        position: relative;
        z-index: 1;
        display: grid;
        grid-template-columns: ${({ $isLayoutB }) =>
            $isLayoutB ? 'minmax(0, 1fr) 320px' : '280px minmax(0, 1fr) 300px'};
        grid-column-gap: var(--spacing-8);
        width: 100%;
        @media (max-width: 1600px) {
            grid-template-columns: ${(props) =>
                props.$isLayoutB ? 'minmax(0, 1fr) 320px' : '230px minmax(0, 1fr) 280px'};
        }
        @media (max-width: 1300px) {
            grid-template-columns: ${(props) =>
                props.$isLayoutB ? 'minmax(0, 1fr) 320px' : '200px minmax(0, 1fr) 280px'};
        }
        @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
            display: block;
        }

        .sport-menu {
            @media ${mediaQuery.isPhone}, ${mediaQuery.isTablet} {
                margin-top: auto;
            }
            .menu-label {
                display: flex;
                align-items: center;
                justify-content: space-between;

                font-size: var(--font-10);
                color: var(--sport-menu-label-color);
                font-weight: var(--font-weight-bold);
                ${({ theme }) => theme.optimizeFontRendering};
                letter-spacing: 1px;
                text-transform: uppercase;
                user-select: none;

                margin: var(--spacing-24) var(--spacing-12);

                svg {
                    width: 12px;
                    opacity: 0.4;
                }
            }
            .show-all-sports {
                margin-top: 0.8rem;
            }
        }

        .sport-matches {
            position: relative;
            padding: ${({ $isLayoutB }) => ($isLayoutB ? '0' : 'var(--spacing-12)')};
            width: 100%;
            min-height: 100vh;
            @media ${mediaQuery.isPhone} {
                padding: ${({ $isLayoutB }) => ($isLayoutB ? '0' : 'var(--spacing-12) var(--spacing-8)')};
            }
            ${({ $isLayoutB }) =>
                !$isLayoutB &&
                css`
                    ${({ theme }) => theme.contentBoxStyle};
                `};
        }

        .sport-matches-b {
            position: relative;
            z-index: 1;
            ${({ $isLayoutB }) =>
                $isLayoutB &&
                css`
                    padding: var(--spacing-12) var(--spacing-8);
                    ${({ theme }) => theme.contentBoxStyle};
                    width: 100%;
                    min-height: 100vh;
                `};
            @media ${mediaQuery.isPhone} {
                padding-bottom: 100px;

                ${({ $isLayoutB }) =>
                    $isLayoutB &&
                    css`
                        width: calc(100% + 1rem);
                        margin-left: -0.5rem;
                    `};
            }
        }

        .sport-sidebar {
            ${hideScrollBar};

            z-index: 5;
            position: sticky;
            top: ${({ $isLayoutB }) => ($isLayoutB ? '5rem' : '10px')};
            overflow-y: auto;
            height: 100vh;
            margin-top: var(--spacing-8);
        }
    }
    .hot-event-card {
        padding: var(--spacing-12);
    }
`;
