import { useEffect, useState } from 'react';
import {
    ContestDetail,
    ContestLegMarket,
    ContestListItem,
    ContestMarketOutcome,
} from '../../types/components/contests/types';
import { getContest, getContestsList } from '../../microservices/tournaments';
import { logger } from '../../services/logger';
import { useStore } from '../useStore';
import { sports } from '../../stores/sports';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

export function useFetchContests(userId?: string, type: Array<ContestListItem['type']> = []) {
    const [contests, setContests] = useState<ContestListItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const { rows } = await getContestsList(type);
                setContests(rows);
            } catch (error) {
                logger.error('useFetchContests', 'fetchData', error);
                setContests([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [userId]);

    return { isLoading, contests };
}

export function useFetchContest({ contestId }: { contestId: number }) {
    const [contest, setContest] = useState<ContestDetail>();
    const [isLoading, setIsLoading] = useState(false);
    const [, setEntries] = useStore(sports.contestSelections.entries);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            let contest = undefined as ContestDetail | undefined;

            try {
                if (!contestId) {
                    setIsLoading(false);
                    return null;
                }

                contest = await getContest(contestId);

                setIsLoading(false);

                if (contest) {
                    setContest(contest);
                    setEntries(contest.entries);
                }
            } catch (error) {
                logger.error('useFetchContest', 'fetchData', error);
                setIsLoading(false);
                setContest(undefined);
            }
        };

        fetchData();
    }, []);
    return { isLoading, contest };
}

export function useContestPreSelectionState() {
    const [initialSelections, setInitialSelections] = useStore(sports.contestSelections.initialSelections);
    const [selections, setSelections] = useStore(sports.contestSelections.selections);
    const [activeEntry, setActiveEntry] = useStore(sports.contestSelections.activeEntry);
    const [entries, setEntries] = useStore(sports.contestSelections.entries);

    const isResubmit =
        activeEntry &&
        !isEmpty(initialSelections[activeEntry.entry_id]) &&
        !isEqual(initialSelections[activeEntry.entry_id], selections[activeEntry.entry_id]);

    function addSelection(market: ContestLegMarket, outcome: ContestMarketOutcome) {
        if (!activeEntry || ['WON', 'ELIMINATED', 'DROPPED_OUT'].includes(activeEntry.status)) {
            return;
        }

        const { entry_id } = activeEntry;
        let selectionsByEntry = selections[entry_id] ?? [];

        if (isEqual(initialSelections[entry_id], selectionsByEntry)) {
            selectionsByEntry = [];
        }

        const selectionIndex = selectionsByEntry.findIndex(
            (selection) => selection.market.market_id === market.market_id,
        );

        if (selectionIndex > -1) {
            selectionsByEntry[selectionIndex].selectedOutcome = outcome;
        } else {
            selectionsByEntry.push({ market, selectedOutcome: outcome });
        }

        setSelections({ ...selections, [entry_id]: selectionsByEntry });
    }

    function removeSelection(market: ContestLegMarket) {
        if (activeEntry) {
            const selectionByEntryId = selections[activeEntry.entry_id].filter(
                (selection) => selection.market.market_id !== market.market_id,
            );

            const newStateSelection = {
                ...selections,
                [activeEntry.entry_id]: selectionByEntryId,
            };

            if (isEmpty(selectionByEntryId)) {
                clearSelections();
            } else {
                setSelections(newStateSelection);
            }
        }
    }

    function clearSelections() {
        if (activeEntry) {
            setSelections({
                ...selections,
                [activeEntry.entry_id]: cloneDeep(initialSelections[activeEntry.entry_id]) || [],
            });
        }
    }

    function getSelectionsByMarketId(marketId: number) {
        if (activeEntry) {
            return selections[activeEntry.entry_id]?.find(({ market }) => market.market_id === marketId);
        }
    }

    function getSelections(): { market: ContestLegMarket; selectedOutcome: ContestMarketOutcome }[] {
        if (activeEntry) {
            return selections[activeEntry.entry_id] || [];
        }

        return [];
    }

    function getPredictionsForSend() {
        if (activeEntry) {
            const selectionsForSend = {};
            selections[activeEntry.entry_id].forEach((selection) => {
                selectionsForSend[selection.market.market_id] = selection.selectedOutcome.outcome_id;
            });

            return [
                {
                    entry_id: activeEntry.entry_id.toString(),
                    selections: selectionsForSend,
                },
            ];
        }
    }

    return {
        activeEntry,
        setActiveEntry,
        entries,
        setEntries,
        setInitialSelections,
        selections,
        setSelections,
        addSelection,
        removeSelection,
        getSelectionsByMarketId,
        getSelections,
        clearSelections,
        isResubmit,
        getPredictionsForSend,
    };
}
