/* eslint-disable @typescript-eslint/ban-ts-comment */
import clone from 'lodash/clone';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import React, { useState } from 'react';
import { translate } from '../../../../services/translate';
import { stores, useStoreWithSelector } from '../../../../stores';
import SportOddsLoad from '../../odds/load/SportOddsLoad';
import Wrapper from './styles';
import { ODDS_STATUS } from '../../../../services/sports/constants';
import UiButton from '../../../ui/button/UiButton';
import { useOddsByOutcomeIds } from '../../../../services/sports/hooks';
import { useSportsUserSettings } from '../../../../services/sports/user-settings';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { useStore } from '../../../../hooks/useStore';

interface Props {
    marketInfo: BetSlipMinimalMarket;
    isDisabled: boolean;
}

export default function SportBetslipOutcomes({ marketInfo, isDisabled }: Props) {
    const [selectedOutcomeId] = useStoreWithSelector(
        stores.sports.betSlipMarketIdToOutcomeId,
        (state) => state[marketInfo.id],
        [marketInfo.id],
    );
    const [isExpanded, setIsExpanded] = useState(false);
    const [oddsByOutcomeId] = useOddsByOutcomeIds(
        marketInfo.outcomes.map((x) => x.id),
        [marketInfo.id],
    );
    const [betSlipMarketIdToOutcomeId] = useStore(stores.sports.betSlipMarketIdToOutcomeId);
    const { isAmericanLayout } = useSportsUserSettings({ sportCategoryId: marketInfo.sport_category_id });

    const useCollapsibleViewTypes = [
        'player_outright',
        'team_outright',
        'exact_score',
        'ht_ft',
        'generic',
        'variable',
        'generic_line',
    ];

    const initialOutcomesAmountVisibleInListView = 1;
    let outcomes = clone(marketInfo.outcomes);
    const isListViewType = useCollapsibleViewTypes.includes(marketInfo.view_type);

    const hasExtraOutcomesToDisplay = marketInfo.outcomes.length > initialOutcomesAmountVisibleInListView;
    const isListViewExtensionToggleVisible = isListViewType && hasExtraOutcomesToDisplay;

    if (isListViewType && !isExpanded && isListViewExtensionToggleVisible) {
        outcomes = sortBy(outcomes, (outcome) => outcome.id !== selectedOutcomeId);
    }

    if (isListViewType) {
        outcomes = outcomes.slice(0, isExpanded ? undefined : initialOutcomesAmountVisibleInListView);

        const selectedOutcomeIds = values(betSlipMarketIdToOutcomeId);
        outcomes = outcomes.filter(
            (outcome) =>
                selectedOutcomeIds.includes(outcome.id) || oddsByOutcomeId[outcome.id]?.status === ODDS_STATUS.OPEN,
        );
    }

    return (
        <Wrapper $isListViewType={isListViewType}>
            {outcomes.map((outcome) => (
                <div className="outcome" key={outcome.id}>
                    <SportOddsLoad
                        isDisabled={isDisabled}
                        outcomeId={outcome.id}
                        market={marketInfo}
                        isBetslip
                        isOutcomeNameVisible={marketInfo.view_type !== 'bet_builder'}
                        isAmericanLayout={isAmericanLayout}
                        small
                    />
                </div>
            ))}

            {isListViewExtensionToggleVisible && (
                <UiButton size="small" block onClick={() => setIsExpanded(!isExpanded)} className="expand-button">
                    {isExpanded ? translate('Collapse', 'ui.sportsbook') : translate('Expand', 'ui.sportsbook')}
                </UiButton>
            )}
        </Wrapper>
    );
}
