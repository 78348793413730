import React, { useEffect, useState } from 'react';
import Wrapper from './styles';
import { depositWithProvider } from '../../../../../services/payments/payments';
import { PROVIDERS, PROVIDER_TYPE } from '../../../../../services/payments/types';
import { logger } from '../../../../../services/logger';
import PaymentProviderNotAvailable from '../../../provider-not-available/PaymentProviderNotAvailable';
import UiDotsLoader from '../../../../ui/dots-loader/UiDotsLoader';
import PaymentDepositProviderTruelayerScripts from './scripts/PaymentDepositProviderTruelayerScripts';
import { getProviderPaymentMethods } from '../../../../../microservices/payments';
import { translate } from '../../../../../services/translate';
import UiGroup from '../../../../ui/group/UiGroup';
import UiButton from '../../../../ui/button/UiButton';
import Ui2FormRadio from '../../../../ui-2/form/radio/Ui2FormRadio';
import Ui2Form from '../../../../ui-2/form/Ui2Form';
import { useForm } from 'react-hook-form';
import { isProduction } from '../../../../../services/util';
import { media } from '../../../../../stores/media/media';
import { useStore } from '../../../../../hooks/useStore';

interface Props {
    amount: number;
    deviceHash: string;
    onClose: () => void;
}

export default function PaymentDepositProviderTruelayer({ amount, deviceHash, onClose }: Props) {
    const [{ isPhone }] = useStore(media);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [hasScriptsLoaded, setHasScriptsLoaded] = useState(false);
    const [isAddingNewAccount, setIsAddingNewAccount] = useState(false);
    const [accounts, setAccounts] = useState<{ description?: string; id: string; number: string; extra?: any }[]>([]);
    const [isDepositing, setIsDepositing] = useState(false);

    const useFormMethods = useForm<{ methodId: string }>({
        defaultValues: {
            methodId: undefined,
        },
    });
    const { formState } = useFormMethods;

    useEffect(() => {
        loadAccounts();
    }, []);

    useEffect(() => {
        if (hasScriptsLoaded && isAddingNewAccount) {
            tryDeposit();
        }
    }, [hasScriptsLoaded, isAddingNewAccount]);

    async function loadAccounts() {
        setIsLoading(true);
        try {
            const accounts = await getProviderPaymentMethods(PROVIDERS.TRUELAYER, PROVIDER_TYPE.DEPOSIT);
            if (accounts.length) {
                setAccounts(accounts);
                setIsAddingNewAccount(false);
            } else {
                setIsAddingNewAccount(true);
            }
        } catch (error) {
            logger.error('PaymentDepositProviderTruelayer', 'loadAccounts', error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }

    async function tryDeposit() {
        setIsDepositing(true);
        setIsLoading(true);
        const { methodId } = useFormMethods.getValues();
        try {
            const paymentData = await depositWithProvider({
                amount,
                deviceHash,
                provider: PROVIDERS.TRUELAYER,
                methodId: !isAddingNewAccount ? methodId : undefined,
                providerParams: {},
            });
            const target = document.getElementById('truelayer-container-id');
            const payment = await Truelayer.Payment({
                ...paymentData,
                target: target!,
                production: isProduction(),
                onDone: () => target?.remove(),
                onAbort: () => target?.remove(),
            });
            payment.start();
        } catch (error) {
            logger.error('PaymentDepositProviderTruelayer', 'tryDeposit', error);
            setIsError(true);
        }
        setIsLoading(false);
    }

    if (isError) {
        return <PaymentProviderNotAvailable onBack={onClose} />;
    }

    return (
        <Wrapper>
            {isLoading && <UiDotsLoader />}
            {accounts.length > 0 && !isAddingNewAccount && !isDepositing && (
                <Ui2Form onSubmit={tryDeposit} useFormMethods={useFormMethods}>
                    <Ui2FormRadio
                        className="account-select"
                        error={formState.errors.methodId}
                        name="methodId"
                        options={accounts.map((account, index) => ({
                            label: account.description || account.number,
                            value: account.id,
                            defaultChecked: index === 0,
                        }))}
                        required
                    />

                    <UiGroup expand>
                        <UiButton
                            block={isPhone}
                            onClick={() => {
                                setIsAddingNewAccount(true);
                            }}
                        >
                            {translate('Add account', 'ui.account')}
                        </UiButton>

                        <UiButton type="button" isFormSubmitButton color="primary" block={isPhone}>
                            {translate('Continue', 'ui.account')}
                        </UiButton>
                    </UiGroup>
                </Ui2Form>
            )}
            {isDepositing && <div id="truelayer-container-id" />}
            <PaymentDepositProviderTruelayerScripts
                onError={() => setIsError(true)}
                onScriptsLoaded={() => setHasScriptsLoaded(true)}
            />
        </Wrapper>
    );
}

declare let Truelayer: {
    Payment: (...args: unknown[]) => Promise<{
        start: () => void;
    }>;
};
