import React, { Fragment } from 'react';
import { useStore } from '../../../hooks/useStore';
import { stores } from '../../../stores';
import routes from '../../../routes.json';
import { useLocation } from 'react-router';
import SeoDescription from '../description/SeoDescription';

export default function SeoFooter() {
    const [language] = useStore(stores.language);
    const location = useLocation();

    const pages = ['sport', 'casino', 'promotions', 'blog', 'virtual-sports'];

    function getPageTranslatedPrefix(prefix: string) {
        return routes?.[prefix]?.[language];
    }

    return (
        <>
            {pages.map((page) => (
                <Fragment key={page}>
                    {getPageTranslatedPrefix(page) &&
                        location.pathname.split('/')[2].includes(getPageTranslatedPrefix(page)) && (
                            <SeoDescription snippetKey={`seo.description.${page}`} />
                        )}
                </Fragment>
            ))}
        </>
    );
}
