import styled, { css } from 'styled-components';
import { mediaQuery } from '../../../styles/utils';

const $isAmericanLayoutTopMarkets = ({ $isAmericanLayoutTopMarkets }: Props) =>
    $isAmericanLayoutTopMarkets &&
    css`
        height: var(--odds-american-height-mobile);
    `;

const isActive = ({ $isActive }: Props) =>
    $isActive &&
    css`
        color: var(--odds-text-active) !important;
        border: var(--odds-border-active) !important;
        background-color: var(--odds-bg-active) !important;
        &:hover {
            color: var(--odds-text-active) !important;
            border: var(--odds-border-active) !important;
        }
        .outcome-line {
            color: var(--odds-line-text-active) !important;
        }
    `;

// TODO: add global color for disabled odds?
const isDisabled = ({ $isDisabled }: Props) =>
    $isDisabled &&
    css`
        opacity: 0.65;
        cursor: not-allowed;
        color: var(--font-color-subtle) !important;
        span.value {
            opacity: 0.4;
        }
    `;

const oddsIncreased = ({ $oddsIncreased }: Props) =>
    $oddsIncreased &&
    css`
        color: var(--odds-increase) !important;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}

        &:after {
            content: '▲';
            position: absolute;
            top: 0.1rem;
            right: 0.1rem;
            font-size: 0.5rem;
        }
    `;

const oddsDecreased = ({ $oddsDecreased }: Props) =>
    $oddsDecreased &&
    css`
        color: var(--odds-decrease) !important;
        font-weight: var(--font-weight-bold);
        ${({ theme }) => theme.optimizeFontRendering}
        &:after {
            content: '▼';
            position: absolute;
            bottom: 0.1rem;
            right: 0.1rem;
            font-size: 0.5rem;
        }
    `;

const oddsChangedAndActive = ({ $oddsDecreased, $oddsIncreased, $isActive }: Props) =>
    ($oddsDecreased || $oddsIncreased) &&
    $isActive &&
    css`
        color: var(--odds-text-active) !important;
        border: var(--odds-border-active) !important;
        background-color: var(--odds-bg-active) !important;
    `;

const oddsTooBig = ({ $oddsTooBig }: Props) =>
    $oddsTooBig &&
    css`
        font-size: 0.75rem;
    `;

const small = ({ small }: Props) =>
    small &&
    css`
        height: 1.55rem;
        font-size: 0.75rem;
    `;

type Props = {
    $isActive?: boolean;
    $isAmericanLayoutTopMarkets?: boolean;
    $isCompactViewEnabled?: boolean;
    $isDisabled?: boolean;
    $isPromotion?: boolean;
    light?: boolean;
    $lineTooBig?: boolean;
    $oddsDecreased?: boolean;
    $oddsIncreased?: boolean;
    $oddsTooBig?: boolean;
    small?: boolean;
};

export default styled.div<Props>`
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .odds-outcome {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        padding: 0.3rem;

        color: var(--odds-outcome-text);
        font-size: 0.7rem;
        text-align: center;
        line-height: 1;
        user-select: none;

        @media ${mediaQuery.isPhone} {
            min-height: ${({ theme }) => theme.odds.outcomeHeight};
        }

        .outcome-name {
            max-width: 98%;
            width: 98%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .raw-line {
            color: var(--odds-line-text);
            font-weight: var(--font-weight-bold);
            white-space: nowrap;
            ${({ theme }) => theme.optimizeFontRendering};
            margin-left: 0.5rem;
            @media ${mediaQuery.isPhone} {
                font-size: 0.875rem;
            }
        }
    }

    .odds-button {
        position: relative;
        display: flex;
        align-items: center;

        background-color: var(--odds-bg);
        border: var(--odds-border);
        border-radius: var(--odds-radius);
        font-size: 0.875rem;
        color: var(--odds-text-color);
        font-weight: var(--odds-text-weight);
        line-height: 1;
        cursor: pointer;
        outline: none;
        user-select: none;
        width: 100%;
        height: var(--odds-height);
        padding: 0;

        .odds-locked.with-odd-value {
            margin-right: 0.25rem;
            margin-top: -0.125rem;
        }

        @media ${mediaQuery.isPhone} {
            font-size: var(--odds-font-size-mobile);
        }

        ${({ $isCompactViewEnabled }) =>
            $isCompactViewEnabled &&
            css`
                font-size: 0.75rem;
                height: var(--odds-height-mobile);
            `};

        @media ${mediaQuery.isPhone} {
            ${$isAmericanLayoutTopMarkets};
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                @media ${mediaQuery.isDesktop}, ${mediaQuery.isLaptop} {
                    background-color: var(--odds-bg-hover);
                    border: var(--odds-border-hover) !important;
                    color: var(--odds-text-hover) !important;
                    .outcome-line {
                        color: var(--odds-line-text-hover);
                    }
                }
                .odds-locked svg {
                    display: block;
                }
            }
        }

        &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
            pointer-events: none;
        }

        .odds-value {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;

            letter-spacing: 0.5px;
            @media ${mediaQuery.isPhone} {
                flex-direction: ${({ $isAmericanLayoutTopMarkets }) => ($isAmericanLayoutTopMarkets ? 'column' : '')};
                justify-content: ${({ $isAmericanLayoutTopMarkets }) => ($isAmericanLayoutTopMarkets ? 'center' : '')};
            }
        }

        .outcome-line {
            display: inline-block;

            font-size: ${({ $lineTooBig, $isAmericanLayoutTopMarkets }) =>
                $lineTooBig && !$isAmericanLayoutTopMarkets ? '80%' : '100%'};

            color: var(--odds-line-text);
            font-weight: var(--font-weight-bold);
            ${({ theme }) => theme.optimizeFontRendering};
            text-align: right;
            white-space: nowrap;

            margin-right: var(--spacing-12);

            @media ${mediaQuery.isPhone} {
                text-align: ${({ $isAmericanLayoutTopMarkets }) => ($isAmericanLayoutTopMarkets ? 'center' : '')};

                margin-bottom: ${({ $isAmericanLayoutTopMarkets }) =>
                    $isAmericanLayoutTopMarkets ? 'var(--spacing-8)' : ''};
                margin-right: ${({ $isAmericanLayoutTopMarkets }) => ($isAmericanLayoutTopMarkets ? 0 : '1rem')};
            }
        }

        .odds-locked svg {
            position: absolute;
            top: 0.1rem;
            right: 0.1rem;
        }

        ${oddsIncreased};
        ${oddsDecreased};
        ${oddsChangedAndActive}
        ${isDisabled};
        ${isActive};
        ${oddsTooBig};
        ${small};
    }
`;
