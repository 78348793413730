import { getStoreValue } from '../stores/store/utils';
import { environment } from '../stores/environment/environment';
import { isFeatureAvailable } from './feature';
import { FEATURE } from './types';
import { BET_TYPE } from './sports/types';
import { isTestUser } from './user';
import uniq from 'lodash/uniq';
import { stores } from '../stores';
import { isLocalDevelopment, isStage } from './environment';

export const getIsBetbuilderMatch = ({
    betbuilder_id,
    openbet_id,
}: {
    betbuilder_id: number | null;
    openbet_id: string | null;
}) => {
    return Boolean(isFeatureAvailable(FEATURE.OPENBET_BETBUILDER) ? openbet_id : betbuilder_id);
};

export function isBetBuilderFeatureAvailable() {
    const { BETBUILDER_WIDGET_URL, BETBUILDER_WIDGET_KEY } = getStoreValue(environment);
    return isFeatureAvailable(FEATURE.BETBUILDER) && Boolean(BETBUILDER_WIDGET_URL && BETBUILDER_WIDGET_KEY);
}

export function isOpenbetAvailable() {
    return isFeatureAvailable(FEATURE.OPENBET_BETBUILDER) && (isTestUser() || isLocalDevelopment() || isStage());
}

export function addBetslipErrorForMatch(matchId: string, error: string) {
    stores.sports.customBetbuilder.betbuilderErrorsByMatchId.set((state) => {
        state[matchId] = uniq((state[matchId] || []).concat(error));
    });
}

function removeBetslipErrorForMatch(matchId: string, error: string) {
    stores.sports.customBetbuilder.betbuilderErrorsByMatchId.set((state) => {
        state[matchId] = (state[matchId] || []).filter((existingError) => existingError !== error);
    });
}

export function removeAllBetslipErrorsForMatch(errorsToRemove: string[]) {
    const betbuilderErrorsByMatchId = getStoreValue(stores.sports.customBetbuilder.betbuilderErrorsByMatchId);
    Object.keys(betbuilderErrorsByMatchId).forEach((matchId) =>
        errorsToRemove.forEach((error) => removeBetslipErrorForMatch(matchId, error)),
    );
}

export const isOpenbetComboBetslip = (betType: BET_TYPE): boolean => {
    return isOpenbetAvailable() && betType === BET_TYPE.COMBO;
};
